// import UI componented
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Menu,
  Icon,
  Dropdown,
  Divider,
  Popup,
  Label
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../framework/internal_page';

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";

export default function ExportForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isReady, setIsReady] = useState<boolean>(true);

  const [options, setOptions] = useState<any>({
    fromDate: null,
    toDate: null,
    status: null,
    paidOut: null,
    caregiverUuid: null,
    serviceRequesterUuid: null,
  })

   // states
   interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const exportBookings = async () => {
    setIsReady(false);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.lang = i18n.language;
    
    EnsanCareLib.fromDate = options.fromDate;
    EnsanCareLib.toDate = options.toDate;
    EnsanCareLib.status = options.status;
    EnsanCareLib.paidOut = options.paidOut;
    EnsanCareLib.caregiverUuid = options.caregiverUuid;
    EnsanCareLib.serviceRequesterUuid = options.serviceRequesterUuid;

    var res:any = await EnsanCareLib.exportBookings();
    
    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => exportBookings()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => exportBookings()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
      setModal({
        visible: true,
        title: t('g.completed'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.download'),
            onClick: () => window.open(res.data.link, '_self')
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }
    
    setIsReady(true);
    return null;
    
  }

  return (
    <InternalPage >
      <Modal
        size='mini'
        onClose={() => setModal(null)}
        open={(modal && modal.visible) ? true : false}
        header={modal ? modal.title : null}
        content={modal ? modal.message : null}
        actions={modal?.action||['Ok']}
      />
      
      <Header
        as='h1'
        content={t('s.bookings.export')}
      />

      <Form>
        <Form.Field>
          <Form.Input
            label={t('s.caregivers.caregiverUuid')}
            placeholder={t('g.typeHere')}
            onChange={(e, data) => setOptions({
              ...options,
              caregiverUuid: data.value
            })}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={t('s.serviceRequesters.serviceRequesterUuid')}
            placeholder={t('g.typeHere')}
            onChange={(e, data) => setOptions({
              ...options,
              serviceRequesterUuid: data.value
            })}
          />
        </Form.Field>
        <Form.Field>
          <Popup 
            trigger={
              <Form.Input
                fluid
                label={t('g.from')}
                placeholder={"2001-09-04"}
                onChange={(e,data) => {
                  if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {

                    setOptions({
                      ...options,
                      fromDate: data.value
                    })
                  } 
                  else {

                    setOptions({
                      ...options,
                      fromDate: null
                    })
                  }
                }}
              />
            }
            header='input format'
            content='example: 2001-09-04'
            on={'hover'}
          />
        </Form.Field>
        <Form.Field>
          <Popup 
            trigger={
              <Form.Input
                fluid
                label={t('g.to')}
                placeholder={"2001-09-04"}
                onChange={(e,data) => {
                  if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {

                    setOptions({
                      ...options,
                      toDate: data.value
                    })
                  } 
                  else {

                    setOptions({
                      ...options,
                      toDate: null
                    })
                  }
                }}
              />
            }
            header='input format'
            content='example: 2001-09-04'
            on={'hover'}
          />
        </Form.Field>
        <Form.Field >
          <Form.Dropdown 
            label={t('g.paid')}
            selection
            fluid
            clearable
            placeholder={t('g.paid')}
            options={[
              {key: 0, value: 'y', text: t('g.paid')},
              {key: 1, value: 'n', text: t('g.unpaid')},
            ]}
            onChange={(e, data) => {
              if(data.value && typeof data.value === 'string') {

                setOptions({
                  ...options,
                  paidOut: data.value
                })
              } 
              else {

                setOptions({
                  ...options,
                  paidOut: null
                })
              }
            }}
          />
        </Form.Field>
        <Form.Field >
          <Form.Dropdown 
            label={t('g.status')}
            selection
            fluid
            clearable
            placeholder={t('g.status')}
            options={[
              {key: 0, value: 'pending', text: t('s.bookings.status.pending')},
              {key: 1, value: 'scheduled', text: t('s.bookings.status.scheduled')},
              {key: 2, value: 'in progress', text: t('s.bookings.status.in progress')},
              {key: 3, value: 'cancelled', text: t('s.bookings.status.cancelled')},
              {key: 4, value: 'completed', text: t('s.bookings.status.completed')}
            ]}
            onChange={(e, data) => {
              if(data.value && typeof data.value === 'string') {

                setOptions({
                  ...options,
                  status: data.value
                })
              } 
              else {

                setOptions({
                  ...options,
                  status: null
                })
              }
            }}
          />
        </Form.Field>
      </Form>

      <Header
        as='h1'
        content={t('g.fileExtension')}
      />

      <Form>
        <Form.Field>
          <Form.Radio
            label='Microsoft Excel (.xlsx)'
            checked={true}
          />
          <Form.Radio
            label='Comma-separated values (.csv)'
            disabled
          />
          <Form.Radio
            label='Adobe PDF (.pdf)'
            disabled
          />
          <Form.Radio
            label='Microsoft Access (.accdb)'
            disabled
          />
           <Form.Radio
            label='SQL (.sql)'
            disabled
          />
        </Form.Field>
      </Form>

      <Divider hidden />

      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="left">
            <Button
              icon='arrow left'
              labelPosition='left'
              content={t('g.cancel')}
              onClick={() => history.push('./')}
            />
          </Grid.Column>
          <Grid.Column floated="right" textAlign="right">
            <Button
              primary
              icon='download'
              labelPosition='right'
              content={t('g.download')}
              loading={!isReady}
              onClick={() => exportBookings()}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </InternalPage>
    
  );
}

