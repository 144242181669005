import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../framework/internal_page';

import CaregiverGeneral from './general';
import CaregiverProfessional from './professional';
import CaregiverAccess from './access';
import CaregiverDocuments from './documents';
import CaregiverSchedule from './schedule';
import CaregiverServices from './services';

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";
import ECCaregivers from '../util/EnsanCareLib/funcs/caregivers';

export default function CaregiverForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [section, setSection] = useState<'general'|'access'|'professional'|'documents'|'schedule'|'services'>('access');
 
  const {caregiver_uuid} = useParams<{caregiver_uuid:string}>();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [caregiver, setCaregiver] = useState<any>(null);

  const portal = config.dev ? config.d.portal : config.p.portal;

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getCaregiver();
  }, [])

  const getCaregiver = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.caregiverUuid = caregiver_uuid;

    var res:any = await EnsanCareLib.getCaregiver();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiver()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiver()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success') {
      if(res.data) {


        setCaregiver(res.data);
      } 
    }
    
    setIsLoading(false);
    return null;
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />
    
    <Header
      as='h1'
      content={(caregiver) 
        ? ECCaregivers.buildName(
          i18n.language,
          caregiver.name_prefix,
          caregiver.first_name_local,
          caregiver.middle_name_local,
          caregiver.last_name_local) 
        : '...'
      }
    />

    <Menu stackable pointing secondary>
      <Menu.Item
        name={t('s.profile.accessInformation')}
        active={section === 'access'}
        onClick={() => setSection('access')}
      />
      <Menu.Item
        name={t('g.generalInformation')}
        active={section === 'general'}
        onClick={() => setSection('general')}
      />
      <Menu.Item
        name={t('s.profile.professionalInformation')}
        active={section === 'professional'}
        onClick={() => setSection('professional')}
      />
      <Menu.Item
        name={t('g.documents')}
        active={section === 'documents'}
        onClick={() => setSection('documents')}
      />
      <Menu.Item
        name={t('g.schedule')}
        active={section === 'schedule'}
        onClick={() => setSection('schedule')}
      />
      <Menu.Item
        name={t('s.services.title')}
        active={section === 'services'}
        onClick={() => setSection('services')}
      />
      <Menu.Item
        name={t('s.caregivers.updateRequests')}
        onClick={(() => window.open(
          `${(config.dev) ? config.d.portal : config.p.portal}caregivers/update-requests/?searchTerm=${caregiver_uuid}`, 
          '_self'
        ))}
      />
    </Menu>

    {(!section || section === 'general') && <CaregiverGeneral />}
    {(section === 'access') && <CaregiverAccess />}
    {(section === 'professional') && <CaregiverProfessional />}
    {(section === 'documents') && <CaregiverDocuments />}
    {(section === 'schedule') && <CaregiverSchedule />}
    {(section === 'services') && <CaregiverServices />}
  </InternalPage>
}