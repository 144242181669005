 /*
  *
  * PROJECT ENSAN CARE
  * Developed by:  3WebBox LLC - 2022
  * 
  * Disclaimor: Please make sure to read related documentation before
  * making any changes to the code. Modify the code under your own
  * responsibility. for help please contact 3WebBox.
  * 
  * https://3webbox.com  : support@3webbox.com
  * 
  * 
  */

export default {
  checkPermission: (
    typeDetails, permissionsToCheck
  ) => {
    if(!typeDetails || !permissionsToCheck) return false;

    if(typeof typeDetails !== 'object') {
      console.warn('typeDetails is not an object');
      return false;
    }

    if(typeof permissionsToCheck !== 'object') {
      console.warn('permissionsToCheck must be an array');
      return false;
    }

    var results = false;

    Object.keys(typeDetails).map((item, key) => {
      permissionsToCheck.map( (permission, key) => {
        if(item === permission) {
          if(typeDetails[item] === 'y') results = true;
        }
      })
    });

    return results;
  },
}