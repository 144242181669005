import { useState } from 'react';
import { useHistory, useParams } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Image,
  Dropdown,
  Divider,
  Menu
} from 'semantic-ui-react';

// import SVG
import Logo from '../../assets/logo-icon.png';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../../framework/internal_page';

// screens
import BookingSessionDetails from './details';
import BookingSessionMedicalReport from './medical_report';

export default function BookingSessionNavigation() {
  const portal = config.dev ? config.d.portal : config.p.portal;
  
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {booking_uuid, session_id} = useParams<any>();

  const [screen, setScreen] = useState<'details'|'medical-report'>('details');

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string
  }

  const [modal, setModal] = useState<modalType|null>(null);

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />
    
    <Header
      as='h1'
      content={t('s.bookings.sessions.title')}
      subheader={`#${booking_uuid} - Session # ${session_id}`}
    />

    <Menu pointing secondary>
      <Menu.Item
        content={t('g.generalInformation')}
        onClick={(() => setScreen('details'))}
        active={screen === 'details'}
      />
      <Menu.Item
        content={t('s.bookings.sessions.additionalCharges.title')}
        onClick={(() => window.open(
          `${portal}bookings/additional-charges/?searchTerm=${booking_uuid}`, 
          '_self'
        ))}
      />
      <Menu.Item
        content={t('s.bookings.sessions.medicalReport.title')}
        onClick={(() => setScreen('medical-report'))}
        active={screen === 'medical-report'}
      />
    </Menu>

    {(!screen || screen === 'details') && <BookingSessionDetails />}
    {(screen === 'medical-report') && <BookingSessionMedicalReport />}
  </InternalPage>
}