import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// import config
import { config } from '../config';

import EnsanCareAPI from '../util/EnsanCareLib/EnsanCareAPI';
import ECAdmin from '../util/EnsanCareLib/funcs/admin';

// import component
import { Label, Menu } from 'semantic-ui-react';

// translation
import { useTranslation } from "react-i18next";

const SideMenu = (props:any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  let userProfile:any = localStorage.getItem('user_profile');
  let userDetails:any = localStorage.getItem('user_details');

  try {
    userProfile = JSON.parse(userProfile);
    userDetails = JSON.parse(userDetails);
  }
  catch(e) {
    console.error(
      'Failed to parse userPRofile or userDetails object',
      e
    );
  }
  
  const [profile, setUserProfile] = useState<{[key: string]: any}|null>(null);

  // Menu
  let mainMenu = [
    {
      permissions: ['all'],
      title:  t('s.default.title'),
      onClick: () => history.replace('/'),
    },
    {
      permissions: ['all'],
      title:  t('s.profile.title'),
      onClick: () => history.push('/management/show/' + userProfile?.user_uuid)
    },
    {
      permissions: ['all'],
      title:  t('s.auth.logout'),
      onClick: () => history.push('/logout')
    }
  ];

  let caregiversMenu = [
    {
      permissions: ['is_admin', 'can_access_caregivers'],
      title: t('s.caregivers.title'),
      onClick: () => history.replace('/caregivers'),
    },
    {
      permissions: ['is_admin', 'can_access_caregivers'],
      title: t('s.caregivers.updateRequests'),
      onClick: () => history.replace('/caregivers/update-requests'),
    },
    {
      permissions: ['is_admin', 'can_access_caregivers'],
      title: t('s.caregivers.referrals'),
      onClick: () => history.replace('/caregivers/referral'),
    }
  ];

  let serviceRequestersMenu = [
    {
      permissions: ['is_admin', 'can_access_service_requesters'],
      title: t('s.serviceRequesters.title'),
      onClick: () => history.replace('/service-requesters'),
    }
  ];

  let bookingsMenu = [
    {
      permissions: ['is_admin', 'can_access_bookings'],
      title: t('s.bookings.title'),
      onClick: () => history.replace('/bookings'),
    },
    {
      permissions: ['is_admin', 'can_access_bookings'],
      title: t('s.bookings.sessions.additionalCharges.title'),
      onClick: () => history.replace('/bookings/additional-charges'),
    }
  ];
  
  let systemMenu = [
    {
      permissions: ['is_admin', 'can_access_notifications'],
      title:  t('s.notifications.title'),
      onClick: () => history.replace('/notifications'),
    },
    {
      permissions: ['is_admin', 'can_access_staff'],
      title:  t('s.profile.management'),
      onClick: () => history.replace('/management'),
    },
    {
      permissions: ['is_admin'],
      title:  t('s.settings.title'),
      onClick: () => history.replace('/settings'),
    }
  ];
  
  return <>
    <Menu vertical fluid secondary>
      <Menu.Item>
        <Menu.Header content={t('g.general')} />
        <Menu.Menu>
          {mainMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!userDetails) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            if(
              !item.permissions.includes( userDetails ) &&
              !item.permissions.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      
      {(userProfile?.type === 'management') ?
      <Menu.Item>
        <Menu.Header content={t('s.serviceRequesters.title')} />
        <Menu.Menu>
          {serviceRequestersMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!userDetails) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            var adminPermissionCheck = ECAdmin.checkPermission(
              userDetails,
              item.permissions
            );

            if(
              !adminPermissionCheck &&
              !item.permissions.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      : null }
      
      {(userProfile?.type === 'management') ?
      <Menu.Item>
        <Menu.Header content={t('s.caregivers.title')} />
        <Menu.Menu>
          {caregiversMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!userDetails) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            var adminPermissionCheck = ECAdmin.checkPermission(
              userDetails,
              item.permissions
            );

            if(
              !adminPermissionCheck &&
              !item.permissions.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      : null }
      
      {(userProfile?.type === 'management') ?
      <Menu.Item>
        <Menu.Header content={t('s.bookings.title')} />
        <Menu.Menu>
          {bookingsMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!userDetails) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            var adminPermissionCheck = ECAdmin.checkPermission(
              userDetails,
              item.permissions
            );

            if(
              !adminPermissionCheck &&
              !item.permissions.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      : null }
      
      {(userProfile?.type === 'management') ?
      <Menu.Item>
        <Menu.Header content={t('g.administration')} />
        <Menu.Menu>
          {systemMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!userDetails) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            var adminPermissionCheck = ECAdmin.checkPermission(
              userDetails,
              item.permissions
            );

            if(
              !adminPermissionCheck &&
              !item.permissions.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      : null }
    </Menu>

    <div style={{padding: 10, fontSize: 9, color: '#999'}}>
      {config.version} <br />
      {t('g.rights')}
    </div>

  </>
}

export default SideMenu;