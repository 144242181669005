import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Menu,
  Dropdown,
  Icon
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../../framework/internal_page';

import EnsanCareAPI from "../../util/EnsanCareLib/EnsanCareAPI";
import ECCaregivers from '../../util/EnsanCareLib/funcs/caregivers';


const CaregiverRequest=(props:any) => {
  const { t, i18n }=useTranslation();
  const history=useHistory();

  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal]=useState<modalType|null>(null);

  const [isProcessing, setIsProcessing]=useState<boolean>(false); 

  const [hideComponent, setHideComponent]=useState<boolean>(false); 

  const [actionReqeusted, setActionRequested]=useState<boolean>(true);
  const [actionReqeustedType, setActionRequestedType]=useState<string>();

  const _handleUpdateRequest=async (action:'approve'|'reject', service_id:number, caregiver_uuid:string) => {
    if(isProcessing) return null;
    setIsProcessing(true);

    var EnsanCareLib:any=new EnsanCareAPI;
    EnsanCareLib.authToken=localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI=(config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.serviceId=service_id;
    EnsanCareLib.caregiverUuid=caregiver_uuid;
    EnsanCareLib.actionType=props.deleteRequested ? 'delete' : 'approve' ;

    var res:any=null;

    if(action === 'approve') {
      res=await EnsanCareLib.approveCaregiverServices();
    }

    else if(action === 'reject') {
      res=await EnsanCareLib.rejectCaregiverServices();
    }
    if (!res) {
      if(config.dev) console.error('Error with the response');
    }

    else if (res.status === "fail") {
      props.setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });
    }

    else if (res.status === "success") {
      if(action === 'approve') {
        setHideComponent(true);
      }
      else if(action === 'reject') {
        setHideComponent(true);
      }
      else {
        if(config.dev) console.warn('Provided action is not supported');
      }
    }

    return null;
  }

  const _renderBtns=() => {
    if(actionReqeusted) {
      return <>
        <Button
          color='green'
          size={'tiny'}
          content={t('g.approve')}
          onClick={() => {
            setActionRequested(false);
            setActionRequestedType("approve")
          }}
        />
        <Button
          color='red'
          size={'tiny'}
          content={t('g.reject')}
          onClick={() => {
            setActionRequested(false);
            setActionRequestedType("reject")
          }}
        />
      </>
    }
    else {
      return <>
        <Button
          color='yellow'
          size={'tiny'}
          content={t('g.confirm')}
          onClick={() => {
            if (
              actionReqeustedType === 'approve' ||
              actionReqeustedType === 'reject'
            ) {
              _handleUpdateRequest(
                actionReqeustedType, 
                props.serviceId, 
                props.caregiverUuid
              )
            } 
            
            else {
              if(config.dev) console.error("control type was not choosen");
              return null;
            }
          }}
        />
        <Button
          size={'tiny'}
          content={t('g.cancel')}
          onClick={() => setActionRequested(true)}
        />
      </>
    } 
  }

  if (hideComponent) return null;

  return (
    <Table.Row>
      <Table.Cell width={2}>
        {props.caregiverShortUuid}
      </Table.Cell>
      <Table.Cell width={3}>
        <a target="_blank" href={props.hrefURL}>
          <div><strong>{props.caregiverName}</strong></div>
        </a>
      </Table.Cell>
      <Table.Cell width={2}>
        {props.type}
      </Table.Cell>
      <Table.Cell width={6}>
        <div><strong>{props.updateTitle}</strong></div>
      </Table.Cell>
      <Table.Cell width={3}>
        {_renderBtns()}
      </Table.Cell>
    </Table.Row>
  )
}

const CaregiversServicesUpdateRequests=(props:any) => {
  const { t, i18n }=useTranslation();
  const history=useHistory();

  const [servicesUpdates, setServicesUpdates]=useState<any[]|null>(null); 

  const [isLoading, setIsLoading]=useState<boolean>(false); 

  const [selectedResPerPage, setSelectedResPerPage]=useState<number>(25);
  const [selectedPageNumber, setSelectedPageNumber]=useState<number>(1);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal]=useState<modalType|null>(null);

  useEffect(() => {
    getCaregiverServices();
  }, []);

  useEffect(() => {
    getCaregiverServices();
  }, [
    selectedResPerPage,
    selectedPageNumber,
    props.searchTerm
  ]);

  const getCaregiverServices=async (page:number=1) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any=new EnsanCareAPI;
    EnsanCareLib.authToken=localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI=(config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang=i18n.language;
    EnsanCareLib.resPerPage=selectedResPerPage;
    EnsanCareLib.currentPage=page||selectedPageNumber;
    EnsanCareLib.pending='y';

    if(props.searchTerm) EnsanCareLib.searchTerm=props.searchTerm;

    var res:any=await EnsanCareLib.getCaregiverServices();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiverServices()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiverServices()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
      setServicesUpdates(res.data);
    }
    
    setIsLoading(false);
    return null;
  }

  const _renderLoadingRow = () => {
    return <Table.Row>
      <Table.Cell colSpan={7} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderRows = () => {
    if(!servicesUpdates || servicesUpdates.length < 1) { 
      return <Table.Row disabled>
        <Table.Cell>
          {t('g.noResults')}
        </Table.Cell>
      </Table.Row>
    };
    
    return servicesUpdates.map( (servicesUpdate, key) => {

      var caregiverShortUuid = servicesUpdate.caregiver_uuid?.substring(
        servicesUpdate.caregiver_uuid.length - 10, servicesUpdate.caregiver_uuid.length
      );

      return <CaregiverRequest 
        hrefURL={"./" + servicesUpdate.caregiver_uuid}
        caregiverName={ECCaregivers.buildName(
          i18n.language, 
          servicesUpdate.name_prefix, 
          servicesUpdate.caregiver_first_name_local, 
          servicesUpdate.caregiver_middle_name_local, 
          servicesUpdate.caregiver_last_name_local
        )}
        caregiverShortUuid={`# ${caregiverShortUuid}`}
        type={!servicesUpdate.delete_requested_at ? t('g.add') : t('g.delete')}
        deleteRequested={servicesUpdate.delete_requested_at}
        updateTitle={servicesUpdate.title}
        serviceId={servicesUpdate.service_id}
        caregiverUuid={servicesUpdate.caregiver_uuid}
        setModal={setModal}
      />
    })
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />

    <Table color='blue' striped selectable>
      <Table.Header>
        <Table.HeaderCell width={2}>{t('g.uuid')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('s.profile.caregiver')}</Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('g.type')}</Table.HeaderCell>
        <Table.HeaderCell width={6}>{t('g.update')} {t('g.value')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('g.control')}</Table.HeaderCell>
      </Table.Header>
      <Table.Body columns={4}>
        {_renderRows()}
        {isLoading && _renderLoadingRow()}
      </Table.Body>
    </Table>
    <Grid verticalAlign='middle'>
      <Grid.Row columns={3}>
        <Grid.Column>
          {t('g.resPerPage')}
          <Dropdown
            style={{
              marginLeft: '10px'
            }}
            inline
            options={[
              {key: 0, value: 25, text: "25"},
              {key: 1, value: 50, text: "50"},
              {key: 2, value: 100, text: "100"},
              {key: 3, value: 500, text: "500"},
            ]}
            defaultValue={selectedResPerPage}
            onChange={(e, data) => {

              if(typeof data.value === 'number'){
                return setSelectedResPerPage(data.value); 
              }

              if(typeof data.value === 'string' && /^[0-9]/.test(data.value)){
                return setSelectedResPerPage(parseInt(data.value));
              }
              
              if(config.dev) console.warn("result per page type is not valid", typeof data.value)
              return null;
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Menu floated='right' pagination size='mini'>
            <Menu.Item 
              as='a' 
              icon 
              onClick={() => {
                if(selectedPageNumber <= 1) {
                  setSelectedPageNumber(selectedPageNumber)
                  return null;
                }

                setSelectedPageNumber(selectedPageNumber-1)
                getCaregiverServices(selectedPageNumber-1);
              }}
              >
              <Icon name='chevron left'/>
            </Menu.Item>
            <Menu.Item>
              <Input 
                value={selectedPageNumber}
                onChange={(e,data) => {
                  if(parseInt(data.value) < 1) return null;
                  if(/^[0-9]/.test(data.value))
                    setSelectedPageNumber(parseInt(data.value));
                }}
              />
              <Button 
                style={{
                  marginLeft: '10px'
                }}
                color='blue'
                onClick={() => {
                  var error = false;

                  if(typeof selectedPageNumber !== 'number') {
                    error = true;
                    if(config.dev) console.warn('Provided page number is not a number', selectedPageNumber);
                  }

                  if(selectedPageNumber < 1) {
                    error = true;
                    if(config.dev) console.warn('Provided page number is less than 1', selectedPageNumber);
                  }

                  if(error) {
                    setModal({
                      visible: true,
                      title: t('g.error'),
                      message: t('g.invalidInput'),
                      action: [
                        {
                          content: t('g.goBack'),
                          onClick: () => history.goBack()
                        },
                      ]
                    });

                    return null;
                  }

                  setSelectedPageNumber(selectedPageNumber)
                  getCaregiverServices(selectedPageNumber);
                }}
              >
                {t('g.go')}
              </Button>
            </Menu.Item>
            <Menu.Item 
              as='a' 
              icon
              onClick={() => {
                setSelectedPageNumber(selectedPageNumber+1)
                getCaregiverServices(selectedPageNumber+1);
              }}
              >
              <Icon name='chevron right' />
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
}

export default CaregiversServicesUpdateRequests;