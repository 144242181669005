// import UI componented
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Menu,
  Icon,
  Dropdown,
  Divider,
  Popup,
  Label
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../framework/internal_page';

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";

export default function ExportForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isReady, setIsReady] = useState<boolean>(true);

  const [options, setOption] = useState<any>({
    fromDate: null,
    toDate: null,
    approved: null,
    isBlocked: null,
  })

   // states
   interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const exportCaregivers = async () => {
    setIsReady(false);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.fromDate = options.fromDate;
    EnsanCareLib.toDate = options.toDate;
    EnsanCareLib.approved = options.approved;
    EnsanCareLib.isBlocked = options.isBlocked;

    var res:any = await EnsanCareLib.exportCaregivers();
    
    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => exportCaregivers()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => exportCaregivers()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
      setModal({
        visible: true,
        title: t('g.completed'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.download'),
            onClick: () => window.open(res.data.link, '_self')
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }
    
    setIsReady(true);
    return null;
    
  }

  return (
    <InternalPage >
      <Modal
        size='mini'
        onClose={() => setModal(null)}
        open={(modal && modal.visible) ? true : false}
        header={modal ? modal.title : null}
        content={modal ? modal.message : null}
        actions={modal?.action||['Ok']}
      />
      
      <Header
        as='h1'
        content={t('s.caregivers.export')}
      />

      <Form>
        <Form.Field>
          <label>{t('g.from')}</label>
          <Popup 
            trigger={
              <Input
                fluid
                placeholder={"2001-09-04"}
                onChange={(e,data) => {
                  if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {

                    setOption({
                      ...options,
                      fromDate: data.value
                    })
                  } 
                  else {

                    setOption({
                      ...options,
                      fromDate: null
                    })
                  }
                }}
              />
            }
            header='input format'
            content='example: 2001-09-04'
            on={'hover'}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('g.to')}</label>
          <Popup 
            trigger={
              <Input
                fluid
                placeholder={"2001-09-04"}
                onChange={(e,data) => {
                  if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {

                    setOption({
                      ...options,
                      toDate: data.value
                    })
                  } 
                  else {

                    setOption({
                      ...options,
                      toDate: null
                    })
                  }
                }}
              />
            }
            header='input format'
            content='example: 2001-09-04'
            on={'hover'}
          />
        </Form.Field>
        <Form.Field >
          <label>{t('g.approved')}</label>
          <Form.Dropdown 
            selection
            fluid
            clearable
            placeholder={t('s.profile.approvedStatus')}
            options={[
              {key: 0, value: 'y', text: t('g.approved')},
              {key: 1, value: 'n', text: t('g.unapproved')},
            ]}
            onChange={(e, data) => {
              if(data.value && typeof data.value === 'string') {

                setOption({
                  ...options,
                  approved: data.value
                })
              } 
              else {

                setOption({
                  ...options,
                  approved: null
                })
              }
            }}
          />
        </Form.Field>
        <Form.Field >
          <label>{t('g.blocked')}</label>
          <Form.Dropdown 
            selection
            fluid
            clearable
            placeholder={t('s.profile.accessStatus')}
            options={[
              {key: 0, value: 'y', text: t('g.blocked')},
              {key: 1, value: 'n', text: t('g.allowed')},
            ]}
            onChange={(e, data) => {
              if(data.value && typeof data.value === 'string') {

                setOption({
                  ...options,
                  isBlocked: data.value
                })
              } 
              else {

                setOption({
                  ...options,
                  isBlocked: null
                })
              }
            }}
          />
        </Form.Field>
      </Form>

      <Header
        as='h1'
        content={t('g.fileExtension')}
      />

      <Form>
        <Form.Field>
          <Form.Radio
            label='Microsoft Excel (.xlsx)'
            checked={true}
          />
          <Form.Radio
            label='Comma-separated values (.csv)'
            disabled
          />
          <Form.Radio
            label='Adobe PDF (.pdf)'
            disabled
          />
          <Form.Radio
            label='Microsoft Access (.accdb)'
            disabled
          />
           <Form.Radio
            label='SQL (.sql)'
            disabled
          />
        </Form.Field>
      </Form>

      <Divider hidden />

      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="left">
            <Button
              icon='arrow left'
              labelPosition='left'
              content={t('g.cancel')}
              onClick={() => history.push('./')}
            />
          </Grid.Column>
          <Grid.Column floated="right" textAlign="right">
            <Button
              primary
              icon='download'
              labelPosition='right'
              content={t('g.download')}
              loading={!isReady}
              onClick={() => exportCaregivers()}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </InternalPage>
    
  );
}

