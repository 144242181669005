import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu,
  MenuItem
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../framework/internal_page';

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";
import ECAdmin from "../util/EnsanCareLib/funcs/admin";

export default function ManagementForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  let userProfile:any = localStorage.getItem('user_profile');
  let userDetails:any = localStorage.getItem('user_details');

  try {
    userProfile = JSON.parse(userProfile);
    userDetails = JSON.parse(userDetails);
  }
  catch(e) {
    console.error(
      'Failed to parse userPRofile or userDetails object',
      e
    );
  }

  const {management_uuid} = useParams<{management_uuid:string}>();
  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [manager, setManager] = useState<any>({
    first_name: null,
    last_name: null,
    phone: null,
    email: null,
    password: null,
    country_code: '+966',
    is_blocked:'n',
    can_access_bookings: 'n',
    can_access_caregivers: 'n',
    can_access_notifications: 'n',
    can_access_payments: 'n',
    can_access_service_requesters: 'n',
    can_access_services: 'n',
    can_access_staff: 'n',
    is_admin: 'n',
  }); 

  const [permissions, setPermissions] = useState<any>(); 

  const countryCode = '+966';

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    if(management_uuid) getManager();
  }, []);

  const getManager = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.managementUuid = management_uuid;

    var res:any = await EnsanCareLib.getManager();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getManager()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getManager()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }
    
    else if(res.status === "success" && res.data) {

      var permissionsTempObj:object = {
        can_access_bookings: res.data.can_access_bookings,
        can_access_caregivers: res.data.can_access_caregivers,
        can_access_notifications: res.data.can_access_notifications,
        can_access_payments: res.data.can_access_payments,
        can_access_service_requesters: res.data.can_access_service_requesters,
        can_access_services: res.data.can_access_services,
        can_access_staff: res.data.can_access_staff,
        is_admin: res.data.is_admin,
      }

      setPermissions(permissionsTempObj);

      setManager({
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        phone: res.data.phone,
        country_code: res.data.country_code,
        email: res.data.email,
        password: res.data.password,
        is_blocked: res.data.is_blocked,
        can_access_bookings: res.data.can_access_bookings,
        can_access_caregivers: res.data.can_access_caregivers,
        can_access_notifications: res.data.can_access_notifications,
        can_access_payments: res.data.can_access_payments,
        can_access_service_requesters: res.data.can_access_service_requesters,
        can_access_services: res.data.can_access_services,
        can_access_staff: res.data.can_access_staff,
        is_admin: res.data.is_admin,
      });
    }

    setIsLoading(false);
    return null;
  }

  const processSubmit = async (actionType:'create'|'profile'|'block'|'delete') => {
    if(isLoading) return true;
    setIsLoading(true);

    var onClickTryAgainAction:any = null;

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;

    var res:any = null;

    if(actionType === 'create') {

      EnsanCareLib.first_name = manager.first_name;
      EnsanCareLib.last_name = manager.last_name;
      EnsanCareLib.phone = manager.phone;
      EnsanCareLib.email = manager.email;
      EnsanCareLib.email = manager.email;
      EnsanCareLib.country_code = manager.country_code;

      EnsanCareLib.is_admin = manager.is_admin;
      EnsanCareLib.can_access_bookings = manager.can_access_bookings;
      EnsanCareLib.can_access_caregivers = manager.can_access_caregivers;
      EnsanCareLib.can_access_notifications = manager.can_access_notifications;
      EnsanCareLib.can_access_payments = manager.can_access_payments;
      EnsanCareLib.can_access_service_requesters = manager.can_access_service_requesters;
      EnsanCareLib.can_access_services = manager.can_access_services;
      EnsanCareLib.can_access_staff = manager.can_access_staff;

      if(manager.password.length > 1){
        EnsanCareLib.password = manager.password
      }

      res = await EnsanCareLib.addManager();

      onClickTryAgainAction = () => processSubmit('create');
    }

    if(actionType === 'profile') {

      EnsanCareLib.managementUuid = management_uuid;
      EnsanCareLib.first_name = manager.first_name;
      EnsanCareLib.last_name = manager.last_name;
      EnsanCareLib.phone = manager.phone;
      EnsanCareLib.email = manager.email;

      if(manager.password.length > 1){
        EnsanCareLib.password = manager.password
      }

      res = await EnsanCareLib.updateManager();

      onClickTryAgainAction = () => processSubmit('profile');
    }

    else if( actionType === 'block') {

      EnsanCareLib.managementUuid = management_uuid;
      EnsanCareLib.is_blocked = manager.is_blocked === 'y' ? 'n' : 'y';
      
      res = await EnsanCareLib.updateManager();

      onClickTryAgainAction = () => processSubmit('block');
    }

    else if(actionType === 'delete') {

      EnsanCareLib.managementUuid = management_uuid;
      res = await EnsanCareLib.deleteManager();

      onClickTryAgainAction = () => processSubmit('delete');
    }

    if (!res) {
      if(config.dev)
        console.error('Failed to complete update settings');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });

    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: onClickTryAgainAction
          },
        ]
      });
    }

    else if (res.status === 'success') {
        if(actionType === 'block') {
          setManager({
            ...manager,
            is_blocked: manager.is_blocked === 'y' ? 'n' : 'y'
          })
          
          if(userDetails.uuid === management_uuid) history.push('../../logout')
        }

        setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            positive: true,
            onClick: actionType === 'delete' ? () => history.push('./') : () => null
          },
        ]
      });
    }
    
    setIsLoading(false);
    return null;
  }

  const setManagerPermissions = async (permission:string) => {
    if(isLoading) return null;
    setIsLoading(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.permission = permission;
    EnsanCareLib.management_uuid = management_uuid;

    var res:any = await EnsanCareLib.setManagerPermissions();

    if (!res) {
      if(config.dev)
        console.error('Failed to complete update settings');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.missingRequirements'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });

    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => setManagerPermissions(permission)
          },
        ]
      });
    }

    else if(res.status === 'success') {
      setManager({
        ...manager,
        [permission]: 'y'
      })
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setIsLoading(false);
    return null;
  }

  const unsetManagerPermissions = async (permission:string) => {
    if(isLoading) return null;
    setIsLoading(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.permission = permission;
    EnsanCareLib.management_uuid = management_uuid;

    var res:any = await EnsanCareLib.unsetManagerPermissions();
    
    if (!res) {
      if(config.dev)
        console.error('Failed to complete update settings');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.missingRequirements'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });

    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => unsetManagerPermissions(permission)
          },
        ]
      });
    }

    else if(res.status === 'success') {
      setManager({
        ...manager,
        [permission]: 'n'
      })
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setIsLoading(false);
    return null;
  }

  const _handleServiceClick = async (permission:string) => {
    if(manager[permission] !== "y") {
      setManagerPermissions(permission);
    }
    else {
      unsetManagerPermissions(permission);
    }
  }

  const _renderSegments = () => {
    if(
        ECAdmin.checkPermission(
          {
            is_admin: localStorage.getItem('is_admin'), 
            can_access_staff: localStorage.getItem('can_access_staff')
          }, 
          ['is_admin', 'can_access_staff']
        ) && (management_uuid)
      ) {
      return  <>
        <Segment color='red'>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                <Header
                  as='h2'
                  content={t('s.profile.accessStatus')}
                />
                
                <div>
                  <div style={{marginBottom: '10px'}}>
                    {t('g.currentStatus')}: <strong>{(manager?.is_blocked === 'y') 
                      ? t('s.profile.accountBlocked') 
                      : t('s.profile.accountAllowedAccess') 
                    }</strong>
                  </div>
                  <div>{t('s.profile.blockUserDescription')}</div>
                </div>
              </Grid.Column>
              <Grid.Column width={4} textAlign='right'>
                <Form.Button
                  content={(manager?.is_blocked === 'y') ? t('g.unblock') : t('g.block')}
                  onClick={() => processSubmit('block')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment color='red'>

          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Header
                  as='h2'
                  content={`${t('g.delete')} ${t('g.account')}`}
                />
                <div>{t('s.management.deleteManagementDescription')}</div>
              </Grid.Column>
              <Grid.Column width={4} textAlign={'right'}>
                <Button
                  content={t('g.delete')}
                  color='red'
                  onClick={() => processSubmit('delete')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment> 
      </>
    }

    else {


      return <></>
    }
  }

  const _renderPermissions = () => {
    if(
      ECAdmin.checkPermission(
        userDetails, 
        ['is_admin', 'can_access_staff']
      ) && (management_uuid)
    ) {
      return <> 
        <Header
          as='h2'
          content={t('s.permissions.title')}
        />

        <Divider hidden />

        <Grid columns={4}>
          <Grid.Row>
            <Grid.Column>
              <Checkbox
                toggle
                disabled={userDetails['is_admin'] !== 'y'}
                checked={manager.is_admin === 'y' ? true : false}
                onChange={(e, data) => {
                  _handleServiceClick('is_admin');
                }}
              />
              <div style={{marginTop: '10px'}}>
                {t('s.permissions.permissionTypes.isAdmin')}
              </div>
            </Grid.Column>
            <Grid.Column>
              <Checkbox
                toggle
                checked={manager.can_access_staff === 'y' ? true : false}
                onChange={(e, data) => {
                  _handleServiceClick('can_access_staff');
                }}
              />
              <div style={{marginTop: '10px'}}>
                {t('s.permissions.permissionTypes.canAccessStaff')}
              </div>
            </Grid.Column>
            <Grid.Column>
              <Checkbox
                toggle
                checked={manager.can_access_services === 'y' ? true : false}
                onChange={(e, data) => {
                  _handleServiceClick('can_access_services');
                }}
              />
              <div style={{marginTop: '10px'}}>
                {t('s.permissions.permissionTypes.canAccessServices')}
              </div>
            </Grid.Column>
            <Grid.Column>
              <Checkbox
                toggle
                checked={manager.can_access_service_requesters === 'y' ? true : false}
                onChange={(e, data) => {
                  _handleServiceClick('can_access_service_requesters');
                }}
              />
              <div style={{marginTop: '10px'}}>
                {t('s.permissions.permissionTypes.canAccessServiceRequesters')}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Checkbox
                toggle
                checked={manager.can_access_caregivers === 'y' ? true : false}
                onChange={(e, data) => {
                  _handleServiceClick('can_access_caregivers');
                }}
              />
              <div style={{marginTop: '10px'}}>
                {t('s.permissions.permissionTypes.canAccessServiceCaregivers')}
              </div>
            </Grid.Column>
            <Grid.Column>
              <Checkbox
                toggle
                checked={manager.can_access_bookings === 'y' ? true : false}
                onChange={(e, data) => {
                  _handleServiceClick('can_access_bookings');
                }}
              />
              <div style={{marginTop: '10px'}}>
                {t('s.permissions.permissionTypes.canAccessBookings')}
              </div>
            </Grid.Column>
            <Grid.Column>
              <Checkbox
                toggle
                checked={manager.can_access_notifications === 'y' ? true : false}
                onChange={(e, data) => {
                  _handleServiceClick('can_access_notifications');
                }}
              />
              <div style={{marginTop: '10px'}}>
                {t('s.permissions.permissionTypes.canAccessNotifications')}
              </div>
            </Grid.Column>
            <Grid.Column>
              <Checkbox
                toggle
                checked={manager.can_access_payments === 'y' ? true : false}
                onChange={(e, data) => {
                  _handleServiceClick('can_access_payments');
                }}
              />
              <div style={{marginTop: '10px'}}>
                {t('s.permissions.permissionTypes.canAccessPayments')}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    }
  }

  return <InternalPage loading={!manager}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal?.action || ['Ok']}
    />
    
    <Header
      as='h1'
      content={
        (manager.first_name !== null && manager.last_name !== null) ? 
        `${manager.first_name} ${manager.last_name}` : 
        '...'}
    />

    <Header
      as='h2'
      content={t('g.generalInformation')}
    />

    <Form>
      <Form.Group widths={'equal'}>
        <Form.Input
          label={t('s.profile.firstName')}
          placeholder={t('g.typeHere')}
          value={manager.first_name}
          onChange={(e,data) => {
            setManager({
              ...manager,
              first_name: data.value
            })
          }}
        />
        <Form.Input
          label={t('s.profile.lastName')}
          placeholder={t('g.typeHere')}
          value={manager.last_name}
          onChange={(e,data) => {
            setManager({
              ...manager,
              last_name: data.value
            })
          }}
        />
      </Form.Group>
      <Form.Group widths={2}>
        <Form.Dropdown
          width={2}
          fluid
          label={t('s.profile.countryCode')}
          selection
          value={countryCode}
          options={[
            {key: 0, value: '+966', text: '+966'}
          ]}
        />
        <Form.Input
          width={14}
          label={t('g.phone')}
          placeholder={'59xx..'}
          value={manager.phone}
          onChange={(e,data) => {
            setManager({
              ...manager,
              phone: data.value
            })
          }}
        />
      </Form.Group>
      <Form.Input
        label={t('g.email')}
        placeholder={t('g.typeHere')}
        value={manager.email}
        onChange={(e,data) => {
          setManager({
            ...manager,
            email: data.value
          })
        }}
      />
      <Form.Input
        label={t('g.password')}
        placeholder={t('g.typeHere')}
        type='password'
        onChange={(e,data) => {
          setManager({
            ...manager,
            password: data.value
          })
        }}
      />
    </Form>
{/* 
    <Divider section hidden /> */}

    {_renderPermissions()}  

    <Divider hidden />
    
    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Button
            icon='arrow left'
            labelPosition='left'
            content={t('g.cancel')}
            onClick={() => history.push('./')}
          />
        </Grid.Column>
        <Grid.Column floated="right" textAlign="right">
          <Button
            primary
            content={t('g.save')}
            onClick={() => (management_uuid) ? processSubmit('profile') : processSubmit('create')}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider hidden />
    
    {_renderSegments()}
     

  </InternalPage>
}