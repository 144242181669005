import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Image,
  Dropdown,
  Divider,
  Menu
} from 'semantic-ui-react';

// import SVG
import Logo from '../../assets/logo-icon.png';

import EnsanCareAPI from '../../util/EnsanCareLib/EnsanCareAPI';
import ECGeneral from '../../util/EnsanCareLib/funcs/general';
import ECServiceRequesters from '../../util/EnsanCareLib/funcs/service_requesters';
import ECCaregivers from '../../util/EnsanCareLib/funcs/caregivers';
import ECServiceCities from '../../util/EnsanCareLib/funcs/service_cities';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";

// map component
import Map from '../../framework/map';
import moment from 'moment';
import InternalPage from '../../framework/internal_page';
import Rating from '../../framework/rating';

const periods = [
  {period: 1, label: '08:00 AM - 11:00 AM'},
  {period: 2, label: '11:00 AM - 02:00 PM'},
  {period: 3, label: '02:00 PM - 05:00 PM'},
  {period: 4, label: '05:00 PM - 08:00 PM'},
  {period: 5, label: '08:00 PM - 10:00 PM'}
];

export default function BookingSessionDetails() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {session_id} = useParams<{session_id:string}>();

  const [isLoadingSession, setIsLoadingSession] = useState<any>(null); 
  const [session, setSession] = useState<any>(null); 
  const [serviceLocation, setServiceLocation] = useState<any>(null);
  const [caregiverLiveCoords, setCaregiverLiveCoords] = useState<any>(null);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    actions: any
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getSession();
  }, [])
  
  const getSession = async () => {
    if(isLoadingSession) return null;
    setIsLoadingSession(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.sessionId = session_id;

    var res = await EnsanCareLib.getBookingSession();

    if(!res) {}

    else if (res.status === 'fail') {}

    else if (res.status === 'success') {
      setSession(res.data);

      if(res.data.service_location) {
        var receivedServiceLocation = res.data.service_location;

        try { 
          if(typeof receivedServiceLocation === 'string') {
            receivedServiceLocation = JSON.parse(receivedServiceLocation)
          }

          setServiceLocation(receivedServiceLocation)
        }
        catch(e) {
          if(config.dev)
            console.error('Faild to parse service location', e);
        }
      } 

      if(res.data.caregiver_live_coords) {
        var receivedCaregiverLiveLocation = res.data.caregiver_live_coords;

        try { 
          if(typeof receivedCaregiverLiveLocation === 'string') {
            receivedCaregiverLiveLocation = JSON.parse(receivedCaregiverLiveLocation)
          }

          setCaregiverLiveCoords(receivedCaregiverLiveLocation)
        }
        catch(e) {
          if(config.dev)
            console.error('Faild to parse service location', e);
        }
      } 
    }

    else {}

    setIsLoadingSession(false);
    return null;
  }
  
  const updateSession = async (type:'caregiver_arrival_status'|'status', value:any) => {
    if(!type || !value) return null;

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.sessionId = session_id;
    EnsanCareLib[type] = value;

    var res = await EnsanCareLib.updateBookingSession();
    
    if(!res) {}

    else if (res.status === 'fail') {}

    else if (res.status === 'success' && res.update_status[type] === true) {
      var tempSession = session;
      tempSession[type] = value;
      
      setSession({...tempSession});
    }

    else {}

    return null;
  }

  const renderSchedulePeriodLabel = () => {
    var periodObj = periods.find(period => period.period === session.schedule_period);
    
    if(periodObj === null || periodObj === undefined){
      return 'Err'
    } else {
      return periodObj.label;
    }
  } 

  if(isLoadingSession) return <Loader active />

  if(!session) return <Grid>
    <Grid.Column textAlign='center'>
      <Divider hidden />
      {t('g.noResultsFound')}
    </Grid.Column>
  </Grid>

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : null}
    />

    <Header
      as='h2'
      content={t('g.generalInformation')}
    />

    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column>
          <Header
            as='h3'
            content={`${t('g.schedule')} ${t('g.date')} / ${t('s.caregivers.schedule.period')}`}
          />

          <div>
            <span>
              {session.schedule_date && moment(session.schedule_date).format(config.momentDateFormat)}
            </span>
            <span style={{marginLeft: '10px'}}>{renderSchedulePeriodLabel()}</span>
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header
            as='h3'
            content={`${t('s.profile.caregiver')}`}
          />

          <div>
            <Image src='' avatar />
            <span>
              {ECCaregivers.buildName(
                i18n.language,
                session.caregiver_name_prefix,
                session.caregiver_first_name_local,
                session.caregiver_middle_name_local,
                session.caregiver_last_name_local
              )}
            </span>
            <span style={{marginLeft: 10}}>
              <a href={`/caregivers/${session.caregiver_uuid}`}>
                ({t('g.view')})
              </a>
            </span>
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header
            as='h3'
            content={`${t('s.bookings.sessions.arrivalStatus')}`}
          />

          <Dropdown 
            selection
            fluid
            placeholder={t('g.selectOne')}
            value={session.caregiver_arrival_status}
            options={[
              {key: 0, value: 'pending', text: t('s.bookings.status.pending')},
              {key: 1, value: 'on the way', text: t('s.bookings.status.on the way')},
              {key: 2, value: 'arrived', text: t('s.bookings.status.arrived')}
            ]}
            onChange={(e, data) => {
              updateSession('caregiver_arrival_status', data.value)
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <div style={{overflow: 'hidden', borderRadius: 15}}>
            <Map 
              height={500}
              center={serviceLocation}
              markers={(serviceLocation) && [
                  {location: serviceLocation},
                  {location: caregiverLiveCoords}
              ]} 
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider hidden section />

    <Grid>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header
            as='h3'
            content={`${t('g.status')}`}
          />

          <Dropdown 
            selection
            fluid
            placeholder={t('g.selectOne')}
            value={session.status}
            options={[
              {key: 0, value: 'pending', text: t('s.bookings.status.pending')},
              {key: 1, value: 'scheduled', text: t('s.bookings.status.scheduled')},
              {key: 2, value: 'in progress', text: t('s.bookings.status.in progress')},
              {key: 3, value: 'cancelled', text: t('s.bookings.status.cancelled')},
              {key: 4, value: 'completed', text: t('s.bookings.status.completed')}
            ]}
            onChange={(e, data) => {
              updateSession('status', data.value)
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    {session?.cancelled_at && <>
      <Divider section />

      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header
              as='h3'
              content={t('g.cancelledBy')}
              color={'red'}
            />
            <div>
              <span style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                {session.cancelled_by}
              </span>
              <span> @ {moment(session.cancelled_at).format(config.momentDateTimeFormat)}</span>
            </div>
          </Grid.Column>
          <Grid.Column>
            <Header
              as='h3'
              content={t('g.cancellationNotes')}
              color={'red'}
            />
            <div>
              {session.cancellation_note}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </> }

    <Divider section />

    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column>
          <Header
            as='h3'
            content={`${t('s.bookings.sessions.prescription')}`}
          />
          <div>
            {session.prescription||'-'}
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header
            as='h3'
            content={t('s.bookings.sessions.planAhead')}
          />
          <div>
            {session.plan_ahead||'-'}
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header
            as='h3'
            content={t('s.bookings.sessions.notes')}
          />
          <div>
            {session.notes||'-'}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider section />

    <Header
      as='h2'
      content={t('g.rating')}
    />

    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header
            as='h3'
            content={t('s.profile.serviceRequester')}
          />
          <div>
            <Rating rating={session.service_requester_rating} />
          </div>
          <div style={{marginTop: 10}}>
            {session.service_requester_feedback||'-'}
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header
            as='h3'
            content={t('s.profile.caregiver')}
          />
          <div>
            <Rating rating={session.caregiver_rating} />
          </div>
          <div style={{marginTop: 10}}>
            {session.caregiver_feedback||'-'}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
}