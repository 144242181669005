import { useTranslation } from 'react-i18next';
import { Divider, Dropdown, Grid, Header, Label, List} from 'semantic-ui-react';
import InternalPage from './framework/internal_page';

import { config } from './config';

import PortalHomeImage from './assets/portal-home-header-image.png';
import { useHistory } from 'react-router';
import Map from './framework/map';

import { useEffect, useState } from 'react';

import EnsanCareAPI from "../src/util/EnsanCareLib/EnsanCareAPI";
import ECCaregivers from './util/EnsanCareLib/funcs/caregivers';
import ECServiceCities from './util/EnsanCareLib/funcs/service_cities';

import moment from 'moment';


export default function Default() {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  const [systemStats, setSystemStats] = useState({
    caregiversCount: 0,
    caregiversPendingCount: 0,
    serviceRequestersCount: 0,
    serviceRequestersOfCurrentMonthCount: 0,
    bookingsCompletedCount: 0,
    bookingsCancelledCount: 0,
    servciesCount: 0,
    revenue: 0,
    profit:0
  });

  const [topRequestedServices, setTopRequestedServices] = useState<Array<Object>>([]);
  const [topAssignedCaregivers, settopAssignedCaregivers] = useState<Array<Object>>([]);
  const [serviceRequesters, setServiceRequesters] = useState<Array<Object>>([]);
  const [bookings, setBookings] = useState<Array<Object>>([]);
  const [serviceCities, setServiceCities] = useState<Array<Object>>([]);
  const [mapPins, setMapPins] = useState<Array<Object>>([]);
  const [selectedPinsType, setSelectedPinsType] = useState<string>();
  const [selectedPinsServiceCityId, setSelectedPinsServiceCityId] = useState<number>();
  
  const dropdownTypeSelection = [
    {
      key: 0,
      text: t('s.bookings.title'),
      value: "bookings"
    },
    {
      key: 1,
      text: t('s.caregivers.title'),
      value: "caregivers"
    }
  ];

  useEffect(() => {
    getServiceCities();
    getSystemStats();
    getTopRequestedServicesrStats();
    getTopAssignedCaregivers();
    getServiceRequesters();
    getBookings();
  }, []);

  useEffect(() => {
    if(!selectedPinsType || !selectedPinsServiceCityId) return;
    
    getMapPins();
  }, [selectedPinsServiceCityId, selectedPinsType])
 
  //getServiceCities
  const getServiceCities = async () => {
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    
    var res:any = await EnsanCareLib.getServiceCities();
    
    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
      return null;
    }

    var serviceCitiesObj:any[any] = [];

    res.data.map((item:any) => {
      serviceCitiesObj.push({
        key: item.id,
        value: item.id,
        text: ECServiceCities.buildLabel(i18n.language, item)
      })
    })
    
    setServiceCities(serviceCitiesObj);
  }

  //getMapPins
  const getMapPins = async () => {
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.serviceCityId = selectedPinsServiceCityId;
    EnsanCareLib.type = selectedPinsType;

    var res:any = await EnsanCareLib.getMapPins();
    
    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
      return null;
    }

    var mapPinsObj:any = [];
    var title:any = null;
    var location:any = null;


    res.data.map((item:any) => {
      if(selectedPinsType === 'bookings'){
        title = item.uuid + " " + item.status;
        location = ECServiceCities.convertCoordsToObject(item.location);    
      }

      if(selectedPinsType === 'caregivers'){
        title = ECCaregivers.buildName(
          i18n.language,
          item.name_prefix, 
          item.first_name_local, 
          item.middle_name_local, 
          item.last_name_local
        );
        location = ECServiceCities.convertCoordsToObject(item.base_coords);
      }

      mapPinsObj.push({
        title: title,
        location: location 
      })
    })
    
    setMapPins(mapPinsObj);
  }

  // setting data in the systemStats object
  const setCaregiversCount = (count:number) => {
    setSystemStats(previousState => {
      return { ...previousState, caregiversCount: count }
    });
  }

  const setCaregiversPendingCount = (count:number) => {
    setSystemStats(previousState => {
      return { ...previousState, caregiversPendingCount: count }
    });
  }

  const setServiceRequestersCount = (count:number) => {
    setSystemStats(previousState => {
      return { ...previousState, serviceRequestersCount: count }
    });
  }

  const setServiceRequestersOfCurrentMonthCount = (count:number) => {
    setSystemStats(previousState => {
      return { ...previousState, serviceRequestersOfCurrentMonthCount: count }
    });
  }

  const setBookingsCompletedCount = (count:number) => {
    setSystemStats(previousState => {
      return { ...previousState, bookingsCompletedCount: count }
    });
  }

  const setBookingsCancelledCount = (count:number) => {
    setSystemStats(previousState => {
      return { ...previousState, bookingsCancelledCount: count }
    });
  }

  const setServciesCount = (count:number) => {
    setSystemStats(previousState => {
      return { ...previousState, servciesCount: count }
    });
  }

  const setRevenue = (count:number) => {
    setSystemStats(previousState => {
      return { ...previousState, revenue: count }
    });
  }

  const setProfit = (count:number) => {
    setSystemStats(previousState => {
      return { ...previousState, profit: count }
    });
  }


  // getSystemStats
  const getSystemStats = async () => {
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    
    var res:any = await EnsanCareLib.getSystemStats();

    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
      return null;
    }

    if(res.data[0].caregivers_count) setCaregiversCount(res.data[0].caregivers_count);
    if(res.data[0].caregivers_pending_count) setCaregiversPendingCount(res.data[0].caregivers_pending_count);

    if(res.data[0].service_requesters_count)setServiceRequestersCount(res.data[0].service_requesters_count);

    if(res.data[0].service_requesters_of_current_month_count)
      setServiceRequestersOfCurrentMonthCount(
        res
        .data[0]
        .service_requesters_of_current_month_count
      );

    if(res.data[0].bookings_completed_count) setBookingsCompletedCount(res.data[0].bookings_completed_count);
    if(res.data[0].bookings_cancelled_count) setBookingsCancelledCount(res.data[0].bookings_cancelled_count);
    if(res.data[0].servcies_count) setServciesCount(res.data[0].servcies_count);
    if(res.data[0].revenue) setRevenue(res.data[0].revenue.toFixed(2));
    if(res.data[0].profit) setProfit(res.data[0].profit.toFixed(2));
  }

  // getTopRequestedServicesrStats
  const getTopRequestedServicesrStats = async () => {

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.numberOfResults = 10;
    
    var res:any = await EnsanCareLib.getTopRequestedServicesrStats();
    
    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
      return null;
    }

    if(res.data && res.data.length > 0) {
      setTopRequestedServices(res.data);
    }
  }

  // getServiceRequesters
  const getServiceRequesters = async () => {
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.resPerPage = 10;
    
    var res:any = await EnsanCareLib.getServiceRequesters();
    
    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
      return null;
    }

    if(res.data && res.data.length > 0) {
      setServiceRequesters(res.data);
    }
  }

  // getBookings
  const getBookings = async () => {
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.resPerPage = 10;
    
    var res:any = await EnsanCareLib.getBookings();
    
    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
      return null;
    }

    if(res.data && res.data.length > 0) {
      setBookings(res.data);
    }
  }

  // getTopAssignedCaregivers
  const getTopAssignedCaregivers = async () => {
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.numberOfResults = 10;
    
    var res:any = await EnsanCareLib.getTopAssignedCaregivers();
    
    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
      return null;
    }

    if(res.data && res.data.length > 0) {
      settopAssignedCaregivers(res.data);
    }
  }
  
  return <>
    <InternalPage>
      <Grid stackable columns={2}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header
              as='h1'
              content={`${t('g.welcomeTo')} ${t('g.appName')}`}
              subheader={t('g.useSideMenuToNavigate')}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider section />

      <Header
        as='h2'
        content={t('g.map')}
        subheader={t('s.serviceCities.mapSelectSCToViewLocationsDescription')}
      />

      <Divider hidden />

      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Dropdown 
              placeholder={t('g.selectOne')}
              fluid
              onChange={(e, data) => {
                if(typeof data.value === 'number') setSelectedPinsServiceCityId(data.value)
              }}
              selection
              search
              options={serviceCities}
            />
          </Grid.Column>
          <Grid.Column>
            <Dropdown 
              placeholder={t('g.selectOne')}
              fluid
              onChange={(e, data) => {
                if(typeof data.value === 'string') setSelectedPinsType(data.value)
              }}
              selection
              search
              options={dropdownTypeSelection}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <div style={{height: '10px'}} />

      <div style={{overflow: 'hidden', borderRadius: 10}}>
        <Map
          height={500}
          markers={mapPins}
          markersType={"circle"}
        />
      </div>

      <Divider section />

      <Header
        as='h2'
        content={t('s.statistics.systemStatistics')}
      />

      <Divider hidden />

      <Header
        as='h3'
        content={t('g.users')}
      />

      <Grid stackable columns={4}>
        <Grid.Row>
          <Grid.Column href={'/caregivers'}>
            <Header
              as='h4'
              content={systemStats.caregiversCount}
              subheader={t('s.profile.caregivers')}
            />
          </Grid.Column>
          <Grid.Column href={'/caregivers/?approvedStatus=n'}>
            <Header
              as='h4'
              content={systemStats.caregiversPendingCount}
              subheader={`${t('g.pending')} ${t('s.profile.caregivers')}`}
              color='orange'
            />
          </Grid.Column>
          <Grid.Column href={'/service-requesters'}>
            <Header
              as='h4'
              content={systemStats.serviceRequestersCount}
              subheader={t('s.profile.serviceRequesters')}
            />
          </Grid.Column>
          <Grid.Column>
            <Header
              as='h4'
              content={systemStats.serviceRequestersOfCurrentMonthCount}
              subheader={`${t('s.profile.serviceRequesters')} ${t('g.currentMonth')}`}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />

      <Header
        as='h3'
        content={t('s.bookings.title')}
      />

      <Grid stackable columns={4}>
        <Grid.Row>
          <Grid.Column href={'/bookings'}>
            <Header
              as='h4'
              content={systemStats.bookingsCompletedCount}
              subheader={t('s.bookings.title')}
            />
          </Grid.Column>
          <Grid.Column href={'/bookings/?status=cancelled'}>
            <Header
              as='h4'
              content={systemStats.bookingsCancelledCount}
              subheader={`${t('g.cancelled')} ${t('s.bookings.title')}`}
              color={'red'}
            />
          </Grid.Column>
          <Grid.Column href={'/settings/services'}>
            <Header
              as='h4'
              content={systemStats.servciesCount}
              subheader={t('s.services.title')}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />

      <Header
        as='h3'
        content={t('g.financials')}
      />

      <Grid stackable columns={4}>
        <Grid.Row>
          <Grid.Column>
            <Header
              as='h4'
              content={systemStats.revenue}
              subheader={`${t('g.total')} ${t('g.revenue')} (${t('c.SR')})`}
            />
          </Grid.Column>
          <Grid.Column>
            <Header
              as='h4'
              content={systemStats.profit}
              subheader={`${t('g.total')} ${t('g.profit')} (${t('c.SR')})`}
              color='green'
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider section />

      <Header
        as='h2'
        content={t('s.statistics.topRecords')}
      />

      <Divider hidden />
      
      <Grid stackable columns={3}>
        <Grid.Row>
          <Grid.Column>
            <Header
              as='h3'
              content={t('s.statistics.topRequestedServices')}
            />

            <List>
              {topRequestedServices.map((item:any, key:number) => <List.Item key={key}>
                {item.service_title} (x{item.number_of_times_ordered})
              </List.Item> )}
            </List>
          </Grid.Column>
          <Grid.Column>
            <Header
              as='h3'
              content={t('s.statistics.topAssignedCaregivers')}
            />

            <List>
              {topAssignedCaregivers.map((item:any, key:number) => {
                var shortUuid = item.caregiver_uuid || null;

                if(shortUuid) shortUuid = shortUuid.substring(shortUuid.length - 5, shortUuid.length);

                const caregiverName = ECCaregivers.buildName(
                  i18n.language,
                  item.name_prefix, 
                  item.first_name_local, 
                  item.middle_name_local, 
                  item.last_name_local
                )

                return <List.Item key={key}>
                  # <a href={`/caregivers/${item.caregiver_uuid}`}><span style={{display: 'inline-block', width: '45px'}}>{shortUuid}</span></a> {caregiverName} 
                  <Label circular color={"green"}>C: {item.completed_bookings_count}</Label>
                </List.Item>
              })}
            </List>
          </Grid.Column>
          <Grid.Column>
            <Header
              as='h3'
              content={t('s.statistics.lastCompletedRequestes')}
            />

            <List>
              {bookings.map((item:any, key:number) => {
                var shortUuid = item.uuid || null;

                if(shortUuid) shortUuid = shortUuid.substring(shortUuid.length - 5, shortUuid.length);

                return <List.Item key={key}>
                  # <a href={`/bookings/${item.uuid}`}><span style={{display: 'inline-block', width: '45px'}}>{shortUuid}</span></a> [{item.status}] -- <i>{moment(item.created_at).fromNow()}</i>
                </List.Item>
              })}
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header
              as='h3'
              content={t('s.statistics.lastRegisteredServiceRequesters')}
            />

            <List>
              {serviceRequesters.map((item:any, key:number) => {
                var shortUuid = item.uuid || null;

                if(shortUuid) shortUuid = shortUuid.substring(shortUuid.length - 5, shortUuid.length);

                return <List.Item key={key}>
                  # <a href={`/service-requesters/${item.uuid}`}><span style={{display: 'inline-block', width: '45px'}}>{shortUuid}</span></a> ({item.country_code}) {item.phone} -- <i>{moment(item.created_at).fromNow()}</i>
                </List.Item>
              })}
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </InternalPage>
  </>
}