import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Button, Divider, Grid, Menu } from 'semantic-ui-react';

import Logo from '../assets/logo-horizontal-colored.png';

import LanguageSelection from './language';
import SideMenu from './side_menu';

export default function SiteHeader(props:any) {
  const {t, i18n} = useTranslation();
  const history = useHistory();

  const [toggleMenu, setToggleMenu] = useState<boolean>(false);

  return <>
    <Grid stackable padded>
      <Grid.Row>
        <Grid.Column width={14}>
          {props.hideLogo ? null : <img src={Logo} height={40} />}
        </Grid.Column>
        <Grid.Column width={2}>
          <LanguageSelection/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row width={16} only='mobile tablet'>
        <Grid.Column>
          <Button
            fluid
            secondary
            content={t('g.menu')}
            onClick={() => setToggleMenu(!toggleMenu)}
          />
          {toggleMenu && <div>
            <Divider hidden />
            <SideMenu />
          </div>}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
}