import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Segment,
  Image,
  Menu,
  Icon,
  Divider
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../../framework/internal_page';

import Map from '../../framework/map';

import EnsanCareAPI from "../../util/EnsanCareLib/EnsanCareAPI";
import ECServiceCities from '../../util/EnsanCareLib/funcs/service_cities';

export default function ServiceCitiesForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false); 
  const [serviceCityFromCoordsIsReady, setServiceCityFromCoordsIsReady] = useState<boolean>(true); 

  const [serviceCity, setServiceCity] = useState<any>({
    id: null,
    city: null,
    state: null,
    country: null,
    listing_order: 1,
    local:{
      en:{
        city: null,
        state: null,
        country: null,
      },
      ar:{
        city: null,
        state: null,
        country: null,
      }
    }
  });
  const [tempServiceCity, setTempServiceCity] = useState<any>({});

  const {service_city_id} = useParams<{service_city_id:string}>();

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    if(service_city_id) getServiceCity();
  }, [])


  const getServiceCity = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.serviceCityId = service_city_id;
    
    var res:any = await EnsanCareLib.getServiceCity();
    
    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServiceCity()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServiceCity()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {

      var serviceCityObj:any = null;

      var serviceCityLocal = res.data[0].local;

      try {
        if (typeof serviceCityLocal === 'string')
        serviceCityLocal = JSON.parse(serviceCityLocal);

        if (typeof serviceCityLocal != 'object') {
          serviceCityLocal = `${JSON.stringify(serviceCityLocal)}`;
        }
      }
      catch (e) {
        if(config.dev) 
          console.error(e);
      }

      serviceCityObj = {
        id: res.data[0].id,
        city: res.data[0].city,
        country: res.data[0].country,
        state: res.data[0].state,
        listing_order: res.data[0].listing_order,
        local: serviceCityLocal,
      }
      
      setServiceCity(serviceCityObj);
    }

    setIsLoading(false);
    return null;
  }

  const getServiceCityIdFromCoords = async (lat:any,lng:any) => {
    if(!serviceCityFromCoordsIsReady) return null;
    setServiceCityFromCoordsIsReady(false);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.lat = lat;
    EnsanCareLib.lng = lng;
    
    var res:any = await EnsanCareLib.getServiceCityIdFromCoords();
    
    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServiceCityIdFromCoords(lat,lng)
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail" && res.code != 404) {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServiceCityIdFromCoords(lat,lng)
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if( (res.status === "fail" && res.code === 404) || res.status === "success") {

      setTempServiceCity({
        en: {
          city: res.data.city || '',
          state: res.data.state || '',
          country: res.data.country || '',
        },
      })
    }

    setServiceCityFromCoordsIsReady(true);
    return null;
  }

  const processSubmit = async () => {
    if(isLoading) return true;
    setIsLoading(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.city = serviceCity.city;
    EnsanCareLib.state = serviceCity.state;
    EnsanCareLib.country = serviceCity.country;
    EnsanCareLib.local = serviceCity.local;
    EnsanCareLib.listingOrder = serviceCity.listing_order;

    var res:any;

    if (!serviceCity.id) {
      

      res = await EnsanCareLib.addServiceCity();
    } 
  
    else {

      EnsanCareLib.serviceCityId = service_city_id;

      res = await EnsanCareLib.updateServiceCity();
    }

    if (!res) {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.missingRequirements'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });

      if(config.dev)
        console.error('Failed to complete update or add serviceCity');
    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => processSubmit()
          },
        ]
      });
    }

    else if (res.status === 'success') {

      var btnAction;

      // modal for adding a new service-cities
      if(!serviceCity.id && res.data.id) { 
        btnAction = () => history.push('../service-cities/show/' + res.data.id);
      }
      
      else {
        btnAction = () => null;
      }

      // modal for updating service-cities
      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            positive: true,
            onClick: btnAction
          },
        ]
      });
    }
    
    setIsLoading(false);
    return null;
  }

  if(!serviceCity) return <> </>;

  return <InternalPage Loading={!serviceCity}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.action : null}
    />
    
    <Header
      as='h1'
      content={t('s.serviceCities.title')}
    /> 
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <div style={{overflow: 'hidden', borderRadius: 10}}>
            <Map
              height={400}
              onClick={(ev:any) => {
                getServiceCityIdFromCoords(ev.lat, ev.lng);
              }}
            />
          </div>
        </Grid.Column>
        <Grid.Column>
          <Grid.Row>
            <Form>
              <Form.Field>
                <label>{t('g.city')}</label>
                <input value={tempServiceCity.en ? tempServiceCity.en.city : ' '} />
              </Form.Field>
              <Form.Field>
                <label>{t('g.state')}</label>
                <input value={tempServiceCity.en ? tempServiceCity.en.state : ' '} />
              </Form.Field>
              <Form.Field>
                <label>{t('g.country')}</label>
                <input value={tempServiceCity.en ? tempServiceCity.en.country : ' '} />
              </Form.Field>
            </Form>
          </Grid.Row>
          <Divider hidden/>
          <Grid.Row>
            <Grid.Column>
              <Button
                color="green"
                content={t('g.use')}
                loading={!serviceCityFromCoordsIsReady}
                onClick={() => {
                  setServiceCity({
                    ...serviceCity,
                    city: tempServiceCity.en ? tempServiceCity.en.city : ' ',
                    state: tempServiceCity.en ? tempServiceCity.en.state : ' ',
                    country: tempServiceCity.en ? tempServiceCity.en.country : ' ',
                    local: {
                      ...serviceCity.local,
                      en: {
                        city: tempServiceCity.en ? tempServiceCity.en.city : ' ',
                        state: tempServiceCity.en ? tempServiceCity.en.state : ' ',
                        country: tempServiceCity.en ? tempServiceCity.en.country : ' ',
                      },
                    }
                  })
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    

    <Divider hidden/>

    <Form>
      <Form.Group widths={'equal'}>
        <Form.Input
          label={`${t('g.city')} (EN)`}
          placeholder={t('g.typeHere')}
          value={serviceCity?.local?.en.city || ''}
          onChange={(e, data) => setServiceCity({
            ...serviceCity,
            local: {
              ...serviceCity.local,
              en: {
                ...serviceCity.local.en,
                city: data.value,
              }
            }
          })}
        />
        <Form.Input
          label={`${t('g.state')} (EN)`}
          placeholder={t('g.typeHere')}
          value={serviceCity?.local?.en.state || ''}
          onChange={(e, data) => setServiceCity({
            ...serviceCity,
            local: {
              ...serviceCity.local,
              en: {
                ...serviceCity.local.en,
                state: data.value,
              }
            }
          })}
        />
        <Form.Input
          label={`${t('g.country')} (EN)`}
          placeholder={t('g.typeHere')}
          value={serviceCity?.local?.en.country || ''}
          onChange={(e, data) => setServiceCity({
            ...serviceCity,
            local: {
              ...serviceCity.local,
              en: {
                ...serviceCity.local.en,
                country: data.value,
              }
            }
          })}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <Form.Input
          label={`${t('g.city')} (AR)`}
          placeholder={t('g.typeHere')}
          value={serviceCity?.local?.ar.city || ''}
          onChange={(e, data) => setServiceCity({
            ...serviceCity,
            local: {
              ...serviceCity.local,
              ar: {
                ...serviceCity.local.ar,
                city: data.value,
              }
            }
          })}
        />
        <Form.Input
          label={`${t('g.state')} (AR)`}
          placeholder={t('g.typeHere')}
          value={serviceCity?.local?.ar.state || ''}
          onChange={(e, data) => setServiceCity({
            ...serviceCity,
            local: {
              ...serviceCity.local,
              ar: {
                ...serviceCity.local.ar,
                state: data.value,
              }
            }
          })}
        />
        <Form.Input
          label={`${t('g.country')} (AR)`}
          placeholder={t('g.typeHere')}
          value={serviceCity?.local?.ar.country || ''}
          onChange={(e, data) => setServiceCity({
            ...serviceCity,
            local: {
              ...serviceCity.local,
              ar: {
                ...serviceCity.local.ar,
                country: data.value,
              }
            }
          })}
        />
      </Form.Group>
    </Form>

    <Divider hidden />

    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Button
            icon='arrow left'
            labelPosition='left'
            content={t('g.cancel')}
            onClick={serviceCity.id ? () => history.push('../') : () => history.push('./')}
          />
        </Grid.Column>
        <Grid.Column floated="right" textAlign="right">
          <Button
            primary
            content={t('g.save')}
            onClick={() => processSubmit()}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

  </InternalPage>
}