import { useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Segment,
  Image,
  Menu
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../../framework/internal_page';

import ServicesCategoriesList from './categories';
import ServicesList from './services';

export default function ServicesMain() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [screen, setScreen] = useState<'categories'|'services'>('categories'); 

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string
  }

  const [modal, setModal] = useState<modalType|null>(null);

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />
    
    <Header
      as='h1'
      content={t('s.services.title')}
    />
    
    <Menu pointing secondary>
      <Menu.Item
        content={t('g.categories')}
        active={screen === 'categories'}
        onClick={() => setScreen('categories')}
      />
      <Menu.Item
        content={t('s.services.title')}
        active={screen === 'services'}
        onClick={() => setScreen('services')}
      />
    </Menu>

    {(!screen || screen === 'categories') && <ServicesCategoriesList />}
    {(screen === 'services') && <ServicesList />}
  </InternalPage>
}