import { useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input
} from 'semantic-ui-react';

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";

import SiteHeader from '../framework/site_header';

export default function Login() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string
  }

  const [modal, setModal] = useState<modalType|null>(null);
  var [processing, setProcessing] = useState<boolean>(false);

  // login information states
  var [phone, setPhone] = useState<string|null>(null);
  var [password, setPassword] = useState<string|null>(null);

  const countryCode = '+966';
  var returnPath:any = null;
  
  if (localStorage.getItem('auth_token')) history.push('/')

  if(history.location.pathname === '/login')
    returnPath = () => { history.push('/') }
  else
    returnPath = () => history.go(0);

  // login
  const login = async () => {
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = 'en';
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.countryCode = '+966';
    EnsanCareLib.phone = phone;
    EnsanCareLib.password = password;
    EnsanCareLib.verificationChannel = 'phone';
    EnsanCareLib.verificationType = 'password';
    EnsanCareLib.userType = 'management';
    
    var res:any = await EnsanCareLib.login();
    
    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
      });

      setProcessing(false);
      return false;
    }

    if(res.status === 'success') {
      localStorage.setItem('auth_token', res.data.auth_token);
      localStorage.setItem('user_profile', JSON.stringify(res.data));

      getManager(res.data.user_uuid);
    }
    else {
      // set the error messages
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('s.auth.incorrectLoginInformation')
      });

      setProcessing(false);
      return false;
    }
  }

  const getManager = async (management_uuid:string) => {
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.managementUuid = management_uuid;

    var res:any = await EnsanCareLib.getManager();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
      });
    }

    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
      });
    }
    
    else if(res.status === "success" && res.data) {
      localStorage.setItem("user_details", JSON.stringify(res.data));
      returnPath();
    }

    setProcessing(false);
    return null;
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />
    <SiteHeader hideLogo />
    <Grid 
      textAlign='center'
      verticalAlign='middle'
      style={{height: '100vh' }}
      padded
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <div style={{justifyContent: 'center', display: 'flex'}}>
          <img src={Logo} width="100" />
        </div>
        
        <Header as='h1' textAlign='center'>
          {t('g.welcomeTo')} {t('g.appName')}
          <Header.Subheader>
            {t('s.auth.loginDescription')}
          </Header.Subheader>
        </Header>
        <Form size='large' onSubmit={() => login()}>
          <Form.Field>
            <Input
              required
              label={{basic: true,  children: countryCode}}
              labelPosition='left'
              value={(phone) ? phone : ''}
              onChange={(e, { value }) => {
                if(value[0] !== '0') setPhone(value)
              }}
              placeholder='56xxx'
              fluid
              autoComplete={false}
            />
          </Form.Field>
          <Form.Input
            required
            fluid
            icon='lock'
            iconPosition='left'
            placeholder={t('g.password')}
            type='password'
            onChange={(e, { value }) => setPassword(value)}
          />
          
          <Button 
            primary
            fluid 
            size='large'
            submit
            loading={processing}
          >
            {t('s.auth.login')}
          </Button>

          <br />
          
          <Button 
            fluid 
            onClick={() => history.push('/password-reset')}
            loading={processing}
          >
            {t('s.auth.resetMyPassword')}
          </Button>

        </Form>
      </Grid.Column>
    </Grid>
  </>
}