import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import EnsanCareAPI from '../../util/EnsanCareLib/EnsanCareAPI';
import ECGeneral from '../../util/EnsanCareLib/funcs/general';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu
} from 'semantic-ui-react';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";

export default function ServiceRequesterMemberMedicalInformation() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {member_id} = useParams<{member_id:string}>();

  const [isUpdating, setIsUpadting] = useState<boolean>(false);
  const [isLoadingMedicalInformation, setIsLoadingMedicalInformation] = useState<boolean>(false);
  const [medicalInformation, setMedicalInformation] = useState<any>(null);

  const [isLoadingMedicalInformationAllergies, setIsLoadingMedicalInformationAllergies] = useState<boolean>(false);
  const [medicalInformationAllergies, setMedicalInformationAllergies] = useState<any>(null);
  const [medicalInformationAllergiesArray, setMedicalInformationAllergiesArray] = useState<any>(null);

  const [isLoadingMedicalInformationChronicDiseases, setIsLoadingMedicalInformationChronicDiseases] = useState<boolean>(false);
  const [medicalInformationChronicDiseases, setMedicalInformationChronicDiseases] = useState<any>(null);
  const [medicalInformationChronicDiseasesArray, setMedicalInformationChronicDiseasesArray] = useState<any>(null);

  const [isLoadingExerciseRoutines, setIsLoadingExerciseRoutines] = useState<boolean>(false);
  const [exerciseRoutines, setExerciseRoutines] = useState<any[]>([]);
  
  const [isLoadingLevelOfActivities, setIsLoadingLevelOfActivities] = useState<boolean>(false);
  const [levelOfActivities, setLevelOfActivities] = useState<any[]>([]);
  
  const [isLoadingDiets, setIsLoadingDiets] = useState<boolean>(false);
  const [diets, setDiets] = useState<any[]>([]);
  
  const [isLoadingAllergies, setIsLoadingAllergies] = useState<boolean>(false);
  const [allergies, setAllergies] = useState<any[]>([]);
  
  const [isLoadingChronicDiseases, setIsLoadingChronicDiseases] = useState<boolean>(false);
  const [chronicDiseases, setChronicDiseases] = useState<any[]>([]);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getExerciseRoutines();
    getLevelOfActivities();
    getDiets();
    getAllergies();
    getChronicDiseases();
    getMedicalInforamtion();
    getMemberMedicalInformationAllergies();
    getMemberMedicalInformationChronicDiseases();
  }, []);

  const getExerciseRoutines = async () => {
    // get services
    if (isLoadingExerciseRoutines) return null;
    setIsLoadingExerciseRoutines(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');

    var res:any = await EnsanCareLib.getExerciseRoutines();
    
    if(!res || !res.code) {
      if(config.dev)
        console.error('Faield to complete the process getServiceRequester');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getExerciseRoutines()
          },
          {
            content: t('g.goBack'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === 'fail') {  
      setModal({
        visible: true,
        title: t('g.sorry'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getExerciseRoutines()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          }
        ]
      });
    }

    else if(res.status === 'success') {
      var tempObj:any[] = [];

      res.data.map((item: any, key:number) => {
        tempObj.push({
          key: key,
          value: item.id,
          text: ECGeneral.getFromLocal(
            i18n.language, item.local
          )
        })
      });
      
      setExerciseRoutines(tempObj);
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setIsLoadingExerciseRoutines(false);
  }

  const getLevelOfActivities = async () => {
    // get services
    if (isLoadingLevelOfActivities) return null;
    setIsLoadingLevelOfActivities(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');

    var res:any = await EnsanCareLib.getLevelOfActivities();
    
    if(!res || !res.code) {
      if(config.dev)
        console.error('Faield to complete the process getServiceRequester');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getLevelOfActivities()
          },
          {
            content: t('g.goBack'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.sorry'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getLevelOfActivities()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          }
        ]
      });
    }

    else if(res.status === 'success') {
      var tempObj:any[] = [];

      res.data.map((item: any, key:number) => {
        tempObj.push({
          key: key,
          value: item.id,
          text: ECGeneral.getFromLocal(
            i18n.language, item.local
          )
        })
      });
      
      setLevelOfActivities(tempObj);
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setIsLoadingLevelOfActivities(false);
  }

  const getDiets = async () => {
    // get services
    if (isLoadingDiets) return null;
    setIsLoadingDiets(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');

    var res:any = await EnsanCareLib.getDiets();
    
    if(!res || !res.code) {
      if(config.dev)
        console.error('Faield to complete the process getServiceRequester');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getDiets()
          },
          {
            content: t('g.goBack'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.sorry'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getDiets()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          }
        ]
      });
    }

    else if(res.status === 'success') {
      var tempObj:any[] = [];

      res.data.map((item: any, key:number) => {
        tempObj.push({
          key: key,
          value: item.id,
          text: ECGeneral.getFromLocal(
            i18n.language, item.local
          )
        })
      });
      
      setDiets(tempObj);
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setIsLoadingDiets(false);
  }

  const getAllergies = async () => {
    // get services
    if (isLoadingAllergies) return null;
    setIsLoadingAllergies(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');

    var res:any = await EnsanCareLib.getAllergies();
    
    if(!res || !res.code) {
      if(config.dev)
        console.error('Faield to complete the process getServiceRequester');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getAllergies()
          },
          {
            content: t('g.goBack'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.sorry'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getAllergies()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          }
        ]
      });
    }

    else if(res.status === 'success') {
      setAllergies(res.data);
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setIsLoadingAllergies(false);
  }

  const getChronicDiseases  = async () => {
    // get services
    if (isLoadingChronicDiseases) return null;
    setIsLoadingChronicDiseases(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');

    var res:any = await EnsanCareLib.getChronicDiseases();
    
    if(!res || !res.code) {
      if(config.dev)
        console.error('Faield to complete the process getServiceRequester');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getChronicDiseases ()
          },
          {
            content: t('g.goBack'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.sorry'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getChronicDiseases ()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          }
        ]
      });
    }

    else if(res.status === 'success') {
      setChronicDiseases(res.data);
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setIsLoadingChronicDiseases(false);
  }

  const getMedicalInforamtion = async () => {
    // get services
    if (isLoadingMedicalInformation) return null;
    setIsLoadingMedicalInformation(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.memberId = member_id;

    var res:any = await EnsanCareLib.getMemberMedicalInformation();
    
    if(!res || !res.code) {
      if(config.dev)
        console.error('Faield to complete the process getServiceRequester');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getMedicalInforamtion ()
          },
          {
            content: t('g.goBack'),
            onClick: () => null
          },
        ]
      });
    }

    if(res.status === 'fail') {  
      setModal({
        visible: true,
        title: t('g.sorry'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getMedicalInforamtion ()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          }
        ]
      });
    }

    else if(res.status === 'success') {
      setMedicalInformation(res.data);
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setIsLoadingMedicalInformation(false);
  }

  const getMemberMedicalInformationAllergies = async () => {
    // get services
    if (isLoadingMedicalInformationAllergies) return null;
    setMedicalInformationAllergies(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.memberId = member_id;

    var res:any = await EnsanCareLib.getMemberMedicalInformationAllergies();
    
    if(!res || !res.code) {
      if(config.dev)
        console.error('Faield to complete the process getServiceRequester');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getMedicalInforamtion ()
          },
          {
            content: t('g.goBack'),
            onClick: () => null
          },
        ]
      });
    }

    if(res.status === 'fail') {  
      setModal({
        visible: true,
        title: t('g.sorry'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getMedicalInforamtion ()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          }
        ]
      });
    }

    else if(res.status === 'success') {
      var data = res.data || [];

      setMedicalInformationAllergies(data);
      
      if(data < 1) setMedicalInformationAllergiesArray([]);
      else {
        var obj:any = [];

        data.map((allergy:any, key:number) => {
          obj.push(allergy.allergy_id);
        });

        setMedicalInformationAllergiesArray(obj);
      } 
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setMedicalInformationAllergies(false);
  }

  const getMemberMedicalInformationChronicDiseases = async () => {
    // get services
    if (isLoadingMedicalInformationChronicDiseases) return null;
    setMedicalInformationChronicDiseases(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.memberId = member_id;

    var res:any = await EnsanCareLib.getMemberMedicalInformationChronicDiseases();
    
    if(!res || !res.code) {
      if(config.dev)
        console.error('Faield to complete the process getServiceRequester');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getMemberMedicalInformationChronicDiseases ()
          },
          {
            content: t('g.goBack'),
            onClick: () => null
          },
        ]
      });
    }

    if(res.status === 'fail') {  
      setModal({
        visible: true,
        title: t('g.sorry'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getMemberMedicalInformationChronicDiseases ()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          }
        ]
      });
    }

    else if(res.status === 'success') {
      var data = res.data || [];
      setMedicalInformationChronicDiseases(data);
      
      if(data < 1) setMedicalInformationChronicDiseasesArray([]);
      else {
        var obj:any = [];

        data.map((chronicDiseas:any, key:number) => {
          obj.push(chronicDiseas.chronic_disease_id);
        });

        setMedicalInformationChronicDiseasesArray(obj);
      } 
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setMedicalInformationChronicDiseases(false);
  }

  const updateMemberMedicalInformation = async () => {
    if(isUpdating) return null;
    setIsUpadting(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.memberId = member_id;

    // list all fields
    EnsanCareLib.height = medicalInformation.height;
    EnsanCareLib.weight = medicalInformation.weight;
    EnsanCareLib.medical_conditions = medicalInformation.medical_conditions;
    EnsanCareLib.surgical_history = medicalInformation.surgical_history;
    EnsanCareLib.medication_routine = medicalInformation.medication_routine;
    EnsanCareLib.trauma = medicalInformation.trauma;
    EnsanCareLib.general_condition = medicalInformation.general_condition;
    EnsanCareLib.type_of_work_id = medicalInformation.type_of_work_id;
    EnsanCareLib.exercise_routine_id = medicalInformation.exercise_routine_id;
    EnsanCareLib.level_of_activities_id = medicalInformation.level_of_activities_id;
    EnsanCareLib.diet_id = medicalInformation.diet_id;
    EnsanCareLib.smoking = medicalInformation.smoking;
    EnsanCareLib.employed = medicalInformation.employed;
    EnsanCareLib.service_city_id = medicalInformation.service_city_id;
    EnsanCareLib.emergency_contact_name = medicalInformation.emergency_contact_name;
    EnsanCareLib.emergency_contact_phone = medicalInformation.emergency_contact_phone;
    
    var res:any = await EnsanCareLib.updateMemberMedicalInformation();

    if(!res || !res.code) {
      if(config.dev)
        console.error('Faield to complete the process getServiceRequester');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => updateMemberMedicalInformation()
          },
          {
            content: t('g.cancel'),
            onClick: () => getMedicalInforamtion()
          },
        ]
      });
    }

    if(res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => updateMemberMedicalInformation()
          },
          {
            content: t('g.cancel'),
            onClick: () => getMedicalInforamtion()
          },
        ]
      });
    }

    else if(res.status === 'success') {
      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            onClick: () => null
          },
        ]
      });

      getMedicalInforamtion();
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setIsUpadting(false);
    return null;
  }

  const toggleMemberMedicalInformationAllergies = async (itemId:any) => {
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.memberId = member_id;

    // list all fields
    EnsanCareLib.allergyId = itemId;
    
    var res:any = await EnsanCareLib.toggleMemberMedicalInformationAllergies();

    if(!res || !res.code) {
      if(config.dev)
        console.error('Faield to complete the process toggleMemberMedicalInformationAllergies');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => toggleMemberMedicalInformationAllergies(itemId)
          },
          {
            content: t('g.cancel'),
            onClick: () => getMedicalInforamtion()
          },
        ]
      });
    }

    if(res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => toggleMemberMedicalInformationAllergies(itemId)
          },
          {
            content: t('g.cancel'),
            onClick: () => getMedicalInforamtion()
          },
        ]
      });
    }

    else if(res.status === 'success') {
      var tempArray:any[] = [];

      if(medicalInformationAllergiesArray.includes(itemId)) {
        await medicalInformationAllergiesArray.map((mia:any, index:number) => {
          if(mia !== itemId) {
            tempArray.push(mia)
          }
        });

        setMedicalInformationAllergiesArray([...tempArray])
      }
      else {
        setMedicalInformationAllergiesArray([
          ...medicalInformationAllergiesArray,
          itemId
        ]);
      }
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }
  }

  const toggleMemberMedicalInformationChronicDiseas = async (itemId:any) => {
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.memberId = member_id;

    // list all fields
    EnsanCareLib.chronicDiseaseId = itemId;
    
    var res:any = await EnsanCareLib.toggleMemberMedicalInformationChronicDiseas();

    if(!res || !res.code) {
      if(config.dev)
        console.error('Faield to complete the process toggleMemberMedicalInformationChronicDiseas');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => toggleMemberMedicalInformationChronicDiseas(itemId)
          },
          {
            content: t('g.cancel'),
            onClick: () => getMedicalInforamtion()
          },
        ]
      });
    }

    if(res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => toggleMemberMedicalInformationChronicDiseas(itemId)
          },
          {
            content: t('g.cancel'),
            onClick: () => getMedicalInforamtion()
          },
        ]
      });
    }

    else if(res.status === 'success') {
      var tempArray:any[] = [];

      if(medicalInformationChronicDiseasesArray.includes(itemId)) {
        await medicalInformationChronicDiseasesArray.map((cdia:any, index:number) => {
          if(cdia !== itemId) {
            tempArray.push(cdia)
          }
        });

        setMedicalInformationChronicDiseasesArray([...tempArray])
      }
      else {
        setMedicalInformationChronicDiseasesArray([
          ...medicalInformationChronicDiseasesArray,
          itemId
        ]);
      }
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }
  }

  if(!medicalInformation) return <Grid>
    <Grid.Row>
      <Grid.Column>
        <Loader size='medium' active inline='centered' />
      </Grid.Column>
    </Grid.Row>
  </Grid>

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal?.title||null}
      content={modal?.message||null}
      actions={modal?.action||['Ok']}
    />

    <Header
      as='h2'
      content={t('s.serviceRequesters.medicalInformation')}
    />

    <Form>
      <Form.Group widths={2}>
        <Form.Input
          label={`${t('s.medicalInformation.height')} (${t('u.cm')})`}
          placeholder={t('g.typeHere')}
          value={medicalInformation?.height}
          onChange={(e, data) => setMedicalInformation({
            ...medicalInformation,
            height: data.value
          })}
        />
        <Form.Input
          label={`${t('s.medicalInformation.weight')} (${t('u.kg')})`}
          placeholder={t('g.typeHere')}
          value={medicalInformation?.weight}
          onChange={(e, data) => setMedicalInformation({
            ...medicalInformation,
            weight: data.value
          })}
        />
      </Form.Group>
      
      <Form.TextArea
        label={t('s.medicalInformation.medicalConditions')}
        placeholder={t('g.typeHere')}
        value={medicalInformation?.medical_conditions}
        onChange={(e, data) => setMedicalInformation({
          ...medicalInformation,
          medical_conditions: data.value
        })}
      />
      
      <Form.TextArea
        label={t('s.medicalInformation.surgicalHistory')}
        placeholder={t('g.typeHere')}
        value={medicalInformation?.surgical_history}
        onChange={(e, data) => setMedicalInformation({
          ...medicalInformation,
          surgical_history: data.value
        })}
      />
      
      <Form.TextArea
        label={t('s.medicalInformation.medicationRoutine')}
        placeholder={t('g.typeHere')}
        value={medicalInformation?.medication_routine}
        onChange={(e, data) => setMedicalInformation({
          ...medicalInformation,
          medication_routine: data.value
        })}
      />
      
      <Form.TextArea
        label={t('s.medicalInformation.trauma')}
        placeholder={t('g.typeHere')}
        value={medicalInformation?.trauma}
        onChange={(e, data) => setMedicalInformation({
          ...medicalInformation,
          trauma: data.value
        })}
      />
      
      <Form.TextArea
        label={t('s.medicalInformation.generalCondition')}
        placeholder={t('g.typeHere')}
        value={medicalInformation?.general_condition}
        onChange={(e, data) => setMedicalInformation({
          ...medicalInformation,
          general_condition: data.value
        })}
      />

      <Form.Group widths={3}>
        <Form.Dropdown
          clearable
          selection
          label={t('s.medicalInformation.exerciseRoutine')}
          placeholder={t('g.selectOne')}
          value={medicalInformation?.exercise_routine_id}
          options={exerciseRoutines}
          onChange={(e, data) => setMedicalInformation({
            ...medicalInformation,
            exercise_routine_id: data.value
          })}
        />
        <Form.Dropdown
          clearable
          selection
          label={t('s.medicalInformation.levelOfActivities')}
          placeholder={t('g.selectOne')}
          value={medicalInformation?.level_of_activities_id}
          options={levelOfActivities}
          onChange={(e, data) => setMedicalInformation({
            ...medicalInformation,
            level_of_activities_id: data.value
          })}
        />
        <Form.Dropdown
          clearable
          selection
          label={t('s.medicalInformation.diet')}
          placeholder={t('g.selectOne')}
          value={medicalInformation?.diet_id}
          options={diets}
          onChange={(e, data) => setMedicalInformation({
            ...medicalInformation,
            diet_id: data.value
          })}
        />
      </Form.Group>

      <Form.Dropdown
        clearable
        selection
        label={t('s.medicalInformation.smoking')}
        placeholder={t('g.selectOne')}
        value={medicalInformation?.smoking}
        options={[
          {key: 0, value: 'n', text: t('g.no')},
          {key: 1, value: 'y', text: t('g.yes')}
        ]}
        onChange={(e, data) => setMedicalInformation({
          ...medicalInformation,
          smoking: data.value
        })}
      />

      <Form.Dropdown
        clearable
        selection
        label={t('s.medicalInformation.employed')}
        placeholder={t('g.selectOne')}
        value={medicalInformation?.employed}
        options={[
          {key: 0, value: 'n', text: t('g.no')},
          {key: 1, value: 'y', text: t('g.yes')}
        ]}
        onChange={(e, data) => setMedicalInformation({
          ...medicalInformation,
          employed: data.value
        })}
      />
      
      <Divider section />

      <Form.Group>
        <Form.Button
          icon='arrow left'
          labelPosition='left'
          content={t('g.cancel')}
          onClick={() => history.goBack()}
        />
        <Form.Button
          primary
          content={t('g.save')}
          onClick={updateMemberMedicalInformation}
        />
      </Form.Group>

      <Divider hidden section />

      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <Header
              as='h3'
              content={t('s.medicalInformation.allergies')}
              dividing
            />
            <div>
              {allergies?.map((item:any, key:number) => {
                return <Form.Checkbox
                  label={ECGeneral.getFromLocal(
                    i18n.language, item.local
                  )}
                  onChange={() => toggleMemberMedicalInformationAllergies(item.id)}
                  checked={medicalInformationAllergiesArray?.includes(item.id)}
                />
              })}
            </div>
          </Grid.Column>
          <Grid.Column>
            <Header
              as='h3'
              content={t('s.medicalInformation.chronicDiseases')}
              dividing
            />
            <div>
              {chronicDiseases?.map((item:any, key:number) => {
                return <Form.Checkbox
                  label={ECGeneral.getFromLocal(
                    i18n.language, item.local
                  )}
                  onChange={() => toggleMemberMedicalInformationChronicDiseas(item.id)}
                  checked={medicalInformationChronicDiseasesArray?.includes(item.id)}
                />
              })}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  </>
}