import React from 'react';
import ReactDOM from 'react-dom';

// language service
import "./util/services/i18n";

import 'semantic-ui-css/semantic.min.css'
import 'semantic-ui-less/semantic.less'

import { 
  Router,
  Switch,
  Route
} from "react-router-dom";

import { createBrowserHistory } from "history";
import './styling.css';

import Login from './auth/login';
import PasswordReset from './auth/password_reset';
import Logout from './auth/logout';
import Default from './default';

import ManagementListing from './management/list';
import ManagementForm from './management/form';

import CaregiverForm from './caregivers/form';
import CaregiversListing from './caregivers/list';
import CaregiversUpdateRequests from './caregivers/update_requests/list';
import CaregiversExport from './caregivers/export_form';
import CaregiversReferral from './caregivers/referral_list';

import ServiceRequestersListing from './service_requesters/list';
import ServiceRequesterForm from './service_requesters/form';
import ServiceRequesterMembersNavigation from './service_requesters/members/navigation';

import BookingsList from './bookings/list';
import BookingDetails from './bookings/details';
import BookingSessionNavigation from './bookings/sessions/navigation';
import BookingSessionAdditionalChargesList from './bookings/sessions/additional_charges/list';
import BookingsExport from './bookings/export_form';

import BannersList from './settings/banners/list';

import ServicesMain from './settings/services/main';
import ServicesCategoryForm from './settings/services/category_form';
import ServicesServiceForm from './settings/services/service_form';
import SettingsList from './settings/list';
import SettingsGeneral from './settings/general/general';
import ServiceCitiesList from './settings/service_cities/list';
import ServiceCitiesForm from './settings/service_cities/form';
import NationalitiesList from './settings/nationalities/list';
import NationalitiesForm from './settings/nationalities/form';
import NotificationsList from './notifications/list';
import TransactionDetails from './bookings/transaction';
import AdditionalChargesList from './settings/additional_charges/list';
import AdditionalChargesForm from './settings/additional_charges/form';

const history = createBrowserHistory();

// check if the user is logged in
var auth:string|null = localStorage.getItem('auth_token');

// for testing purposes
// auth='true'';

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
    <Router history={history}>
      <Switch>
        {/** General screens */}
        <Route path="/login" children={<Login />} />
        <Route path="/password-reset" children={<PasswordReset />} />
        <Route path="/logout" children={<Logout />} />

        {/** Caregivers Screens */}
        <Route path="/caregivers/update-requests" children={auth ? <CaregiversUpdateRequests /> : <Login />} />
        <Route path="/caregivers/export" children={auth ? <CaregiversExport /> : <Login />} />
        <Route path="/caregivers/referral" children={auth ? <CaregiversReferral /> : <Login />} />
        <Route path="/caregivers/:caregiver_uuid" children={auth ? <CaregiverForm /> : <Login />} />
        <Route path="/caregivers" children={auth ? <CaregiversListing /> : <Login />} />

        {/** Service Requesters Screens */}
        <Route path="/service-requesters/:service_requester_uuid/members/:member_id" children={auth ? <ServiceRequesterMembersNavigation /> : <Login />} />
        <Route path="/service-requesters/:service_requester_uuid" children={auth ? <ServiceRequesterForm /> : <Login />} />
        <Route path="/service-requesters" children={auth ? <ServiceRequestersListing /> : <Login />} />


        {/** Bookings Screens */}
        <Route path="/bookings/payments/:transaction_uuid" children={auth ? <TransactionDetails /> : <Login />} />
        <Route path="/bookings/export" children={auth ? <BookingsExport /> : <Login />} />
        <Route path="/bookings/additional-charges" children={auth ? <BookingSessionAdditionalChargesList /> : <Login />} />
        <Route path="/bookings/:booking_uuid/sessions/:session_id" children={auth ? <BookingSessionNavigation /> : <Login />} />
        <Route path="/bookings/:booking_uuid" children={auth ? <BookingDetails /> : <Login />} />
        <Route path="/bookings" children={auth ? <BookingsList /> : <Login />} />

        {/** Management Screens */}
        <Route path="/management/create" children={auth ? <ManagementForm /> : <Login />} />
        <Route path="/management/show/:management_uuid" children={auth ? <ManagementForm /> : <Login />} />
        <Route path="/management" children={auth ? <ManagementListing /> : <Login />} />

        { /** Notifications */}
        <Route path="/notifications" children={auth ? <NotificationsList /> : <Login />} />

        {/** Settings Screens */}
        <Route path="/settings/banners" children={auth ? <BannersList /> : <Login />} />

        <Route path="/settings/services/category/create" children={auth ? <ServicesCategoryForm /> : <Login />} />
        <Route path="/settings/services/service/create" children={auth ? <ServicesServiceForm /> : <Login />} />
        <Route path="/settings/services/category/show/:category_id" children={auth ? <ServicesCategoryForm /> : <Login />} />
        <Route path="/settings/services/service/show/:service_id" children={auth ? <ServicesServiceForm /> : <Login />} />
        <Route path="/settings/services" children={auth ? <ServicesMain /> : <Login />} />

        <Route path="/settings/nationalities/create" children={auth ? <NationalitiesForm /> : <Login />} />
        <Route path="/settings/nationalities/show/:nationality_id" children={auth ? <NationalitiesForm /> : <Login />} />
        <Route path="/settings/nationalities" children={auth ? <NationalitiesList /> : <Login />} />

        <Route path="/settings/additional-charges/create" children={auth ? <AdditionalChargesForm /> : <Login />} />
        <Route path="/settings/additional-charges/show/:additional_charge_id" children={auth ? <AdditionalChargesForm /> : <Login />} />
        <Route path="/settings/additional-charges" children={auth ? <AdditionalChargesList /> : <Login />} />

        <Route path="/settings/service-cities/create" children={auth ? <ServiceCitiesForm /> : <Login />} />
        <Route path="/settings/service-cities/show/:service_city_id" children={auth ? <ServiceCitiesForm /> : <Login />} />
        <Route path="/settings/service-cities" children={auth ? <ServiceCitiesList /> : <Login />} />

        <Route path="/settings/general" children={auth ? <SettingsGeneral /> : <Login />} />

        <Route path="/settings" children={auth ? <SettingsList /> : <Login />} />

        <Route path="/" children={auth ? <Default /> : <Login />} />
      </Switch>
    </Router>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
