import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Segment,
  Image,
  Menu,
  Icon,
  Label,
  Divider
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../../framework/internal_page';

import EnsanCareAPI from "../../util/EnsanCareLib/EnsanCareAPI";
import ECGeneral from "../../util/EnsanCareLib/funcs/general"

export default function AdditionalChargesForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [actionReqeusted, setActionRequested] = useState<boolean>(true);

  const [additionalCharge, setAdditionalCharge] = useState<any>({
    id: null,
    description: null,
    price: 0,
    active: 'n'
  });

  const {additional_charge_id} = useParams<{additional_charge_id:string}>();

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    if(additional_charge_id) getAdditionalCharge();
  }, [])

  const getAdditionalCharge = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.additionalChargeId = additional_charge_id;

    var res:any = await EnsanCareLib.getAdditionalCharge();

    if(!res || !res.data) {
      if(config.dev) 
        console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: processSubmit
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });
    } 
   
    else if (res.status === 'success' && res.data) {
      if(config.dev) console.warn('no results found');
     
      var tempObj = {
        id: res.data.id || null,
        description: res.data.description || null,
        price: res.data.price || null,
        active: res.data.active || null
      }

      setAdditionalCharge(tempObj);
    }
    
    setIsLoading(false);
    return null;
  }

  const processSubmit = async () => {
    if(isLoading) return true;
    setIsLoading(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.description = additionalCharge.description;
    EnsanCareLib.price = additionalCharge.price;
    EnsanCareLib.active = additionalCharge.active;

    var res:any;

    if (!additionalCharge.id) {
      res = await EnsanCareLib.addAdditionalCharge();
    } 
  
    else {
      EnsanCareLib.additionalChargeId = additionalCharge.id;
      res = await EnsanCareLib.updateAdditionalCharge();
    }

    if (!res) {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.missingRequirements'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack
          },
        ]
      });

      if(config.dev)
        console.error('Failed to complete update or add additional charge');
    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: processSubmit
          },
        ]
      });
    }

    else if (res.status === 'success') {
      // modal for adding a new additional charge
      if(!additionalCharge.id && res.data.id) { 
        setModal({
          visible: true,
          title: t('g.processCompleted'),
          message: t('g.processCompletedMessage'),
          action: [
            {
              content: t('g.ok'),
              positive: true,
              onClick: () => history.push('../additional-charges/show/' + res.data.id)
            },
          ]
        });
      }

      // modal for updating additional charge
      else{
        setModal({
          visible: true,
          title: t('g.processCompleted'),
          message: t('g.processCompletedMessage'),
          action: [
            {
              content: t('g.ok'),
              positive: true,
              onClick: () => null
            },
          ]
        });
      }
    }
    
    setIsLoading(false);
    return null;
  }

  const processDelete = async () =>{
    if(isLoading) return true;
    setIsLoading(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.additionalChargeId = additional_charge_id;

    var res:any = await EnsanCareLib.deleteAdditionalCharge();
    
    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: processDelete
          },
        ]
      });
    }

    else if (res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: processDelete
          },
        ]
      });
    }

    else if (res.status === "success") {
      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            onClick: () => history.push('../')
          },
        ]
      });
    }

    setIsLoading(false);
    return null;
  }

  const _renderDeleteBtn = () => {
    if(additional_charge_id) {
      if(actionReqeusted){
        return <>
          <Button
            negative
            content={t('g.delete')}
            onClick={() => {setActionRequested(false)}}
          />
        </>
      }
      else {
        return <>
          <Button
            color='yellow'
            content={t('g.confirm')}
            onClick={() => {processDelete()}}
          />
          <Button
            content={t('g.cancel')}
            onClick={() => setActionRequested(true)}
          />
        </>
      }
    }
  }
  
  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.action : null}
    />
    
    <Header
      as='h1'
      content={'# ' + additionalCharge.id || '...'}
    />

    <Form>
      <Form.Select
        label={t('g.active')}
        placeholder={t('g.selectOne')}
        value={additionalCharge.active || null}
        onChange={(e, { value }) => setAdditionalCharge({
          ...additionalCharge,
          active: value||null
        })}
        options={[
          {key: 'n', value: 'n', text: t('g.no')},
          {key: 'y', value: 'y', text: t('g.yes')}
        ]}
      />

      <Form.Input
        label={t('g.description')}
        placeholder={additionalCharge.description ? additionalCharge.description : t('g.typeHere')}
        value={additionalCharge.description || ''}
        onChange={(e, data) => setAdditionalCharge({
          ...additionalCharge,
          description: data.value||''
        })}
      />
      
      <Form.Input
        label={t('g.price')}
        placeholder={0}
        value={additionalCharge.price}
        onChange={(e, data) => setAdditionalCharge({
          ...additionalCharge,
          price: data.value
        })}
      />
    </Form>

    <Divider hidden />

    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Button
            icon='arrow left'
            labelPosition='left'
            content={t('g.cancel')}
            onClick={() => history.push('./')}
          />
          {_renderDeleteBtn()}
        </Grid.Column>
        <Grid.Column floated="right" textAlign="right">
          <Button
            primary
            content={t('g.save')}
            onClick={() => processSubmit()}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </InternalPage>
}