import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import EnsanCareAPI from '../../util/EnsanCareLib/EnsanCareAPI';
import ECGeneral from '../../util/EnsanCareLib/funcs/general';
import ECServiceCities from '../../util/EnsanCareLib/funcs/service_cities';
import ServiceCitiesForm from '../../settings/service_cities/form';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu
} from 'semantic-ui-react';

// import app config
import { config } from '../../config';

// components
import Map from '../../framework/map';

// translation
import { useTranslation } from "react-i18next";

export default function ServiceRequesterMemberGeneral() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {member_id} = useParams<{member_id:string}>();
  
  const [isUpdating, setIsUpadting] = useState<boolean>(false);
  const [isLoadingMember, setIsLoadingMember] = useState<boolean>(false);
  const [member, setMember] = useState<any>(null);

  const [isLoadingServiceCities, setIsLoadingServiceCities] = useState<boolean>(false);
  const [serviceCities, setServiceCities] = useState<any[any]>([]);

  const [isLoadingNationalities, setIsLoadingNationalities] = useState<boolean>(false);
  const [nationalities, setNationalities] = useState<any[any]>([]);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getNationalities();
    getServiceCities();
    getMember();
  }, []);
  
  const getServiceCities = async () => {
    if(isLoadingServiceCities) return null;
    setIsLoadingServiceCities(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    
    var res:any = await EnsanCareLib.getServiceCities();
    
    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
      return null;
    }

    var serviceCitiesObj:any[any] = [];

    res.data.map((item:any) => {
      serviceCitiesObj.push({
        key: item.id,
        value: item.id,
        text: ECServiceCities.buildLabel(i18n.language, item)
      })
    })
    
    setServiceCities(serviceCitiesObj);
    setIsLoadingServiceCities(false);
  }

  
  const getNationalities = async () => {
    if(isLoadingServiceCities) return null;
    setIsLoadingNationalities(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.showAll = 'y';
    EnsanCareLib.orderBy = 'label';
    
    var res:any = await EnsanCareLib.getNationalities();
    
    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
      return null;
    }

    var nationalitiesObj:any[any] = [];

    res.data.map((item:any) => {
      nationalitiesObj.push({
        key: item.id,
        value: item.id,
        text: ECGeneral.getFromLocal(i18n.language, item.local)
      })
    })
    
    setNationalities(nationalitiesObj);
    setIsLoadingNationalities(false);
  }

  const getMember = async () => {
    if(!member_id) {
      setModal({
        visible: true,
        title: t('g.error'),
        message: t('g.missingRequirements') + ' member ID',
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getMember()
          }
        ]
      });

      return null;
    }

    if(isLoadingMember) return null;
    setIsLoadingMember(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.memberId = member_id;

    var res:any = await EnsanCareLib.getMember();

    if(!res || !res.data) {
      if(config.dev)
        console.error('Faield to complete the process getServiceRequester');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => history.goBack()
          },
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });
    }

    else if (res.data) {
      setMember({
        ...res.data,
        dob: _renderDate(res.data.dob)
      });
    }

    setIsLoadingMember(false);
    return null;
  }

  const updateMember = async () => {
    if(isUpdating) return null;
    setIsUpadting(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.memberId = member_id;

    // list all fields
    EnsanCareLib.first_name = member.first_name;
    EnsanCareLib.middle_name = member.middle_name;
    EnsanCareLib.last_name = member.last_name;
    EnsanCareLib.dob = member.dob;
    EnsanCareLib.gender = member.gender;
    EnsanCareLib.nationality_id = member.nationality_id;
    EnsanCareLib.legal_id_type = member.legal_id_type;
    EnsanCareLib.legal_id = member.legal_id;
    EnsanCareLib.marital_status = member.marital_status;
    EnsanCareLib.number_of_children = member.number_of_children;
    EnsanCareLib.relationship = member.relationship;
    EnsanCareLib.default_coords = member.default_coords;
    EnsanCareLib.address_label = member.address_label;
    EnsanCareLib.address_1 = member.address_1;
    EnsanCareLib.address_2 = member.address_2;
    EnsanCareLib.service_city_id = member.service_city_id;
    EnsanCareLib.emergency_contact_name = member.emergency_contact_name;
    EnsanCareLib.emergency_contact_phone = member.emergency_contact_phone;
    
    var res:any = await EnsanCareLib.updateMember();

    if(!res || !res.code) {
      if(config.dev)
        console.error('Faield to complete the process getServiceRequester');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => updateMember()
          },
          {
            content: t('g.cancel'),
            onClick: () => getMember()
          },
        ]
      });
    }

    if(res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => updateMember()
          },
          {
            content: t('g.cancel'),
            onClick: () => getMember()
          },
        ]
      });
    }

    else if(res.status === 'success') {
      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            onClick: () => null
          },
        ]
      });

      getMember();
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setIsUpadting(false);
    return null;
  }

  const _renderDate = (date:string|null) => {
    var receivedDate:string|null = date;

    if(receivedDate) receivedDate = receivedDate.split('T')[0];

    return receivedDate;
  }

  const _renderMap = () => {
    var location:any = null;
    var marker:any[] = [];

    if(member.default_coords) {
      location = member.default_coords;
      location = ECServiceCities.convertCoordsToObject(location);
  
      marker = [{
        title: member.address_label,
        location: location
      }];
    }
    
    return <div style={{overflow: 'hidden', borderRadius: 10}}>
      <Map 
        center={location}
        markers={marker}
        options={{
          draggableCursor: "default",
          draggingCursor: "pointer",
          fullscreenControl: false,
        }}
        height={500}
        onClick={(ev:any) => {
          setMember({
            ...member,
            default_coords: {
              latitude: ev.lat, longitude: ev.lng
            }
          })
        }}
      />
    </div>
  }

  if(!member) return <Grid>
    <Grid.Row>
      <Grid.Column>
        <Loader size='medium' active inline='centered' />
      </Grid.Column>
    </Grid.Row>
  </Grid>

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal?.title||null}
      content={modal?.message||null}
      actions={modal?.action||['Ok']}
    />

    <Header
      as='h2'
      content={t('g.generalInformation')}
    />

    <Form>
      <Form.Group widths={3}>
        <Form.Input
          label={t('s.profile.firstName')}
          placeholder={t('g.typeHere')}
          value={member?.first_name}
          onChange={(e, data) => setMember({
            ...member,
            first_name: data.value
          })}
        />
        <Form.Input
          label={t('s.profile.middleName')}
          placeholder={t('g.typeHere')}
          value={member?.middle_name}
          onChange={(e, data) => setMember({
            ...member,
            middle_name: data.value
          })}
        />
        <Form.Input
          label={t('s.profile.lastName')}
          placeholder={t('g.typeHere')}
          value={member?.last_name}
          onChange={(e, data) => setMember({
            ...member,
            last_name: data.value
          })}
        />
      </Form.Group>
      
      <Form.Group widths={2}>
        <Form.Input
          label={`${t('s.profile.dateOfBirth')} (YYYY-MM-DD)`}
          placeholder={t('g.typeHere')}
          value={_renderDate(member.dob)}
          onChange={(e, data) => { 
            console.log(_renderDate(data.value))
            setMember({
              ...member,
              dob: _renderDate(data.value)
            })
          }}
        />
        <Form.Dropdown
          selection
          label={t('s.profile.gender')}
          placeholder={t('g.selectOne')}
          value={member?.gender}
          options={[
            { id: 1, value: 'male', text: t('s.profile.male')},
            { id: 2, value: 'female', text: t('s.profile.female')}
          ]}
          onChange={(e, data) => setMember({
            ...member,
            gender: data.value
          })}
        />
      </Form.Group>


      <Form.Group widths={3}>
        <Form.Dropdown
          selection
          loading={isLoadingNationalities}
          label={t('s.profile.nationality')}
          placeholder={t('g.selectOne')}
          value={member?.nationality_id}
          options={nationalities}
          onChange={(e, data) => setMember({
            ...member,
            nationality_id: data.value
          })}
        />
        <Form.Dropdown
          selection
          label={t('s.profile.legalIdType')}
          placeholder={t('g.selectOne')}
          value={member?.legal_id_type}
          options={[
            {id: 0, value: 'nid', text: t('s.profile.nationalId')},
            {id: 1, value: 'iqama', text: t('s.profile.iqama')},
          ]}
          onChange={(e, data) => setMember({
            ...member,
            legal_id_type: data.value
          })}
        />
        <Form.Input
          label={t('s.profile.legalId')}
          placeholder={t('g.typeHere')}
          value={member?.legal_id}
          onChange={(e, data) => setMember({
            ...member,
            legal_id: data.value
          })}
        />
      </Form.Group>

      <Form.Group widths={3}>
        <Form.Dropdown
          selection
          label={t('s.profile.maritalStatus')}
          placeholder={t('g.selectOne')}
          value={member?.marital_status}
          options={[
            {id: 0, value: 'single', text: t('s.profile.single')},
            {id: 2, value: 'married', text: t('s.profile.married')},
            {id: 2, value: 'widowed', text: t('s.profile.widowed')},
            {id: 2, value: 'divorced', text: t('s.profile.divorced')},
          ]}
          onChange={(e, data) => setMember({
            ...member,
            marital_status: data.value
          })}
        />
        <Form.Input
          label={t('s.profile.numberOfChildren')}
          placeholder={t('g.typeHere')}
          value={member?.number_of_children}
          onChange={(e, data) => setMember({
            ...member,
            number_of_children: data.value
          })}
        />
        <Form.Dropdown
          selection
          label={t('s.profile.relationship')}
          placeholder={t('g.selectOne')}
          clearable
          value={member?.relationship}
          options={[
            {id: 0, value: 'grandparent', text: t('s.profile.grandparent')},
            {id: 2, value: 'parent', text: t('s.profile.parent')},
            {id: 3, value: 'sibling', text: t('s.profile.sibling')},
            {id: 4, value: 'son-daughter', text: t('s.profile.son-daughter')},
            {id: 5, value: 'cousin', text: t('s.profile.cousin')},
            {id: 6, value: 'partner', text: t('s.profile.partner')},
            {id: 7, value: 'nephew-niece', text: t('s.profile.nephew-niece')},
          ]}
          onChange={(e, data) => setMember({
            ...member,
            relationship: data.value
          })}
        />
      </Form.Group>

      <Divider hidden section />

      <Header
        as='h3'
        content={t('g.address')}
      />

      <Form.Input
        label={t('g.addressLabel')}
        placeholder={t('g.typeHere')}
        value={member?.address_label}
        onChange={(e, data) => setMember({
          ...member,
          address_label: data.value
        })}
      />

      <Form.Group widths={2}>
        <Form.Input
          label={t('g.address1')}
          placeholder={t('g.typeHere')}
          value={member?.address_1}
          onChange={(e, data) => setMember({
            ...member,
            address_1: data.value
          })}
        />
        <Form.Input
          label={t('g.address2')}
          placeholder={t('g.typeHere')}
          value={member?.address_2}
          onChange={(e, data) => setMember({
            ...member,
            address_2: data.value
          })}
        />
      </Form.Group>

      <Form.Dropdown
        selection
        loading={isLoadingServiceCities}
        label={t('s.serviceCities.serviceCity')}
        placeholder={t('g.selectOne')}
        value={member?.service_city_id}
        options={serviceCities}
        onChange={(e, data) => setMember({
          ...member,
          service_city_id: data.value
        })}
      />

      <Form.Field label={t('g.addressCoords')} />

      {_renderMap()}

      <Divider hidden section />

      <Header
        as='h2'
        content={t('g.emergencyContact')}
      />

      <Form.Field>
        <Form.Input
          label={t('s.profile.fullName')}
          placeholder={t('g.typeHere')}
          value={member?.emergency_contact_name}
          onChange={(e, data) => setMember({
            ...member,
            emergency_contact_name: data.value
          })}
        />
      </Form.Field>

      <Form.Field>
        <Form.Input
          label={t('g.phone')}
          placeholder={t('g.typeHere')}
          value={member?.emergency_contact_phone}
          onChange={(e, data) => setMember({
            ...member,
            emergency_contact_phone: data.value
          })}
        />
      </Form.Field>
      
      <Form.Group>
        <Form.Field>
          <Form.Button 
            content='Update'
            onClick={updateMember}
          />
        </Form.Field>
      </Form.Group>
    </Form>

    <Divider section hidden />

    <Segment color='red'>
      <Header
        as='h2'
        content={`${t('g.delete')} ${t('s.serviceRequesters.member')}`}
      />

      <div>{t('s.serviceRequesters.deleteMemberDescription')}</div>
      
      <Divider hidden />

      <Button
        content={t('g.delete')}
        color='red'
        onClick={() => setModal({
          visible: true,
          title: `${t('g.delete')} ${t('g.account')}`,
          message: t('g.processIsNotReversable'),
          action: [
            {
              content: t('g.cancel'),
              onClick: () => setModal(null)
            },
            {
              content: t('g.confirm'),
              color: 'red',
              onClick: () => null
            }
          ]
        })}
      />
    </Segment>
  </>
}