import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import EnsanCareAPI from '../util/EnsanCareLib/EnsanCareAPI';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu
} from 'semantic-ui-react';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";

export default function ServiceRequesterGeneral() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {service_requester_uuid} = useParams<{service_requester_uuid:string}>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serviceRequester, setServiceReqeuster] = useState<any>(null); 

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getServiceRequester();
  }, []);

  const getServiceRequester = async () => {
    if(!service_requester_uuid) {
      setModal({
        visible: true,
        title: t('g.error'),
        message: t('g.missingRequirements') + ' service requester UUID',
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          }
        ]
      });

      return null;
    }

    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.serviceRequester = service_requester_uuid;

    var res:any = await EnsanCareLib.getServiceRequester();

    if(!res || !res.data) {
      if(config.dev)
        console.error('Faield to complete the process getServiceRequester');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServiceRequester()
          },
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });
    }

    else if(res.status === 'fail') { 
      setModal({
        visible: true,
        title: t('g.sorry'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServiceRequester()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          }
        ]
      });
    }

    else if (res.data) {
      setServiceReqeuster(res.data);
    }

    setIsLoading(false);
    return null;
  }

  if(!serviceRequester) return <Grid>
    <Grid.Row>
      <Grid.Column>
        <Loader size='medium' active inline='centered' />
      </Grid.Column>
    </Grid.Row>
  </Grid>

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal?.title||null}
      content={modal?.message||null}
      actions={modal?.action||['Ok']}
    />

    <Header
      as='h2'
      content={t('g.generalInformation')}
    />

    <Form>
      <Form.Input
        label={t('s.profile.cachedRating')}
        value={serviceRequester.cached_rating}
        disabled
      />
    </Form>
  </>
}