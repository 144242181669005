import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu,
  Dropdown,
  Icon
} from 'semantic-ui-react';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";

import EnsanCareAPI from "../../util/EnsanCareLib/EnsanCareAPI";
import ECServiceRequesters from '../../util/EnsanCareLib/funcs/service_requesters';

import moment from 'moment';
import remoteAssetsUrl from '../../remote_assets_uri.json';

export default function ServiceRequesterMemberDocuments() {
  const host = config.dev ? config.d.API : config.p.API;
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {service_requester_uuid} = useParams<{service_requester_uuid:string}>();
  const {member_id} = useParams<{member_id:string}>();

  const [isLoading, setIsLoading] = useState<boolean>(false); 
  const [isUploading, setIsUploading] = useState<boolean>(false); 

  const [newDocumentTitle, setNewDocumentTitle] = useState<any>(null);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [selectedRelatedTo, setSelectedRelatedTo] = useState<any>(null);

  const [documents, setDocuments] = useState<Array<Object>>([]);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    actions: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getDocuments();
  }, []);

  const uploadDocument = () => {
    if(isUploading) return null;
    setIsUploading(true);

    var baseAPI = config.dev ? config.d.API : config.p.API;
    var authToken = localStorage.getItem('auth_token');

    const data:any = new FormData() 

    data.append("document", selectedDocument);
    data.append("title", newDocumentTitle);
    data.append("related_to", selectedRelatedTo);
    data.append("service_requester_uuid", service_requester_uuid);
    data.append("member_id", member_id);

    fetch(
      `${baseAPI}users/service-requesters/documents`,
      {
        method: "POST",
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
        body: data
      }
    )
    .then(response => response.json())
    .then(res => {
      if(!res || res.status === 'fail') {
        if(config.dev) console.warn('No results from server', res);

        setModal({
          visible: true,
          title: t("g.failedToProcessRequest"),
          message: t("g.failedToProcessRequestMessage"),
          actions: [{ content: t('g.ok') }]
        });
      }
      else {
        getDocuments();
        setNewDocumentTitle(null);
        setSelectedDocument(null);
      }
    })
    .catch(e => {
      if(config.dev) console.warn(e);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{ content: t('g.ok') }]
      });
    });

    setIsUploading(false);
    return null;
  }

  const getDocuments = async (page:number = 1) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.serviceReqeusterUuid = service_requester_uuid;
    EnsanCareLib.memberId = member_id;
    
    EnsanCareLib.showAll = 'y';

    var res:any = await EnsanCareLib.getServiceRequesterDocuments();


    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        actions: [
          {
            content: t('g.tryAgain'),
            onClick: () => getDocuments()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        actions: [
          {
            content: t('g.tryAgain'),
            onClick: () => getDocuments()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
      setDocuments(res.data);
    }
    
    setIsLoading(false);
    return null;
  }

  const _renderLoadingRow = () => {
    return <Table.Row>
      <Table.Cell colSpan={5} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderRows = () => {
    if(!documents || documents.length < 1) { 
      return <Table.Row disabled>
        <Table.Cell>
          {t('g.noResults')}
        </Table.Cell>
      </Table.Row>
    };

    return documents.map((document:any, key:number) => {console.log(document)
      return (
        <Table.Row>
          <Table.Cell width={8}>{document.uuid}</Table.Cell>
          <Table.Cell>{document.ext}</Table.Cell>
          <Table.Cell>{document.size}</Table.Cell>
          <Table.Cell>{moment(document.size).format(config.momentDateTimeFormat)}</Table.Cell>
          <Table.Cell textAlign='right'>
            <Button
              size='tiny'
              content={t('g.view')}
              onClick={() => window.open(
                `${host}${remoteAssetsUrl.users_documents}${document.service_requester_uuid}/${document.member_id}/documents/${document.uuid}.${document.ext}`, 
                '_blank'
              )}
            />
          </Table.Cell>
        </Table.Row>
      )
    })
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />

    <Table color='blue' striped>
      <Table.Header>
        <Table.HeaderCell width={2}>{t('g.uuid')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.fileExtension')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.size')} ({t('u.mb')})</Table.HeaderCell>
        <Table.HeaderCell>{t('g.createdAt')}</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderRows()}
        {isLoading && _renderLoadingRow()}
      </Table.Body>
    </Table>

    <Divider hidden />

    <Form id='upload-form' loading={isUploading} onSubmit={uploadDocument}>
      <Form.Input
        fluid
        required
        label={t('g.title')}
        value={newDocumentTitle}
        onChange={(e, data) => {
          setNewDocumentTitle(data.value)
        }}
      />     
      <Form.Select
        fluid
        required
        label={t('g.relatedTo')}
        placeholder={t('g.selectOne')}
        options={[
          { key: 'general', text: t('g.general'), value: 'general' },
          { key: 'medical document', text: 'Medical Document', value: 'medical document' }
        ]}
        onChange={(e, { value }) => setSelectedRelatedTo(value)}
      /> 
      <Form.Input
        required
        type='file'
        accept='image/jpeg,application/pdf'
        onChange={(event, e) => {
          if(event && event.target && event.target.files) {
            var file:any = event.target.files[0];
            setSelectedDocument(file);
          }
        }}
      />
      <Form.Button
        primary
        icon={'upload'}
        content={`${t('g.upload')} ${t('g.document')}`}
        type={'submit'}
      />
    </Form>
  </>
}