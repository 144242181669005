import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import EnsanCareAPI from '../../util/EnsanCareLib/EnsanCareAPI';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu,
  Image
} from 'semantic-ui-react';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";

export default function ServiceRequesterMemberInsuranceCards() {
  const host = config.dev ? config.d.API : config.p.API;
  const assetsUris = require('../../remote_assets_uri.json');

  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {service_requester_uuid} = useParams<{service_requester_uuid:string}>();
  const {member_id} = useParams<{member_id:string}>();
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [insuranceCards, setInsuranceCards] = useState<any[]>([]);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getInsuranceCards();
  }, []);  

  const getInsuranceCards = async () => {
    if(!member_id) {
      setModal({
        visible: true,
        title: t('g.error'),
        message: t('g.missingRequirements') + ' member ID',
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          }
        ]
      });

      return null;
    }

    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.memberId = member_id;

    var res:any = await EnsanCareLib.getMemberInsurancesInformation();
    
    if(!res || !res.data) {
      if(config.dev)
        console.error('Faield to complete the process getMemberInsuranceInformation');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getInsuranceCards()
          },
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });
    }

    else if (res.data) {
      setInsuranceCards(res.data);
    }

    setIsLoading(false);
    return null;
  }

  const deleteCard = async (id:number) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.insuranceInformationId = id;

    var res:any = await EnsanCareLib.deleteMemberInsuranceInformation();
    
    if(!res || res.status !== 'success') {
      if(config.dev)
        console.error('Faield to complete the process getMemberInsuranceInformation');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => deleteCard(id)
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else {
      getInsuranceCards();
    }

    setIsLoading(false);
    return null;
  }

  if(!insuranceCards || isLoading) return <Grid>
    <Grid.Row>
      <Grid.Column>
        <Loader size='medium' active inline='centered' />
      </Grid.Column>
    </Grid.Row>
  </Grid>

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal?.title||null}
      content={modal?.message||null}
      actions={modal?.action||['Ok']}
    />

    <Header
      as='h2'
      content={t('s.serviceRequesters.insuranceCards')}
    />

    <Grid columns={4}>
      <Grid.Row>
        {insuranceCards.map((card:any, key:number) => {
          var imagePath = host + assetsUris.users_documents;
          imagePath += service_requester_uuid;
          imagePath += `/${card.member_id}/insurance_cards/${card.id}.png`;

          var expDate = card.expiration_date;

          if(expDate) {
            expDate = expDate.split('-');

            if(expDate.length > 0) {
              expDate = expDate[0] + '-' + expDate[1];
            }
            else return '/** Error';
          }

          return <Grid.Column>
          <Segment>
            <Header
              as='h3'
              content={card.provider}
              subheader={`#${card.id}`}
            />

            <Image
              src={imagePath}
              fluid
              rounded
            />

            <div style={{marginTop: 15}}>
              <div>
                {t('s.serviceRequesters.insuranceCardNumber')}: 
                <span> <strong>{card.number}</strong></span>
              </div>
              <div>{t('g.expiration')}: {expDate}</div>
            </div>

            <Divider />

            <div>
              <Button 
                size='tiny'
                content={t('g.view')}
                onClick={() => window.open(imagePath, '_blank')}
              />
              <Button 
                color='red'
                size='tiny'
                content={t('g.delete')}
                onClick={() => setModal({
                  visible: true,
                  title: `${t('g.delete')} ${t('g.account')}`,
                  message: t('g.processIsNotReversable'),
                  action: [
                    {
                      content: t('g.cancel'),
                      onClick: () => setModal(null)
                    },
                    {
                      content: t('g.confirm'),
                      color: 'red',
                      onClick: () => deleteCard(card.id)
                    }
                  ]
                })}
              />
            </div>
          </Segment>
        </Grid.Column>
        })}        
      </Grid.Row>
    </Grid>

  </>
}