import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Menu
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../../framework/internal_page';

import CaregiversScheduleUpdateRequests from './schedule_updates';
import CaregiversServicesUpdateRequests from './services_updates';
import CaregiversProfileUpdateRequests from './profile_updates';

import { URLQuery } from '../../util/tools';


export default function CaregiversUpdateRequests() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [section, setSection] = useState<'profile'|'schedule'|'services'>('profile'); 
  
  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const urlSearchTerm = URLQuery('searchTerm', window) || null;

  useEffect(() => {
    setSearchTerm(urlSearchTerm);
    setIsReady(true);
  }, []);

  return <InternalPage loading={!isReady}>
    <Header
      as='h1'
      content={t('s.caregivers.updateRequests')}
    />

    <Form>
      <Form.Input
        icon={'search'}
        placeholder={t('g.search')}
        onChange={(e,data) => setSearchTerm(data.value)}
        value={searchTerm}
      />
    </Form>

    <Menu stackable>
      <Menu.Item
        content={t('s.profile.title')}
        active={section === 'profile'}
        onClick={() => setSection('profile')}
      />
      <Menu.Item
        content={t('g.schedule')}
        active={section === 'schedule'}
        onClick={() => setSection('schedule')}
      />
      <Menu.Item
        content={t('s.services.title')}
        active={section === 'services'}
        onClick={() => setSection('services')}
      />
    </Menu>
    
    {(!section || section === 'profile') && 
      <CaregiversProfileUpdateRequests 
        searchTerm={searchTerm}
      />}
    {(section === 'schedule') && 
      <CaregiversScheduleUpdateRequests 
        searchTerm={searchTerm}
      />}
    {(section === 'services') && 
      <CaregiversServicesUpdateRequests 
        searchTerm={searchTerm}
      />}
  </InternalPage>
}