import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu
} from 'semantic-ui-react';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import Map from '../framework/map';

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";
import ECGeneral from '../util/EnsanCareLib/funcs/general';
import moment from 'moment';


export default function CaregiverGeneral() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {caregiver_uuid} = useParams<{caregiver_uuid:string}>();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [caregiver, setCaregiver] = useState<any>(null);

  const [nationalities, setNationalities] = useState<any[any]>([]);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getCaregiver();
    getNationalities();
  }, [])

  const getCaregiver = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.caregiverUuid = caregiver_uuid;

    var res:any = await EnsanCareLib.getCaregiver();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiver()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiver()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success') {
      if(res.data) {

        var tempCaregiver:any = res.data;
        tempCaregiver.first_name_local = {
          ar: ECGeneral.getFromLocal("ar",res.data.first_name_local) || null,
          en: ECGeneral.getFromLocal("en",res.data.first_name_local) || null,
        };

        tempCaregiver.middle_name_local = {
          ar: ECGeneral.getFromLocal("ar",res.data.middle_name_local) || null,
          en: ECGeneral.getFromLocal("en",res.data.middle_name_local) || null,
        }
        
        tempCaregiver.last_name_local = {
          ar: ECGeneral.getFromLocal("ar",res.data.last_name_local) || null,
          en: ECGeneral.getFromLocal("en",res.data.last_name_local) || null,
        }

        tempCaregiver.cached_rating = tempCaregiver.cached_rating?.toFixed(2) || 0;

        if(tempCaregiver.commission) {
          tempCaregiver.commission = (tempCaregiver.commission * 100).toFixed(2);
        }

        setCaregiver(tempCaregiver);
      } 
    }
    
    setIsLoading(false);
    return null;
  }

  const getNationalities = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.showAll = 'y';
    EnsanCareLib.orderBy = 'label';

    var res:any = await EnsanCareLib.getNationalities();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getNationalities()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getNationalities()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success') {
      if(res.data) {
        var nationalitiesObj:any[any] = [];

        res.data.map((item:any) => {
          nationalitiesObj.push({
            key: item.id,
            value: item.id,
            text: ECGeneral.getFromLocal(i18n.language, item.local)
          })
        })
      
        setNationalities(nationalitiesObj);
      } 
    }
    
    setIsLoading(false);
    return null;
  }

  const _renderDate = (date:string|null) => {
    var receivedDate:string|null = date;

    if(receivedDate) receivedDate = receivedDate.split('T')[0];

    return receivedDate;
  }

  const processSubmit = async () => {
    if(isLoading) return true;
    setIsLoading(true);

    var tempCaregiver = {
      ...caregiver,
      dob: caregiver.dob?.substring(0, caregiver.dob.indexOf('T'))
    }

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.caregiverUuid = caregiver_uuid;

    EnsanCareLib.name_prefix = tempCaregiver.name_prefix;
    EnsanCareLib.first_name_local = tempCaregiver.first_name_local;
    EnsanCareLib.middle_name_local = tempCaregiver.middle_name_local;
    EnsanCareLib.last_name_local = tempCaregiver.last_name_local;
    EnsanCareLib.gender = tempCaregiver.gender;
    EnsanCareLib.nationality_id = tempCaregiver.nationality_id;
    EnsanCareLib.legal_id_type = tempCaregiver.legal_id_type;
    EnsanCareLib.legal_id = tempCaregiver.legal_id;
    EnsanCareLib.cached_rating = tempCaregiver.cached_rating;
    EnsanCareLib.commission = tempCaregiver.commission / 100;

    if(caregiver.dob?.indexOf('T') < 0){
      EnsanCareLib.dob = caregiver.dob;
    } 

    else {
      EnsanCareLib.dob = tempCaregiver.dob;
    }
  

    var res:any = await EnsanCareLib.updateCaregiver();

    if (!res) {
      if(config.dev)
        console.error('Failed to complete update settings');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.missingRequirements'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack
          },
        ]
      });

    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: processSubmit()
          },
        ]
      });
    }

    else if (res.status === 'success') {
        setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            positive: true,
            onClick: () => null
          },
        ]
      });
    }
    
    setIsLoading(false);
    return null;
  }

  if(!caregiver) return <Loader active/>

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal?.action||['Ok']}
    />

    <Header
      as='h2'
      content={t('g.generalInformation')}
    />

    <Form>
      <Form.Dropdown
        selection
        label={t('s.caregivers.namePrefix')}
        placeholder={t('g.selectOne')}
        options={[
            {key: 0, value: 'Mr.', text: t('s.caregivers.namePrefixs.Mr')},
            {key: 1, value: 'Mrs.', text: t('s.caregivers.namePrefixs.Mrs')},
            {key: 2, value: 'Ms.', text: t('s.caregivers.namePrefixs.Ms')},
            {key: 3, value: 'Dr.', text: t('s.caregivers.namePrefixs.Dr')},
            {key: 4, value: 'Prof.', text: t('s.caregivers.namePrefixs.Prof')},
          ]}
        value={caregiver.name_prefix}
        onChange={(e, data) => {
          setCaregiver({
            ...caregiver,
            name_prefix:data.value
          })
        }}
      />
      <Form.Group widths={3}>
        <Form.Input
          label={`${t('s.profile.firstName')} (${t('l.english')})`}
          placeholder={t('g.typeHere')}
          value={caregiver ? caregiver.first_name_local.en : null}
          onChange={(e, data) => {
            setCaregiver({
              ...caregiver,
              first_name_local:{
                ...caregiver.first_name_local,
                en: data.value
              }
            })
          }}
        />
        <Form.Input
          label={`${t('s.profile.middleName')} (${t('l.english')})`}
          placeholder={t('g.typeHere')}
          value={caregiver ? caregiver.middle_name_local.en : null}
          onChange={(e, data) => {
            setCaregiver({
              ...caregiver,
              middle_name_local:{
                ...caregiver.middle_name_local,
                en: data.value
              }
            })
          }}
        />
        <Form.Input
          label={`${t('s.profile.lastName')} (${t('l.english')})`}
          placeholder={t('g.typeHere')}
          value={caregiver ? caregiver.last_name_local.en : null}
          onChange={(e, data) => {
            setCaregiver({
              ...caregiver,
              last_name_local:{
                ...caregiver.last_name_local,
                en: data.value
              }
            })
          }}
        />
      </Form.Group>

      <Form.Group widths={3}>
        <Form.Input
          label={`${t('s.profile.firstName')} (${t('l.arabic')})`}
          placeholder={t('g.typeHere')}
          value={caregiver ? caregiver.first_name_local.ar : null}
          onChange={(e, data) => {
            setCaregiver({
              ...caregiver,
              first_name_local:{
                ...caregiver.first_name_local,
                ar: data.value
              }
            })
          }}
        />
        <Form.Input
          label={`${t('s.profile.middleName')} (${t('l.arabic')})`}
          placeholder={t('g.typeHere')}
          value={caregiver ? caregiver.middle_name_local.ar : null}
          onChange={(e, data) => {
            setCaregiver({
              ...caregiver,
              middle_name_local:{
                ...caregiver.middle_name_local,
                ar: data.value
              }
            })
          }}
        />
        <Form.Input
          label={`${t('s.profile.lastName')} (${t('l.arabic')})`}
          placeholder={t('g.typeHere')}
          value={caregiver ? caregiver.last_name_local.ar : null}
          onChange={(e, data) => {
            setCaregiver({
              ...caregiver,
              last_name_local:{
                ...caregiver.last_name_local,
                ar: data.value
              }
            })
          }}
        />
      </Form.Group>
      
      <Form.Group widths={2}>
        <Form.Input
          label={`${t('s.profile.dateOfBirth')} (YYYY-MM-DD)`}
          placeholder={t('g.typeHere')}
          value={_renderDate(caregiver.dob)}
          onChange={(e, data) => {
            setCaregiver({
              ...caregiver,
              dob: data.value
            })
          }}
        />
        <Form.Dropdown
          selection
          label={t('s.profile.gender')}
          placeholder={t('g.selectOne')}
          options={[
            { id: 1, value: 'male', text: t('s.profile.male')},
            { id: 2, value: 'female', text: t('s.profile.female')}
          ]}
          value={caregiver.gender}
          onChange={(e, data) => {
            setCaregiver({
              ...caregiver,
              gender:data.value
            })
          }}
        />
      </Form.Group>

      <Form.Group widths={3}>
        <Form.Dropdown
          selection
          label={t('s.profile.nationality')}
          placeholder={t('g.selectOne')}
          options={nationalities}
          value={caregiver.nationality_id}
          onChange={(e, data) => {
            setCaregiver({
              ...caregiver,
              nationality_id:data.value
            })
          }}
        />
        <Form.Dropdown
          selection
          label={t('s.profile.legalIdType')}
          placeholder={t('g.selectOne')}
          options={[
            {key: 0, value: 'nid', text: t('s.profile.nationalId')},
            {key: 1, value: 'iqama', text: t('s.profile.iqama')},
            {key: 2, value: 'passport', text: t('s.profile.passport')},
          ]}
          value={caregiver.legal_id_type}
          onChange={(e, data) => {
            setCaregiver({
              ...caregiver,
              legal_id_type:data.value
            })
          }}
        />
        <Form.Input
          label={t('s.profile.legalId')}
          placeholder={t('g.typeHere')}
          value={caregiver ? caregiver.legal_id : null}
          onChange={(e, data) => {
            setCaregiver({
              ...caregiver,
              legal_id:data.value
            })
          }}
        />
      </Form.Group>

      <Form.Input
        label={`${t('g.commission')} (%)`}
        placeholder={t('g.typeHere')}
        value={caregiver ? caregiver.commission : null}
        onChange={(e, data) => {
          setCaregiver({
            ...caregiver,
            commission: data.value
          })
        }}
      />

      <Form.Input
        label={t('s.profile.cachedRating')}
        placeholder={t('g.typeHere')}
        value={caregiver ? caregiver.cached_rating : null}
        disabled={true}
      />

      <Divider section />
      
    </Form>

    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Button
            icon='arrow left'
            labelPosition='left'
            content={t('g.cancel')}
            onClick={() => history.goBack()}
          />
        </Grid.Column>
        <Grid.Column floated="right" textAlign="right">
          <Button
            primary
            content={t('g.save')}
            onClick={() => processSubmit()}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
}