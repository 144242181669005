import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu,
  Icon,
  Dropdown
} from 'semantic-ui-react';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";
import moment from 'moment';

import remoteAssetsUrl from '../remote_assets_uri.json';

const DocRow = (props:any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const host = config.dev ? config.d.API : config.p.API;

  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const [isProcessing, setIsProcessing] = useState<boolean>(false); 

  const [hideBtns, setHideBtns] = useState<boolean>(false); 

  const [actionReqeusted, setActionRequested] = useState<boolean>(true);
  const [actionReqeustedType, setActionRequestedType] = useState<string>();

  const [rowType, setRowType] = useState<'warning'|'positive'|'negative'|null>(null); 
  
  var actionColumn:any[] = [];

  var shortDocUuid = props.docUuid?.substring(
    props.docUuid.length - 5, 
    props.docUuid.length
  )

  useEffect(() => {
    _init();
  }, [
    props.deleted_at, 
    props.approved_at, 
  ]);

  const _init = () => {
    if(props.deleted_at) setRowType('negative');

    else if(props.approved_at) setRowType('positive');

    else setRowType(null);

    return null;
  }

  const _handleUpdateRequest = async (action:'approve'|'reject', doc_uuid:number) => {
    if(isProcessing) return null;
    setIsProcessing(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.documentUuid = doc_uuid;
   

    var res:any = null;

    if (action === 'approve') {
      res = await EnsanCareLib.approveCaregiverDocuments();
    }
    else if (action === 'reject') {
      res = await EnsanCareLib.rejectCaregiverDocuments();
    }

    if (!res) {
      if(config.dev) console.error('Error with the response');
    }

    else if (res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });
    }
    
    else if (res.status === "success") {
      if(action === 'approve') {
        setHideBtns(true);
        setRowType('positive');
      }
      else if(action === 'reject') {
        setHideBtns(true);
        setRowType('negative');
      }
      else {
        if(config.dev) console.warn('Provided action is not supported');
      }
    }

    return null;
  }

  const _renderBtns = () => {
    if(actionReqeusted) {
      return <>
        <Button
          color='green'
          size={'tiny'}
          content={t('g.approve')}
          onClick={() => {
            setActionRequested(false);
            setActionRequestedType("approve")
          }}
        />
        <Button
          color='red'
          size={'tiny'}
          content={t('g.reject')}
          onClick={() => {
            setActionRequested(false);
            setActionRequestedType("reject")
          }}
        />
      </>
    }
    else {
      return <>
        <Button
          color='yellow'
          size={'tiny'}
          content={t('g.confirm')}
          onClick={() => {
            if (
              actionReqeustedType === "approve"||
              actionReqeustedType === "reject"
            ) {
              _handleUpdateRequest (
                actionReqeustedType, 
                props.docUuid
              )
            } 
            else {
              return console.error("control type was not choosen")
            }
          }}
        />
        <Button
          size={'tiny'}
          content={t('g.cancel')}
          onClick={() => setActionRequested(true)}
        />
      </>
    } 
  }

  if(props.approved_at) {
    actionColumn.push(
      <Table.Cell width={4}>
        <div>
          <strong>{t('g.approved')}</strong> {moment(props.approved_at).format(config.momentDateTimeFormat)}
        </div>
      </Table.Cell>
    );
  }

  else if(props.deleted_at) {
    actionColumn.push(
      <Table.Cell width={4}>
        <div>
          <strong>{t('g.rejected')}</strong> {moment(props.deleted_at).format(config.momentDateTimeFormat)}
        </div>
      </Table.Cell>
    );
  }

  else actionColumn.push(
    <Table.Cell width={4} textAlign={"right"}>
      {!hideBtns && _renderBtns()}
    </Table.Cell>
  );

  return (
    <Table.Row 
      warning={rowType === 'warning'}
      negative={rowType === 'negative'}
      positive={rowType === 'positive'}
    >
      <Table.Cell width={2}
        onClick={() => window.open(
          `${host}${remoteAssetsUrl.caregivers_documents}${props.caregiverUuid}/${props.docUuid}.${props.docExt}`, 
          '_blank'
        )}
      >
        <a >
          # {shortDocUuid}
        </a>
      </Table.Cell>
      <Table.Cell width={2}>
        {props.title}
      </Table.Cell>
      <Table.Cell width={4}>
        {moment(props.created_at).format(config.momentDateTimeFormat)}
      </Table.Cell>
      {actionColumn}
    </Table.Row>
  )
}

const CaregiverDocuments = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {caregiver_uuid} = useParams<{caregiver_uuid:string}>();

  const [docs, setDocs] = useState<any>(null); 

  const [isLoading, setIsLoading] = useState<boolean>(false); 
  const [isUploading, setIsUploading] = useState<boolean>(false); 

  const [selectedResPerPage, setSelectedResPerPage] = useState<number>(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);

  const [newDocumentTitle, setNewDocumentTitle] = useState<any>(null);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [selectedRelatedTo, setSelectedRelatedTo] = useState<any>(null);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    actions: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getCaregiverDocuments()
  }, [])

  const uploadDocument = () => {
    if(isUploading) return null;
    setIsUploading(true);

    var baseAPI = config.dev ? config.d.API : config.p.API;
    var authToken = localStorage.getItem('auth_token');

    const data:any = new FormData() 

    data.append("document", selectedDocument);
    data.append("title", newDocumentTitle);
    data.append("related_to", selectedRelatedTo);
    data.append("caregiver_uuid", caregiver_uuid);

    fetch(
      `${baseAPI}users/caregivers/documents`, 
      {
        method: "POST",
        headers: {
          Authorization: 'Bearer ' + authToken,
        },
        body: data
      }
    )
    .then(response => response.json())
    .then(res => {
      if(!res || res.status === 'fail') {
        if(config.dev) console.warn('No results from server', res);

        setModal({
          visible: true,
          title: t("g.failedToProcessRequest"),
          message: t("g.failedToProcessRequestMessage"),
          actions: [{ content: t('g.ok') }]
        });
      }
      else {
        getCaregiverDocuments();
        setNewDocumentTitle(null);
        setSelectedDocument(null);
        setSelectedRelatedTo(null);
      }
    })
    .catch(e => {
      if(config.dev) console.warn(e);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{ content: t('g.ok') }]
      });
    });

    setIsUploading(false);
    return null;
  }

  const getCaregiverDocuments = async (page:number = 1) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.caregiverUuid = caregiver_uuid;
    EnsanCareLib.resPerPage = selectedResPerPage;
    EnsanCareLib.currentPage = page||selectedPageNumber;
    EnsanCareLib.showDeleted = 'y';
    
    var res:any = await EnsanCareLib.getCaregiverDocuments();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        actions: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiverDocuments()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        actions: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiverDocuments()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
      
      setDocs(res.data);
    }
    
    setIsLoading(false);
    return null;
  }

  const _renderLoadingRow = () => {
    return <Table.Row>
      <Table.Cell colSpan={7} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderRows = () => {
    if(!docs || docs.length < 1) {
      return <Table.Row disabled>
        <Table.Cell>
          {t('g.noResults')}
        </Table.Cell>
      </Table.Row>
    }
    
    return docs.map( (doc:any, key:number) => {

      return <DocRow 
        docUuid={doc.uuid}
        caregiverUuid={doc.caregiver_uuid}
        title={doc.title}
        created_at={doc.created_at}
        deleted_at={doc.deleted_at}
        approved_at={doc.approved_at}
        docExt={doc.ext}
      />
    })
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal?.actions||['Ok']}
    />

    <Header
      as='h2'
      content={t('g.documents')}
    />
    <Menu stackable>
      <Menu.Menu position='right'>
        <Menu.Item
          name={t('g.generalInformation')}
          onClick={() => getCaregiverDocuments()}
        >
          <Icon name='refresh'/>
        </Menu.Item>
      </Menu.Menu>
    </Menu>

    <Table color='blue' columns={4}>
      <Table.Header>
        <Table.HeaderCell width={2}>{t('g.id')}</Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('g.details')}</Table.HeaderCell>
        <Table.HeaderCell width={4}>{t('g.createdAt')}</Table.HeaderCell>
        <Table.HeaderCell width={4}></Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderRows()}
        {isLoading && _renderLoadingRow()}
      </Table.Body>
    </Table>

    <Grid verticalAlign='middle'>
      <Grid.Row columns={3}>
        <Grid.Column>
          {t('g.resPerPage')}
          <Dropdown
            style={{
              marginLeft: '10px'
            }}
            inline
            options={[
              {key: 0, value: 25, text: "25"},
              {key: 1, value: 50, text: "50"},
              {key: 2, value: 100, text: "100"},
              {key: 3, value: 500, text: "500"},
            ]}
            defaultValue={selectedResPerPage}
            onChange={(e, data) => {

              if(typeof data.value === 'number'){
                return setSelectedResPerPage(data.value); 
              }

              if(typeof data.value === 'string' && /^[0-9]/.test(data.value)){
                return setSelectedResPerPage(parseInt(data.value));
              }
              
              if(config.dev) console.warn("result per page type is not valid", typeof data.value)
              return null;
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Menu floated='right' pagination size='mini'>
            <Menu.Item 
              as='a' 
              icon 
              onClick={() => {
                
                if(selectedPageNumber <= 1) {
                  setSelectedPageNumber(selectedPageNumber)
                  return null;
                }

                setSelectedPageNumber(selectedPageNumber-1)
                getCaregiverDocuments(selectedPageNumber-1);
              }}
              >
              <Icon name='chevron left'/>
            </Menu.Item>
            <Menu.Item>
              <Input
                value={selectedPageNumber}
                onChange={(e,data) => {

                  if(parseInt(data.value) < 1) return null;

                  if(/^[0-9]/.test(data.value))
                    setSelectedPageNumber(parseInt(data.value));
                }}
              />
              <Button 
                style={{
                  marginLeft: '10px'
                }}
                color='blue'
                onClick={() => {
                  var error = false;
                  
                  if(typeof selectedPageNumber !== 'number') {
                    error = true;
                    if(config.dev) console.warn('Provided page number is not a number', selectedPageNumber);
                  }

                  if(selectedPageNumber < 1) {
                    error = true;
                    if(config.dev) console.warn('Provided page number is less than 1', selectedPageNumber);
                  }

                  if(error) {
                    setModal({
                      visible: true,
                      title: t('g.error'),
                      message: t('g.invalidInput'),
                      actions: [
                        {
                          content: t('g.goBack'),
                          onClick: () => history.goBack()
                        },
                      ]
                    });
                    return null;
                  }
                  
                  setSelectedPageNumber(selectedPageNumber)
                  getCaregiverDocuments(selectedPageNumber);
                }}
              >
                {t('g.go')}
              </Button>
            </Menu.Item>
            <Menu.Item 
              as='a' 
              icon
              onClick={() => {
                setSelectedPageNumber(selectedPageNumber+1)
                getCaregiverDocuments(selectedPageNumber+1);
              }}
              >
              <Icon name='chevron right' />
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider hidden />

    <Form id='upload-form' loading={isUploading} onSubmit={uploadDocument}>
      <Form.Input
        fluid
        required
        label={t('g.title')}
        value={newDocumentTitle}
        onChange={(e, data) => {
          setNewDocumentTitle(data.value)
        }}
      />
      <Form.Select
        fluid
        required
        label={t('g.relatedTo')}
        placeholder={t('g.selectOne')}
        options={[
          { key: 'general', text: t('g.general'), value: 'general' },
          { key: 'legal_id', text: t('g.legalId'), value: 'legal_id' },
          { key: 'scfhs_front', text: t('g.scfhsFront'), value: 'scfhs_front' },
          { key: 'scfhs_back', text: t('g.scfhsBack'), value: 'scfhs_back' },
        ]}
        onChange={(e, { value }) => setSelectedRelatedTo(value)}
      />
      <Form.Input
        required
        type='file'
        accept='image/jpeg,application/pdf'
        onChange={(event, e) => {
          if(event && event.target && event.target.files) {
            var file:any = event.target.files[0];
            setSelectedDocument(file);
          }
        }}
      />
      <Form.Button
        primary
        icon={'upload'}
        content={`${t('g.upload')} ${t('g.document')}`}
        type={'submit'}
      />
    </Form>
  </>
}

export default CaregiverDocuments;