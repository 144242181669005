import { useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import SiteHeader from '../framework/site_header';

export default function PasswordReset() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string
  }

  const [modal, setModal] = useState<modalType|null>(null);
  var [processing, setProcessing] = useState<boolean>(false);

  // login information states
  var [phone, setPhone] = useState<string|null>(null);
  var [otpRequested, setOtpRequested] = useState<boolean>(false);
  var [otp, setOtp] = useState<string|null>(null);
  var [password, setPassword] = useState<string|null>(null);

  var [errors, setErrors] = useState<any>(null);

  const countryCode = '+966';
  var returnPath:any = null;

  if(
    history.location.pathname === '/login' ||
    history.location.pathname === '/password-reset'
  )
    returnPath = () => { history.push('/') }
  else
    returnPath = () => history.go(0);

  const requestOTP = async () => {}

  // reset the password
  const resetPassword = async () => {}

  // login
  const login = async () => {}

  const getProfile = async (profileId:string) => {}

  const _renderResetBtn = () => {
    var label = t('g.reset');
    var action:any = () => null;

    if(!otpRequested) {
      action = () => requestOTP()
    }

    if(otpRequested) {
      label = `${t('g.update')} ${t('g.password')}`
      action = () => resetPassword()
    }

    return <Button 
      fluid 
      onClick={action}
      loading={processing}
    >
      {label}
    </Button>
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />

    <SiteHeader hideLogo />
    
    <Grid 
      textAlign='center'
      verticalAlign='middle'
      style={{height: '100vh' }}
      padded
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <div style={{justifyContent: 'center', display: 'flex'}}>
          <img src={Logo} width="100" />
        </div>
        
        <Header as='h1' textAlign='center'>
          {t('s.auth.resetMyPassword')}
          <Header.Subheader>
            {t('s.auth.resetMyPasswordDescription')}
          </Header.Subheader>
        </Header>
        <Form size='large'>
          <Form.Field>
            <Input
              required
              label={{basic: true,  children: countryCode}}
              labelPosition='left'
              value={(phone) ? phone : ''}
              onChange={(e, { value }) => {
                if(value[0] !== '0') setPhone(value)
              }}
              placeholder='56xxx'
              fluid
            />
          </Form.Field>
          {(!otpRequested) ? null : <div>
            <p style={{textAlign: 'left'}}>
              {t('s.auth.phoneOtpDescription')} (<span className='link' onClick={requestOTP}>{t('s.auth.resendOtp')}</span>)
            </p>
            <Form.Input
              required
              fluid
              icon='lock'
              iconPosition='left'
              placeholder={t('s.auth.phoneOtp')}
              onChange={(e, { value }) => setOtp(value)}
            /> 

            <p style={{textAlign: 'left'}}>
              {t('g.password')}:
            </p>
            <Form.Input
              required
              fluid
              icon='lock'
              iconPosition='left'
              placeholder={t('g.password')}
              type='password'
              onChange={(e, { value }) => setPassword(value)}
            /> 

            <br />
          </div> }
          
          {_renderResetBtn()}

          <div style={{padding: 20}}>
            <a href="/login">
              {t("s.auth.login")}
            </a>
          </div>
        </Form>
      </Grid.Column>
    </Grid>
  </>
}