import { config } from "../config";
import GoogleMapReact from 'google-map-react';

import MapMarkerPin from '../assets/google_map_marker.png';

const MapMarker = (props:any) => {
  if(props.markersType === 'circle'){
    return (
      <div 
        style={{
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          backgroundColor: 'rgba(244, 0, 0, 0.5)',
          marginTop: '-15px',
          marginLeft: '-15px',
        }}
      />
    )
  } 
  
  else {
    return <div style={{ marginTop: '-30px', marginLeft: '-8.5px'}}>
      <img src={MapMarkerPin} height={30} width={17} />
    </div>
  }
}

const Map = (props:any) => {
  let defaultContainerStyle = {
    height: props.height || '220px', 
    width: '100%', 
    borderRadius: '15px'
  }

  let defaultCenter = {
    lat: 24.7132204,
    lng: 46.6944105
  }

  if(props.center) {
    var lat = null;
    var lng = null;

    if(props.center.latitude)
      lat = props.center.latitude;

    else if (props.center.lat)
      lat = props.center.lat;

    if(props.center.longitude)
      lng = props.center.longitude;

    else if (props.center.lng)
      lng = props.center.lng;
      
    defaultCenter = {
      lat: lat,
      lng: lng
    }
  }

  const _renderMarkers = () => {
    if(!props.markers) return null; 

    if(typeof props.markers !== 'object') return null;

    if(props.markers.length < 1) return null;

    var render:any = [];

    props.markers.forEach((marker:any, key:number) => {
      if(
        !marker || 
        !marker.location
      ) return null;
      
      var lat = null;
      var lng = null;

      if(marker.location.latitude)
        lat = marker.location.latitude;

      else if (marker.location.lat)
        lat = marker.location.lat;

      if(marker.location.longitude)
        lng = marker.location.longitude;

      else if (marker.location.lng)
        lng = marker.location.lng;

      if(!lat || !lng) return null;

      render.push( 
        <MapMarker 
          key={key}
          lat={lat}
          lng={lng}
          markersType={props.markersType}
        />
      );
    })

    return render;
  }

  return <div style={props.containerStyle||defaultContainerStyle}>
    <GoogleMapReact
      bootstrapURLKeys={{ key: config.googleMapAPIKey }}
      center={defaultCenter}
      defaultZoom={8}
      options={props.options}
      onClick={props.onClick||null}
      yesIWantToUseGoogleMapApiInternals
    >
      {_renderMarkers()}    
    </GoogleMapReact>
  </div>
}

export default Map;