/*
 *
 * PROJECT ENSAN CARE
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

export default {
  buildName: (language, namePrefix, firstName, middleName, lastName, defReturn='') => {
    var fullName = '';

    if(firstName) {
      try {
        if(typeof firstName === 'string')
          firstName = JSON.parse(firstName);

        if(firstName[language]) fullName += firstName[language];
        else if(firstName['en']) fullName += firstName['en'];
        else fullName += '';
      }
      catch(e) {
        if(this.debug) console.warn('processing first name', e);
        fullName += '';
      }
    }

    if(middleName) {
      try {
        if(typeof middleName === 'string')
          middleName = JSON.parse(middleName);

        if(fullName.length > 0) fullName += ' ';

        if(middleName[language]) fullName += middleName[language];
        else if(middleName['en']) fullName += middleName['en'];
        else fullName += '';
      }
      catch(e) {
        if(this.debug) console.warn('processing middle name', e);
        fullName += '';
      }
    }

    if(lastName) { 
      try {
        if(typeof lastName === 'string')
          lastName = JSON.parse(lastName);

        if(fullName.length > 0) fullName += ' ';

        if(lastName[language]) fullName += lastName[language];
        else if(lastName['en']) fullName += lastName['en'];
        else fullName += '';
      }
      catch(e) {
        if(this.debug) console.warn('processing last name', e);
        fullName += '';
      }
    }

    // title and translation
    if(namePrefix && fullName.length > 0) {
      fullName = `${namePrefix} ${fullName}`;
    }
    else if (fullName.length > 0) {
      fullName = `${fullName}`;
    }
    else {
      fullName = defReturn;
    }
    
    return fullName;
  },

  // This function converts the spoken languages
  // to object if it was string
  spokenLangaugesToObject: (sourceData, sourceType) => {
    if(!sourceData || !sourceType) return null;

    var spokenLangauges = null;

    if(sourceType === 'userDetails') {
      if(
        sourceData &&
        sourceData.spoken_languages
      ) {
        spokenLangauges = sourceData.spoken_languages;
      }
      else {
        return null;
      }
    }
    else if (sourceType === 'direct') {
      spokenLangauges = sourceData;
    }
    else {
      console.warn('Provided sourceType is invalide: ', sourceType);
      return null;
    }
    
    if(typeof spokenLangauges === 'string') {
      try {
        spokenLangauges = JSON.parse(spokenLangauges);
        return spokenLangauges;
      }
      catch(e) {
        console.warn('Failed to parse spokenLanguages', spokenLangauges, e);
        return null;
      }
    }
    else {
      return spokenLangauges
    }
  }
}