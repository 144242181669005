import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu,
  Label
} from 'semantic-ui-react';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";
import ECGeneral from '../util/EnsanCareLib/funcs/general';

export default function CaregiverServices() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  
  const {caregiver_uuid} = useParams<{caregiver_uuid:string}>();

  const [services, setServices] = useState<any>(null); 
  // const [caregiverServices, setCaregiverServices] = useState<any>(null); 

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [selectedServicesIds, setSelectedServicesIds] = useState<Array<any>>([]);
  
  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getServices();
    getCaregiverServices();
  }, []);

  const getServices = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    
    var res:any = await EnsanCareLib.getServices();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServices()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServices()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {



      setServices(res.data);
    }
    
    setIsLoading(false);
    return null;
  }

  const getCaregiverServices = async (page:number = 1) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.caregiverUuid = caregiver_uuid;

    var res:any = await EnsanCareLib.getCaregiverServices();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiverServices()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiverServices()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
      var tempSelectedServicesIds:any = [];

      if(res.data && res.data.length > 0) {
        res.data.map((service:any, key:number) => {
          tempSelectedServicesIds.push(service.service_id);
        })
      }
      
      setSelectedServicesIds(tempSelectedServicesIds);

    }
    
    setIsLoading(false);
    return null;
  }

  const setCaregiverServices = async (serviceId:number) => {
    // get services
    if(isLoading) return null;
    setIsLoading(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.serviceId = serviceId;
    EnsanCareLib.caregiverUuid = caregiver_uuid;

    var res:any = await EnsanCareLib.setCaregiverServices();

    if (!res) {
      if(config.dev)
        console.error('Failed to complete update settings');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.missingRequirements'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack
          },
        ]
      });

    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => setCaregiverServices(serviceId)
          },
        ]
      });
    }

    else if(res.status === 'success') {
      var tempSelectedServicesIds:any = selectedServicesIds;
      tempSelectedServicesIds.push(serviceId);
      setSelectedServicesIds([...tempSelectedServicesIds]);
      
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setIsLoading(false);
    return null;
  }

  const unsetCaregiverServices = async (serviceId:number) => {
    // get services
    if(isLoading) return null;
    setIsLoading(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.serviceId = serviceId;
    EnsanCareLib.caregiverUuid = caregiver_uuid;

    var res:any = await EnsanCareLib.unsetCaregiverServices();
    
    if (!res) {
      if(config.dev)
        console.error('Failed to complete update settings');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.missingRequirements'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack
          },
        ]
      });

    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => unsetCaregiverServices(serviceId)
          },
        ]
      });
    }

    else if(res.status === 'success') {

      var cleanedUpItems:Array<any> = [];
      var tempSelectedServicesIds:any = selectedServicesIds;
      tempSelectedServicesIds.forEach((item:any, key:number) => {
        if(item !== serviceId) cleanedUpItems.push(item);
      });

      tempSelectedServicesIds = cleanedUpItems;
      setSelectedServicesIds(cleanedUpItems);
    }

    else {  
      // --
      // handle server error
      console.warn('Response returned error', res);
    }

    setIsLoading(false);
    return null;
  }

  const _handleServiceClick = async (serviceId:number) => {
    if(selectedServicesIds.includes(serviceId)) {
      unsetCaregiverServices(serviceId);
    }
    else {
      setCaregiverServices(serviceId);
    }
  }

  const _renderServices = () => {
  
    var currentServiceCategoryId:number|null = null;
    var render:any = [];
    
    if(services?.length > 0) services.map((service:any, key:number) => {
      
      if(service.service_category_id !== currentServiceCategoryId) {
        // service title
        render.push(
          <Header>
            {ECGeneral.getFromLocal(
              i18n.language, 
              service.category_local,
              service.category_title
            )}
          </Header>
        );

        currentServiceCategoryId = service.service_category_id;
      }

      var serviceTitle = ECGeneral.getFromLocal(
        i18n.language, 
        service.title_local,
        service.title
      )
      
      // add the service container
      render.push(
        <Segment>
          <Grid >
            <Grid.Row>
              <Grid.Column width={1} verticalAlign={"middle"} textAlign={"center"}>
                <Checkbox 
                  checked={selectedServicesIds.includes(service.id)}
                  onClick={() => _handleServiceClick(service.id)}
                />
              </Grid.Column>
              <Grid.Column width={15}>
                <Header as="h4">
                  {serviceTitle} 
                  <Label>
                  {service?.cost?.toFixed(2)} {t('c.SR')}
                  </Label>
                </Header>
                <div>
                  {ECGeneral.getFromLocal(
                    i18n.language, 
                    service.description_local,
                    ' '
                  )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      );
    })

    return <div>
      {render}
    </div>
  }  

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal?.title||null}
      content={modal?.message || null}
      actions={modal?.action || ['Ok']}
    />

    <Header
      as='h2'
      content={t('s.services.title')}
    />

    <Form>

      {_renderServices()}
      
    </Form>
  </>
}