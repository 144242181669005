import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu,
  Card,
  GridColumn
} from 'semantic-ui-react';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import Map from '../framework/map';

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";
import ECGeneral from '../util/EnsanCareLib/funcs/general';
import ECServiceCities from "../util/EnsanCareLib/funcs/service_cities";

export default function CaregiverProfessional() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {caregiver_uuid} = useParams<{caregiver_uuid:string}>();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [caregiver, setCaregiver] = useState<any>(null);
  const [caregiverSCFHSDocs, setCaregiverSCFHSDocs] = useState<any>(null);
  const [serviceCities, setServiceCities] = useState<Array<Object>>([]);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getCaregiver();
    getServiceCities();
  }, [])

  const getCaregiver = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.caregiverUuid = caregiver_uuid;

    var res:any = await EnsanCareLib.getCaregiver();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiver()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiver()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success') {
      if(res.data) {

        var tempCaregiver:any = res.data;
        tempCaregiver.job_title_local = {
          ar: ECGeneral.getFromLocal("ar", res.data.job_title_local, null),
          en: ECGeneral.getFromLocal("en", res.data.job_title_local, null),
        };

        /**
         * spoken language filteration
         */
        if(typeof tempCaregiver.spoken_languages === 'string'){
          try {
            tempCaregiver.spoken_languages = JSON.parse(res.data.spoken_languages);  
          } catch (e) {
            if(config.dev) console.error(e)
          }
        }

         /**
         * base_coords language filteration
         */
        if(res.data.base_coords){
          tempCaregiver.base_coords = [{location: ECServiceCities.convertCoordsToObject(res.data.base_coords)}];
        }

        setCaregiver(res.data);
      } 
    }
    
    setIsLoading(false);
    return null;
  }

  const getServiceCities = async () => {
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    
    var res:any = await EnsanCareLib.getServiceCities();
    
    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServiceCities()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServiceCities()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {

      var serviceCitiesObj:any[any] = [];

      res.data.map((item:any) => {
        serviceCitiesObj.push({
          key: item.id,
          value: item.id,
          text: ECServiceCities.buildLabel(i18n.language, item)
        })
      })
      
      setServiceCities(serviceCitiesObj);
    }
  }

  const processSubmit = async () => {
    if(isLoading) return true;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.caregiverUuid = caregiver_uuid;

    EnsanCareLib.scfhs_number = caregiver.scfhs_number;
    EnsanCareLib.biography = caregiver.biography;
    EnsanCareLib.address_1 = caregiver.address_1;
    EnsanCareLib.address_2 = caregiver.address_2;
    EnsanCareLib.service_city_id = caregiver.service_city_id;
    EnsanCareLib.iban = caregiver.iban;
    EnsanCareLib.bank_name = caregiver.bank_name;
    EnsanCareLib.job_title_local = caregiver.job_title_local;
    EnsanCareLib.spoken_languages = caregiver.spoken_languages;

    // changing the base_coords to format the database will accept
    if(caregiver.base_coords !== null) {
      EnsanCareLib.base_coords = {
        lat: caregiver.base_coords[0].location.latitude,
        lng: caregiver.base_coords[0].location.longitude
      };
    }
   
    var res:any = await EnsanCareLib.updateCaregiver();

    if (!res) {
      if(config.dev)
        console.error('Failed to complete update settings');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.missingRequirements'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack
          },
        ]
      });

    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: processSubmit()
          },
        ]
      });
    }

    else if (res.status === 'success') {
        setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            positive: true,
            onClick: () => null
          },
        ]
      });
    }
    
    setIsLoading(false);
    return null;
  }

  if(!caregiver) return <Loader active/>


  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal?.title||null}
      content={modal?.message || null}
      actions={modal?.action || ['Ok']}
    />

    <Header
      as='h2'
      content={t('s.profile.professionalInformation')}
    />

    <Form>
      <Form.Group widths={2}>
        <Form.Input
          label={`${t('s.caregivers.jobTitle')} (${t('l.english')})`}
          placeholder={t('g.typeHere')}
          value={caregiver.job_title_local.en}
          onChange={(e,data) => {
            setCaregiver({
              ...caregiver,
              job_title_local:{
                ...caregiver.job_title_local,
                en: data.value
              }
            })
          }}
        />
        <Form.Input
          label={`${t('s.caregivers.jobTitle')} (${t('l.arabic')})`}
          placeholder={t('g.typeHere')}
          value={caregiver.job_title_local.ar}
          onChange={(e,data) => {
            setCaregiver({
              ...caregiver,
              job_title_local:{
                ...caregiver.job_title_local,
                ar: data.value
              }
            })
          }}
        />
      </Form.Group>

      <Form.Input
        label={t('s.caregivers.scfhsNumber')}
        placeholder={t('g.typeHere')}
        value={caregiver.scfhs_number}
        onChange={(e,data) => {
          setCaregiver({
            ...caregiver,
            scfhs_number:data.value
          })
        }}
      />

      <Divider hidden />

      <Form.TextArea
        label={t('s.caregivers.biography')}
        placeholder={t('g.typeHere')}
        value={caregiver.biography}
        onChange={(e,data) => {
          setCaregiver({
            ...caregiver,
            biography:data.value
          })
        }}
      />

      <Header
        as='h3'
        content={t('s.caregivers.spokenLanguages')}
      />

      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Checkbox
                checked={caregiver.spoken_languages?.includes("english")}
                label={t('l.english')}
                onChange={(e, data) => {
                  if(data.checked){
                    if( !caregiver.spoken_languages?.includes("english") ) {
                      var languageSet = [];

                      if(caregiver.spoken_languages) {
                        languageSet = caregiver.spoken_languages
                      }

                      setCaregiver({
                        ...caregiver,
                        spoken_languages:[...languageSet ,"english"]
                      })
                    }
                  } 
                  
                  else{
                    setCaregiver({
                      ...caregiver,
                      spoken_languages:caregiver.spoken_languages.filter(
                        (language:any) => language != 'english'
                      )
                    })
                  }
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Checkbox
                checked={caregiver.spoken_languages?.includes("arabic")}
                label={t('l.arabic')}
                onChange={(e, data) => {
                  if(data.checked){
                    if( !caregiver.spoken_languages?.includes("arabic") ) {
                      var languageSet = [];

                      if(caregiver.spoken_languages) {
                        languageSet = caregiver.spoken_languages
                      }

                      setCaregiver({
                        ...caregiver,
                        spoken_languages:[...languageSet ,"arabic"]
                      })
                    }
                  } 
                  
                  else{
                    setCaregiver({
                      ...caregiver,
                      spoken_languages:caregiver.spoken_languages.filter(
                        (language:any) => language != 'arabic'
                      )
                    })
                  }
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Divider hidden section />

      <Header
        as='h3'
        content={t('g.address')}
      />

      <Form.Group widths={2}>
        <Form.Input
          label={t('g.address1')}
          placeholder={t('g.typeHere')}
          value={caregiver.address_1}
          onChange={(e,data) => {
            setCaregiver({
              ...caregiver,
              address_1:data.value
            })
          }}
        />
        <Form.Input
          label={t('g.address2')}
          placeholder={t('g.typeHere')}
          value={caregiver.address_2}
          onChange={(e,data) => {
            setCaregiver({
              ...caregiver,
              address_2:data.value
            })
          }}
        />
      </Form.Group>

      <Form.Dropdown
        selection
        label={t('s.serviceCities.serviceCity')}
        placeholder={t('g.selectOne')}
        options={serviceCities}
        value={caregiver.service_city_id}
        onChange={(e,data) => {
          setCaregiver({
            ...caregiver,
            service_city_id:data.value
          })
        }}
      />
      
      <Form.Field label={t('g.addressCoords')} />
      <div style={{overflow: 'hidden', borderRadius: 10}}>
        <Map 
          markers={caregiver?.base_coords}
          center={caregiver?.base_coords?.[0]?.location}
          options={{
            draggableCursor: "default",
            draggingCursor: "pointer",
            fullscreenControl: false,
          }}
          height={500}
          onClick={(ev:any) => {
            setCaregiver({
              ...caregiver,
              base_coords: [{
                location: {latitude: ev.lat, longitude: ev.lng}
              }]
            })
          }}
        />
      </div>

      <Divider hidden section />

      <Header
        as='h3'
        content={t('s.caregivers.bankingInformation')}
      />

      <Form.Input
        label={t('s.caregivers.bankName')}
        placeholder={t('g.typeHere')}
        value={caregiver.bank_name}
        onChange={(e,data) => {
          setCaregiver({
            ...caregiver,
            bank_name:data.value
          })
        }}
      />

      <Form.Input
        label={t('s.caregivers.IBAN')}
        placeholder={t('g.typeHere')}
        value={caregiver.iban}
        onChange={(e,data) => {
          setCaregiver({
            ...caregiver,
            iban:data.value
          })
        }}
      />

      <Divider section />

    </Form>

    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Button
            icon='arrow left'
            labelPosition='left'
            content={t('g.cancel')}
            onClick={() => history.goBack()}
          />
        </Grid.Column>
        <Grid.Column floated="right" textAlign="right">
          <Button
            primary
            content={t('g.save')}
            onClick={() => processSubmit()}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
}