import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu,
  Label
} from 'semantic-ui-react';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";

const periods = [
  {period: 1, label: '08:00 AM\n11:00 AM'},
  {period: 2, label: '11:00 AM\n02:00 PM'},
  {period: 3, label: '02:00 PM\n05:00 PM'},
  {period: 4, label: '05:00 PM\n08:00 PM'},
  {period: 5, label: '08:00 PM\n10:00 PM'}
];

const scheduleDays = 14;

function SchdeuleCell(props:any) {

  const [isReady, setIsReady] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const [backgroundColor, setBackgroundColor] = useState<'blue'|'red'|'yellow'|'grey'|'green'>('grey');
  const [textColor, setTextColor] = useState<string|null>();
  const [action, setAction] = useState<'set'|'unset'>('set');
  const [matchedScheduleId, setMatchedScheduleId] = useState<number|null>(null);

  useEffect(() => {
    init();
  });

  const init = () => {
    // if(isReady) return null;

    // set the initial colors
    if(props.caregiverSchedule && props.caregiverSchedule.length > 0) {
      props.caregiverSchedule.forEach((schedule:any, sKey:number) => {
        if(!schedule.date || !schedule.period) return null;

          
        var scheduleDate = schedule.date || null;
        scheduleDate = scheduleDate.split('T')[0];
      
        if(
          scheduleDate === props.date &&
          schedule.period === props.period.period
        ) {
          setAction('unset');
          setMatchedScheduleId(schedule.id);

          // colors setting
          if(schedule.session_id) {
            setBackgroundColor('red');
            setTextColor(null);
          }
  
          else if(schedule.delete_requested_at) {
            setBackgroundColor('blue');
            setTextColor(null);
          }
          
          else if(schedule.approved_at) {
            setBackgroundColor('green');
            setTextColor(null);
          }
          
          else  {
            setBackgroundColor('yellow');
            setTextColor(null);
          }
        }
      })
    }

    // setIsReady(true);
  }

  return <>
    <div style={{marginBottom: 5}}>
      <Label size='small' content={props.period.label} color={backgroundColor} />
    </div>
  </>
}

export default function CaregiverSchedule() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {caregiver_uuid} = useParams<{caregiver_uuid:string}>();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [caregiverSchedule, setCaregiverSchedule] = useState<any>();

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getCaregiverSchedule();
  },[])

  const getCaregiverSchedule = async (page:number = 1) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.caregiverUuid = caregiver_uuid;
    EnsanCareLib.showAll = 'y';

    var res:any = await EnsanCareLib.getCaregiverSchedule();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiverSchedule()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiverSchedule()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
   
     
      setCaregiverSchedule(res.data);
    }
    
    setIsLoading(false);
    return null;
  }

  const _renderDays = () => {
    var render = [];
    var paddingSpaceAdded = false;

    for(var i=0; i < scheduleDays; i++) {
      var date = new Date();
      date.setDate(date.getDate() + i);

      var rowDate:any = date.toJSON();
      rowDate = rowDate.split('T')[0];

      var dayNumber = date.getDay();

      if(!paddingSpaceAdded) {
        for(var x=0; x < dayNumber; x++) {
          render.push(<Grid.Column />);
        }

        paddingSpaceAdded = true;
      }

      render.push(<>
        <Grid.Column>
          <Header
            as='h4'
            content={`${date.toDateString()}`}
            subheader={`${date.toLocaleDateString()}`}
          />
          {periods.map((period:any, pKey:number) => (
            <SchdeuleCell
              key={`p-${pKey}`}
              caregiverSchedule={caregiverSchedule}
              date={rowDate}
              period={period}
            />
          ))}
          
        </Grid.Column>
      </>)
    }

    return render;
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />

    <Header
      as='h2'
      content={t('g.schedule')}
    />

    <Grid columns={7}>
      {_renderDays()}
    </Grid>
  </>
}