import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Menu,
  Icon,
  Dropdown
} from 'semantic-ui-react';

// import SVG
import Logo from '../../../assets/logo-icon.png';

// import app config
import { config } from '../../../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../../../framework/internal_page';

import EnsanCareAPI from "../../../util/EnsanCareLib/EnsanCareAPI";

import moment from 'moment';
import { URLQuery } from '../../../util/tools';

const BookingsAdditionalSession = (props:any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();


  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const [isProcessing, setIsProcessing] = useState<boolean>(false); 

  const [hideBtns, setHideBtns] = useState<boolean>(false); 

  const [actionReqeusted, setActionRequested] = useState<boolean>(true);
  const [actionReqeustedType, setActionRequestedType] = useState<string>();

  const [rowType, setRowType] = useState<'warning'|'positive'|'negative'|null>(null); 
  
  var actionColumn:any[] = [];

  var shortBookingUuid = props.bookingUuid?.substring(
    props.bookingUuid.length - 5, 
    props.bookingUuid.length
  )

  useEffect(() => {
    _init();
  }, [
    props.rejected_at, 
    props.approved_at, 
    props.paid_at
  ]);

  const _init = () => {
    if(props.rejected_at) setRowType('negative');

    else if(props.approved_at && !props.paid_at) setRowType('warning');

    else if(props.approved_at && props.paid_at) setRowType('positive');

    else setRowType(null);

    return null;
  }

  const _handleUpdateRequest = async (action:'approve'|'reject', additional_charge_id:number) => {
    if(isProcessing) return null;
    setIsProcessing(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.additionalChargeId = additional_charge_id;

    var res:any = null;

    if (action === 'approve') {
      res = await EnsanCareLib.approveBookingSessionAdditionalCharges();
    }
    else if (action === 'reject') {
      res = await EnsanCareLib.rejectBookingSessionAdditionalCharges();
    }

    if (!res) {
      if(config.dev) console.error('Error with the response');
    }

    else if (res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });
    }
    
    else if (res.status === "success") {
      if(action === 'approve') {
        setHideBtns(true);
        setRowType('warning');
      }
      else if(action === 'reject') {
        setHideBtns(true);
        setRowType('negative');
      }
      else {
        if(config.dev) console.warn('Provided action is not supported');
      }
    }

    return null;
  }

  const _renderBtns = () => {
    if(actionReqeusted) {
      return <>
        <Button
          color='green'
          size={'tiny'}
          content={t('g.approve')}
          onClick={() => {
            setActionRequested(false);
            setActionRequestedType("approve")
          }}
        />
        <Button
          color='red'
          size={'tiny'}
          content={t('g.reject')}
          onClick={() => {
            setActionRequested(false);
            setActionRequestedType("reject")
          }}
        />
      </>
    }
    else {
      return <>
        <Button
          color='yellow'
          size={'tiny'}
          content={t('g.confirm')}
          onClick={() => {
            if (
              actionReqeustedType === "approve"||
              actionReqeustedType === "reject"
            ) {
              _handleUpdateRequest (
                actionReqeustedType, 
                props.id
              )
            } 
            else {
              return console.error("control type was not choosen")
            }
          }}
        />
        <Button
          size={'tiny'}
          content={t('g.cancel')}
          onClick={() => setActionRequested(true)}
        />
      </>
    } 
  }

  if(props.rejected_at) {
    actionColumn.push(
      <Table.Cell width={4}>
        <div>
          <strong>{t('g.rejected')}</strong> {moment(props.rejected_at).format(config.momentDateTimeFormat)}
        </div>
      </Table.Cell>
    );
  }

  else if(props.approved_at && !props.paid_at){
    actionColumn.push(
      <Table.Cell width={4}>
        <div>
          <div>
            <strong>{t('g.approved')}</strong> {moment(props.approved_at).format(config.momentDateTimeFormat)}
          </div>
          <div>
            <strong>{t('g.paidOutAt')} -</strong>
          </div>
        </div>
      </Table.Cell>
    );
  }

  else if(props.approved_at && props.paid_at){ 
    actionColumn.push(
      <Table.Cell width={4}>
        <div>
          <div>
            <strong>{t('g.approved')}</strong> {moment(props.approved_at).format(config.momentDateTimeFormat)}
          </div>
          <div>
            <strong>{t('g.paidOutAt')}</strong> {moment(props.paid_at).format(config.momentDateTimeFormat)}
          </div>
        </div>
      </Table.Cell>
    );
  }

  else actionColumn.push(
    <Table.Cell width={4}>
      {!hideBtns && _renderBtns()}
    </Table.Cell>
  );

  return (
    <Table.Row 
      warning={rowType === 'warning'}
      negative={rowType === 'negative'}
      positive={rowType === 'positive'}
    >
      <Table.Cell width={2}>
        {props.id}
      </Table.Cell>
      <Table.Cell width={2}>
        <a target="_blank" href={props.hrefURL}>
          <div>
            <strong># {shortBookingUuid}</strong>
          </div>
        </a>
        <div>
          {t('s.bookings.sessions.session')}: {props.sessionId}
        </div>
      </Table.Cell>
      <Table.Cell width={4}>
        <div>
          <div>
            <strong>{props.total} {t('c.SR')}</strong>
          </div>
          <div>
            {props.description}
          </div>
        </div>
      </Table.Cell>
      <Table.Cell width={4}>
        {moment(props.created_at).format(config.momentDateTimeFormat)}
      </Table.Cell>
      {actionColumn}
    </Table.Row>
  )
}

const BookingSessionAdditionalChargesList = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [bookingAdditionalCharges, setBookingAdditionalCharges] = useState<any[]|null>(null); 

  const [selectedResPerPage, setSelectedResPerPage] = useState<number>(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);

  const [sessionSearchTerm, setSessionSearchTerm] = useState<number|null>();
  const [searchTerm, setSearchTerm] = useState<string|number|null>();

  const [status, setStatus] = useState<"approved"|"rejected"|"pending"|null>();
  const [paymentStatus, setPaymentStatus] = useState<"paid"|"unpaid"|null>();
  const [orderDesc, setOrderDesc] = useState<boolean>(false);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const urlSearchTerm = URLQuery('searchTerm', window) || null;

  useEffect(() => {
    setSearchTerm(urlSearchTerm);
    setIsReady(true);
    
    getBookingSessionAdditionalCharges();
  }, []);

  useEffect(() => {
    getBookingSessionAdditionalCharges();
  }, [
    selectedResPerPage,
    selectedPageNumber,
    sessionSearchTerm,
    searchTerm,
    paymentStatus,
    status,
    orderDesc
  ])

  const getBookingSessionAdditionalCharges = async (page:number = 1) => {
    if(isLoading || !isReady) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.resPerPage = selectedResPerPage;
    EnsanCareLib.currentPage = page||selectedPageNumber;
    EnsanCareLib.orderDirection = orderDesc ? 'desc' : 'asc';

    switch (status) {
      case "approved":
        EnsanCareLib.approved = 'y'
        break;
      case "rejected":
        EnsanCareLib.rejected = 'y'
        break;
      case "pending":
        EnsanCareLib.pending = 'y'
        break;
    }

    switch (paymentStatus) {
      case "paid":
        EnsanCareLib.paid = 'y'
        break;
      case "unpaid":
        EnsanCareLib.unpaid = 'y'
        break;
    }

    //search filters
    if(sessionSearchTerm) EnsanCareLib.sessionId = sessionSearchTerm;
    if(searchTerm) EnsanCareLib.searchTerm = searchTerm;

    var res:any = await EnsanCareLib.getBookingSessionAdditionalCharges();

    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });
    } 

    else {
      if(config.dev) console.warn('no results found');
     
      setBookingAdditionalCharges(res.data);
    }
    
    setIsLoading(false);
    return null;
  }

  const _renderLoadingRow = () => {
    return <Table.Row>
      <Table.Cell colSpan={7} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderRows = () => {
    if(!bookingAdditionalCharges || bookingAdditionalCharges.length < 1) { 
      return <Table.Row disabled>
        <Table.Cell>
          {t('g.noResults')}
        </Table.Cell>
      </Table.Row>
    };
    
    return bookingAdditionalCharges.map( (bookingSession, key) => {
      return <BookingsAdditionalSession
        id={bookingSession.id}
        bookingUuid={bookingSession.booking_uuid}
        sessionId={bookingSession.session_id}
        total={bookingSession.total}
        description={bookingSession.description}
        created_at={bookingSession.created_at}
        approved_at={bookingSession.approved_at}
        paid_at={bookingSession.paid_at}
        rejected_at={bookingSession.rejected_at}
        updated={searchTerm? true : false}
      />
    })
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />
    
    <Header
      as='h1'
      content={t('s.bookings.sessions.additionalCharges.title')}
    />

    <Form>
      <Form.Group widths={'equal'}>
        <Form.Field>
          <Form.Input
            icon={'search'}
            placeholder={t('g.search')}
            value={searchTerm}
            onChange={(e,data) => {
              setSearchTerm(data.value);
            }}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            icon={'search'}
            placeholder={t('s.bookings.sessions.session')}
            onChange={(e,data) => {
              if(parseInt(data.value) < 1) return null;
              if(/^[0-9]/.test(data.value)){
                setSessionSearchTerm(parseInt(data.value));
              } else {
                setSessionSearchTerm(null);
              }
            }}
          />
        </Form.Field>
        <Form.Field>
          <Form.Dropdown
            selection
            fluid
            clearable
            placeholder={t('g.status')}
            options={[
              {key: 0, value: 'approved', text: t('g.approved')},
              {key: 1, value: 'rejected', text: t('g.rejected')},
              {key: 2, value: 'pending', text: t('g.pending')}
            ]}
            onChange={(e,data) => {
              if(
                data.value === 'approved' ||
                data.value === 'rejected' ||
                data.value === 'pending'
              ) setStatus(data.value);
              else setStatus(null);

              return null;
            }}
          />
        </Form.Field>
        <Form.Field>
          <Form.Dropdown
            selection
            fluid
            clearable
            placeholder={t('s.payments.title')}
            options={[
              {key: 0, value: 'unpaid', text: t('g.unpaid')},
              {key: 1, value: 'paid', text: t('g.paid')},
            ]}
            onChange={(e,data) => {
              if(
                data.value === 'unpaid' ||
                data.value === 'paid'
              ) setPaymentStatus(data.value);
              else setPaymentStatus(null);

              return null
            }}
          />
        </Form.Field>
      </Form.Group>
    </Form>

    <Table color='blue' striped selectable sortable>
      <Table.Header>
        <Table.HeaderCell width={2}>{t('g.id')}</Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('s.bookings.booking')}</Table.HeaderCell>
        <Table.HeaderCell width={4}>{t('g.description')}</Table.HeaderCell>
        <Table.HeaderCell 
          width={4}
          sorted={orderDesc ? "descending" : 'ascending'}
          onClick={() => setOrderDesc(!orderDesc)}
        >
          {t('g.createdAt')}
        </Table.HeaderCell>
        <Table.HeaderCell width={4}>{t('g.control')}</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderRows()}
        {isLoading && _renderLoadingRow()}
      </Table.Body>
    </Table>
    <Grid verticalAlign='middle'>
      <Grid.Row columns={3}>
        <Grid.Column>
          {t('g.resPerPage')}
          <Dropdown
            style={{
              marginLeft: '10px'
            }}
            inline
            options={[
              {key: 0, value: 25, text: "25"},
              {key: 1, value: 50, text: "50"},
              {key: 2, value: 100, text: "100"},
              {key: 3, value: 500, text: "500"},
            ]}
            defaultValue={selectedResPerPage}
            onChange={(e, data) => {

              if(typeof data.value === 'number'){
                return setSelectedResPerPage(data.value); 
              }

              if(typeof data.value === 'string' && /^[0-9]/.test(data.value)){
                return setSelectedResPerPage(parseInt(data.value));
              }
              
              if(config.dev) console.warn("result per page type is not valid", typeof data.value)

              return null;
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Menu floated='right' pagination size='mini'>
            <Menu.Item 
              as='a' 
              icon 
              onClick={() => {
                if(selectedPageNumber <= 1) {
                  setSelectedPageNumber(selectedPageNumber)
                  return null;
                }

                setSelectedPageNumber(selectedPageNumber-1)
                getBookingSessionAdditionalCharges(selectedPageNumber-1);
              }}
              >
              <Icon name='chevron left'/>
            </Menu.Item>
            <Menu.Item>
              <Input 
                value={selectedPageNumber}
                onChange={(e,data) => {
                  if(parseInt(data.value) < 1) return null;
                  if(/^[0-9]/.test(data.value))
                    setSelectedPageNumber(parseInt(data.value));
                }}
              />
              <Button 
                style={{
                  marginLeft: '10px'
                }}
                color='blue'
                onClick={() => {
                  var error=false;

                  if(typeof selectedPageNumber !== 'number') {
                    error=true;
                    if(config.dev) console.warn('Provided page number is not a number', selectedPageNumber);
                  }

                  if(selectedPageNumber < 1) {
                    error=true;
                    if(config.dev) console.warn('Provided page number is less than 1', selectedPageNumber);
                  }

                  if(error) {
                    setModal({
                      visible: true,
                      title: t('g.error'),
                      message: t('g.invalidInput'),
                      action: [
                        {
                          content: t('g.goBack'),
                          onClick: () => history.goBack()
                        },
                      ]
                    });

                    return null;
                  }

                  setSelectedPageNumber(selectedPageNumber)
                  getBookingSessionAdditionalCharges(selectedPageNumber);
                }}
              >
                {t('g.go')}
              </Button>
            </Menu.Item>
            <Menu.Item 
              as='a' 
              icon
              onClick={() => {
                setSelectedPageNumber(selectedPageNumber+1)
                getBookingSessionAdditionalCharges(selectedPageNumber+1);
              }}
              >
              <Icon name='chevron right' />
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </InternalPage>
}

export default BookingSessionAdditionalChargesList;