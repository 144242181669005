import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Image,
  Dropdown,
  Divider,
  List,
  TextArea,
  Segment
} from 'semantic-ui-react';

import EnsanCareAPI from '../util/EnsanCareLib/EnsanCareAPI';
import ECGeneral from '../util/EnsanCareLib/funcs/general';
import ECServiceRequesters from '../util/EnsanCareLib/funcs/service_requesters';
import ECCaregivers from '../util/EnsanCareLib/funcs/caregivers';
import ECServiceCities from '../util/EnsanCareLib/funcs/service_cities';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../framework/internal_page';
import Map from '../framework/map';

import moment from 'moment';

import remoteAssetsUrl from '../remote_assets_uri.json';

export default function BookingDetails() {
  const host = config.dev ? config.d.API : config.p.API;
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {booking_uuid} = useParams<{booking_uuid:string}>();

  // this state is used as a placeholder for notes to submit 
  // for both cancellation and refund notes.
  const [notesInput, setNotesInput] = useState<string|null>(null);

  const [bookingDetails, setBookingDetails] = useState<any>(null); 

  const [serviceCity, setServiceCity] = useState<string|null>(null);

  const [isLoadingBooking, setIsLoadingBooking] = useState<boolean>(false); 
  
  const [sessions, setSessions] = useState<any[]|null>(null); 
  const [isLoadingSessions, setIsLoadingSessions] = useState<boolean>(false);
  
  const [transactions, setTransaction] = useState<any[]|null>(null); 
  const [isLoadingTransactions, setIsLoadingTransactions] = useState<boolean>(false);

  const [bookingServices, setBookingServices] = useState<any>(null);
  const [bookingAttachments, setBookingAttachments] = useState<any>(null);

  // states
  interface modalType {
    includeInput?: boolean,
    inputContentType?: 'refund'|'cancellation'|null,
    visible: boolean,
    title: string,
    message: string|null,
    actions: any
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getBooking();
    getBookingSessions();
    getBookingTransactions();
  }, []);

  useEffect(() => {
    getServiceCity();
  }, [bookingDetails]);

  //getServiceCity
  const getServiceCity = async () => {
    if (!bookingDetails || bookingDetails.service_city_id === null) {
      return null
    }

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.serviceCityId = bookingDetails?.service_city_id;
    
    var res:any = await EnsanCareLib.getServiceCity();

    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
      return null;
    }

    const serviceCityName = ECServiceCities.buildLabel(i18n.language, res.data[0])
    
    setServiceCity(serviceCityName);
  }

  // get booking details
  const getBooking = async () => {
    if(isLoadingBooking) return null;
    setIsLoadingBooking(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.bookingUuid = booking_uuid;

    var res = await EnsanCareLib.getBooking();
    
    if(!res) {}

    else if (res.status === 'fail' || !res.data.details) {}

    else if (res.status === 'success') {
      setBookingDetails(res.data.details);
      setBookingServices(res.data.services);
      setBookingAttachments(res.data.attachments);
    }

    else {}

    setIsLoadingBooking(false);
    return null;
  }
  
  const getBookingSessions = async () => {
    if(isLoadingSessions) return null;
    setIsLoadingSessions(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.bookingUuid = booking_uuid;

    var res = await EnsanCareLib.getBookingSessions();

    if(!res) {}

    else if (res.status === 'fail') {}

    else if (res.status === 'success') {
      setSessions(res.data);
    }

    else {}

    setIsLoadingSessions(false);
    return null;
  }

  const getBookingTransactions = async () => {
    if(isLoadingSessions) return null;
    setIsLoadingSessions(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.bookingUuid = booking_uuid;
    EnsanCareLib.orderBy = 'created_at';
    EnsanCareLib.orderDirection = 'ASC';

    var res = await EnsanCareLib.getTransactions();

    if(!res) {}

    else if (res.status === 'fail') {}

    else if (res.status === 'success') {
      setTransaction(res.data);
    }

    else {}

    setIsLoadingSessions(false);
    return null;
  }

  const updateBooking = async (type:'status'|'paid_out_at', value:any) => {
    if(!type || !value) return null;
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.bookingUuid = booking_uuid;
    EnsanCareLib.debug = config.dev;
    EnsanCareLib[type] = value;
    EnsanCareLib.cancellation_note = notesInput;
    
    var res = await EnsanCareLib.updateBooking();
    
    if(!res) {}

    else if (res.status === 'fail') {}

    else if (res.status === 'success' && res.update_status[type] === true) {
      if(type === 'paid_out_at') {
        value = value.toString() || null;
      }

      var tempBookingDetails = bookingDetails;
      tempBookingDetails[type] = value;
      setBookingDetails({...tempBookingDetails});
    }

    else {}

    return null;
  }

  const _renderSessionsLoadingRow = () => {
    if(isLoadingSessions) return <Table.Row>
      <Table.Cell colSpan={7} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderSessionsRows = () => {
    if(!sessions || sessions.length < 1) return null;

    return sessions.map( (session, key) => {
      return <Table.Row 
        key={key}
        onClick={() => window.open(`/bookings/${booking_uuid}/sessions/${session.id}`, '_self')}
      >
        <Table.Cell>
          {session.id}
        </Table.Cell>
        <Table.Cell>
          {moment(session.schedule_date).format(config.momentDateFormat)}
        </Table.Cell>
        <Table.Cell>
          {session.schedule_period}
        </Table.Cell>
        <Table.Cell>
          {t(`s.bookings.status.${session.status}`)}
        </Table.Cell>
      </Table.Row>
    })
  }

  const _renderTransactionsLoadingRow = () => {
    if(isLoadingSessions) return <Table.Row>
      <Table.Cell colSpan={7} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderTransactionsRows = () => {
    if(!transactions || transactions.length < 1) return null;

    return transactions.map( (transaction, key) => {
      var transactionUuid = transaction.uuid;

      if(transactionUuid) {
        transactionUuid = transactionUuid.substring(transactionUuid.length - 8)
      }

      var purposeUuid = transaction.purpose_id;

      if(purposeUuid) {
        purposeUuid = purposeUuid.substring(purposeUuid.length - 8)
      }

      var refundAt = '';
      var warning = false;

      if(transaction.refund_at) {
        warning = true;
        refundAt = moment(transaction.refund_at).format(config.momentDateTimeFormat);
      }

      var amount = null;

      if(transaction.amount) {
        amount = transaction.amount.toFixed(2);
      }

      return <Table.Row 
        key={key}
        warning={warning}
        onClick={() => window.open('/bookings/payments/' + transaction.uuid, '_self')}
      >
        <Table.Cell>
          #{transactionUuid}
        </Table.Cell>
        <Table.Cell>
          {transaction.purpose}
        </Table.Cell>
        <Table.Cell>
          #{purposeUuid}
        </Table.Cell>
        <Table.Cell>
          {amount}
        </Table.Cell>
        <Table.Cell>
          {moment(transaction.created_at).format(config.momentDateTimeFormat)}
          <span style={{marginLeft: '5px', fontWeight: 'bold'}}>
            {t(`g.${transaction.transaction_status}`)}
          </span>
        </Table.Cell>
        <Table.Cell>{refundAt}</Table.Cell>
      </Table.Row>
    })
  }

  const _renderAttachmentsLoadingRow = () => {
    if(isLoadingBooking) return <Table.Row>
      <Table.Cell colSpan={5} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderAttachmentsRows = () => {
    if(!bookingAttachments || bookingAttachments.length < 1) return null;
    
    return bookingAttachments.map( (attachment:any, key:number) => {
      return <Table.Row>
        <Table.Cell width={8}>{attachment.uuid}</Table.Cell>
        <Table.Cell>{attachment.ext}</Table.Cell>
        <Table.Cell>{attachment.size}</Table.Cell>
        <Table.Cell>{moment(attachment.size).format(config.momentDateTimeFormat)}</Table.Cell>
        <Table.Cell textAlign='right'>
          <Button
            size='tiny'
            content={t('g.view')}
            onClick={() => window.open(
              `${host}${remoteAssetsUrl.bookings_documents}${booking_uuid}/attachments/${attachment.uuid}.${attachment.ext}`, 
              '_blank'
            )}
          />
        </Table.Cell>
      </Table.Row>
    })
  }

  if(!bookingDetails && !serviceCity) return <InternalPage loading />

  return <InternalPage >
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={<div style={{padding: '20px'}}>
        <div style={{paddingBottom: '10px', lineHeight: '18px'}}>
          {modal?.message}
        </div>
        {(modal?.includeInput) && null}
      </div>}
      actions={modal ? modal.actions : null}
    />
    
    <Header
      as='h1'
      content={t('s.bookings.booking')}
      subheader={`#${bookingDetails?.uuid} - ${moment(bookingDetails?.created_at).format(config.momentDateTimeFormat)}`}
    />

    {/** general information */}
    <Header
      as='h2'
      content={t('g.generalInformation')}
    />

    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column>
          <Header
            as='h3'
            content={t('s.serviceRequesters.member')}
          />

          <div>
            <Image src='' avatar />
            <span>
              {ECServiceRequesters.buildMemberName(
                bookingDetails.member_first_name,
                bookingDetails.member_middle_name,
                bookingDetails.member_last_name
              )}
            </span>
            <span style={{marginLeft: 10}}>
              <a href={`/service-requesters/${bookingDetails.service_requester_uuid}/members/${bookingDetails.member_id}`}>
                ({t('g.view')})
              </a>
            </span>
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header
            as='h3'
            content={t('s.profile.caregiver')}
          />

          <div>
            <Image src='' avatar />
            <span>
              {ECCaregivers.buildName(
                i18n.language,
                bookingDetails.caregiver_name_prefix,
                bookingDetails.caregiver_first_name_local,
                bookingDetails.caregiver_middle_name_local,
                bookingDetails.caregiver_last_name_local
              )}
            </span>
            <span style={{marginLeft: 10}}>
              <a href={`/caregivers/${bookingDetails.caregiver_uuid}`}>
                ({t('g.view')})
              </a>
            </span>
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header
            as='h3'
            content={t('g.status')}
          />
          <Dropdown
            fluid
            selection
            placeholder={t('g.selectOne')}
            value={bookingDetails.status}
            options={[
              {key: 0, value: 'pending payment', text: t('s.bookings.status.pending payment')},
              {key: 1, value: 'pending acceptance', text: t('s.bookings.status.pending acceptance')},
              {key: 2, value: 'on going', text: t('s.bookings.status.on going')},
              {key: 3, value: 'completed', text: t('s.bookings.status.completed')}
            ]}
            onChange={(e, data) => {
              updateBooking('status', data.value)
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider section />

    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Header
            as='h3'
            content={t('s.services.title')}
          />
          <List bulleted>
            {bookingServices?.map((service:any, key:number) => {
              var serviceLabel = ECGeneral.getFromLocal(
                i18n.language,
                service.service_local,
                service.service_title
              )

              var serviceCategoryLabel = ECGeneral.getFromLocal(
                i18n.language,
                service.service_category_local,
                service.service_category_title
              )

              return <List.Item
                key={key}
                content={`${serviceLabel} (${serviceCategoryLabel})`}
              />
            })}            
          </List>
        </Grid.Column>
        <Grid.Column>
          <Header
            as='h3'
            content={t('s.bookings.symptoms')}
          />
          <div>
            {bookingDetails?.symptoms}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider section />

    {bookingDetails?.cancelled_at && <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Header
              as='h3'
              content={t('g.cancelledBy')}
              color={'red'}
            />
            <div>
              <span style={{textTransform: 'capitalize', fontWeight: 'bold'}}>
                {bookingDetails.cancelled_by}
              </span>
              <span> @ {moment(bookingDetails.cancelled_at).format(config.momentDateTimeFormat)}</span>
            </div>
          </Grid.Column>
          <Grid.Column>
            <Header
              as='h3'
              content={t('g.cancellationNotes')}
              color={'red'}
            />
            <div>
              {bookingDetails.cancellation_note}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </> }

    {bookingDetails?.aceppted_at && <>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as='h3'
              content={t('g.acceptedAt')}
            />
            <div>
              {moment(bookingDetails.aceppted_at).format(config.momentDateTimeFormat)}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </> }

    <Divider section />

    <Grid>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header
            as='h3'
            content={t('s.serviceCities.serviceCity')}
          />
           <div>
            <span>
              {serviceCity}
            </span>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <div style={{overflow: 'hidden', borderRadius: 10}}>
            <Map 
              height={500}
              center={(bookingDetails.location)  
                ? ECServiceCities.convertCoordsToObject(bookingDetails.location)
                : null
              }
              markers={(bookingDetails.location)  
                ? [{location: ECServiceCities.convertCoordsToObject(bookingDetails.location)}]
                : null
              } 
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider hidden section />

    <Grid>
      <Grid.Row columns={5}>
        <Grid.Column>
          <Header
            as='h3'
            content={t('g.cost')}
          />

          <div>
            {bookingDetails.cost?.toFixed(2)}
            <span style={{marginLeft: 10}}>
              {t('c.SR')}
            </span>
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header
            as='h3'
            content={t('s.bookings.sessions.additionalCharges.title')}
          />

          <div>
            {(bookingDetails.total - bookingDetails.cost).toFixed(2)}
            <span style={{marginLeft: 10}}>
              {t('c.SR')}
            </span>
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header
            as='h3'
            content={t('g.total')}
          />

          <div>
            {bookingDetails.total?.toFixed(2)}
            <span style={{marginLeft: 10}}>
              {t('c.SR')}
            </span>
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header
            as='h3'
            content={t('g.commission')}
          />

          <div>
            {bookingDetails.commission_value * 100}%
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header
            as='h3'
            content={t('g.paidOutAt')}
          />

          <div>
            {bookingDetails.paid_out_at
              ? moment(bookingDetails.paid_out_at).format(config.momentDateTimeFormat)
              : <Button 
                content={`${t('s.bookings.setPaidOutStatus')}`} 
                onClick={() => setModal({
                  visible: true,
                  includeInput: false,
                  inputContentType: null,
                  title: t('g.areYouSure'),
                  message: `${t('s.bookings.setPaidOutDescription')}. ${t('g.processIsNotReversable')}`,
                  actions: [
                    {
                      content: t('g.cancel'),
                      onClick: () => null
                    },
                    {
                      positive: true,
                      content: t('g.confirm'),
                      onClick: () => updateBooking('paid_out_at', new Date())
                    }
                  ]
                })}
              />
            }
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider section />

    {/** sessions list information */}
    <Header
      as='h2'
      content={t('s.bookings.attachments.title')}
    />

    <Table stackable striped color='blue'>
      <Table.Header>
        <Table.HeaderCell width={2}>{t('g.uuid')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.fileExtension')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.size')} ({t('u.mb')})</Table.HeaderCell>
        <Table.HeaderCell>{t('g.createdAt')}</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderAttachmentsRows()}
        {_renderAttachmentsLoadingRow()}
      </Table.Body>
    </Table>

    <Divider section />

    {/** sessions list information */}
    <Header
      as='h2'
      content={t('s.bookings.sessions.title')}
    />

    <Table striped stackable color='blue'>
      <Table.Header>
        <Table.HeaderCell>{t('g.id')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.date')}</Table.HeaderCell>
        <Table.HeaderCell>{t('s.caregivers.schedule.period')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.status')}</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderSessionsRows()}
        {_renderSessionsLoadingRow()}
      </Table.Body>
    </Table>

    {/** sessions list information */}
    <Header
      as='h2'
      content={t('s.payments.title')}
    />

    <Table striped stackable color='blue'>
      <Table.Header>
        <Table.HeaderCell>{t('g.uuid')}</Table.HeaderCell>
        <Table.HeaderCell>{t('s.payments.purpose')}</Table.HeaderCell>
        <Table.HeaderCell>{t('s.payments.purpose')} {t('g.uuid')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.amount')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.date')} / {t('g.status')}</Table.HeaderCell>
        <Table.HeaderCell>{t('s.payments.refundAt')}</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderTransactionsRows()}
        {_renderTransactionsLoadingRow()}
      </Table.Body>
    </Table>

    { !['cancelled', 'completed'].includes(bookingDetails.status) && <>
      <Divider section />

      <Header
        as='h2'
        content={`${t('g.cancel')} ${t('s.bookings.booking')}`}
      />

      <Segment color={'red'}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form>
                <TextArea 
                  rows={4} 
                  placeholder={t('g.cancellationNotes')}
                  onChange={(e, data) => {
                    setNotesInput(data?.value?.toString()||'')
                  }}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign={'right'}>
              <Button
                disabled={notesInput?.length ? false : true}
                negative
                content={`${t('g.cancel')} ${t('s.bookings.booking')}`}
                onClick={() => updateBooking('status','cancelled')}
              />  
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      </>
    }

  </InternalPage>
}