/*
 *
 * PROJECT ENSAN CARE
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

export default {
  getFromLocal: (
    language, localSource, defReturn
  ) => {
    if(localSource) try {
      if(typeof localSource === 'string')
        localSource = JSON.parse(localSource);

      localSource = localSource[language] || localSource['en'];
    }
    catch(e) {
      // if title is available use it
      if(defReturn)
        localSource = defReturn;

      else
        localSource = '/** Render Error';
    }

    if(!localSource && defReturn) localSource = defReturn;
    
    return localSource;
  },

  checkColorCode: (hexColor, allowEmpty=true) => {
    var regex = /^#[a-zA-Z0-9]{6}/;

    // no need to check if empty
    if(
      allowEmpty && 
      (!hexColor || hexColor.length === 0)
    ) return true;

    if(!hexColor) {
      console.warn('no value entered', hexColor);
      return false;
    }

    if(hexColor.length != 7) {
      return false;
    }

    if(regex.test(hexColor))
      return true;

    return false;
  }
}