import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Segment,
  Image,
  Divider,
  Popup
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../../framework/internal_page';

import EnsanCareAPI from "../../util/EnsanCareLib/EnsanCareAPI";
import ECGeneral from "../../util/EnsanCareLib/funcs/general"

export default function ServicesCategoryForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [actionRequested, setActionRequested] = useState<boolean>(true);

  const [category, setCategory] = useState<any>({
    id: null,
    listing_order: null,
    active: null,
    title: null,
    color_code: null,
    local: {
      en: null,
      ar: null
    }
  }); 

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const {category_id} = useParams<{category_id:string}>();
  const [selectedImage, setSelectedImage] = useState<any>(null);

  useEffect(() => {
    if(category_id) getServiceCategory();
  }, [])

  const getServiceCategory = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.categoryId = category_id;

    var res:any = await EnsanCareLib.getServiceCategory();
    
    if(!res) {
      if(config.dev) 
        console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => getServiceCategory()
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => getServiceCategory()
          },
        ]
      });
    } 
   
    else if (res.status === 'success') {
      if(res.data) {
        var tempObj = {
          id: res.data.id || null,
          active: res.data.active || null,
          listing_order: res.data.listing_order || null,
          title: res.data.title  || null,
          color_code: res.data.color_code || null,
          local: {
            en: res.data.local ? ECGeneral.getFromLocal("en", res.data.local) : null,
            ar: res.data.local ? ECGeneral.getFromLocal("ar", res.data.local) : null
          }
        }

        setCategory(tempObj);
      }
    }
    
    setIsLoading(false);
    return null;
  }

  const processSubmit = async () => {
    if(isLoading) return true;
    setIsLoading(true);

    if(!ECGeneral.checkColorCode(category.color_code, false)) {
      setIsLoading(false);
      return null;
    }

    const data:any = new FormData();
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.useAppend = true;
    
    data.append("listing_order", category.listing_order);
    data.append("active", category.active);
    data.append("title", category.title);
    data.append("color_code", category.color_code);
    data.append("local", JSON.stringify(category.local));
    data.append("icon", selectedImage);

    var res:any;
    
    if (!category.id) {
      EnsanCareLib.body = data;
      res = await EnsanCareLib.addServiceCategory();
    } 
  
    else {
      EnsanCareLib.categoryId = category.id;
      EnsanCareLib.body = data;

      res = await EnsanCareLib.updateServiceCategory();
    }console.log(res)

    if (!res) {
      if(config.dev)
        console.error('Failed to complete update or add category');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => processSubmit()
          },
        ]
      });

    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => processSubmit()
          },
        ]
      });
    }

    else if (res.status === 'success') {

      var btnAction;

      // modal for adding a new category
      if(!category.id && res.data.id) { 
        btnAction = () => history.push('../services/category/show/' + res.data.id);
      }
      
      else {
        btnAction = () => null;
      }

      // modal for updating category
      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            positive: true,
            onClick: btnAction
          },
        ]
      });
    }
    
    setIsLoading(false);
    return null;
  }


  const processDelete = async () =>{
    if(isLoading) return true;
    setIsLoading(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.categoryId = category_id;

    var res:any = await EnsanCareLib.deleteServiceCategory();
    
    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => processDelete()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if (res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => processDelete()
          },
        ]
      });
    }

    else if (res.status === "success") {
      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            positive: true,
            onClick: () => history.push('../')
          },
        ]
      });
    }

    setIsLoading(false);
    return null;
  }

  const _renderDeleteBtn = () => {
    if(category_id) {
      if(actionRequested){
        return <>
          <Button
            negative
            content={t('g.delete')}
            onClick={() => setActionRequested(false)}
          />
        </>
      }
      else {
        return <>
          <Button
            color='yellow'
            content={t('g.confirm')}
            onClick={() => processDelete()}
          />
          <Button
            content={t('g.cancel')}
            onClick={() => setActionRequested(true)}
          />
        </>
      }
    }
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.action : null}
    />
    
    <Header
      as='h2'
      content={category.title || '...'}
    />

    <Form>
      <Form.Input
        label={t('g.listingOrder')}
        placeholder={0}
        value={category.listing_order || ''}
        onChange={(e, data) => setCategory({
          ...category,
          listing_order: data.value||''
        })}
      />

      <Form.Select
        label={t('g.active')}
        placeholder={t('g.selectOne')}
        value={category.active || null}
        onChange={(e, { value }) => setCategory({
          ...category,
          active: value||null
        })}
        options={[
          {key: 'n', value: 'n', text: t('g.no')},
          {key: 'y', value: 'y', text: t('g.yes')}
        ]}
      />

      <Form.Input
        label={t('g.title')}
        placeholder={t('g.typeHere')}
        value={category.title || ''}
        onChange={(e, data) => setCategory({
          ...category,
          title: data.value||''
        })}
      />

      <Form.Group widths={2}>
        <Form.Input
          label={`${t('g.title')} (EN)`}
          placeholder={t('g.typeHere')}
          value={category.local.en || ''}
          onChange={(e, data) => setCategory({
            ...category,
            local: {
              ...category.local,
              en: data.value||''
            }
          })}
        />
        <Form.Input
          label={`${t('g.title')} (AR)`}
          placeholder={t('g.typeHere')}
          value={category.local.ar || ''}
          onChange={(e, data) => setCategory({
            ...category,
            local: {
              ...category.local,
              ar: data.value||''
            }
          })}
        />
      </Form.Group>

      <Form.Input
        label='Icon'
        type='file'
        accept='image/png'
        onChange={(event, e) => {
          if(event && event.target && event.target.files) {
            var file:any = event.target.files[0];
            setSelectedImage(file);
          }
        }}
      />

      <Popup 
        header={"input example"}
        content={"hex value: #000XXX"}
        trigger={
          <Form.Input
            label={t('g.color')}
            placeholder={t('g.typeHere')}
            error={!ECGeneral.checkColorCode(category.color_code, false)}
            value={category.color_code || ''}
            onChange={(e, data) => {
              setCategory({
                ...category,
                color_code: data.value
              })}
            }
          />
        }
      />

    </Form>

    <Divider hidden />

    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Button
            icon='arrow left'
            labelPosition='left'
            content={t('g.cancel')}
            onClick={() => category_id ? history.push('../../') : history.push('../')}
          />
          {_renderDeleteBtn()}
        </Grid.Column>
        <Grid.Column floated="right" textAlign="right">
          <Button
            primary
            content={t('g.save')}
            onClick={() => processSubmit()}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

  </InternalPage>
}