import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Dropdown,
  Menu,
  Icon,
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../framework/internal_page';

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";

export default function ManagementListing() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [selectedResPerPage, setSelectedResPerPage] = useState<number>(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string|null>(null);

  const [managers, setManagers] = useState<any[]|null>(null); 

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getManagers()
  }, []);

  useEffect(() => {
    getManagers()
  }, [
    searchTerm,
    selectedPageNumber,
    selectedResPerPage
  ]);

  const getManagers = async (page:number = 1) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;

    EnsanCareLib.resPerPage = selectedResPerPage;
    EnsanCareLib.currentPage = page||selectedPageNumber;
    if(searchTerm) EnsanCareLib.searchTerm = searchTerm;

    var res:any = await EnsanCareLib.getManagers();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getManagers()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getManagers()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }
    
    else if(res.status === "success" && res.data) {

      setManagers(res.data);
    }

    setIsLoading(false);
    return null;
  }

  const _renderLoadingRow = () => {
    return <Table.Row>
      <Table.Cell colSpan={7} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderRows = () => {
    if(!managers || managers.length < 1) return null;
    
    return managers.map( (manager, key) => {
      var uuid = manager.uuid || null;

      if(uuid) uuid = uuid.substring(uuid.length - 5, uuid.length);

      return <Table.Row onClick={() => window.open(`/management/show/${manager.uuid}`, '_self')}>
        <Table.Cell>#{uuid}</Table.Cell>
        <Table.Cell>{manager.first_name} {manager.last_name}</Table.Cell>
        <Table.Cell>{manager.country_code} {manager.phone}</Table.Cell>
        <Table.Cell>{manager.email}</Table.Cell>
      </Table.Row>
    })
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.action : null}
    />
    
    <Header
      as='h1'
      content={t('s.profile.management')}
    />

    <Form>
      <Form.Input
        icon={'search'}
        placeholder={t('g.search')}
        onChange={(e,data) => setSearchTerm(data.value)}
        value={searchTerm}
      />
    </Form>

    <Menu>  
      <Menu.Item
        icon='plus'
        content={t('g.addNew')}
        onClick={() => window.open('/management/create', '_self')}
      />
    </Menu>

    <Table color='blue' striped selectable>
      <Table.Header>
        <Table.HeaderCell>{t('g.uuid')}</Table.HeaderCell>
        <Table.HeaderCell>{t('s.profile.fullName')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.phone')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.email')}</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderRows()}
        {isLoading && _renderLoadingRow()}
      </Table.Body>
    </Table>
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column>
          {t('g.resPerPage')}
          <Dropdown
            style={{
              marginLeft: '10px'
            }}
            inline
            options={[
              {key: 0, value: 25, text: "25"},
              {key: 1, value: 50, text: "50"},
              {key: 2, value: 100, text: "100"},
              {key: 3, value: 500, text: "500"},
            ]}
            defaultValue={selectedResPerPage}
            onChange={(e, data) => {

              if(typeof data.value === 'number'){
                return setSelectedResPerPage(data.value); 
              }

              if(typeof data.value === 'string' && /^[0-9]/.test(data.value)){
                return setSelectedResPerPage(parseInt(data.value));
              }
              
              if(config.dev) console.warn("result per page type is not valid", typeof data.value)
              return null;
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Menu floated='right' pagination size='mini'>
            <Menu.Item 
              as='a' 
              icon 
              onClick={() => {
                if(selectedPageNumber <= 1) {
                  setSelectedPageNumber(selectedPageNumber)
                  return null;
                }

                setSelectedPageNumber(selectedPageNumber-1)
                getManagers(selectedPageNumber-1);
              }}
              >
              <Icon name='chevron left'/>
            </Menu.Item>
            <Menu.Item>
              <Input 
                value={selectedPageNumber}
                onChange={(e,data) => {
                  if(parseInt(data.value) < 1) return null;
                  if(/^[0-9]/.test(data.value))
                    setSelectedPageNumber(parseInt(data.value));
                }}
              />
              <Button 
                style={{
                  marginLeft: '10px'
                }}
                color='blue'
                onClick={() => {
                  var error = false;

                  if(typeof selectedPageNumber !== 'number') {
                    error = true;
                    if(config.dev) console.warn('Provided page number is not a number', selectedPageNumber);
                  }

                  if(selectedPageNumber < 1) {
                    error = true;
                    if(config.dev) console.warn('Provided page number is less than 1', selectedPageNumber);
                  }

                  if(error) {
                    setModal({
                      visible: true,
                      title: t('g.error'),
                      message: t('g.invalidInput'),
                      action: [
                        {
                          content: t('g.goBack'),
                          onClick: () => history.goBack()
                        },
                      ]
                    });

                    return null;
                  }

                  setSelectedPageNumber(selectedPageNumber)
                  getManagers(selectedPageNumber);
                }}
              >
                {t('g.go')}
              </Button>
            </Menu.Item>
            <Menu.Item 
              as='a' 
              icon
              onClick={() => {
                setSelectedPageNumber(selectedPageNumber+1)
                getManagers(selectedPageNumber+1);
              }}
              >
              <Icon name='chevron right' />
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </InternalPage>
}