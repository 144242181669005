import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Image,
  Dropdown,
  Divider,
  List,
  TextArea
} from 'semantic-ui-react';

import EnsanCareAPI from '../util/EnsanCareLib/EnsanCareAPI';
import ECGeneral from '../util/EnsanCareLib/funcs/general';
import ECServiceRequesters from '../util/EnsanCareLib/funcs/service_requesters';
import ECCaregivers from '../util/EnsanCareLib/funcs/caregivers';
import ECServiceCities from '../util/EnsanCareLib/funcs/service_cities';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../framework/internal_page';
import Map from '../framework/map';

import moment from 'moment';

export default function TransactionDetails() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {transaction_uuid} = useParams<{transaction_uuid:string}>();

  // this state is used as a placeholder for notes to submit 
  // for both cancellation and refund notes.
  const [refundEmptyError, setRefundEmptyError] = useState<boolean>(false);

  const [transactionDetails, setTransactionDetails] = useState<any>(null); 
  const [isLoading, setIsLoading] = useState<boolean>(false); 

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string|null,
    actions: any
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getTransaction();
  }, []);

  // get booking details
  const getTransaction = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.transactionUuid = transaction_uuid;

    var res = await EnsanCareLib.getTransaction();
    
    if(!res) {}

    else if (res.status === 'fail' || !res.data) {}

    else if (res.status === 'success') {
      setTransactionDetails(res.data);
    }

    else {}

    setIsLoading(false);
    return null;
  }

  const refundTransaction = async () => {
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.transactionUuid = transaction_uuid;
    EnsanCareLib.debug = config.dev;
    EnsanCareLib.refundAt = true;
    EnsanCareLib.refundNotes = transactionDetails.refund_notes;
    
    var res = await EnsanCareLib.updateTransaction();
    
    if(!res) {}

    else if (res.status === 'fail') {}

    else if (res.status === 'success') {
      getTransaction();
      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        actions: [
          {
            content: t('g.ok'),
            positive: true,
            onClick: () => null
          },
        ]
      });
    }

    else {}

    return null;
  }

  if(isLoading || !transactionDetails) return <InternalPage loading />

  // title: t('g.notes'),
  // message: t('s.payments.refundNotesRequired'),
  return <InternalPage >
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={<div style={{padding: '20px'}}>
        <div style={{paddingBottom: '10px', lineHeight: '18px'}}>
          {modal?.message}
        </div>
      </div>}
      actions={modal ? modal.actions : null}
    />
    
    <Header
      as='h1'
      content={t('s.payments.transaction')}
    />

    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column width={3}>
          <strong>{t('g.uuid')}:</strong>
        </Grid.Column>
        <Grid.Column>#{transactionDetails.uuid}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column width={3}>
          <strong>{t('s.payments.purpose')}:</strong>
        </Grid.Column>
        <Grid.Column>{transactionDetails.purpose}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column width={3}>
          <strong>{t('s.payments.purpose')} {t('g.uuid')}:</strong>
        </Grid.Column>
        <Grid.Column></Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column width={3}>
          <strong>{t('g.amount')}:</strong>
        </Grid.Column>
        <Grid.Column>{transactionDetails.amount}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column width={3}>
          <strong>{t('g.date')}:</strong>
        </Grid.Column>
        <Grid.Column>{transactionDetails.created_at}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column width={3}>
          <strong>{t('g.status')}:</strong>
        </Grid.Column>
        <Grid.Column>{transactionDetails.transaction_status}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column width={3}>
          <strong>Checkout ID:</strong>
        </Grid.Column>
        <Grid.Column>{transactionDetails.checkout_id}</Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column width={3}>
          <strong>{t('s.payments.refundAt')}:</strong>
        </Grid.Column>
        <Grid.Column>{
          transactionDetails.refund_at 
          ? moment(transactionDetails.refund_at).format(config.momentDateTimeFormat)
          : null
        }</Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <div style={{marginBottom: '15px'}}>
            <strong>{t('s.payments.refundNotes')}:</strong>
          </div>
          <Form>
            <Form.Field
              control={TextArea}
              error={refundEmptyError}
              value={transactionDetails.refund_notes||null}
              onChange={(e: any, { value }: any) => {
                setRefundEmptyError(false);
                setTransactionDetails({
                  ...transactionDetails,
                  refund_notes: value||null
                });
              }}
            />
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider section />
    
    <Grid>
      <Grid.Row>
        <Grid.Column textAlign='right'>
          {(!transactionDetails.refund_at) && <Button
            content={t('g.refund')}
            onClick={() => {
              if(!transactionDetails.refund_notes || transactionDetails.refund_notes.length < 1) {
                setRefundEmptyError(true);
                return null;
              }

              else setModal({
                visible: true,
                title: `${t('g.areYouSure')}`,
                message: t('g.processIsNotReversable'),
                actions: [
                  {
                    content: t('g.cancel'),
                    onClick: () => setModal(null)
                  },
                  {
                    content: t('g.confirm'),
                    color: 'red',
                    onClick: () => refundTransaction()
                  }
                ]
              })
            }}
          />}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </InternalPage>
}