import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu,
  Dropdown,
  Icon
} from 'semantic-ui-react';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";

import EnsanCareAPI from "../../util/EnsanCareLib/EnsanCareAPI";
import ECServiceRequesters from '../../util/EnsanCareLib/funcs/service_requesters';

import moment from 'moment';

export default function ServiceRequesterMembers() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {service_requester_uuid} = useParams<{service_requester_uuid:string}>();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [members, setMembers] = useState<Array<Object>>([]);
  const [nationalitiesList, setNationalitiesList] = useState<Array<any>>([]);

  const [selectedResPerPage, setSelectedResPerPage] = useState<number>(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getMembers();
    getNationalities();
  }, []);

  useEffect(() => {
    getMembers();
  }, [selectedResPerPage]);

  const getNationalities = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.showDeleted = 'y';
    EnsanCareLib.showAll = 'y';
    EnsanCareLib.orderBy = 'label';
    
    var res:any = await EnsanCareLib.getNationalities();

    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
    }

    if(res.data) {
      setNationalitiesList(res.data);
    }
    
    setIsLoading(false);
    return null;
  }

  const getMembers = async (page:number = 1) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.serviceReqeusterUuid = service_requester_uuid;
    EnsanCareLib.resPerPage = selectedResPerPage;
    
    EnsanCareLib.currentPage = page||selectedPageNumber;

    var res:any = await EnsanCareLib.getMembers();


    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getMembers()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getMembers()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
   
     
      setMembers(res.data);
    }
    
    setIsLoading(false);
    return null;
  }

  const _renderLoadingRow = () => {
    return <Table.Row>
      <Table.Cell colSpan={7} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderRows = () => {
    
    if(!members || members.length < 1) { 
      return <Table.Row disabled>
        <Table.Cell>
          {t('g.noResults')}
        </Table.Cell>
      </Table.Row>
    };

    return members.map((member:any, key:number) => {

      var uuid = member.service_requester_uuid || null;
     
      var nationality:any = {} 

      if(nationalitiesList.length > 0) {
        nationality = nationalitiesList.find(nat => nat.id === member.nationality_id);
       
        if(!nationality) 
          console.warn("could not find a nationality")
      }
      
      
      return (
        <Table.Row 
          onClick={() => window.open('/service-requesters/'+(uuid)+"/members/"+member.id, '_self')}
        >
          <Table.Cell width={2}>{member.id ? member.id : ''}</Table.Cell>
          <Table.Cell width={3}>
            {(member.first_name || member.middle_name || member.last_name) ?
              ECServiceRequesters.buildMemberName(
                member.first_name, 
                member.middle_name, 
                member.last_name
              ) : ''
            }
          </Table.Cell>
          <Table.Cell width={3}>{nationality ? nationality.label : ''}</Table.Cell>
          <Table.Cell width={3}>
            {member.dob ? moment(member.dob).format(config.momentDateFormat) : ''}
          </Table.Cell>
          <Table.Cell width={2}>{member.marital_status ? member.marital_status : ''}</Table.Cell>
          <Table.Cell width={3}>{member.number_of_children ? member.number_of_children : ''}</Table.Cell>
          <Table.Cell width={3}>{member.relationship ? member.relationship : ''}</Table.Cell>
        </Table.Row>
      )
    })
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />

    <Header
      as='h2'
      content={t('s.serviceRequesters.members')}
    />

    <Table color='blue' striped>
      <Table.Header>
        <Table.HeaderCell width={2}>{t('g.id')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('s.profile.fullName')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('s.profile.nationality')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('s.profile.dateOfBirth')}</Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('s.profile.maritalStatus')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('s.profile.numberOfChildren')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('s.profile.relationship')}</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderRows()}
        {isLoading && _renderLoadingRow()}
      </Table.Body>
    </Table>
    <Grid verticalAlign='middle'>
      <Grid.Row columns={3}>
        <Grid.Column>
          {t('g.resPerPage')}
          <Dropdown
            style={{
              marginLeft: '10px'
            }}
            inline
            options={[
              {key: 0, value: 25, text: "25"},
              {key: 1, value: 50, text: "50"},
              {key: 2, value: 100, text: "100"},
              {key: 3, value: 500, text: "500"},
            ]}
            defaultValue={selectedResPerPage}
            onChange={(e, data) => {

              if(typeof data.value === 'number'){
                return setSelectedResPerPage(data.value); 
              }

              if(typeof data.value === 'string' && /^[0-9]/.test(data.value)){
                return setSelectedResPerPage(parseInt(data.value));
              }
              
              if(config.dev) console.warn("result per page type is not valid", typeof data.value)
              return null;
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Menu floated='right' pagination size='mini'>
            <Menu.Item 
              as='a' 
              icon 
              onClick={() => {
                
                if(selectedPageNumber <= 1) {
                  setSelectedPageNumber(selectedPageNumber)
                  return null;
                }

                setSelectedPageNumber(selectedPageNumber-1)
                getMembers(selectedPageNumber-1);
              }}
              >
              <Icon name='chevron left'/>
            </Menu.Item>
            <Menu.Item>
              <Input
                value={selectedPageNumber}
                onChange={(e,data) => {

                  if(parseInt(data.value) < 1) return null;

                  if(/^[0-9]/.test(data.value))
                    setSelectedPageNumber(parseInt(data.value));
                }}
              />
              <Button 
                style={{
                  marginLeft: '10px'
                }}
                color='blue'
                onClick={() => {
                  var error = false;
                  
                  if(typeof selectedPageNumber !== 'number') {
                    error = true;
                    if(config.dev) console.warn('Provided page number is not a number', selectedPageNumber);
                  }

                  if(selectedPageNumber < 1) {
                    error = true;
                    if(config.dev) console.warn('Provided page number is less than 1', selectedPageNumber);
                  }

                  if(error) {
                    setModal({
                      visible: true,
                      title: t('g.error'),
                      message: t('g.invalidInput'),
                      action: [
                        {
                          content: t('g.goBack'),
                          onClick: () => history.goBack()
                        },
                      ]
                    });
                    return null;
                  }
                  
                  setSelectedPageNumber(selectedPageNumber)
                  getMembers(selectedPageNumber);
                }}
              >
                {t('g.go')}
              </Button>
            </Menu.Item>
            <Menu.Item 
              as='a' 
              icon
              onClick={() => {
                setSelectedPageNumber(selectedPageNumber+1)
                getMembers(selectedPageNumber+1);
              }}
              >
              <Icon name='chevron right' />
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
}