import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Menu,
  Dropdown,
  Icon,
  Popup,
  Checkbox
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../framework/internal_page';

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";
import ECCaregivers from '../util/EnsanCareLib/funcs/caregivers';
import ECServiceCities from '../util/EnsanCareLib/funcs/service_cities';

import { URLQuery } from '../util/tools';

import moment from 'moment';

export default function BookingsList() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [bookings, setBookings] = useState<any[]|null>(null); 

  const [selectedResPerPage, setSelectedResPerPage] = useState<number>(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);
  const [bookingStatus, setBookingStatus] = useState<string|null>();
  const [searchTerm, setSearchTerm] = useState<string|null>();
  const [fromTerm, setFromTerm] = useState<string|null>();
  const [toTerm, setToTerm] = useState<string|null>();
  const [orderDirection, setOrderDirection] = useState<'desc'|'asc'>('desc');

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [selectedBookings, setSelectedBookings] = useState<any[]>([]);

  const urlBookingStatus = URLQuery('status', window) || null;
  const memberId = URLQuery('memberId', window) || null;
  const [isReady, setIsReady] = useState<boolean>(false);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    setBookingStatus(urlBookingStatus);
    setIsReady(true);console.log(memberId)

    if (isReady) getBookings();
  }, [])

  useEffect(() => {
    if (isReady) getBookings()
  }, [
    selectedResPerPage,
    selectedPageNumber,
    bookingStatus,
    searchTerm,
    fromTerm,
    toTerm,
    orderDirection
  ]);

  const getBookings = async (page:number = 1) => {
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.resPerPage = selectedResPerPage;
    EnsanCareLib.currentPage = page||selectedPageNumber;
    EnsanCareLib.orderDirection = orderDirection;

    if(fromTerm) EnsanCareLib.fromDate = fromTerm ;
    if(toTerm) EnsanCareLib.toDate = toTerm ;

    // filters
    if(searchTerm) EnsanCareLib.searchTerm = searchTerm;
    if(bookingStatus) EnsanCareLib.status = bookingStatus;
    if(memberId) EnsanCareLib.memberId = memberId;

    var res:any = await EnsanCareLib.getBookings();

    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
    } 
    else if(res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });
    } 
    else if(res.data) {
      setBookings([...res.data]);
    } 

    setIsLoading(false);
    return null;
  }

  useEffect(() => console.log(isUpdating), [isUpdating])

  const updatePaidOut = async () => {
    if(!selectedBookings || selectedBookings.length < 1) return null;
    setIsUpdating(true);

    for(var i = 0; i < selectedBookings.length; i++) {
      var EnsanCareLib:any = new EnsanCareAPI;
      EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
      EnsanCareLib.authToken = localStorage.getItem('auth_token');
      EnsanCareLib.bookingUuid = selectedBookings[i];
      EnsanCareLib.debug = config.dev;
      EnsanCareLib.paid_out_at = new Date();
      
      var res = await EnsanCareLib.updateBooking();
    }

    setTimeout(() => {
      setIsUpdating(false);
      getBookings();
    }, 1500);
    
    return null;
  }

  const _renderLoadingRow = () => {
    return <Table.Row>
      <Table.Cell colSpan={8} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderRows = () => {
    if(!bookings || bookings.length < 1) { 
      return <Table.Row disabled>
        <Table.Cell>
          {t('g.noResults')}
        </Table.Cell>
      </Table.Row>
    };
    
    return bookings.map( (booking, key) => {

      var bookingShortUuid = booking.uuid?.substring(
        booking.uuid.length - 5, booking.uuid.length
      );

      var caregiverShortUuid = booking.caregiver_uuid?.substring(
        booking.caregiver_uuid.length - 10, booking.caregiver_uuid.length
      );

      var serviceRequesterShortUuid = booking.service_requester_uuid?.substring(
        booking.service_requester_uuid.length - 10, booking.service_requester_uuid.length
      );
      
      return <Table.Row>
        <Table.Cell>
          {(!booking.paid_out_at) && <Checkbox 
            checked={selectedBookings.includes(booking.uuid)}
            onChange={(e, data) => {
              var tempArray = selectedBookings;
              const index = tempArray.indexOf(booking.uuid);

              if (index > -1) { // only splice array when item is found
                tempArray.splice(index, 1); // 2nd parameter means remove one item only
              }
              else {
                tempArray.push(booking.uuid)
              }
              
              setSelectedBookings([...tempArray]);
            }}
          />}
        </Table.Cell>
        <Table.Cell width={1} onClick={() => window.open(`./bookings/${booking.uuid}`, '_self')}>
          # {bookingShortUuid}
        </Table.Cell>
        <Table.Cell width={3} onClick={() => window.open(`./bookings/${booking.uuid}`, '_self')}>
          <div>
            <div>
              <strong>
                {booking.member_first_name} {booking.member_middle_name} {booking.member_last_name}
              </strong>
            </div>
            <div>
              # {serviceRequesterShortUuid}
            </div>
          </div>
        </Table.Cell>
        <Table.Cell width={3} onClick={() => window.open(`./bookings/${booking.uuid}`, '_self')}>
          <div>
            <div>
              <strong>
                {ECCaregivers.buildName(
                  i18n.language,
                  booking.caregiver_name_prefix,
                  booking.caregiver_first_name_local,
                  booking.caregiver_middle_name_local,
                  booking.caregiver_last_name_local,
                )}
              </strong>
            </div>
            <div>
              # {caregiverShortUuid}
            </div>
          </div>
        </Table.Cell>
        <Table.Cell width={3} onClick={() => window.open(`./bookings/${booking.uuid}`, '_self')}>
          {ECServiceCities.buildLabel(
            i18n.language, 
            {
              country: booking.service_cities_country,
              state: booking.service_cities_state,
              city: booking.service_cities_city,
              local: booking.service_cities_local
            }
          )}
        </Table.Cell>
        <Table.Cell width={2} onClick={() => window.open(`./bookings/${booking.uuid}`, '_self')}>
          {moment(booking.created_at).format(config.momentDateFormat)}
        </Table.Cell>
        <Table.Cell width={2} onClick={() => window.open(`./bookings/${booking.uuid}`, '_self')}>
          {t(`s.bookings.status.${booking.status}`)}
        </Table.Cell>
        <Table.Cell width={2} textAlign='right' onClick={() => window.open(`./bookings/${booking.uuid}`, '_self')}>
          {booking.total ? booking.total.toFixed(2) : '-'}
        </Table.Cell>
      </Table.Row>
    })
  }

  return <InternalPage loading={!isReady}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />
    
    <Header
      as='h1'
      content={t('s.bookings.title')}
    />

    <Form>
      <Form.Group widths={'equal'}>
        <Form.Field>
          <Form.Input
            icon={'search'}
            placeholder={t('g.search')}
            onChange={(e,data) => {setSearchTerm(data.value)}}
          />
        </Form.Field>
        <Form.Field>
          <Popup 
            trigger={
              <Form.Input
                icon={'search'}
                placeholder={t('g.from')}
                onChange={(e,data) => {
                  if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {
                    setFromTerm(data.value)
                  } else {
                    setFromTerm(null)
                  }
                }}
              />
            }
            header='input format'
            content='example: 2001-09-04'
            on={'hover'}
          />
         
        </Form.Field>
        <Form.Field>
          <Popup 
            trigger={
              <Form.Input
                icon={'search'}
                placeholder={t('g.to')}
                onChange={(e,data) => {
                  if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {
                    setToTerm(data.value)
                  } else {
                    setToTerm(null)
                  }
                }}
              />
            }
            header='input format'
            content='example: 2001-09-04'
            on={'hover'}
          />
        </Form.Field>
        <Form.Field>
          <Form.Dropdown
            selection
            fluid
            clearable
            placeholder={t('g.status')}
            options={[
              {key: 0, value: 'pending payment', text: t('s.bookings.status.pending payment')},
              {key: 1, value: 'pending acceptance', text: t('s.bookings.status.pending acceptance')},
              {key: 2, value: 'on going', text: t('s.bookings.status.on going')},
              {key: 3, value: 'completed', text: t('s.bookings.status.completed')},
              {key: 4, value: 'cancelled', text: t('s.bookings.status.cancelled')},
            ]}
            value={bookingStatus || ""}
            onChange={(e,data) => {
              if(data.value && typeof data.value === 'string') {
                setBookingStatus(data.value)
              } else {
                setBookingStatus(null)
              }
            }}
          />
        </Form.Field>
      </Form.Group>
    </Form>


    <Menu stackable>
      <Menu.Menu position='right'>
        <Menu.Item
          name={t('s.bookings.setPaidOutStatus')}
          onClick={() => updatePaidOut()}
        >{console.log('isUpdating', isUpdating)}
          {(!isUpdating) ? <>
            {t('s.bookings.setPaidOutStatus')}
          </> : <>
            <Loader 
              active
              inline
              size='mini'
            /> <span style={{marginLeft: 10}}>{t('g.loading')}...</span>
          </>}
        </Menu.Item>
        <Menu.Item
          name={t('g.generalInformation')}
          onClick={() => history.push("../bookings/export")}
        >
          <Icon name='external'/>
          {t('g.export')}
        </Menu.Item>
      </Menu.Menu>
    </Menu>

    <Table color='blue' striped selectable sortable>
      <Table.Header>
        <Table.HeaderCell></Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('g.uuid')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('s.serviceRequesters.member')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('s.profile.caregiver')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('s.serviceCities.serviceCity')}</Table.HeaderCell>
        <Table.HeaderCell 
          width={2}
          sorted={(orderDirection === 'desc') ? "descending" : 'ascending'}
          onClick={() => setOrderDirection((orderDirection === 'desc') ? "asc" : 'desc')}
        >
          {t('g.createdAt')}
        </Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('g.status')}</Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign='right'>{t('g.total')} (SAR)</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderRows()}
        {isLoading && _renderLoadingRow()}
      </Table.Body>
    </Table>
    <Grid verticalAlign='middle'>
      <Grid.Row columns={3}>
        <Grid.Column>
          {t('g.resPerPage')}
          <Dropdown
            style={{
              marginLeft: '10px'
            }}
            inline
            options={[
              {key: 0, value: 25, text: "25"},
              {key: 1, value: 50, text: "50"},
              {key: 2, value: 100, text: "100"},
              {key: 3, value: 500, text: "500"},
            ]}
            defaultValue={selectedResPerPage}
            onChange={(e, data) => {

              if(typeof data.value === 'number'){
                return setSelectedResPerPage(data.value); 
              }

              if(typeof data.value === 'string' && /^[0-9]/.test(data.value)){
                return setSelectedResPerPage(parseInt(data.value));
              }
              
              if(config.dev) console.warn("result per page type is not valid", typeof data.value)
              return null;
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Menu floated='right' pagination size='mini'>
            <Menu.Item 
              as='a' 
              icon 
              onClick={() => {
                if(selectedPageNumber <= 1) {
                  setSelectedPageNumber(selectedPageNumber)
                  return null;
                }

                setSelectedPageNumber(selectedPageNumber-1)
                getBookings(selectedPageNumber-1);
              }}
              >
              <Icon name='chevron left'/>
            </Menu.Item>
            <Menu.Item>
              <Input 
                value={selectedPageNumber}
                onChange={(e,data) => {
                  if(parseInt(data.value) < 1) return null;
                  if(/^[0-9]/.test(data.value))
                    setSelectedPageNumber(parseInt(data.value));
                }}
              />
              <Button 
                style={{
                  marginLeft: '10px'
                }}
                color='blue'
                onClick={() => {
                  var error = false;

                  if(typeof selectedPageNumber !== 'number') {
                    error = true;
                    if(config.dev) console.warn('Provided page number is not a number', selectedPageNumber);
                  }

                  if(selectedPageNumber < 1) {
                    error = true;
                    if(config.dev) console.warn('Provided page number is less than 1', selectedPageNumber);
                  }

                  if(error) {
                    setModal({
                      visible: true,
                      title: t('g.error'),
                      message: t('g.invalidInput'),
                      action: [
                        {
                          content: t('g.goBack'),
                          onClick: () => history.goBack()
                        },
                      ]
                    });

                    return null;
                  }

                  setSelectedPageNumber(selectedPageNumber)
                  getBookings(selectedPageNumber);
                }}
              >
                {t('g.go')}
              </Button>
            </Menu.Item>
            <Menu.Item 
              as='a' 
              icon
              onClick={() => {
                setSelectedPageNumber(selectedPageNumber+1)
                getBookings(selectedPageNumber+1);
              }}
              >
              <Icon name='chevron right' />
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </InternalPage>
}