import { useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Segment,
  Image,
  Menu,
  Icon
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../framework/internal_page';

const SettingListingButton = (props:any) => {
  return <Grid.Column>
    <Segment 
      className='settings-container' 
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Icon name={props.icon} size='big' color={props.color} />

      <Header
        as='h4'
        content={props.title}
      />

      <div className='description'>{props.description}</div>
    </Segment>
  </Grid.Column>
}

export default function SettingsList() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [screen, setScreen] = useState<'categories'|'services'>('categories'); 

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const settings = [
    {
      title: t('s.settings.general'),
      description: t('s.settings.settingsDescription'),
      icon: 'settings',
      color: 'blue',
      onClick: () => window.open('/settings/general', '_self')
    },
    {
      title: t('s.services.title'),
      description: t('s.services.settingsDescription'),
      icon: 'stethoscope',
      color: 'red',
      onClick: () => window.open('/settings/services', '_self')
    },
    {
      title: t('s.profile.nationalities'),
      description: t('s.profile.nationalitiesSettingsDescription'),
      icon: 'id card',
      color: 'teal',
      onClick: () => window.open('/settings/nationalities', '_self')
    },
    {
      title: t('s.serviceCities.title'),
      description: t('s.serviceCities.settingsDescription'),
      icon: 'map',
      color: 'olive',
      onClick: () => window.open('/settings/service-cities', '_self')
    },
    {
      title: t('s.banners.title'),
      description: t('s.banners.settingsDescription'),
      icon: 'images',
      color: 'blue',
      onClick: () => window.open('/settings/banners', '_self')
    },
    {
      title: t('s.settings.additionalChargesSettingsTitle'),
      description: t('s.settings.additionalChargesSettingsDescription'),
      icon: 'medkit',
      color: 'red',
      disabled: false,
      onClick: () => window.open('/settings/additional-charges', '_self')
    },


    /**
     * 
     * Keep future development containers at
     * the end to avoid distracting the user.
     * 
     */

     {
      title: t('s.settings.dietsSettingsTitle'),
      description: t('s.settings.dietsSettingsDescription'),
      icon: 'weight',
      color: 'purple',
      disabled: true,
      onClick: () => null
    },
    {
      title: t('s.settings.allergiesSettingTitle'),
      description: t('s.settings.allergiesSettingDescription'),
      icon: 'braille',
      color: 'yellow',
      disabled: true,
      onClick: () => null
    },
    {
      title: t('s.settings.chronicDiseasesSettingTitle'),
      description: t('s.settings.chronicDiseasesSettingDescription'),
      icon: 'heartbeat',
      color: 'violet',
      disabled: true,
      onClick: () => null
    },
    {
      title: t('s.settings.levelOfActivitiesSettingTitle'),
      description: t('s.settings.levelOfActivitiesSettingDescription'),
      icon: 'child',
      color: 'red',
      disabled: true,
      onClick: () => null
    },
    {
      title: t('s.settings.exerciseRoutineSettingsTitle'),
      description: t('s.settings.exerciseRoutineSettingsDescription'),
      icon: 'table tennis',
      color: 'orange',
      disabled: true,
      onClick: () => null
    },
    {
      title: t('s.settings.medicalReportCategoriesSettingsTitle'),
      description: t('s.settings.medicalReportCategoriesSettingsDescription'),
      icon: 'paste',
      color: 'green',
      disabled: true,
      onClick: () => null
    },
  ]

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />
    
    <Header
      as='h1'
      content={t('s.settings.title')}
    />

    <Grid columns={5}>
      {settings && settings.map((setting:any, key:number) => {
        return <SettingListingButton
          title={setting.title}
          description={setting.description}
          icon={setting.icon}
          color={setting.color}
          disabled={setting.disabled}
          onClick={setting.onClick}
        />
      })}
    </Grid>
  </InternalPage>
}