/*
 *
 * PROJECT ENSAN CARE
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

export default {
  buildMemberName: (firstName, middleName, lastName) => {
    var profileName = '';

    if(firstName) profileName = firstName;

    if(middleName) profileName = `${profileName} ${middleName}`;

    if(lastName) profileName = `${profileName} ${lastName}`;

    if(profileName.length < 1) {
      profileName = 'N/A'
    }

    return profileName;
  }
}