/*
 *
 * PROJECT ENSAN CARE
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

export default {
  buildLabel: (language, sc, defReturn='') => {
    if(!sc.country || !sc.state || !sc.city) {
      console.warn('Received service city is missing key information', sc);
      return defReturn;
    }
    
    var defaultLabel = `${sc.city}, ${sc.state}, ${sc.country}`;
    var localLabel = null;

    if(sc.local) {
      var localLanguage = language || 'en';
      localLabel = sc.local;
  
      if(typeof localLabel === 'string') {
        try {
          localLabel = JSON.parse(localLabel);
        }
        catch (e) {
          console.warn('Failed to parse service city', sc, e);
          return defReturn;
        }
      }

      if(localLabel[localLanguage]) {
        localLabel = localLabel[localLanguage];
        localLabel = `${localLabel.city}, ${localLabel.state}, ${localLabel.country}`;
      }
    }

    return localLabel || defaultLabel;
  },

  convertCoordsToObject: (coords) => {
    if(!coords) return null;

    if(typeof coords === 'string'){
      try{
        coords = JSON.parse(coords);
      }
      catch (e){
        console.warn('Failed to parse coords', coords, e);
        return null;
      }
    }

    if(coords.latitude && coords.longitude) return coords;

    if(!coords.lat){
      console.warn("coods missing lat")
      return null;
    }

    if(!coords.lng){
      console.warn("coods missing lng")
      return null;
    }

    return {
      latitude: coords.lat,
      longitude: coords.lng
    };
  }
}