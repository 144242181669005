import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Segment,
  Image,
  Dropdown,
  Icon,
  Menu
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";

import EnsanCareAPI from "../../util/EnsanCareLib/EnsanCareAPI";

const CategoryRow = (props:any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const [isProcessing, setIsProcessing] = useState<boolean>(false); 

  const [componentDeleted, setComponentDeleted] = useState<boolean>(false); 

  const [actionReqeusted, setActionRequested] = useState<boolean>(true);

  const _handleUpdateRequest = async (category_id:number) => {
    if(isProcessing) return null;
    setIsProcessing(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.categoryId = category_id;

    var res:any = await EnsanCareLib.deleteServiceCategory();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => _handleUpdateRequest(props.id)
          },
        ]
      });
    }

    else if (res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => _handleUpdateRequest(props.id)
          },
        ]
      });
    }

    else if (res.status === "success") {
      setComponentDeleted(true);
    }

    return null;
  }

  const _renderBtns=() => {
    if(actionReqeusted) {
      return <>
        <Button
          size={'tiny'}
          content={t('g.edit')}
          onClick={() => window.open(`/settings/services/category/show/${props.id}`, '_self')}
        />
        <Button
          color='red'
          size={'tiny'}
          content={t('g.delete')}
          onClick={() => {
            setActionRequested(false);
          }}
        />
      </>
    }
    else {
      return <>
        <Button
          color='yellow'
          size={'tiny'}
          content={t('g.confirm')}
          onClick={() => {
            _handleUpdateRequest(props.id)
          }}
        />
        <Button
          size={'tiny'}
          content={t('g.cancel')}
          onClick={() => setActionRequested(true)}
        />
      </>
    } 
  }

  if(componentDeleted) return null;

  return (
    <Table.Row warning={(props.active === 'n' ? true : false)}>
      <Table.Cell width={1}>{props.id}</Table.Cell>
      <Table.Cell width={1}>
        <div 
          style={{
            backgroundColor: props.backgroundColor, 
            width: 50, 
            height: 50,
            borderRadius: 25,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }} 
        >
          <Image
            src={props.imageSrc}
            circular
          />
        </div>
      </Table.Cell>
      <Table.Cell width={1}>
        <div>{props.listingOrder}</div>
      </Table.Cell>
      <Table.Cell width={10}>
        <div>{props.title}</div>
      </Table.Cell>
      <Table.Cell width={3}>
        {_renderBtns()}
      </Table.Cell>
    </Table.Row>
  )
}

const ServicesCategoriesList = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [categories, setCategories] = useState<any>(null); 

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [selectedResPerPage, setSelectedResPerPage] = useState<number>(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getServicesCategories();
  },[])

  useEffect(() => {
    getServicesCategories();
  },[
    selectedResPerPage,
    selectedPageNumber
  ])

  const getServicesCategories = async (page:number = 1) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.resPerPage = selectedResPerPage;
    EnsanCareLib.currentPage = page||selectedPageNumber;

    var res:any = await EnsanCareLib.getServicesCategories();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServicesCategories()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServicesCategories()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success') {
      if(res.data) {
        setCategories(res.data);
      } 
    }
    
    setIsLoading(false);
    return null;
  }

  const _renderLoadingRow = () => {
    return <Table.Row>
      <Table.Cell colSpan={7} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderRows = () => {
    var assetsUris=require("../../remote_assets_uri.json");
    var host=(config.dev) ? config.d.API : config.p.API;

    if(!categories || categories.length < 1) { 
      return <Table.Row disabled>
        <Table.Cell>
          {t('g.noResults')}
        </Table.Cell>
      </Table.Row>
    };
    
    return categories.map( (category:any, key:number) => {
      return <CategoryRow
        id={category.id}
        backgroundColor={category.color_code}
        imageSrc={host+assetsUris.services_categories_icons+category.id+".png"}
        title={category.title}
        listingOrder={category.listing_order}
        active={category.active}
      />
    })
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.action : null}
    />
    
    <Menu>  
      <Menu.Item
        icon='plus'
        content={t('g.addNew')}
        onClick={() => window.open('/settings/services/category/create', '_self')}
      />
    </Menu>

    <Table color='blue' stackable striped>
      <Table.Header>
        <Table.HeaderCell width={1}>{t('g.id')}</Table.HeaderCell>
        <Table.HeaderCell width={1}></Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('g.listing')}</Table.HeaderCell>
        <Table.HeaderCell width={10}>{t('g.title')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('g.control')}</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderRows()}
        {isLoading && _renderLoadingRow()}
      </Table.Body>
    </Table>
    <Grid verticalAlign='middle'>
      <Grid.Row columns={3}>
        <Grid.Column>
          {t('g.resPerPage')}
          <Dropdown
            style={{
              marginLeft: '10px'
            }}
            inline
            options={[
              {key: 0, value: 25, text: "25"},
              {key: 1, value: 50, text: "50"},
              {key: 2, value: 100, text: "100"},
              {key: 3, value: 500, text: "500"},
            ]}
            defaultValue={selectedResPerPage}
            onChange={(e, data) => {

              if(typeof data.value === 'number'){
                return setSelectedResPerPage(data.value); 
              }

              if(typeof data.value === 'string' && /^[0-9]/.test(data.value)){
                return setSelectedResPerPage(parseInt(data.value));
              }
              
              if(config.dev) console.warn("result per page type is not valid", typeof data.value)
              return null;
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Menu floated='right' pagination size='mini'>
            <Menu.Item 
              as='a' 
              icon 
              onClick={() => {
                if(selectedPageNumber <= 1) {
                  setSelectedPageNumber(selectedPageNumber)
                  return null;
                }

                setSelectedPageNumber(selectedPageNumber-1)
                getServicesCategories(selectedPageNumber-1);
              }}
              >
              <Icon name='chevron left'/>
            </Menu.Item>
            <Menu.Item>
              <Input 
                value={selectedPageNumber}
                onChange={(e,data) => {
                  if(parseInt(data.value) < 1) return null;
                  if(/^[0-9]/.test(data.value))
                    setSelectedPageNumber(parseInt(data.value));
                }}
              />
              <Button 
                style={{
                  marginLeft: '10px'
                }}
                color='blue'
                onClick={() => {
                  var error = false;

                  if(typeof selectedPageNumber !== 'number') {
                    error = true;
                    if(config.dev) console.warn('Provided page number is not a number', selectedPageNumber);
                  }

                  if(selectedPageNumber < 1) {
                    error = true;
                    if(config.dev) console.warn('Provided page number is less than 1', selectedPageNumber);
                  }

                  if(error) {
                    setModal({
                      visible: true,
                      title: t('g.error'),
                      message: t('g.invalidInput'),
                      action: [
                        {
                          content: t('g.goBack'),
                          onClick: () => history.goBack()
                        },
                      ]
                    });

                    return null;
                  }

                  setSelectedPageNumber(selectedPageNumber)
                  getServicesCategories(selectedPageNumber);
                }}
              >
                {t('g.go')}
              </Button>
            </Menu.Item>
            <Menu.Item 
              as='a' 
              icon
              onClick={() => {
                setSelectedPageNumber(selectedPageNumber+1)
                getServicesCategories(selectedPageNumber+1);
              }}
              >
              <Icon name='chevron right' />
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
}

export default ServicesCategoriesList;