const packageManager = require('../package.json');

export const config = {
  // This object provides all the global configurations for the system
  // Update by adding the new variables in the proper section with
  // the propse comments to explain the content.

  // Start --

  // Set the app running mode
  // true:        This mode will print all the debug and actions to console
  // false:       This mode will use the production variables and
  //              will ONLY print error logs to the consoel
  dev:                                   false,

  // Version
  version:                               packageManager.version,

  // The development credintials and variables in the system
  // use it to point to the development or staging server to run tests
  d: {
    API:                                 "http://localhost:5080/",
    portal:                              "https://core-ensancare-com.me-staging-s1.3webbox.com/",
    pay:                                 "https://pay.ensancare.com/v1/index.html",
  },

  // The production credintials and variables in the system
  // used when dev is set to false
  p: {
    API:                                 "https://core.ensancare.com/",
    portal:                              "https://acc.ensancare.com/",
    pay:                                 "https://pay.ensancare.com/v1/index.html",
  },

  momentDateTimeFormat:                   'DD/MM/YYYY @ hh:mm A',
  momentDateFormat:                       'DD/MM/YYYY',

  // Websites and APIs settings
  websiteUrl:                            "https://ensancare.com/",
  termsAndConditionsUri:                 'https://ensancare.com/about-us/terms-and-conditions/',
  privacyPolicyUri:                      'https://ensancare.com/about-us/privacy-policy/',

  // DGEra URL
  webbox:                                "https://3webbox.com/",

  // OneSignal Configs
  oneSignalAppId:                        "",

  // Google map API key
  googleMapAPIKey:                       "AIzaSyAGgrFxVcwphRgu6AKyW863Lj18eUFYdt8",
}
