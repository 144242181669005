import { Icon } from "semantic-ui-react";

const Rating = (props:any) => {
  var rating = props.rating || 0;
  var render = [];

  for(var i = 0; i < 5; i++) {
    var color:'yellow'|'grey' = 'grey';

    if(i < rating) { color = 'yellow'}

    render.push(
      <Icon color={color} name='star' />
    );
  }

  return <>{render}</>;
}

export default Rating;