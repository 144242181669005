import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Divider,
  Message,
  Segment,
  Checkbox,
  Menu,
  Dropdown,
  Icon
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../framework/internal_page';

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";
import ECCaregivers from "../util/EnsanCareLib/funcs/caregivers";

export default function NotificationsList() {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  
  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [selectedResPerPage, setSelectedResPerPage] = useState<number>(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string|null>();

  const [selectedUsers, setSelectedUsers] = useState<Array<any>>([]);
  
  const [notificationSubject, setNotificationSubject] = useState<string|null>();
  const [notificationSubjectError, setNotificationSubjectError] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string|null>();
  const [notificationMessageError, setNotificationMessageError] = useState<boolean>(false);

  const [users, setUsers] = useState<any>(null);

  const [userType, setUserType] = useState<'caregivers'|'service requesters'|null>(null); 

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getUsers();
  }, [])

  useEffect(() => {
    setSelectedPageNumber(1);
    setSelectedUsers([]);

    getUsers();
  }, [
    userType,
  ])

  useEffect(() => {
    getUsers();
  }, [
    selectedResPerPage,
    searchTerm,
  ])

  const getUsers = async (page:number = 1) => {
    if(userType === null) {
      
      setUsers(null);

      return null;
    }

    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.resPerPage = selectedResPerPage;
    EnsanCareLib.currentPage = page||selectedPageNumber;
    if(searchTerm) EnsanCareLib.searchTerm = searchTerm;

    var res:any = null;

    if(userType === 'caregivers') {

      //---

      res = await EnsanCareLib.getCaregivers();
    }

    else if (userType === 'service requesters') {

      //---
      
      res = await EnsanCareLib.getServiceRequesters();
    }

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getUsers()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getUsers()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 

    else if(res.status === 'success' && res.data) {
   
      setUsers(res.data);
    }
    
    setIsLoading(false);
    return null;

  }

  const sendNotification = async (channel:string) => {
    var error = false;

    if(!notificationSubject || notificationSubject.length < 1) {
      setNotificationSubjectError(true);
      error = true;
    }

    if(!notificationMessage || notificationMessage.length < 1) {
      setNotificationMessageError(true);
      error = true;
    }

    if(error) return null;

    if(isLoading) return null;
    setIsLoading(true);

    var tempMessage:any = null;

    if(channel === 'email') {
      tempMessage = {
        html: notificationMessage,
        text: notificationMessage,
      }
    }
    else {
      tempMessage = notificationMessage;
    }

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.channel = channel;
    EnsanCareLib.userType = userType;
    EnsanCareLib.receivers = selectedUsers;
    EnsanCareLib.subject = notificationSubject;
    EnsanCareLib.messsage = tempMessage;

    var res:any = await EnsanCareLib.sendNotification();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => sendNotification(channel)
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => sendNotification(channel)
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 

    else if(res.status === 'success') {
      setModal({
        visible: true,
        title: t('g.success'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            onClick: () => null
          },
        ]
      });
    }
    
    setIsLoading(false);
    return null;
  }

  const _renderLoadingRow = () => {
    return <Table.Row>
      <Table.Cell colSpan={7} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderRows = () => {
    if(!users || users.length < 1) { 
      return <Table.Row disabled>
        <Table.Cell width={2}>
          {t('g.noResults')}
        </Table.Cell>
      </Table.Row>
    };

    return users.map((user:any, key:number) => {
      var uuid = user.uuid || null;

      if(uuid) uuid = uuid.substring(uuid.length - 5, uuid.length);

      return <Table.Row>
        <Table.Cell width={1}>
          <Checkbox
            checked={selectedUsers.includes(user.uuid)}
            onChange={(e,data) => {

              if(data.checked) {
                if( !selectedUsers.includes(user.uuid) ) {
                  var tempSelectedUsers:any = selectedUsers;
                  tempSelectedUsers.push(user.uuid);
                  setSelectedUsers([...tempSelectedUsers])
                }
              } 

              else {
                setSelectedUsers(
                  selectedUsers.filter(
                    (uuid:string) => uuid != user.uuid
                  )
                )
              }

            }}
          />
        </Table.Cell>
        <Table.Cell width={2}># {uuid}</Table.Cell>
        <Table.Cell width={4}>
          {
            userType === "caregivers" ?
              ECCaregivers.buildName(
                i18n.language,
                user.name_prefix,
                user.first_name_local, 
                user.middle_name_local, 
                user.last_name_local
              ) :  
              "N/A"
          }
        </Table.Cell >
        <Table.Cell width={4}>({user.country_code}) {user.phone}</Table.Cell>
        <Table.Cell width={5}>{user.email}</Table.Cell>
      </Table.Row>
    })
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal?.action||['Ok']}
    />
    
    <Header
      as='h1'
      content={t('s.notifications.title')}
    />

    <Form>
      <Form.Group widths={16}>
        <Form.Field width={4}>
          <Form.Dropdown
            selection
            fluid
            clearable
            value={userType||''}
            placeholder={t('g.type')}
            options={[
              {key: 0, value: 'caregivers', text: t('s.profile.caregivers')},
              {key: 1, value: 'service requesters', text: t('s.profile.serviceRequesters')},
            ]}
            onChange={(e, data) => {
              if(
                data.value && 
                typeof data.value === 'string' &&
                  (
                    data.value === 'caregivers' ||
                    data.value === 'service requesters'
                  )
              ) {

                setUserType(data.value);
              }

              else {

                setUserType(null);
              }

            }}
          />
        </Form.Field>
        <Form.Field width={12}>
          <Form.Input
            fluid
            icon={'search'}
            placeholder={t('g.search')}
            onChange={(e, data) => {
              setSearchTerm(data.value)
            }}
          />
        </Form.Field>
      </Form.Group>
    </Form>

    <Table color='blue' striped selectable>
      <Table.Header>
        <Table.HeaderCell width={1}></Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('g.uuid')}</Table.HeaderCell>
        <Table.HeaderCell width={4}>{t('s.profile.fullName')}</Table.HeaderCell>
        <Table.HeaderCell width={4}>{t('g.phone')}</Table.HeaderCell>
        <Table.HeaderCell width={5}>{t('g.email')}</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderRows()}
        {isLoading && _renderLoadingRow()}
      </Table.Body>
    </Table>

    <Grid verticalAlign='middle'>
      <Grid.Row columns={3}>
        <Grid.Column>
          {t('g.resPerPage')}
          <Dropdown
            style={{
              marginLeft: '10px'
            }}
            inline
            options={[
              {key: 0, value: 25, text: "25"},
              {key: 1, value: 50, text: "50"},
              {key: 2, value: 100, text: "100"},
              {key: 3, value: 500, text: "500"},
            ]}
            defaultValue={selectedResPerPage}
            onChange={(e, data) => {

              if(typeof data.value === 'number'){
                return setSelectedResPerPage(data.value); 
              }

              if(typeof data.value === 'string' && /^[0-9]/.test(data.value)){
                return setSelectedResPerPage(parseInt(data.value));
              }
              
              if(config.dev) console.warn("result per page type is not valid", typeof data.value)
              return null;
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Menu floated='right' pagination size='mini'>
            <Menu.Item 
              as='a' 
              icon 
              onClick={() => {
                if(selectedPageNumber <= 1) {
                  setSelectedPageNumber(selectedPageNumber)
                  return null;
                }

                setSelectedPageNumber(selectedPageNumber-1)
                getUsers(selectedPageNumber-1);
              }}
              >
              <Icon name='chevron left'/>
            </Menu.Item>
            <Menu.Item>
              <Input 
                value={selectedPageNumber}
                onChange={(e,data) => {
                  if(parseInt(data.value) < 1) return null;
                  if(/^[0-9]/.test(data.value))
                    setSelectedPageNumber(parseInt(data.value));
                }}
              />
              <Button 
                style={{
                  marginLeft: '10px'
                }}
                color='blue'
                onClick={() => {
                  var error=false;

                  if(typeof selectedPageNumber !== 'number') {
                    error = true;
                    if(config.dev) console.warn('Provided page number is not a number', selectedPageNumber);
                  }

                  if(selectedPageNumber < 1) {
                    error = true;
                    if(config.dev) console.warn('Provided page number is less than 1', selectedPageNumber);
                  }

                  if(error) {
                    setModal({
                      visible: true,
                      title: t('g.error'),
                      message: t('g.invalidInput'),
                      action: [
                        {
                          content: t('g.goBack'),
                          onClick: () => history.goBack()
                        },
                      ]
                    });
                    return null;
                  }

                  setSelectedPageNumber(selectedPageNumber)
                  getUsers(selectedPageNumber);
                }}
              >
                {t('g.go')}
              </Button>
            </Menu.Item>
            <Menu.Item 
              as='a' 
              icon
              onClick={() => {
                setSelectedPageNumber(selectedPageNumber+1)
                getUsers(selectedPageNumber+1);
              }}
              >
              <Icon name='chevron right' />
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Divider section />

    <Message warning>
      <Header
        icon='exclamation triangle'
        content={t('g.caution')}
      />

      <Divider />

      <div>
        <div>{t('s.notifications.messageLimitations')}</div>
        <ul>
          <li>{t('s.notifications.messageEmailLimitations')}</li>
          <li>{t('s.notifications.messageSMSLimitations')}</li>
          <li>{t('s.notifications.messagePushNotificationsLimitations')}</li>
        </ul>
      </div>
    </Message>
    
    <Divider section hidden />

    <Form>
      <Form.Input
        fluid
        label={t('g.subject')}
        placeholder={t('g.typeHere')}
        error={notificationSubjectError}
        onChange={(e, data) => {
          setNotificationSubjectError(false);
          setNotificationSubject(data.value)
        }}
      />

      <Form.TextArea
        label={t('g.message')}
        placeholder={t('g.typeHere')}
        rows={5}
        error={notificationMessageError}
        onChange={(e, data) => {
          setNotificationSubjectError(false);
          setNotificationMessage(data.value?.toString())
        }}
      />

      <Segment error inverted color='red'>
        <Header
          icon='info'
          content={`${t('g.warning')} - ${t('g.sms')} / ${t('g.email')}`}
        />
  
        <Divider />
  
        <div>{t('s.notifications.spamWarning')}</div>
  
        <Header
          as='h4'
          content={t('s.notifications.usageResponsibility')}
        />
      </Segment>

      <div style={{marginTop: 20, marginBottom: 20}}>
        {t('g.selectedUsers')}: <span><strong>{selectedUsers?.length||0}</strong></span>
      </div>

      <Form.Group>
        <Form.Button
          content={`${t('g.send')} ${t('g.sms')}`}
          onClick={() => sendNotification('sms')}
        />
        <Form.Button
          content={`${t('g.send')} ${t('g.email')}`}
          onClick={() => sendNotification('email')}
        />
        <Form.Button
          content={`${t('g.send')} ${t('g.pushNotifications')}`}
          onClick={() => sendNotification('notificaiton')}
        />
      </Form.Group>
    </Form>
  </InternalPage>
}