import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Segment,
  Image,
  Divider,
  Dropdown,
  TextArea,
  Message,
  Icon
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";

import InternalPage from '../../framework/internal_page';

import EnsanCareAPI from "../../util/EnsanCareLib/EnsanCareAPI";
import ECGeneral from "../../util/EnsanCareLib/funcs/general"

export default function SettingsGeneral() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const [settings, setSettings] = useState<any>({});
  const [fullObjSettings, setFullObjSettings] = useState<any>(null);

  useEffect(() => {
    getSettings();
  }, []);

  const settingFromSettings:any = (key:string, settingsArray:any[]) => {
    if(!key || !settingsArray) return null;

    if(
      typeof settingsArray !== 'object'
      || settingsArray.length < 1
    ) return null;

    var result:any = null;

    for(var i = 0; i < settingsArray.length; i++) {
      if(settingsArray[i].uuid === key) result = settingsArray[i];
    }
    
    return result;
  }

  const getSettings = async () => {
    if(isLoading) return null
    setIsLoading(true)

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;

    var res:any = await EnsanCareLib.getSettings();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getSettings()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getSettings()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 

    if(res.status === "success" && res.data) {
      setFullObjSettings(res.data)
      var tempSettings:any = {};

      res.data.map((setting:any, key:number) => {
        if(setting.uuid === "booking-consent") {
          tempSettings[`${setting.uuid}`] = {
            ar: ECGeneral.getFromLocal("ar",setting.value) || null,
            en: ECGeneral.getFromLocal("en",setting.value) || null
          };
        } 
        
        else 
          tempSettings[`${setting.uuid}`] = setting.value || null;
      })

      setSettings(tempSettings);
    }

    setIsLoading(false)
    return null;
  }

  const processSubmit = async () => {
    if(isLoading) return true;
    setIsLoading(true);

    var tempSettings = {
      ...settings,
      'booking-consent': JSON.stringify(settings['booking-consent'])
    }

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.key = tempSettings;
  
    var res:any = await EnsanCareLib.updateSettings();

    if (!res) {
      if(config.dev)
        console.error('Failed to complete update settings');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.missingRequirements'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack
          },
        ]
      });

    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: processSubmit
          },
        ]
      });
    }

    else if (res.status === 'success') {
        setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            positive: true,
            onClick: () => null
          },
        ]
      });
    }
    
    setIsLoading(false);
    return null;
  }

  if(!fullObjSettings) return null;

  return <InternalPage loading={isLoading}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.action : null}
    />
    
    <Header
      as='h1'
      content={t('g.general')}
    />

    <Message warning>
      <Header
        icon='exclamation triangle'
        content={t('g.caution')}
      />

      <Divider />

      <Grid columns={2} verticalAlign='middle'>
        <Grid.Column width={13}>
          {t('s.settings.updateWarningMessage')}
        </Grid.Column>
        <Grid.Column textAlign='right' width={3}>
          <Button
            content={t('g.update')}
            onClick={() => processSubmit()}
          />
        </Grid.Column>
      </Grid>
    </Message>

    <Divider section />

    <Header
      as='h2'
      content={t('s.settings.general')}
      dividing
    />

    <Grid>
      <Grid.Row verticalAlign='middle'>
        <Grid.Column width={6}>
          <b>{settingFromSettings('system-commission', fullObjSettings)?.title}</b>
          <div>{settingFromSettings('system-commission', fullObjSettings)?.description}</div>
        </Grid.Column>
        <Grid.Column width={10}>
          <Input
            fluid
            placeholder={'0.00 - 1.00'}
            value={settings[`${settingFromSettings('system-commission', fullObjSettings).uuid}`]}
            onChange={(e,data) => setSettings({
              ...settings,
              [`${settingFromSettings('system-commission', fullObjSettings)?.uuid}`]: data.value
            })}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider section hidden />
    
    <Header
      as='h2'
      content={t('s.settings.systemLinks')}
      dividing
    />

    <Grid>
      {['facebook-link', 'twitter-link', 'instagram-link', 'linkedin-link',
        'website-url', 'privacy-url', 'terms-and-conditions-url', 'support-url',
        'help-url', 'contact-url', 'about-url'
      ].map((link, key) => {
        var settingObj:any = null;
        return fullObjSettings?.map((obj:any, key:number) => {
          if(obj.uuid === link) {
            settingObj = obj;

            return <Grid.Row verticalAlign='middle'>
              <Grid.Column width={6}>
                <b>{settingObj.title}</b>
                <div>{settingObj.description}</div>
              </Grid.Column>
              <Grid.Column width={10}>
                <Input
                  fluid
                  placeholder={'https://wwww...'}
                  value={settings[`${settingObj.uuid}`]}
                  onChange={(e,data) => setSettings({
                    ...settings,
                    [`${settingObj.uuid}`]: data.value
                  })}
                />
              </Grid.Column>
            </Grid.Row>
          }
        })
      })}
    </Grid>

    <Divider section hidden />

    <Header
      as='h2'
      content={t('s.settings.payments')}
      dividing
    />

    <Grid>
      <Grid.Row verticalAlign='middle'>
        <Grid.Column width={6}>
          <Header
            as='h3'
            content={t('s.settings.enableApplePay')}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <Dropdown
            fluid
            selection
            placeholder={t('g.selectOne')}
            options={[
              {key: 0, text: t('g.yes'), value: 'y'},
              {key: 1, text: t('g.no'), value: 'n'}
            ]}
            value={settings["enable-apple-pay"] === "y" ? "y" : "n"}
            onChange={(e,data) => setSettings({
              ...settings,
              ['enable-apple-pay']: data.value
            })}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign='middle'>
        <Grid.Column width={6}>
          <Header
            as='h3'
            content={t('s.settings.enableCreditCard')}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <Dropdown
            fluid
            selection
            placeholder={t('g.selectOne')}
            options={[
              {key: 0, text: t('g.yes'), value: 'y'},
              {key: 1, text: t('g.no'), value: 'n'}
            ]}
            value={settings["enable-credit-card"] === "y" ? "y" : "n"}
            onChange={(e,data) => setSettings({
              ...settings,
              ['enable-credit-card']: data.value
            })}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider section hidden />    

    <Header
      as='h2'
      content={t('s.settings.contactInformation')}
      dividing
    />

    <Grid>
      <Grid.Row verticalAlign='middle'>
        <Grid.Column width={6}>
          <Header
            as='h3'
            content={t('s.settings.whatsAppPhoneNumber')}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <Input
            fluid
            placeholder={'+966512344444'}
            value={settings["whatsapp-phone-number"]}
            onChange={(e,data) => setSettings({
              ...settings,
              ['whatsapp-phone-number']: data.value
            })}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign='middle'>
        <Grid.Column width={6}>
          <Header
            as='h3'
            content={t('s.settings.callPhoneNumber')}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <Input
            fluid
            placeholder={'+966512344444'}
            value={settings["call-phone-number"]}
            onChange={(e,data) => setSettings({
              ...settings,
              ['call-phone-number']: data.value
            })}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider section hidden />    

    <Header
      as='h2'
      content={t('s.settings.consent')}
      dividing
    />

    <Grid>
      <Grid.Row>
        <Grid.Column width={6}>
          <Header
            as='h3'
            content={`${t('s.settings.consentContent')} (${t('l.english')})`}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <Form>
            <TextArea
              fluid
              placeholder={t('g.typeHere')}
              rows={10}
              value={ECGeneral.getFromLocal("en", settings["booking-consent"])}
              onChange={(e,data) => setSettings({
                ...settings,
                ['booking-consent']: {
                  ...['booking-consent'],
                  en: data.value,
                }
              })}
            />
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={6}>
          <Header
            as='h3'
            content={`${t('s.settings.consentContent')} (${t('l.arabic')})`}
          />
        </Grid.Column>
        <Grid.Column width={10}>
          <Form>
            <TextArea
              fluid
              placeholder={t('g.typeHere')}
              rows={10}
              value={ECGeneral.getFromLocal("ar", settings["booking-consent"])}
              onChange={(e,data) => setSettings({
                ...settings,
                ['booking-consent']: {
                  ...settings['booking-consent'],
                  ar: data.value
                }
              })}
            />
          </Form>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider section />

    <Message warning>
      <Header
        icon='exclamation triangle'
        content={t('g.caution')}
      />

      <Divider />

      <Grid columns={2} verticalAlign='middle'>
        <Grid.Column width={13}>
          {t('s.settings.updateWarningMessage')}
        </Grid.Column>
        <Grid.Column textAlign='right' width={3}>
          <Button
            content={t('g.update')}
            onClick={() => processSubmit()}
          />
        </Grid.Column>
      </Grid>
    </Message>

    
    
  </InternalPage>
}