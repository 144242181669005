import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Menu,
  Icon,
  Dropdown,
  Divider,
  Popup,
  Label
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../framework/internal_page';

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";
import ECCaregivers from "../util/EnsanCareLib/funcs/caregivers";
import ECServiceCities from "../util/EnsanCareLib/funcs/service_cities";

import { URLQuery } from '../util/tools';

import moment from 'moment';

export default function CaregiversListing() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [caregivers, setCaregivers] = useState<any[]|null>(null); 
  const [serviceCities, setServiceCities] = useState<Array<Object>>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [selectedResPerPage, setSelectedResPerPage] = useState<number>(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);

  const [selectedIsBlocked, setSelectedIsBlocked] = useState<string|null>();
  const [selectedApprovedStatus, setSelectedApprovedStatus] = useState<string|null>();
  const [searchTerm, setSearchTerm] = useState<string|null>();
  const [selectedPinsServiceCityId, setSelectedPinsServiceCityId] = useState<number|null>();
  const [orderDesc, setOrderDesc] = useState<boolean>(true);

  const urlApprovedStatus = URLQuery('approvedStatus', window) || null;
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    setSelectedApprovedStatus(urlApprovedStatus);
    setIsReady(true);

    if (isReady) getCaregivers();
    getServiceCities();
  }, []);

  useEffect(() => {
    
    if(isReady) getCaregivers();
  }, [
    selectedResPerPage,
    selectedIsBlocked,
    selectedApprovedStatus,
    searchTerm,
    selectedPinsServiceCityId,
    orderDesc
  ]);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  
  const getCaregivers = async (page:number = 1) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.resPerPage = selectedResPerPage;
    EnsanCareLib.currentPage = page||selectedPageNumber;
    EnsanCareLib.serviceCityId = selectedPinsServiceCityId;
    EnsanCareLib.orderDirection = orderDesc ? 'desc' : 'asc';

    // optional filters
    if(selectedIsBlocked) EnsanCareLib.isBlocked = selectedIsBlocked;
    if(selectedApprovedStatus) EnsanCareLib.approvedStatus = selectedApprovedStatus;
    if(searchTerm) EnsanCareLib.searchTerm = searchTerm;

    var res:any = await EnsanCareLib.getCaregivers();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregivers()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregivers()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
  

      setCaregivers(res.data); 
    }
    
    setIsLoading(false);
    return null;
  }

  //getServiceCities
  const getServiceCities = async () => {
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    
    var res:any = await EnsanCareLib.getServiceCities();
    
    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
      return null;
    }

    var serviceCitiesObj:any[any] = [];

    res.data.map((item:any) => {
      serviceCitiesObj.push({
        key: item.id,
        value: item.id,
        text: ECServiceCities.buildLabel(i18n.language, item)
      })
    })
    
    setServiceCities(serviceCitiesObj);
  }

  const _renderLoadingRow = () => {
    return <Table.Row>
      <Table.Cell colSpan={7} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderRows = () => {
    if(!caregivers || caregivers.length < 1) { 
      return <Table.Row disabled>
        <Table.Cell>
          {t('g.noResults')}
        </Table.Cell>
      </Table.Row>
    };
    
    return caregivers.map( (caregiver, key) => {
      var uuid = caregiver.uuid || null;

      if(uuid) uuid = uuid.substring(uuid.length - 5, uuid.length);

      return <Table.Row 
        onClick={() => window.open(`/caregivers/${caregiver.uuid}`, '_self')}
        warning={!caregiver.approved_at}
      >
        <Table.Cell width={2}># {uuid}</Table.Cell>
        <Table.Cell width={3}>
          {
            ECCaregivers.buildName(
              i18n.language,
              caregiver.name_prefix,
              caregiver.first_name_local, 
              caregiver.middle_name_local, 
              caregiver.last_name_local
            )
          }
        </Table.Cell>
        <Table.Cell width={3}>{caregiver.country_code} - {caregiver.phone}</Table.Cell>
        <Table.Cell width={2}>{caregiver.email}</Table.Cell>
        <Table.Cell width={1}>{caregiver.commission ? `${(caregiver.commission * 100).toFixed(2)}%` : ''}</Table.Cell>
        <Table.Cell width={2}>{caregiver.is_blocked === 'y' ? t('g.yes') : t('g.no')}</Table.Cell>
        <Table.Cell width={3}>
          {moment(caregiver.created_at).format(config.momentDateFormat)}
        </Table.Cell>
      </Table.Row>
    })
  }

  return <InternalPage loading={!isReady}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal?.action||['Ok']}
    />
    
    <Header
      as='h1'
      content={t('s.profile.caregivers')}
    />

    <Form>
      <Form.Group>
        <Form.Field width={5}>
          <Form.Input
            icon={'search'}
            placeholder={t('g.search')}
            onChange={(e, data) => {
              setSearchTerm(data.value)
            }}
          />
        </Form.Field>
        <Form.Field width={5}>
          <Form.Dropdown
            selection
            fluid
            clearable
            placeholder={t('s.serviceCities.title')}
            options={serviceCities}
            onChange={(e, data) => {

              if(data.value && typeof data.value === 'number') {
                setSelectedPinsServiceCityId(data.value)
              } 
              
              else {
                setSelectedPinsServiceCityId(null)
              }

            }}
          />
        </Form.Field>
        <Form.Field width={3}>
          <Form.Dropdown
            selection
            fluid
            clearable
            placeholder={t('s.profile.accessStatus')}
            options={[
              {key: 0, value: 'y', text: t('g.blocked')},
              {key: 1, value: 'n', text: t('g.allowed')},
            ]}
            onChange={(e, data) => {
              
              if(data.value && typeof data.value === 'string') {
                setSelectedIsBlocked(data.value)
              } else {
                setSelectedIsBlocked(null)
              }

            }}
          />
        </Form.Field>
        <Form.Field width={3}>
          <Form.Dropdown
            selection
            fluid
            clearable
            placeholder={t('s.profile.approvedStatus')}
            options={[
              {key: 0, value: 'y', text: t('g.approved')},
              {key: 1, value: 'n', text: t('g.unapproved')},
            ]}
            value={selectedApprovedStatus || ""}
            onChange={(e, data) => {

              if(data.value && typeof data.value === 'string') {
                setSelectedApprovedStatus(data.value)
              } else {
                setSelectedApprovedStatus(null)
              }

            }}
          />
        </Form.Field>
      </Form.Group>
    </Form>

    <Menu stackable>
      <Menu.Menu position='right'>
        <Menu.Item
          name={t('g.generalInformation')}
          onClick={() => history.push("../caregivers/export")}
        >
          <Icon name='external'/>
          {t('g.export')}
        </Menu.Item>
      </Menu.Menu>
    </Menu>

    <Table color='blue' striped selectable sortable>
      <Table.Header>
        <Table.HeaderCell width={2}>{t('g.uuid')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('s.profile.fullName')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('g.phone')}</Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('g.email')}</Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('g.commission')}</Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('g.blocked')}</Table.HeaderCell>
        <Table.HeaderCell 
          width={3}
          sorted={orderDesc ? "descending" : 'ascending'}
          onClick={() => setOrderDesc(!orderDesc)}
        >
          {t('g.createdAt')}
        </Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderRows()}
        {(isLoading) && _renderLoadingRow()}
      </Table.Body>
    </Table>
    <Grid verticalAlign='middle'>
      <Grid.Row columns={3}>
        <Grid.Column>
          {t('g.resPerPage')}
          <Dropdown
            style={{
              marginLeft: '10px'
            }}
            inline
            options={[
              {key: 0, value: 25, text: "25"},
              {key: 1, value: 50, text: "50"},
              {key: 2, value: 100, text: "100"},
              {key: 3, value: 500, text: "500"},
            ]}
            defaultValue={selectedResPerPage}
            onChange={(e, data) => {

              if(typeof data.value === 'number'){
                return setSelectedResPerPage(data.value); 
              }

              if(typeof data.value === 'string' && /^[0-9]/.test(data.value)){
                return setSelectedResPerPage(parseInt(data.value));
              }
              
              if(config.dev) console.warn("result per page type is not valid", typeof data.value)
              return null;
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Menu floated='right' pagination size='mini'>
            <Menu.Item 
              as='a' 
              icon 
              onClick={() => {
                if(selectedPageNumber <= 1) {
                  setSelectedPageNumber(selectedPageNumber)
                  return null;
                }

                setSelectedPageNumber(selectedPageNumber-1)
                getCaregivers(selectedPageNumber-1);
              }}
              >
              <Icon name='chevron left'/>
            </Menu.Item>
            <Menu.Item>
              <Input 
                value={selectedPageNumber}
                onChange={(e,data) => {
                  if(parseInt(data.value) < 1) return null;
                  if(/^[0-9]/.test(data.value))
                    setSelectedPageNumber(parseInt(data.value));
                }}
              />
              <Button 
                style={{
                  marginLeft: '10px'
                }}
                color='blue'
                onClick={() => {
                  var error=false;

                  if(typeof selectedPageNumber !== 'number') {
                    error = true;
                    if(config.dev) console.warn('Provided page number is not a number', selectedPageNumber);
                  }

                  if(selectedPageNumber < 1) {
                    error = true;
                    if(config.dev) console.warn('Provided page number is less than 1', selectedPageNumber);
                  }

                  if(error) {
                    setModal({
                      visible: true,
                      title: t('g.error'),
                      message: t('g.invalidInput'),
                      action: [
                        {
                          content: t('g.goBack'),
                          onClick: () => history.goBack()
                        },
                      ]
                    });
                    return null;
                  }

                  setSelectedPageNumber(selectedPageNumber)
                  getCaregivers(selectedPageNumber);
                }}
              >
                {t('g.go')}
              </Button>
            </Menu.Item>
            <Menu.Item 
              as='a' 
              icon
              onClick={() => {
                setSelectedPageNumber(selectedPageNumber+1)
                getCaregivers(selectedPageNumber+1);
              }}
              >
              <Icon name='chevron right' />
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </InternalPage>
}