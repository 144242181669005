import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import EnsanCareAPI from '../../util/EnsanCareLib/EnsanCareAPI';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../../config';

// components
import InternalPage from '../../framework/internal_page';
import ServiceRequesterMemberGeneral from './general';
import ServiceRequesterMemberInsuranceCards from './insurance_cards';
import ServiceRequesterMemberMedicalInformation from './medical_information';
import ServiceRequesterMemberDocuments from './documents';

// translation
import { useTranslation } from "react-i18next";

export default function ServiceRequesterMembersNavigation() {
  const portal = config.dev ? config.d.portal : config.p.portal;

  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [section, setSection] = useState<'general'|'insuranceCards'|'medicalInformation'|'documents'>('general');
  
  const {service_requester_uuid} = useParams<{service_requester_uuid:string}>();
  const {member_id} = useParams<{member_id:string}>();
  
  const [isLoadingMember, setIsLoadingMember] = useState<boolean>(false);
  const [member, setMember] = useState<any>(null);
  
  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getMember();
  }, []);

  const getMember = async () => {
    if(!member_id) {
      setModal({
        visible: true,
        title: t('g.error'),
        message: t('g.missingRequirements') + ' member ID',
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getMember()
          }
        ]
      });

      return null;
    }

    if(isLoadingMember) return null;
    setIsLoadingMember(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.memberId = member_id;

    var res:any = await EnsanCareLib.getMember();

    if(!res || !res.data) {
      if(config.dev)
        console.error('Faield to complete the process getServiceRequester');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => history.goBack()
          },
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });
    }

    else if (res.data) {
      setMember(res.data);
    }

    setIsLoadingMember(false);
    return null;
  }

  if(!member) return <Grid>
    <Grid.Row>
      <Grid.Column>
        <Loader size='medium' active inline='centered' />
      </Grid.Column>
    </Grid.Row>
  </Grid>

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal?.title||null}
      content={modal?.message||null}
      actions={modal?.action||['Ok']}
    />
    
    <Header
      as='h1'
      content={(member) 
        ? `${member?.first_name} ${member?.last_name}` 
        : '...'
      }
    />

    <Menu>
      <Menu.Item
        name={t('g.generalInformation')}
        active={section === 'general'}
        onClick={() => setSection('general')}
      />
      <Menu.Item
        name={t('s.serviceRequesters.insuranceCards')}
        active={section === 'insuranceCards'}
        onClick={() => setSection('insuranceCards')}
      />
      <Menu.Item
        name={t('s.serviceRequesters.medicalInformation')}
        active={section === 'medicalInformation'}
        onClick={() => setSection('medicalInformation')}
      />
      <Menu.Item
        name={t('g.documents')}
        active={section === 'documents'}
        onClick={() => setSection('documents')}
      />
      <Menu.Item
        name={t('s.bookings.title')}
        onClick={() => window.open(
          `${portal}bookings/?serviceRequesterUuid=${member.service_requester_uuid}&memberId=${member.id}`,
          '_self'
        )}
      />
    </Menu>

    {(!section || section === 'general') && <ServiceRequesterMemberGeneral />}
    {(section === 'insuranceCards') && <ServiceRequesterMemberInsuranceCards />}
    {(section === 'medicalInformation') && <ServiceRequesterMemberMedicalInformation />}
    {(section === 'documents') && <ServiceRequesterMemberDocuments />}
  </InternalPage>
}