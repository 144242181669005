import { useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../framework/internal_page';
import ServiceRequesterGeneral from './general';
import ServiceRequesterMembers from './members/list';
import ServiceRequesterAccess from './access';

export default function ServiceRequesterForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [section, setSection] = useState<'general'|'access'|'members'>('access');
  const [user, setUser] = useState<any>(null);

  const countryCode = '+966';

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string
  }

  const [modal, setModal] = useState<modalType|null>(null);

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={['Ok']}
    />
    
    <Header
      as='h1'
      content={(user) ? `${user?.first_name} ${user?.last_name}` : '...'}
    />

    <Menu stackable pointing secondary>
      <Menu.Item
        name={t('s.profile.accessInformation')}
        active={section === 'access'}
        onClick={() => setSection('access')}
      />
      <Menu.Item
        name={t('g.generalInformation')}
        active={section === 'general'}
        onClick={() => setSection('general')}
      />
      <Menu.Item
        name={t('s.serviceRequesters.members')}
        active={section === 'members'}
        onClick={() => setSection('members')}
      />
    </Menu>

    {(!section || section === 'general') && <ServiceRequesterGeneral />}
    {(section === 'access') && <ServiceRequesterAccess />}
    {(section === 'members') && <ServiceRequesterMembers />}
  </InternalPage>
}