import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import EnsanCareAPI from '../util/EnsanCareLib/EnsanCareAPI';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu
} from 'semantic-ui-react';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";

export default function ServiceRequesterAccess() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {service_requester_uuid} = useParams<{service_requester_uuid:string}>();

  const countryCode = '+966';
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [serviceRequester, setServiceReqeuster] = useState<any>({
    uuid: null,
    cached_rating: 0,
    country_code: "+966",
    phone: null,
    deleted_at: null,
    email: null,
    is_blocked: null,
    password: null,
    pref_language: null,
  }); 

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getServiceRequester();
  }, []);

  const getServiceRequester = async () => {
    if(!service_requester_uuid) {
      setModal({
        visible: true,
        title: t('g.error'),
        message: t('g.missingRequirements') + ' service requester UUID',
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          }
        ]
      });

      return null;
    }

    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.serviceRequester = service_requester_uuid;

    var res:any = await EnsanCareLib.getServiceRequester();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServiceRequester()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getServiceRequester()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
      setServiceReqeuster(res.data);
    }
    
    setIsLoading(false);
    return null;
  }

  const processSubmit = async (actionType:'profile'|'block'|'delete') => {
    if(isLoading) return true;
    setIsLoading(true);

    var onClickTryAgainAction:any = null;

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.serviceRequesterUuid = service_requester_uuid;
    
    var res:any = null;

    if(actionType === 'profile') {
      EnsanCareLib.phone = serviceRequester.phone;
      EnsanCareLib.email = serviceRequester.email;
      EnsanCareLib.otp = "Skip";

      if(serviceRequester.password.length > 1){
        EnsanCareLib.password = serviceRequester.password
      }

      res = await EnsanCareLib.updateServiceRequester();

      onClickTryAgainAction = () => processSubmit('profile');
    }

    else if( actionType === 'block') {
      EnsanCareLib.is_blocked = serviceRequester.is_blocked === 'y' ? 'n' : 'y';
      
      res = await EnsanCareLib.updateServiceRequester();

      onClickTryAgainAction = () => processSubmit('block');
    }

    else if(actionType === 'delete') {
      res = await EnsanCareLib.deleteServiceRequester();

      onClickTryAgainAction = () => processSubmit('delete');
    }

    if (!res) {
      if(config.dev)
        console.error('Failed to complete update settings');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });

    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: onClickTryAgainAction
          },
        ]
      });
    }

    else if (res.status === 'success') {
      if(actionType === 'block') {
        setServiceReqeuster({
          ...serviceRequester,
          is_blocked: serviceRequester.is_blocked === 'y' ? 'n' : 'y'
        })
      }

      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            positive: true,
            onClick: actionType === 'delete' ? () => history.push('./') : () => null
          },
        ]
      });
    }
    
    setIsLoading(false);
    return null;
  }

  if(!serviceRequester) return <Grid>
    <Grid.Row>
      <Grid.Column>
        <Loader size='medium' active inline='centered' />
      </Grid.Column>
    </Grid.Row>
  </Grid>

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal?.title||null}
      content={modal?.message||null}
      actions={modal?.action||['Ok']}
    />

    <Form>
      <Form.Group widths={2}>
        <Form.Dropdown
          width={2}
          fluid
          label={t('s.profile.countryCode')}
          selection
          value={countryCode}
          options={[
            {key: 0, value: '+966', text: '+966'}
          ]}
          di
        />
        <Form.Input
          label={t('g.phone')}
          placeholder={t('g.typeHere')}
          value={serviceRequester.phone}
          onChange={(e, data) => setServiceReqeuster({
            ...serviceRequester,
            phone: data.value
          })}
        />
      </Form.Group>

      <Form.Input
        label={t('g.email')}
        placeholder={t('g.typeHere')}
        value={serviceRequester.email}
        onChange={(e, data) => setServiceReqeuster({
          ...serviceRequester,
          email: data.value
        })}
      />

      <Form.Input
        label={t('g.password')}
        placeholder={t('g.typeHere')}
        type='password'
        value={serviceRequester.password}
        onChange={(e, data) => setServiceReqeuster({
          ...serviceRequester,
          password: data.value
        })}
      />
    </Form>

    <Divider hidden />

    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Button
            icon='arrow left'
            labelPosition='left'
            content={t('g.cancel')}
            onClick={() => history.goBack()}
          />
        </Grid.Column>
        <Grid.Column floated="right" textAlign="right">
          <Button
            primary
            content={t('g.save')}
            onClick={() => processSubmit('profile')}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider section hidden />

    <Segment color='red'>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={12}>
            <Header
              as='h2'
              content={t('s.profile.accessStatus')}
            />
            
            <div>
              <div style={{marginBottom: '10px'}}>
                {t('g.currentStatus')}: <strong>{(serviceRequester?.is_blocked === 'y') 
                  ? t('s.profile.accountBlocked') 
                  : t('s.profile.accountAllowedAccess') 
                }</strong>
              </div>
              <div>{t('s.profile.blockUserDescription')}</div>
            </div>
          </Grid.Column>
          <Grid.Column width={4} textAlign='right'>
            <Form.Button
              content={(serviceRequester?.is_blocked === 'y') ? t('g.unblock') : t('g.block')}
              onClick={() => processSubmit('block')}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>

    <Segment color='red'>

      <Grid>
        <Grid.Row>
          <Grid.Column width={12}>
            <Header
              as='h2'
              content={`${t('g.delete')} ${t('g.account')}`}
            />
            <div>{t('s.caregivers.deleteCaregiverDescription')}</div>
          </Grid.Column>
          <Grid.Column width={4} textAlign={'right'}>
            <Button
              content={t('g.delete')}
              color='red'
              onClick={() => processSubmit('delete')}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>  
  </>
}