// import UI componented
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Menu,
  Icon,
  Dropdown,
  Divider,
  Popup,
  Label
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../framework/internal_page';

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";

import moment from 'moment';

const ReferralRow = (props:any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const [isProcessing, setIsProcessing] = useState<boolean>(false); 

  const [componentDeleted, setComponentDeleted] = useState<boolean>(false); 

  const [actionReqeusted, setActionRequested] = useState<boolean>(true);

  const _handleUpdateRequest = async (referral_by_uuid:string, referral_uuid:string) => {
    if(isProcessing) return null;
    setIsProcessing(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.referredByUuid = referral_by_uuid;
    EnsanCareLib.referredUuid = referral_uuid;

    var res:any = await EnsanCareLib.updateReferral();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => _handleUpdateRequest(props.referral_by_uuid, props.referral_to_uuid)
          },
        ]
      });
    }

    else if (res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => _handleUpdateRequest(props.referral_by_uuid, props.referral_to_uuid)
          },
        ]
      });
    }

    else if (res.status === "success") {
      setComponentDeleted(true);
    }

    return null;
  }

  const _renderBtns=() => {
    if(actionReqeusted) {
      return <>
        <Button
          color='yellow'
          size={'tiny'}
          content={t('s.bookings.paidOut')}
          onClick={() => {
            setActionRequested(false);
          }}
        />
      </>
    }
    else {
      return <>
        <Button
          color='yellow'
          size={'tiny'}
          content={t('g.confirm')}
          onClick={() => {
            _handleUpdateRequest(props.referral_by_uuid, props.referral_to_uuid)
          }}
        />
        <Button
          size={'tiny'}
          content={t('g.cancel')}
          onClick={() => setActionRequested(true)}
        />
      </>
    } 
  }

  // if(componentDeleted) return null;
  
  if(props.paid_at) {
    return (
      <Table.Row positive>
        <Table.Cell width={4}>{props.referred_by}</Table.Cell>
        <Table.Cell width={3}>{props.referred_to}</Table.Cell>
        <Table.Cell width={3}>{props.booking_uuid}</Table.Cell>
        <Table.Cell width={3}>{moment(props.created_at).format(config.momentDateFormat)}</Table.Cell>
        <Table.Cell width={3}>
          {moment(props.paid_at).format(config.momentDateFormat)}
        </Table.Cell>
      </Table.Row>
    )
  }
  if(componentDeleted) {
    return (
      <Table.Row positive>
        <Table.Cell width={4}>{props.referred_by}</Table.Cell>
        <Table.Cell width={3}>{props.referred_to}</Table.Cell>
        <Table.Cell width={3}>{props.booking_uuid}</Table.Cell>
        <Table.Cell width={3}>{moment(props.created_at).format(config.momentDateFormat)}</Table.Cell>
        <Table.Cell width={3}></Table.Cell>
      </Table.Row>
    )
  }
  else {
    return (
      <Table.Row>
        <Table.Cell width={4}>{props.referred_by}</Table.Cell>
        <Table.Cell width={3}>{props.referred_to}</Table.Cell>
        <Table.Cell width={3}>{props.booking_uuid}</Table.Cell>
        <Table.Cell width={3}>{moment(props.created_at).format(config.momentDateFormat)}</Table.Cell>
        <Table.Cell width={3}>
          {_renderBtns()}
        </Table.Cell>
      </Table.Row>
    )
  }
}

const ReferralList = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [selectedResPerPage, setSelectedResPerPage] = useState<number>(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);

  const [referredBy , setReferredBy ] = useState<string|null>();
  const [referredTo , setReferredTo ] = useState<string|null>();
  const [bookingUuid , setBookingUuid ] = useState<string|null>();
  const [selectedPaid, setSelectedPaid] = useState<string|null>();

  const [isReady, setIsReady] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [referrals, serReferrals] = useState<any>();

   // states
   interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getReferrals();
  }, []);

  useEffect(() => {
    getReferrals();
  }, [
    selectedResPerPage,
    selectedPageNumber,
    referredBy,
    referredTo,
    bookingUuid,
    selectedPaid
  ]);

  const getReferrals = async (page:number = 1) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.resPerPage = selectedResPerPage;
    EnsanCareLib.currentPage = page||selectedPageNumber;
    // EnsanCareLib.orderDirection = orderDesc ? 'desc' : 'asc';

    // optional filters
    if(referredBy) EnsanCareLib.referredByUuid = referredBy;
    if(referredTo) EnsanCareLib.referredUuid = referredTo;
    if(bookingUuid) EnsanCareLib.bookingUuid = bookingUuid;
    if(selectedPaid) EnsanCareLib.paidOut = selectedPaid;

    var res:any = await EnsanCareLib.getReferrals();
    
    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getReferrals()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getReferrals()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
      serReferrals(res.data)
    }
    
    setIsLoading(false);
    return null;
    
  }

  const _renderLoadingRow = () => {
    return <Table.Row>
      <Table.Cell colSpan={7} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderRows = () => {

    if(!referrals || referrals.length < 1) { 
      return <Table.Row disabled>
        <Table.Cell>
          {t('g.noResults')}
        </Table.Cell>
      </Table.Row>
    };
    
    return referrals.map( (referral:any, key:number) => {
      
      var referral_by = referral.referred_by_caregiver_uuid || null;

      if(referral_by) referral_by = referral_by.substring(referral_by.length - 10, referral_by.length);


      var referral_to = referral.referred_caregiver_uuid || null;

      if(referral_to) referral_to = referral_to.substring(referral_to.length - 10, referral_to.length);


      var booking_uuid = referral.booking_uuid || null;

      if(booking_uuid) booking_uuid = booking_uuid.substring(booking_uuid.length - 10, booking_uuid.length);

      return <ReferralRow
        referred_by={referral_by}
        referred_to={referral_to}
        booking_uuid={booking_uuid}
        created_at={referral.created_at}
        paid_at={referral.commission_paid_at}
        
        // used inside the component for the update function
        referral_by_uuid={referral.referred_by_caregiver_uuid}
        referral_to_uuid={referral.referred_caregiver_uuid}
      />
    })
  }

  return (
    <InternalPage >
      <Modal
        size='mini'
        onClose={() => setModal(null)}
        open={(modal && modal.visible) ? true : false}
        header={modal ? modal.title : null}
        content={modal ? modal.message : null}
        actions={modal?.action||['Ok']}
      />
      
      <Header
        as='h1'
        content={t('s.caregivers.referrals')}
      />

    <Form>    
      <Form.Group>
        <Form.Field width={4}>
          <Form.Input
            icon={'search'}
            placeholder={t('s.caregivers.referredBy')}
            onChange={(e, data) => {
              setReferredBy(data.value)
            }}
          />
        </Form.Field>
        <Form.Field width={4}>
          <Form.Input
            icon={'search'}
            placeholder={t('s.caregivers.referredTo')}
            onChange={(e, data) => {
              setReferredTo(data.value)
            }}
          />
        </Form.Field>
        <Form.Field width={4}>
          <Form.Input
            icon={'search'}
            placeholder={t('s.bookings.bookingUuid')}
            onChange={(e, data) => {
              setBookingUuid(data.value)
            }}
          />
        </Form.Field>
        <Form.Field width={4}>
          <Form.Dropdown
            selection
            fluid
            clearable
            placeholder={t('g.paid')}
            options={[
              {key: 0, value: 'y', text: t('g.paid')},
              {key: 1, value: 'n', text: t('g.unpaid')},
            ]}
            value={selectedPaid || ""}
            onChange={(e, data) => {

              if(data.value && typeof data.value === 'string') {
                setSelectedPaid(data.value)
              } else {
                setSelectedPaid(null)
              }

            }}
          />
        </Form.Field>
      </Form.Group>
    </Form>

    <Table color='blue' stackable striped>
      <Table.Header>
        <Table.HeaderCell width={4}>{t('s.caregivers.referredBy')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('s.caregivers.referredTo')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('s.bookings.bookingUuid')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('g.createdAt')}</Table.HeaderCell>
        <Table.HeaderCell width={3}></Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderRows()}
        {isLoading && _renderLoadingRow()}
      </Table.Body>
    </Table>

    <Grid verticalAlign='middle'>
      <Grid.Row columns={3}>
        <Grid.Column>
          {t('g.resPerPage')}
          <Dropdown
            style={{
              marginLeft: '10px'
            }}
            inline
            options={[
              {key: 0, value: 25, text: "25"},
              {key: 1, value: 50, text: "50"},
              {key: 2, value: 100, text: "100"},
              {key: 3, value: 500, text: "500"},
            ]}
            defaultValue={selectedResPerPage}
            onChange={(e, data) => {

              if(typeof data.value === 'number'){
                return setSelectedResPerPage(data.value); 
              }

              if(typeof data.value === 'string' && /^[0-9]/.test(data.value)){
                return setSelectedResPerPage(parseInt(data.value));
              }
              
              if(config.dev) console.warn("result per page type is not valid", typeof data.value)
              return null;
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Menu floated='right' pagination size='mini'>
            <Menu.Item 
              as='a' 
              icon 
              onClick={() => {
                if(selectedPageNumber <= 1) {
                  setSelectedPageNumber(selectedPageNumber)
                  return null;
                }

                setSelectedPageNumber(selectedPageNumber-1)
                getReferrals(selectedPageNumber-1);
              }}
              >
              <Icon name='chevron left'/>
            </Menu.Item>
            <Menu.Item>
              <Input 
                value={selectedPageNumber}
                onChange={(e,data) => {
                  if(parseInt(data.value) < 1) return null;
                  if(/^[0-9]/.test(data.value))
                    setSelectedPageNumber(parseInt(data.value));
                }}
              />
              <Button 
                style={{
                  marginLeft: '10px'
                }}
                color='blue'
                onClick={() => {
                  var error=false;

                  if(typeof selectedPageNumber !== 'number') {
                    error = true;
                    if(config.dev) console.warn('Provided page number is not a number', selectedPageNumber);
                  }

                  if(selectedPageNumber < 1) {
                    error = true;
                    if(config.dev) console.warn('Provided page number is less than 1', selectedPageNumber);
                  }

                  if(error) {
                    setModal({
                      visible: true,
                      title: t('g.error'),
                      message: t('g.invalidInput'),
                      action: [
                        {
                          content: t('g.goBack'),
                          onClick: () => history.goBack()
                        },
                      ]
                    });
                    return null;
                  }

                  setSelectedPageNumber(selectedPageNumber)
                  getReferrals(selectedPageNumber);
                }}
              >
                {t('g.go')}
              </Button>
            </Menu.Item>
            <Menu.Item 
              as='a' 
              icon
              onClick={() => {
                setSelectedPageNumber(selectedPageNumber+1)
                getReferrals(selectedPageNumber+1);
              }}
              >
              <Icon name='chevron right' />
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </InternalPage>
    
  );
}

export default ReferralList;