import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router'

import { 
  Button,
  Grid, 
  Header
} from 'semantic-ui-react';

import Logo from '../assets/logo-icon.png';

export default function Logout() {
  const history = useHistory();
  const {t, i18n} = useTranslation();

  useEffect(() => {
    // clear all the neccessary stored values
    localStorage.removeItem('auth_token');
    localStorage.removeItem('device_uuid');
    localStorage.removeItem('user_profile');
    localStorage.removeItem('user_details');
  }, [])

  return <>
    <Grid textAlign='center' verticalAlign='middle' style={{height: '100vh' }} padded>
      <Grid.Column style={{ maxWidth: 450 }}>
        <div style={{justifyContent: 'center', display: 'flex'}}>
          <img src={Logo} width="100" />
        </div>
        
        <Header as='h1' textAlign='center'>
          {t('s.auth.bye')}
        </Header>
        <p>{t('s.auth.byeDescription')}</p>

        <Button primary onClick={() => history.push('/login')}>
          {t('s.auth.login')}
        </Button>
      </Grid.Column>
    </Grid>
  </>
}