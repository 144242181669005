import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Segment,
  Image,
  Menu,
  Icon,
  Label,
  Divider
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../../framework/internal_page';

import EnsanCareAPI from "../../util/EnsanCareLib/EnsanCareAPI";
import ECGeneral from "../../util/EnsanCareLib/funcs/general"

export default function NationalitiesForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [actionReqeusted, setActionRequested] = useState<boolean>(true);

  const [nationality, setNationality] = useState<any>({
    id: null,
    label: null,
    local: {
      en: null,
      ar: null
    }
  });

  const {nationality_id} = useParams<{nationality_id:string}>();

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    if(nationality_id) getNationality();
  }, [])

  const getNationality = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.nationalityId = nationality_id;

    var res:any = await EnsanCareLib.getNationality();

    if(!res || !res.data) {
      if(config.dev) 
        console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: processSubmit
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });
    } 
   
    else if (res.status === 'success' && res.data) {
      if(config.dev) console.warn('no results found');
     
      var tempObj = {
        id: res.data.id || null,
        label: res.data.label  || null,
        local: {
          en: res.data.local ? ECGeneral.getFromLocal("en", res.data.local) : null,
          ar: res.data.local ? ECGeneral.getFromLocal("ar", res.data.local) : null
        }
      }

      setNationality(tempObj);
    }
    
    setIsLoading(false);
    return null;
  }

  const processSubmit = async () => {
    if(isLoading) return true;
    setIsLoading(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.label = nationality.label;
    EnsanCareLib.local = JSON.stringify(nationality.local);

    var res:any;

    if (!nationality.id) {
      res = await EnsanCareLib.addNationality();
    } 
  
    else {
      EnsanCareLib.nationalityId = nationality.id;
      res = await EnsanCareLib.updateNationality();
    }

    if (!res) {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.missingRequirements'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack
          },
        ]
      });

      if(config.dev)
        console.error('Failed to complete update or add nationality');
    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: processSubmit
          },
        ]
      });
    }

    else if (res.status === 'success') {

      // modal for adding a new nationality
      if(!nationality.id && res.data.id) { 
        setModal({
          visible: true,
          title: t('g.processCompleted'),
          message: t('g.processCompletedMessage'),
          action: [
            {
              content: t('g.ok'),
              positive: true,
              onClick: () => history.push('../nationalities/show/' + res.data.id)
            },
          ]
        });
      }

      // modal for updating nationality
      else{
        setModal({
          visible: true,
          title: t('g.processCompleted'),
          message: t('g.processCompletedMessage'),
          action: [
            {
              content: t('g.ok'),
              positive: true,
              onClick: () => null
            },
          ]
        });
      }
    }
    
    setIsLoading(false);
    return null;
  }

  const processDelete = async () =>{
    if(isLoading) return true;
    setIsLoading(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.nationalityId = nationality_id;

    var res:any = await EnsanCareLib.deleteNationality();
    
    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: processDelete
          },
        ]
      });
    }

    else if (res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: processDelete
          },
        ]
      });
    }

    else if (res.status === "success") {
      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            onClick: () => history.push('../')
          },
        ]
      });
    }

    setIsLoading(false);
    return null;
  }

  const _renderDeleteBtn = () => {
    if(nationality_id) {
      if(actionReqeusted){
        return <>
          <Button
            negative
            content={t('g.delete')}
            onClick={() => {setActionRequested(false)}}
          />
        </>
      }
      else {
        return <>
          <Button
            color='yellow'
            content={t('g.confirm')}
            onClick={() => {processDelete()}}
          />
          <Button
            content={t('g.cancel')}
            onClick={() => setActionRequested(true)}
          />
        </>
      }
    }
  }
  
  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.action : null}
    />
    
    <Header
      as='h1'
      content={nationality.label || '...'}
    />

    <Form>
      <Form.Input
        label={t('g.title')}
        placeholder={nationality.label ? nationality.label : t('g.typeHere')}
        value={nationality.label || ''}
        onChange={(e, data) => setNationality({
          ...nationality,
          label: data.value||''
        })}
      />
      
      <Form.Input
        label={`${t('g.title')} ${t('l.english')}`}
        placeholder={nationality.local.en ? nationality.local.en : t('g.typeHere')}
        value={nationality.local.en || ''}
        onChange={(e, data) => setNationality({
          ...nationality,
          local: {
            ...nationality.local,
            en: data.value||''
          }
        })}
      />

      <Form.Input
        label={`${t('g.title')} ${t('l.arabic')}`}
        placeholder={nationality.local.ar ? nationality.local.ar : t('g.typeHere')}
        value={nationality.local.ar || ''}
        onChange={(e, data) => 
          setNationality({
            ...nationality,
            local: {
              ...nationality.local,
              ar: data.value||''
            }
          })
        }
      />
    </Form>

    <Divider hidden />

    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Button
            icon='arrow left'
            labelPosition='left'
            content={t('g.cancel')}
            onClick={() => history.push('./')}
          />
          {_renderDeleteBtn()}
        </Grid.Column>
        <Grid.Column floated="right" textAlign="right">
          <Button
            primary
            content={t('g.save')}
            onClick={() => processSubmit()}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </InternalPage>
}