/*
 *
 * PROJECT ENSAN CARE
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 * CODE VERSION: 0.1591
 * 
 */

export default class EnsanCareAPI {
  constructor() {
    this.baseAPI = null;
    this.method = 'GET';
    this.headers = null;
    this.authToken = null;
    this.additionalHeaders = null;
    this.query = null;
    this.body = {};
    this.res = {
      code: null,
      status: null,
      message: null,
      data: null,
      current_page: null,
      res_per_page: null,
      errors: null,
      update_status: null,
    };
    this.lang = 'en';
  }

  async request() {
    if(!this.url) {
      console.log('URL is required to make a process');
      return false;
    }

    // set default hedaers
    if(!this.headers && !this.useAppend) {
      this.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    } 
    else {
      this.headers = {};
    }

    if(this.authToken) {
      this.headers.authorization = `Bearer ${this.authToken}`;
    }

    if(this.additionalHeaders) {
      this.headers = {
        ...this.headers,
        ...this.additionalHeaders        
      }
    }

    if(this.method == 'GET') {
      this.body = null;
    }
    else if (this.useAppend) {
      this.body = this.body;
    }
    else {
      this.body = JSON.stringify(this.body);
    };

    await fetch(this.url, {
      method: this.method,
      headers: this.headers,
      body: this.body
    })
    .then((response) => {
      var resJson = response.json();
      this.res.code = response.status;

      return resJson;
    })
    .then((json) => {
      if( this.debug ) console.log(json); 

      var res = json;

      // set response status
      this.res.status = res.status;

      if(res.data)
        this.res.data = res.data;

      if(res.status == 'fail') {
        var errors = [];
        // this means return errors list
        if(res.errors) {
          res.errors.map( error => {
            errors.push(error[this.lang])
          })

          this.res.errors = errors;
        }
      }
      else {
        if(res.message)
          this.res.message = res.message[this.lang];

        if(res.data)
          this.res.data = res.data;

        if(res.current_page)
          this.res.current_page = res.current_page;

        if(res.res_per_page)
          this.res.res_per_page = res.res_per_page;

        if(res.update_status)
          this.res.update_status = res.update_status;
      }
    })
    .catch((error) => {
      console.error(error);
      this.res.errors = ["Could not make the request - check logs"];
    });

    return this.res;
  }

  getErrorsString() {
    var errors = this.res.errors;
    var errorsString = '';

    if(errors.length < 1) { return ''; }

    errors.map( (error) => {
      if(errorsString != '') { errorsString += '\r\n' }

      if(errors.length > 1) errorsString += '\u2022 ';
      errorsString += error;
    });

    return errorsString;
  }

  /**
   * 
   * APIS CALLS //==//
   * 
   */

  /**
   * 
   * USERS //==//
   * 
   */

  /**
   * 
   * SEND OTP 
   * 
   */

  async sendOtp () {
    var type = null;
    var data = {};

    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    // check if email
    // check if phone
    
    if(this.type) {
      type = this.type;
    }

    if(!this.loggedIn) {
      if(!this.email && !this.phone) {
        console.error('email or phone is required');
        return null; 
      }

      if(this.email) {
        type = 'email';
        data = { email : this.email }
      }

      if(this.phone) {
        type = 'phone';
        
        if(!this.countryCode) {
          console.error('countryCode is required for phone type OTP');
          return null;
        }

        data = {
          phone: this.phone,
          country_code: this.countryCode
        }
      }

      data = JSON.stringify(data);
    }
    else {
      if(!this.userUuid) {
        console.error('userUuid is required');
        return null;
      }
      
      if(!this.authToken) {
        console.error('authToken is required');
        return null;
      }
    }

    if(!type) {
      console.error('type was not automatically detected nor provided in the API object');
      return null;
    }

    this.body = {
      type: type,
      data: data
    }

    if(this.userType) {
      this.body = {
        ...this.body,
        user_type: this.userType
      }
    }

    this.url = `${this.baseAPI}users/auth/send-otp/${this.loggedIn ? this.userUuid : ''}`;
    this.method = 'POST';
    
    return await this.request();
  }

  /*
   *
   * LOGIN
   * 
   */ 

  async login() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var userTypes = ['management', 'service requester', 'caregiver'];
    var verificationChannels = ['phone', 'email'];
    var verificationTypes = ['password', 'otp'];
    
    var errors = false;
    var userType = this.userType || null;
    var verificationChannel = this.verificationChannel || null;
    var verificationType = this.verificationType || null;
    var countryCode = this.countryCode || null;
    var phone = this.phone || null;
    var email = this.email || null;
    var password = this.password || null;
    var otpCode = this.otpCode || null;
    var pushToken = this.pushToken || null;

    if(!userTypes.includes(userType)) {
      console.error('User type is incorrect', userType);
      errors = true;
    }

    if(
      !verificationChannels.includes(verificationChannel) ||
      !verificationTypes.includes(verificationType)
    ) {
      console.error(
        'Verification channel or type is incorrect',
        verificationChannel,
        verificationType
      );
      errors = true;
    }

    if(verificationChannel === 'phone') {
      if(!phone || !countryCode) {
        console.error('Must provide a phone number to continue');
        errors = true;
      }
    }

    if(verificationChannel === 'email') {
      if(!email) {
        console.error('Must provide a email to continue');
        errors = true;
      }
    }

    if(verificationType === 'password') {
      if(!password) {
        console.error('Must provide a password to continue');
        errors = true;
      }
    }

    if(verificationType === 'otp') {
      if(!otpCode) {
        console.error('Must provide an OTP code to continue');
        errors = true;
      }
    }

    if(errors) { 
      console.error('1x000021548'); 
      return false;
    }

    this.url = `${this.baseAPI}users/login`;
    this.method = 'POST';
    this.body = {
      user_type: userType,
      verification_channel: verificationChannel,
      verification_type: verificationType
    }

    if(password) this.body = {
      ...this.body,
      password: password
    };

    if(otpCode) this.body = {
      ...this.body,
      otp_code: otpCode
    }

    if(phone && countryCode) {
      this.body = {
        ...this.body,
        phone: phone,
        country_code: countryCode
      }
    }
    
    if(email) {
      this.body = {
        ...this.body,
        email: email
      }
    }

    if(this.pushToken) {
      this.body = {
        ...this.body,
        push_token: pushToken
      }
    }

    return await this.request();
  }

  /**
   * 
   * CHECK ID AVAILABILITY
   * 
   */

  async checkAccountAvailability() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var userTypes = ['service requester', 'caregiver'];
    
    var errors = false;
    var userType = this.userType || null;
    var countryCode = this.countryCode || null;
    var phone = this.phone || null;
    var email = this.email || null;

    if(!userTypes.includes(userType)) {
      console.error('User type is incorrect', userType);
      errors = true;
    }

    if(!phone && !email) {
      console.error(
        'Must provide a phone number or an email to continue',
        {phone: phone, email: email}
      );
      errors = true;
    }

    if(phone && !countryCode) {
      if(!countryCode) {
        console.error('Must provide the country code to continue');
        errors = true;
      }
    }

    if(errors) { 
      console.error('1x00015487'); 
      return false;
    }

    this.url = `${this.baseAPI}users/check-availability`;
    this.method = 'POST';
    this.body = {
      user_type: userType
    }

    if(phone && countryCode) {
      this.body = {
        ...this.body,
        phone: phone,
        country_code: countryCode
      }
    }
    
    if(email) {
      this.body = {
        ...this.body,
        email: email
      }
    }

    return await this.request();
  }

  /**
   * 
   * SIGN UP
   * 
   * IMPORTANT NOTE: Requirements DO NOT specify the sign up process
   * to include email and password sign up option, yet it was add
   * to the code for easier future integration if that changed.
   * 
   */

  async signup() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var userTypes = ['service requester', 'caregiver'];
    var verificationChannels = ['phone', 'email'];
    var verificationTypes = ['password', 'otp'];
    
    var errors = false;
    var userType = this.userType || null;
    var verificationChannel = this.verificationChannel || null;
    var verificationType = this.verificationType || null;
    var countryCode = this.countryCode || null;
    var phone = this.phone || null;
    var email = this.email || null;
    var password = this.password || null;
    var otpCode = this.otpCode || null;
    var pushToken = this.pushToken || null;

    if(!userTypes.includes(userType)) {
      console.error('User type is incorrect', userType);
      errors = true;
    }

    if(
      !verificationChannels.includes(verificationChannel) ||
      !verificationTypes.includes(verificationType)
    ) {
      console.error(
        'Verification channel or type is incorrect',
        verificationChannel,
        verificationType
      );
      errors = true;
    }

    if(verificationChannel === 'phone') {
      if(!phone || !countryCode) {
        console.error('Must provide a phone number to continue');
        errors = true;
      }
    }

    if(verificationChannel === 'email') {
      if(!email) {
        console.error('Must provide a email to continue');
        errors = true;
      }
    }

    if(verificationType === 'password') {
      if(!password) {
        console.error('Must provide a password to continue');
        errors = true;
      }
    }

    if(verificationType === 'otp') {
      if(!otpCode) {
        console.error('Must provide an OTP code to continue');
        errors = true;
      }
    }

    if(errors) { 
      console.error('1x255875'); 
      return false;
    }

    this.body = {
      user_type: userType,
      verification_channel: verificationChannel,
      verification_type: verificationType
    }

    if(password) this.body = {
      ...this.body,
      password: password
    };

    if(otpCode) this.body = {
      ...this.body,
      otp_code: otpCode
    }

    if(phone && countryCode) {
      this.body = {
        ...this.body,
        phone: phone,
        country_code: countryCode
      }
    }
    
    if(email) {
      this.body = {
        ...this.body,
        email: email
      }
    }

    if(this.pushToken) {
      this.body = {
        ...this.body,
        push_token: pushToken
      }
    }

    this.url = `${this.baseAPI}users`;
    this.method = 'POST';
    return await this.request();
  }

  /**
   * 
   * LOGOUT
   * 
   */

  async logout() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.deviceUuid && !this.allDevices) {
      console.error('Must specify a device or all devices');
      return null; 
    }

    if(!this.userType) {
      console.error('userType is required');
      return null; 
    }

    var query = '';

    if(this.deviceUuid) query += `&deviceUuid=${this.deviceUuid}`;
    if(this.allDevices) query += `&allDevices=1`;

    query += `&userType=${this.userType}`

    this.url = `${this.baseAPI}users/logout/?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * RESET PASSWORD
   * 
   */

  async resetPassword() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.userType) {
      console.error('userType is required');
      return null; 
    }

    if(!this.verificationChannel) {
      console.error('verificationChannel is required');
      return null; 
    }

    if(!this.phone && !this.email) {
      console.error('Require phone or email to continue');
      return null; 
    }

    if(this.phone && !this.countryCode) {
      console.error('countryCode is required');
      return null; 
    }

    if(!this.password) {
      console.error('password is required');
      return null; 
    }

    if(!this.otpCode) {
      console.error('otpCode is required');
      return null; 
    }

    this.url = `${this.baseAPI}users/reset-password`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * UPDATE USER
   * 
   */

  async updateUser() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }
    
    var userUuid = this.userUuid || null;
    var userType = this.userType || null;
    var password = this.password || null;
    var isBlocked = this.isBlocked || null;
    var countryCode = this.countryCode || null;
    var phone = this.phone || null;
    var email = this.email || null;
    var otpCode = this.otpCode || null;
    var prefLanguage = this.prefLanguage || null;

    if(!userType) {
      console.error('userType is required');
      return null;
    }

    if(email || phone || countryCode) {
      if(!otpCode) {
        console.error('Must provide OTP');
        return null;
      }
    }

    if(
      prefLanguage &&
      prefLanguage.length !== 2
    ) {
      console.error('Preferred language must be 2 character', prefLanguage);
      return null;
    }

    this.body = {}

    if(userUuid) this.body = {
      ...this.body,
      user_uuid: userUuid
    };

    if(password) this.body = {
      ...this.body,
      password: password
    };

    if(otpCode) this.body = {
      ...this.body,
      otp_code: otpCode
    }

    if(phone && countryCode) {
      this.body = {
        ...this.body,
        phone: phone,
        country_code: countryCode
      }
    }
    
    if(email) {
      this.body = {
        ...this.body,
        email: email
      }
    }

    if(isBlocked) {
      this.body = {
        ...this.body,
        is_blocked: isBlocked
      }
    }

    if(prefLanguage) this.body = {
      ...this.body,
      pref_langauge: prefLanguage
    }

    this.url = `${this.baseAPI}users`;
    this.method = 'PUT';
    return await this.request();
  }

  /**
   * 
   * SUBMIT DELETE REQUEST
   * 
   */

  async submitAccountDeletionRequest() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    this.url = `${this.baseAPI}users/request_deletion/create`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * SERVICE REQUESTERS //==//
   * 
   */

  /**
   * 
   * GET SERVICE REQUESTERS
   * 
   */

  async getServiceRequesters() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    if(this.isBlocked) query += '&isBlocked=' + this.isBlocked;
    if(this.searchTerm) query += '&searchTerm=' + this.searchTerm;

    this.url = `${this.baseAPI}users/service-requesters/general/list?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET SERVICE REQUESTER
   * 
   */

  async getServiceRequester() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.serviceRequester) {
      console.error('Missing serviceRequester');
      return null;
    }

    this.url = `${this.baseAPI}users/service-requesters/general/show/${this.serviceRequester}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * UPDATE SERVICE REQUESTER
   * 
   */

  async updateServiceRequester() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.serviceRequesterUuid)  {
      console.error('serviceRequesterUuid is required');
      return null;
    }
    
    var phone = this.phone || null;
    var email = this.email || null;
    var password = this.password || null;
    var isBlocked = this.is_blocked || null;
    var otp = this.otp || null;

    this.body = {};

    if(
      (this.phone || this.email) &&
      !this.otp
    ) {
      console.error('OTP is required to update phone or email');
      return null;
    }

    if(this.phone) {
      this.body = {
        ...this.body,
        phone: phone,
        otp: otp
      }
    }

    if(this.email) {
      this.body = {
        ...this.body,
        email: email,
        otp: otp
      }
    }

    if(this.password) {
      this.body = {
        ...this.body,
        password: password,
      }
    }

    if(isBlocked) {
      this.body = {
        ...this.body,
        is_blocked: isBlocked
      }
    }

    this.url = `${this.baseAPI}users/service-requesters/general/${this.serviceRequesterUuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * DELETE SERVICE REQUESTER
   * 
   */

  async deleteServiceRequester() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.serviceRequesterUuid) {
      console.error('Missing serviceRequesterUuid');
      return null;
    }

    this.url = `${this.baseAPI}users/service-requesters/general/${this.serviceRequesterUuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * GET SERVICE REQUESTER DOCUMENTS
   * 
   */

  async getServiceRequesterDocuments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.relatedTo) query += '&relatedTo=' + encodeURIComponent(this.relatedTo);
    if(this.memberId) query += '&memberId=' + this.memberId;
    if(this.serviceRequesterUuid) query += '&serviceRequesterUuid=' + this.serviceRequesterUuid;
    if(this.showDeleted) query += '&showDeleted=' + this.showDeleted;

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    this.url = `${this.baseAPI}users/service-requesters/documents/list/?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * DELETE SERVICE REQUESTER DOCUMENTS
   * 
   */

  async deleteServiceRequesterDocuments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.documentUuid) {
      console.error('documentUuid is required');
      return null; 
    }

    this.url = `${this.baseAPI}users/service-requesters/documents/${this.documentUuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * GET MEMBERS
   * 
   */

  async getMembers() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    var query = '';

    if(this.serviceReqeusterUuid) query += `&serviceRequesterUuid=${this.serviceReqeusterUuid}`

    if(this.onlyCompleted) query += '&onlyCompleted=' + this.onlyCompleted;
    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    this.url = `${this.baseAPI}users/service-requesters/members/general/list/?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET MEMBER
   * 
   */

  async getMember() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.memberId) {
      console.error( 'memberId is required.',  this.memberId );
      return null;
    }

    this.url = `${this.baseAPI}users/service-requesters/members/general/show/${this.memberId}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * CHECK MEMBER PROFILE COMPLETION
   * 
   */

  async checkMemberProfileCompletion() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.memberId) {
      console.error( 'memberId is required.',  this.memberId );
      return null;
    }

    this.url = `${this.baseAPI}users/service-requesters/members/general/check-profile-completion/${this.memberId}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * ADD MEMBER
   * 
   */

  async addMember() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.serviceReqeusterUuid)  {
      console.error('serviceReqeusterUuid is required');
      return null;
    }

    this.url = `${this.baseAPI}users/service-requesters/members/general/${this.serviceReqeusterUuid}`;
    
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * UPDATE MEMBER
   * 
   */

  async updateMember() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.memberId)  {
      console.error('memberId is required');
      return null;
    }
    
    var firstName = this.first_name || null;
    var middleName = this.middle_name || null;
    var lastName = this.last_name || null;
    var dob = this.dob || null;
    var gender = this.gender || null;
    var nationalityId = this.nationality_id || null;
    var legalIdType = this.legal_id_type || null;
    var legalId = this.legal_id || null;
    var maritalStatus = this.marital_status || null;
    var numberOfChildren = this.number_of_children || null;
    var relationship = this.relationship || null;
    var addressLabel = this.address_label || null;
    var address1 = this.address_1 || null;
    var address2 = this.address_2 || null;
    var serviceCityId = this.service_city_id || null;
    var defaultCoords = this.default_coords || null;
    var emergencyContactName = this.emergency_contact_name || null;
    var emergencyContactPhone = this.emergency_contact_phone || null;

    this.body = {};

    if(firstName) {
      this.body = {
        ...this.body,
        first_name: firstName
      }
    }

    if(middleName) {
      this.body = {
        ...this.body,
        middle_name: middleName
      }
    }

    if(lastName) {
      this.body = {
        ...this.body,
        last_name: lastName
      }
    }

    if(dob) {
      this.body = {
        ...this.body,
        dob: dob
      }
    }

    if(gender) {
      this.body = {
        ...this.body,
        gender: gender
      }
    }

    if(nationalityId) {
      this.body = {
        ...this.body,
        nationality_id: nationalityId
      }
    }

    if(legalIdType) {
      this.body = {
        ...this.body,
        legal_id_type: legalIdType
      }
    }

    if(legalId) {
      this.body = {
        ...this.body,
        legal_id: legalId
      }
    }

    if(maritalStatus) {
      this.body = {
        ...this.body,
        marital_status: maritalStatus
      }
    }

    if(numberOfChildren) {
      this.body = {
        ...this.body,
        number_of_children: numberOfChildren
      }
    }

    if(relationship) {
      this.body = {
        ...this.body,
        relationship: relationship
      }
    }

    if(addressLabel) {
      this.body = {
        ...this.body,
        address_label: addressLabel
      }
    }

    if(address1) {
      this.body = {
        ...this.body,
        address_1: address1
      }
    }

    if(address2) {
      this.body = {
        ...this.body,
        address_2: address2
      }
    }

    if(serviceCityId) {
      this.body = {
        ...this.body,
        service_city_id: serviceCityId
      }
    }

    if(defaultCoords) {
      this.body = {
        ...this.body,
        default_coords: defaultCoords
      }
    }

    if(emergencyContactName) {
      this.body = {
        ...this.body,
        emergency_contact_name: emergencyContactName
      }
    }

    if(emergencyContactPhone) {
      this.body = {
        ...this.body,
        emergency_contact_phone: emergencyContactPhone
      }
    }

    this.url = `${this.baseAPI}users/service-requesters/members/general/${this.memberId}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * DELETE MEMBER
   * 
   */
  
  async deleteMember(){
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.memberId){
      console.error("service city ID is not provided");
      return null;
    }

    this.url = `${this.baseAPI}users/service-requesters/members/general/${this.memberId}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * GET MEMBER INSURANCE INFORMATION
   * 
   */

   async getMemberInsurancesInformation() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.serviceReqeusterUuid) query += `&serviceReqeusterUuid=${this.serviceReqeusterUuid}`;
    if(this.memberId) query += `&memberId=${this.memberId}`;
    if(this.provider) query += `&provider=${this.provider}`;

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    this.url = `${this.baseAPI}users/service-requesters/members/insurance-information/list/?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET MEMBER INSURANCE INFORMATION
   * 
   */

  async getMemberInsuranceInformation() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.insuranceInformationId) {
      console.error('Missing insuranceInformationId');
      return null;
    }

    this.url = `${this.baseAPI}users/service-requesters/members/insurance-information/show/${this.insuranceInformationId}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * ADD MEMBER INSURANCE INFORMATION
   * 
   */

  async addMemberInsuranceInformation() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.memberId) {
      console.error('memberId is required');
      return null;
    }

    if(!this.number) {
      console.error('number is required');
      return null;
    }

    if(!this.provider) {
      console.error('provider is required');
      return null;
    }

    if(!this.expirationDate) {
      console.error('expirationDate is required');
      return null;
    }

    this.body = {
      number: this.number,
      provider: this.provider,
      expiration_date: this.expirationDate
    }

    this.url = `${this.baseAPI}users/service-requesters/members/insurance-information/${this.memberId}`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * UPDATE MEMBER INSURANCE INFORMATION
   * 
   */

  async updateMemberInsuranceInformation() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.insuranceInformationId) {
      console.error('insuranceInformationId is required');
      return null; 
    }

    this.body = {}

    if(this.provider) {
      this.body = {
        ...this.body,
        provider: this.provider
      }
    }

    if(this.number) {
      this.body = {
        ...this.body,
        number: this.number
      }
    }

    if(this.expirationDate) {
      this.body = {
        ...this.body,
        expiration_date: this.expirationDate
      }
    }

    this.url = `${this.baseAPI}users/service-requesters/members/insurance-information/${this.insuranceInformationId}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * DELETE MEMBER INSURANCE INFORMATION
   * 
   */

  async deleteMemberInsuranceInformation() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.insuranceInformationId) {
      console.error('Missing serviceId');
      return null;
    }

    this.url = `${this.baseAPI}users/service-requesters/members/insurance-information/${this.insuranceInformationId}`;
    this.method = 'DELETE';

    return await this.request();
  }


  /**
   * 
   * UPDATE MEMBER MEDICAL INFORMATION
   * 
   */

  async getMemberMedicalInformation() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.memberId)  {
      console.error('memberId is required');
      return null;
    }

    this.url = `${this.baseAPI}users/service-requesters/members/medical-information/${this.memberId}`;
    this.method = 'GET';

    return await this.request();
  }


  /**
   * 
   * UPDATE MEMBER MEDICAL INFORMATION
   * 
   */

  async updateMemberMedicalInformation() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.memberId)  {
      console.error('memberId is required');
      return null;
    }
    
    var height = this.height || null;
    var weight = this.weight || null;
    var medicalConditions = this.medical_conditions || null;
    var surgicalHistory = this.surgical_history || null;
    var medicationRoutine = this.medication_routine || null;
    var trauma = this.trauma || null;
    var generalCondition = this.general_condition || null;
    var typeOfWorkId = this.type_of_work_id || null;
    var exerciseRoutineId = this.exercise_routine_id || null;
    var levelOfActivitiesId = this.level_of_activities_id || null;
    var dietId = this.diet_id || null;
    var smoking = this.smoking || null;
    var employed = this.employed || null;

    this.body = {};

    if(height) {
      this.body = {
        ...this.body,
        height: height
      }
    }

    if(weight) {
      this.body = {
        ...this.body,
        weight: weight
      }
    }

    if(medicalConditions) {
      this.body = {
        ...this.body,
        medical_conditions: medicalConditions
      }
    }

    if(surgicalHistory) {
      this.body = {
        ...this.body,
        surgical_history: surgicalHistory
      }
    }

    if(medicationRoutine) {
      this.body = {
        ...this.body,
        medication_routine: medicationRoutine
      }
    }

    if(trauma) {
      this.body = {
        ...this.body,
        trauma: trauma
      }
    }

    if(generalCondition) {
      this.body = {
        ...this.body,
        general_condition: generalCondition
      }
    }

    if(typeOfWorkId) {
      this.body = {
        ...this.body,
        type_of_work_id: typeOfWorkId
      }
    }

    if(exerciseRoutineId) {
      this.body = {
        ...this.body,
        exercise_routine_id: exerciseRoutineId
      }
    }

    if(levelOfActivitiesId) {
      this.body = {
        ...this.body,
        level_of_activities_id: levelOfActivitiesId
      }
    }

    if(dietId) {
      this.body = {
        ...this.body,
        diet_id: dietId
      }
    }

    if(smoking) {
      this.body = {
        ...this.body,
        smoking: smoking
      }
    }

    if(employed) {
      this.body = {
        ...this.body,
        employed: employed
      }
    }

    this.url = `${this.baseAPI}users/service-requesters/members/medical-information/${this.memberId}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * GET MEMBER MEDICAL INFORMATION CHRONIC DISEASES
   * 
   */

   async getMemberMedicalInformationChronicDiseases() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.memberId) {
      console.error('memberId is required');
      return null; 
    }

    this.url = `${this.baseAPI}users/service-requesters/members/medical-information-chronic_diseases/list/${this.memberId}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * TOGGLE MEMBER MEDICAL INFORMATION CHRONIC DISEASES
   * 
   */

   async toggleMemberMedicalInformationChronicDiseas() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.memberId) {
      console.error('memberId is required');
      return null; 
    }

    if(!this.chronicDiseaseId) {
      console.error('chronicDiseaseId is required');
      return null; 
    }

    this.body = {
      chronic_disease_id: this.chronicDiseaseId
    }

    this.url = `${this.baseAPI}users/service-requesters/members/medical-information-chronic_diseases/toggle/${this.memberId}`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * GET MEMBER MEDICAL INFORMATION ALLERGIES
   * 
   */

   async getMemberMedicalInformationAllergies() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.memberId) {
      console.error('memberId is required');
      return null; 
    }

    this.url = `${this.baseAPI}users/service-requesters/members/medical-information-allergies/list/${this.memberId}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * TOGGLE MEMBER MEDICAL INFORMATION ALLERGIES
   * 
   */

   async toggleMemberMedicalInformationAllergies() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.memberId) {
      console.error('memberId is required');
      return null; 
    }

    if(!this.allergyId) {
      console.error('allergyId is required');
      return null; 
    }

    this.body = {
      allergy_id: this.allergyId
    }

    this.url = `${this.baseAPI}users/service-requesters/members/medical-information-allergies/toggle/${this.memberId}`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * CAREGIVERS //==//
   * 
   */

   /**
   * 
   * GET CAREGIVERS
   * 
   */

    async getCaregivers() {
      if(!this.baseAPI) {
        console.error('baseAPI path is not set');
        return null; 
      }
  
      if(!this.authToken) {
        console.error('authToken is required');
        return null; 
      }

      var query = '';

      if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
      if(this.currentPage) query += '&page=' + this.currentPage;
      if(this.orderBy) query += '&orderBy=' + this.orderBy;
      if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

      if(this.isBlocked) query += '&isBlocked=' + this.isBlocked;
      if(this.searchTerm) query += '&searchTerm=' + this.searchTerm;
      if(this.approvedStatus) query += '&approvedStatus=' + this.approvedStatus;
      if(this.serviceCityId) query += '&serviceCityId=' + this.serviceCityId;
      if(this.servicesList) query += '&servicesList=' + this.servicesList;
      if(this.availableSchedule) query += '&availableSchedule=' + this.availableSchedule;

      if(this.showAll) query += '&showAll=y';

      if(this.calculateDistance) query += '&calculateDistance=y';
      if(this.sortByDistance) query += '&sortByDistance=y';
      if(this.sourceCoordsLat) query += '&sourceCoordsLat=' + this.sourceCoordsLat;
      if(this.sourceCoordsLng) query += '&sourceCoordsLng=' + this.sourceCoordsLng;
  
      this.url = `${this.baseAPI}users/caregivers/general/list?${query}`;
      this.method = 'GET';
  
      return await this.request();
    }

  /**
   * 
   * GET CAREGIVER
   * 
   */

  async getCaregiver() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }
    
    var errors = false;
    var caregiverUuid = this.caregiverUuid || null;

    if(!caregiverUuid) {
      console.error(
        'Caregiver UUID is required.',
        caregiverUuid
      );
      errors = true;
    }

    if(errors) { 
      console.error('1x00015488'); 
      return false;
    }

    this.url = `${this.baseAPI}users/caregivers/general/show/${caregiverUuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET CAREGIVER SERVICES
   * 
   */

  async getCaregiverServices() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.pending) query += '&pending=' + this.pending;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.searchTerm) query += '&searchTerm=' + this.searchTerm;
    if(this.caregiverUuid) query += '&caregiverUuid=' + this.caregiverUuid;

    this.url = `${this.baseAPI}users/caregivers/services/list?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * SET CAREGIVER SERVICES
   * 
   */

  async setCaregiverServices() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.serviceId) {
      console.error('serviceId is required');
      return null; 
    }

    this.body = {
      service_id: this.serviceId,
      caregiver_uuid: this.caregiverUuid,
    }

    this.url = `${this.baseAPI}users/caregivers/services/set`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * UNSET CAREGIVER SERVICES
   * 
   */

  async unsetCaregiverServices() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.serviceId) {
      console.error('serviceId is required');
      return null; 
    }
    var query = '';

    if(this.caregiverUuid) query += '&caregiverUuid=' + this.caregiverUuid;

    this.url = `${this.baseAPI}users/caregivers/services/unset/${this.serviceId}/?${query}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * APPROVE CAREGIVER SERVICES
   * 
   */

  async approveCaregiverServices() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.serviceId) {
      console.error('serviceId is required');
      return null; 
    }

    if(!this.caregiverUuid) {
      console.error('caregiverUuid is required');
      return null; 
    }

    this.body = {
      service_id: this.serviceId,
      caregiver_uuid: this.caregiverUuid,
      action_type: this.actionType
    }

    this.url = `${this.baseAPI}users/caregivers/services/approve/`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * REJECT CAREGIVER SERVICES
   * 
   */

  async rejectCaregiverServices() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.serviceId) {
      console.error('serviceId is required');
      return null; 
    }

    if(!this.caregiverUuid) {
      console.error('caregiverUuid is required');
      return null; 
    }

    this.body = {
      service_id: this.serviceId,
      caregiver_uuid: this.caregiverUuid
    }

    this.url = `${this.baseAPI}users/caregivers/services/reject`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * GET CAREGIVER SCHEDULE
   * 
   */

  async getCaregiverSchedule() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.caregiverUuid) query += '&caregiverUuid=' + this.caregiverUuid;
    if(this.fromDate) query += '&fromDate=' + this.fromDate;
    if(this.pending) query += '&pending=' + this.pending;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.searchTerm) query += '&searchTerm=' + this.searchTerm;
    if(this.showAll) query += '&showAll=' + this.showAll;


    this.url = `${this.baseAPI}users/caregivers/schedule/list?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * SET CAREGIVER SCHEDULE
   * 
   */

  async setCaregiverSchedule() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.date) {
      console.error('date is required');
      return null; 
    }

    if(!this.period) {
      console.error('period is required');
      return null; 
    }

    this.body = {
      date: this.date,
      period: this.period
    }

    this.url = `${this.baseAPI}users/caregivers/schedule/set`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * UNSET CAREGIVER SCHEDULE
   * 
   */

  async unsetCaregiverSchedule() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.scheduleId) {
      console.error('scheduleId is required');
      return null; 
    }

    this.url = `${this.baseAPI}users/caregivers/schedule/unset/${this.scheduleId}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * APPROVE CAREGIVER SCHEDULE
   * 
   */

  async approveCaregiverSchedule() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.scheduleId) {
      console.error('scheduleId is required');
      return null; 
    }
    
    this.body = {
      schedule_id: this.scheduleId
    }

    this.url = `${this.baseAPI}users/caregivers/schedule/approve`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * REJECT CAREGIVER SCHEDULE
   * 
   */

  async rejectCaregiverSchedule() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.scheduleId) {
      console.error('scheduleId is required');
      return null; 
    }
    
    this.body = {
      schedule_id: this.scheduleId
    }

    this.url = `${this.baseAPI}users/caregivers/schedule/reject`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * UPDATE CAREGIVER
   * 
   */

  async updateCaregiver() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }
    
    var errors = false;
    var namePrefix = this.name_prefix || null;
    var firstNameLocal = this.first_name_local || null
    var middleNameLocal = this.middle_name_local || null
    var lastNameLocal = this.last_name_local || null
    var caregiverUuid = this.caregiverUuid || null;
    var phone = this.phone || null;
    var email = this.email || null;
    var password = this.password || null;
    var commission = this.commission || null;
    var otp = this.otp || null;
    var gender = this.gender || null;
    var nationalityId = this.nationality_id || null;
    var legalIdType = this.legal_id_type || null;
    var legalId = this.legal_id || null;
    var dob = this.dob || null;
    var jobTitleLocal = this.job_title_local || null;
    var scfhsNumber = this.scfhs_number || null;
    var iban = this.iban || null;
    var bankName = this.bank_name || null;
    var address1 = this.address_1 || null;
    var address2 = this.address_2 || null;
    var serviceCityId = this.service_city_id || null;
    var baseCoords = this.base_coords || null;
    var biography = this.biography || null;
    var spokenLanguages = this.spoken_languages || null;
    var isBlocked = this.is_blocked || null;
    var cancellationCounter = this.cancellation_counter || null;
    var approvedAt = this.approved_at || null;

    this.body = {};

    if(!caregiverUuid) {
      console.error(
        'Caregiver UUID is required.',
        caregiverUuid
      );

      errors = true;
    }

    if(
      (phone || email) &&
      !otp
    ) {
      console.error('Updating phone or email requires OTP')
      return null;
    }

    if(phone) {
      this.body = {
        ...this.body,
        phone: phone
      }
    }

    if(password) {
      this.body = {
        ...this.body,
        password: password
      }
    }

    if(commission) {
      this.body = {
        ...this.body,
        commission: commission
      }
    }

    if(firstNameLocal) {
      this.body = {
        ...this.body,
        first_name_local: firstNameLocal
      }
    }

    if(middleNameLocal) {
      this.body = {
        ...this.body,
        middle_name_local: middleNameLocal
      }
    }

    if(lastNameLocal) {
      this.body = {
        ...this.body,
        last_name_local: lastNameLocal
      }
    }

    if(email) {
      this.body = {
        ...this.body,
        email: email
      }
    }

    if(otp) {
      this.body = {
        ...this.body,
        otp: otp
      }
    }

    if(namePrefix) {
      this.body = {
        ...this.body,
        name_prefix: namePrefix
      }
    }

    if(gender) {
      this.body = {
        ...this.body,
        gender: gender
      }
    }

    if(nationalityId) {
      this.body = {
        ...this.body,
        nationality_id: nationalityId
      }
    }

    if(legalIdType) {
      this.body = {
        ...this.body,
        legal_id_type: legalIdType
      }
    }

    if(legalId) {
      this.body = {
        ...this.body,
        legal_id: legalId
      }
    }

    if(dob) {
      this.body = {
        ...this.body,
        dob: dob
      }
    }

    if(jobTitleLocal) {
      this.body = {
        ...this.body,
        job_title_local: jobTitleLocal
      }
    }

    if(scfhsNumber) {
      this.body = {
        ...this.body,
        scfhs_number: scfhsNumber
      }
    }

    if(iban) {
      this.body = {
        ...this.body,
        iban: iban
      }
    }

    if(bankName) {
      this.body = {
        ...this.body,
        bank_name: bankName
      }
    }

    if(address1) {
      this.body = {
        ...this.body,
        address_1: address1
      }
    }

    if(address2) {
      this.body = {
        ...this.body,
        address_2: address2
      }
    }

    if(serviceCityId) {
      this.body = {
        ...this.body,
        service_city_id: serviceCityId
      }
    }

    if(baseCoords) {
      this.body = {
        ...this.body,
        base_coords: baseCoords
      }
    }

    if(biography) {
      this.body = {
        ...this.body,
        biography: biography
      }
    }

    if(spokenLanguages) {
      this.body = {
        ...this.body,
        spoken_languages: spokenLanguages
      }
    }

    if(isBlocked) {
      this.body = {
        ...this.body,
        is_blocked: isBlocked
      }
    }

    if(cancellationCounter) {
      this.body = {
        ...this.body,
        cancellation_counter: cancellationCounter
      }
    }
    
    if(approvedAt) {
      this.body = {
        ...this.body,
        approved_at: approvedAt
      }
    }

    if(errors) { 
      console.error('1x00015482'); 
      return false;
    }

    this.url = `${this.baseAPI}users/caregivers/general/${caregiverUuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * DELETE CAREGIVER
   * 
   */

  async deleteCaregiver() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var caregiverUuid = this.caregiverUuid || null;

    if(!caregiverUuid) {
      console.error(
        'Caregiver UUID is required.',
        caregiverUuid
      );
    }

    this.url = `${this.baseAPI}users/caregivers/general/${caregiverUuid}`;
    this.method = 'DELETE';

    return await this.request();
  }
  

  /**
   * 
   * GET PENDING UPDATE REQUESTS
   * 
   */

   async getCaregiverPendingUpdateRequests() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;
    if(this.pending) query += '&pending=' + this.pending;

    if(this.searchTerm) query += '&searchTerm=' + this.searchTerm;

    this.url = `${this.baseAPI}users/caregivers/update-requests/list?${query}`;
    this.method = 'GET';

    return await this.request();
  }  

  /**
   * 
   * APPROVE PENDING UPDATE REQUESTS
   * 
   */

  async approveCaregiverPendingUpdateRequests() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.updateRequestId) {
      console.error('updateRequestId is required');
      return null; 
    }

    this.body = {
      update_request_id: this.updateRequestId
    }

    this.url = `${this.baseAPI}users/caregivers/update-requests/approve/`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * REJECT PENDING UPDATE REQUESTS
   * 
   */

  async rejectCaregiverPendingUpdateRequests() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.updateRequestId) {
      console.error('updateRequestId is required');
      return null; 
    }

    this.body = {
      update_request_id: this.updateRequestId
    }

    this.url = `${this.baseAPI}users/caregivers/update-requests/reject`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * GET CAREGIVER DOCUMENTS
   * 
   */

  async getCaregiverDocuments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.relatedTo) query += '&relatedTo=' + encodeURIComponent(this.relatedTo);
    if(this.caregiverUuid) query += '&caregiverUuid=' + this.caregiverUuid;
    if(this.showDeleted) query += '&showDeleted=' + this.showDeleted;

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    this.url = `${this.baseAPI}users/caregivers/documents/list/?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * DELETE CAREGIVER DOCUMENTS
   * 
   */

  async deleteCaregiverDocuments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.documentUuid) {
      console.error('documentUuid is required');
      return null; 
    }

    this.url = `${this.baseAPI}users/caregivers/documents/${this.documentUuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * APPROVE CAREGIVER DOCUMENT
   * 
   */

  async approveCaregiverDocuments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.documentUuid) {
      console.error('documentUuid is required');
      return null; 
    }

    this.body = {
      document_uuid: this.documentUuid
    }

    this.url = `${this.baseAPI}users/caregivers/documents/approve/`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * REJECT CAREGIVER DOCUMENT
   * 
   */

  async rejectCaregiverDocuments() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.documentUuid) {
      console.error('documentUuid is required');
      return null; 
    }

    this.body = {
      document_uuid: this.documentUuid
    }

    this.url = `${this.baseAPI}users/caregivers/documents/reject`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * GET REFERRALS
   * 
   */

  async getReferrals() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.referredByUuid) query += '&referredByUuid=' + this.referredByUuid;
    if(this.referredUuid) query += '&referredUuid=' + this.referredUuid;
    if(this.bookingUuid) query += '&bookingUuid=' + this.bookingUuid;
    if(this.paidOut) query += '&paidOut=' + this.paidOut;
    if(this.showDeleted) query += '&showDeleted=' + this.showDeleted;
    if(this.showAll) query += '&showAll=' + this.showAll;

    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;

    this.url = `${this.baseAPI}users/caregivers/referrals/list?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * UPDATE REFERRAL
   * 
   */

  async updateReferral() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.referredUuid) {
      console.error('referred_to is required');
      return null; 
    }

    if(!this.referredByUuid) {
      console.error('referred_by is required');
      return null; 
    }

    this.body = {
      referred_uuid: this.referredUuid,
      referred_by_uuid: this.referredByUuid
    }

    this.url = `${this.baseAPI}users/caregivers/referrals/update`;
    this.method = 'PUT';

    return await this.request();
  }
  

  /**
   * 
   * CHECK CAREGIVER REFERRAL
   * 
   */

  async checkCaregiverReferral() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.referredCountryCode) {
      console.error('referredCountryCode is required');
      return null; 
    }

    if(!this.referredPhone) {
      console.error('referredPhone is required');
      return null; 
    }

    if(!this.referredByCountryCode) {
      console.error('referredCountryCode is required');
      return null; 
    }

    if(!this.referredByPhone) {
      console.error('referredPhone is required');
      return null; 
    }

    var query = '';

    query += `&referredCountryCode=${this.referredCountryCode}`;
    query += `&referredPhone=${this.referredPhone}`;
    query += `&referredByCountryCode=${this.referredByCountryCode}`;
    query += `&referredByPhone=${this.referredByPhone}`;

    this.url = `${this.baseAPI}users/caregivers/referrals/?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * ADD REFERRAL RECORD
   * 
   */

  async addCaregiverReferral() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.referredByCountryCode) {
      console.error('referredCountryCode is required');
      return null; 
    }

    if(!this.referredByPhone) {
      console.error('referredPhone is required');
      return null; 
    }

    this.body = {
      referred_by_countryCode: this.referredByCountryCode,
      referred_by_phone: this.referredByPhone
    }

    this.url = `${this.baseAPI}users/caregivers/referrals/`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * BOOKINGS //===//
   * 
   */

  /**
   * 
   * GET BOOKINGS
   * 
   */

  async getBookings() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.status) query += '&status=' + this.status;
    if(this.bookingUuid) query += '&bookingUuid=' + this.bookingUuid;

    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;
    if(this.searchTerm) query += '&searchTerm=' + this.searchTerm;
    if(this.paidOut) query += '&paidOut=' + this.paidOut;
    if(this.fromDate) query += '&fromDate=' + this.fromDate;
    if(this.toDate) query += '&toDate=' + this.toDate;

    if(this.memberId) query += '&memberId=' + this.memberId;

    this.url = `${this.baseAPI}bookings/general/list?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET BOOKING
   * 
   */

  async getBooking() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.bookingUuid) {
      console.error('bookingUuid is required');
      return null; 
    }

    this.url = `${this.baseAPI}bookings/general/show/${this.bookingUuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * PREPARE BOOKING
   * 
   */

  async prepareBooking() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.memberId) {
      console.error('memberId is required');
      return null; 
    }

    if(!this.caregiverUuid) {
      console.error('caregiverUuid is required');
      return null; 
    }

    if(!this.location) {
      console.error('location is required');
      return null; 
    }

    if(!this.serviceCityId) {
      console.error('serviceCityId is required');
      return null; 
    }

    if(!this.symptoms) {
      console.error('symptoms is required');
      return null; 
    }

    if(!this.scheduleIds) {
      console.error('scheduleIds is required');
      return null; 
    }

    if(!this.servicesIds) {
      console.error('servicesIds is required');
      return null; 
    }

    this.body = {
      member_id: this.memberId,
      caregiver_uuid: this.caregiverUuid,
      location: this.location,
      service_city_id: this.serviceCityId,
      symptoms: this.symptoms,
      schedule_ids: this.scheduleIds,
      services_ids: this.servicesIds,
    }

    this.url = `${this.baseAPI}bookings/general/prepare`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * UPDATE BOOKING
   * 
   */

  async updateBooking() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.bookingUuid) {
      console.error('bookingUuid is required');
      return null; 
    }
    
    var status = this.status || null;
    var cancellationNote = this.cancellation_note || null;
    var symptoms = this.symptoms || null;
    var paidOutAt = this.paid_out_at || null;

    this.body = {};

    if(status) {
      this.body = {
        ...this.body,
        status: status
      }
    }

    if(cancellationNote) {
      this.body = {
        ...this.body,
        cancellation_note: cancellationNote
      }
    }

    if(symptoms) {
      this.body = {
        ...this.body,
        symptoms: symptoms
      }
    }

    if(paidOutAt) {
      this.body = {
        ...this.body,
        paid_out_at: paidOutAt
      }
    }

    this.url = `${this.baseAPI}bookings/general/${this.bookingUuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * ACCEPT BOOKING
   * 
   */

  async acceptBooking() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.bookingUuid) {
      console.error('bookingUuid is required');
      return null; 
    }

    this.url = `${this.baseAPI}bookings/general/accept/${this.bookingUuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * REJECT BOOKING SESSION ADDITIONAL CHARGES
   * 
   */

  async rejectBooking() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.bookingUuid) {
      console.error('bookingUuid is required');
      return null; 
    }

    this.url = `${this.baseAPI}bookings/general/reject/${this.bookingUuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * DELETE BOOKING
   * 
   */

  async deleteBooking() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.bookingUuid) {
      console.error('bookingUuid is required');
      return null; 
    }

    this.url = `${this.baseAPI}bookings/general/${this.bookingUuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * SESSIONS //===//
   * 
   */

  /**
   * 
   * GET BOOKING SESSION
   * 
   */

  async getBookingSession() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.sessionId) {
      console.error('sessionId is required');
      return null; 
    }

    this.url = `${this.baseAPI}bookings/sessions/show/${this.sessionId}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET BOOKING SESSIONS
   * 
   */

  async getBookingSessions() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.bookingUuid) query += '&bookingUuid=' + this.bookingUuid;
    if(this.sessionId) query += '&sessionId=' + this.sessionId;
    if(this.status) query += '&status=' + this.status;

    if(this.unratedBy) {
      if(this.unratedBy === 'service requester') {
        query += '&unratedBy=serviceRequester';
      }
      else {
        query += '&unratedBy=' + this.unratedBy;
      }
    }

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    this.url = `${this.baseAPI}bookings/sessions/list/?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET UPCOMING / SCHEDULED SESSIONS
   * 
   */

  async getScheduledSessions() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.caregiverUuid) query += '&caregiverUuid=' + this.caregiverUuid;
    if(this.serviceReqeusterUuid) query += '&serviceReqeusterUuid=' + this.serviceReqeusterUuid;
    if(this.memberId) query += '&memberId=' + this.memberId;
    if(this.status) query += '&status=' + this.status;

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    this.url = `${this.baseAPI}bookings/sessions/list/?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * UPDATE BOOKING SESSION
   * 
   */

  async updateBookingSession() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.sessionId) {
      console.error('sessionId is required');
      return null; 
    }
    
    var status = this.status || null;
    var cancellationNote = this.cancellation_note || null;
    var serviceRequesterRating = this.service_requester_rating || null;
    var serviceRequesterFeedback = this.service_requester_feedback || null;
    var caregiverRating = this.caregiver_rating || null;
    var caregiverFeedback = this.caregiver_feedback || null;
    var jobTitleLocal = this.job_title_local || null;
    var notes = this.notes || null;
    var planAhead = this.plan_ahead || null;
    var prescription = this.prescription || null;
    var caregiverArrivalStatus = this.caregiver_arrival_status || null;
    var caregiverLiveCoords = this.caregiver_live_coords || null;

    this.body = {};

    if(status) {
      this.body = {
        ...this.body,
        status: status
      }
    }

    if(cancellationNote) {
      this.body = {
        ...this.body,
        cancellation_note: cancellationNote
      }
    }

    if(serviceRequesterRating) {
      this.body = {
        ...this.body,
        service_requester_rating: serviceRequesterRating
      }
    }

    if(serviceRequesterFeedback) {
      this.body = {
        ...this.body,
        service_requester_feedback: serviceRequesterFeedback
      }
    }

    if(caregiverRating) {
      this.body = {
        ...this.body,
        caregiver_rating: caregiverRating
      }
    }

    if(caregiverFeedback) {
      this.body = {
        ...this.body,
        caregiver_feedback: caregiverFeedback
      }
    }

    if(jobTitleLocal) {
      this.body = {
        ...this.body,
        job_title_local: jobTitleLocal
      }
    }

    if(notes) {
      this.body = {
        ...this.body,
        notes: notes
      }
    }

    if(planAhead) {
      this.body = {
        ...this.body,
        plan_ahead: planAhead
      }
    }

    if(prescription) {
      this.body = {
        ...this.body,
        prescription: prescription
      }
    }

    if(caregiverArrivalStatus) {
      this.body = {
        ...this.body,
        caregiver_arrival_status: caregiverArrivalStatus
      }
    }

    if(caregiverLiveCoords) {
      this.body = {
        ...this.body,
        caregiver_live_coords: caregiverLiveCoords
      }
    }

    this.url = `${this.baseAPI}bookings/sessions/${this.sessionId}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * GET BOOKING SESSION MEDICAL REPORT
   * 
   */

  async getBookingSessionMedicalReports() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.sessionId) {
      console.error('sessionId is required');
      return null; 
    }

    var query = '';

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    this.url = `${this.baseAPI}bookings/sessions/medical-reports/list/${this.sessionId}/?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * DELETE BOOKING SESSION MEDICAL REPORT
   * 
   */

  async deleteBookingSessionMedicalReport() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.medicalReportUuid) {
      console.error('medicalReportUuid is required');
      return null; 
    }

    this.url = `${this.baseAPI}bookings/sessions/medical-reports/${this.medicalReportUuid}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * GET BOOKING SESSION ADDITIONAL CHARGES
   * 
   */

  async getBookingSessionAdditionalCharges() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.bookingUuid) query += '&bookingUuid=' + this.bookingUuid;
    if(this.sessionId) query += '&sessionId=' + this.sessionId;
    if(this.searchTerm) query += '&searchTerm=' + this.searchTerm;
    if(this.pending) query += '&pending=' + this.pending;
    if(this.rejected) query += '&rejected=' + this.rejected;
    if(this.approved) query += '&approved=' + this.approved;
    if(this.paid) query += '&paid=' + this.paid;
    if(this.unpaid) query += '&unpaid=' + this.unpaid;
    
    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    this.url = `${this.baseAPI}bookings/sessions/additional-charges/list/?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * ADD BOOKING SESSION ADDITIONAL CHARGES
   * 
   */

  async addBookingSessionAdditionalCharge() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.sessionId) {
      console.error('sessionId is required');
      return null; 
    }

    if(!this.additionalChargeId) {
      console.error('additionalChargeId is required');
      return null; 
    }

    this.body = {
      sessionId: this.sessionId,
      additional_charge_id: this.additionalChargeId
    }

    if(this.active) {
      this.body = {
        ...this.body,
        active: this.active
      }
    }

    this.url = `${this.baseAPI}bookings/sessions/additional-charges/${this.sessionId}`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * APPROVE BOOKING SESSION ADDITIONAL CHARGES
   * 
   */

  async approveBookingSessionAdditionalCharges() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.additionalChargeId) {
      console.error('additionalChargeId is required');
      return null; 
    }

    this.body = {
      additional_charge_id: this.additionalChargeId
    }

    this.url = `${this.baseAPI}bookings/sessions/additional-charges/approve`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * REJECT BOOKING SESSION ADDITIONAL CHARGES
   * 
   */

  async rejectBookingSessionAdditionalCharges() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.additionalChargeId) {
      console.error('additionalChargeId is required');
      return null; 
    }

    this.body = {
      additional_charge_id: this.additionalChargeId
    }

    this.url = `${this.baseAPI}bookings/sessions/additional-charges/reject`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * DELETE BOOKING SESSION MEDICAL REPORT
   * 
   */

  async cancelBookingSessionAdditionalCharges() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.additionalChargeId) {
      console.error('additionalChargeId is required');
      return null; 
    }

    this.body = {
      additional_charge_id: this.additionalChargeId
    }

    this.url = `${this.baseAPI}bookings/sessions/additional-charges/cancel`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * SYSTEM //===//
   * 
   */

  /**
   * 
   * GENERAL
   * 
   */

  async geodecode() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.lat){
      console.error("latitude (lat) is not provided");
      return null;
    }

    if(!this.lng){
      console.error("longitude (lng) is not provided");
      return null;
    }

    this.url = `${this.baseAPI}system/general/geodecode/?lat=${this.lat}&&lng=${this.lng}&&lang=${this.lang}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * SERVICE CITIES //===//
   * 
   */

  /**
   * 
   * GET SERVICE CITY ID FROM COORDS
   * 
   */

   async getServiceCityIdFromCoords() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.lat){
      console.error("latitude (lat) is not provided");
      return null;
    }

    if(!this.lng){
      console.error("longitude (lng) is not provided");
      return null;
    }

    this.url = `${this.baseAPI}system/service-cities/from-coords/?lat=${this.lat}&&lng=${this.lng}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET SERVICE CITIES
   * 
   */

   async getServiceCities() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;
    
    if(this.searchTerm) query += '&searchTerm=' + this.searchTerm;

    if(this.authToken)
      this.url = `${this.baseAPI}system/service-cities/list?${query}`;
    else
      this.url = `${this.baseAPI}system/service-cities/guests/list?${query}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET SERVICE CITY
   * 
   */

   async getServiceCity() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.serviceCityId) {
      console.error('serviceCityId is required');
      return null; 
    }

    if(this.authToken)
      this.url = `${this.baseAPI}system/service-cities/${this.serviceCityId}`;
    else
      this.url = `${this.baseAPI}system/service-cities/guests/${this.serviceCityId}`;

    this.method = 'GET';

    return await this.request();
  }


  /**
   * 
   * POST SERVICE CITIES
   * 
   */

  async addServiceCity(){
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.city){
      console.error("city name is not provided");
      return null;
    }

    if(!this.state){
      console.error("state name is not provided");
      return null;
    }

    if(!this.country){
      console.error("country name is not provided");
      return null;
    }

    if(!this.local){
      console.error("serviceLocal is not provided");
      return null;
    }

    if(typeof this.listingOrder !== 'number'){
      console.error("listingOrder is not provided");
      return null;
    }

    var serviceCity = this.city || null;
    var serviceState = this.state || null;
    var serviceCountry = this.country || null;
    var serviceLocal = this.local || null;
    var listingOrder = this.listingOrder || null;

    this.body = {};

    if(serviceCity) {
      this.body = {
        ...this.body,
        city: serviceCity
      }
    }

    if(serviceState) {
      this.body = {
        ...this.body,
        state: serviceState
      }
    }

    if(serviceCountry) {
      this.body = {
        ...this.body,
        country: serviceCountry
      }
    }

    if(serviceLocal) {
      this.body = {
        ...this.body,
        local: serviceLocal
      }
    }

    if(typeof this.listingOrder === 'number') {
      this.body = {
        ...this.body,
        listing_order: listingOrder
      }
    }

    this.url = `${this.baseAPI}system/service-cities/`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * PUT SERVICE CITIES
   * 
   */
  
   async updateServiceCity(){
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var serviceCityId = this.serviceCityId || null;
    var serviceCity = this.city || null;
    var serviceState = this.state || null;
    var serviceCountry = this.country || null;
    var serviceLocal = this.local || null;
    var listingOrder = this.listingOrder || null;

    this.body = {};

    if(serviceCityId) {
      this.body = {
        ...this.body,
        serviceCityId: serviceCityId
      }
    }

    if(serviceCity) {
      this.body = {
        ...this.body,
        city: serviceCity
      }
    }

    if(serviceState) {
      this.body = {
        ...this.body,
        state: serviceState
      }
    }

    if(serviceCountry) {
      this.body = {
        ...this.body,
        country: serviceCountry
      }
    }


    if(serviceLocal) {
      this.body = {
        ...this.body,
        local: serviceLocal
      }
    }

    if(listingOrder) {
      this.body = {
        ...this.body,
        listing_order: listingOrder
      }
    }
    
    this.url = `${this.baseAPI}system/service-cities/${this.serviceCityId}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * DELETE SERVICE CITIES
   * 
   */
  
  async deleteServiceCity(){
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.serviceCityId){
      console.error("service city ID is not provided");
      return null;
    }

    this.url = `${this.baseAPI}system/service-cities/${this.serviceCityId}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * SETTINGS //===//
   * 
   */

  /**
   * 
   * GET SETTING
   * 
   */

   async getSetting() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.key){
      console.error("key is not provided");
      return null;
    }

    if(this.authToken)
      this.url = `${this.baseAPI}system/settings/show/${this.key}`;
    else
      this.url = `${this.baseAPI}system/settings/guests/show/${this.key}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET SETTINGS 
   * 
   */

  async getSettings() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    this.url = `${this.baseAPI}system/settings/list`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * PUT SETTINGS 
   * 
   */

   async updateSettings() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var key = this.key|| null;

    this.body = {};

    if(key) {
      this.body = key
    }

    this.url = `${this.baseAPI}system/settings`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   *  DIETS //===//
   * 
   */

  /**
   * 
   * GET DIET
   * 
   */

   async getDiet() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.dietId){
      console.error("dietId is not provided");
      return null;
    }

    if(this.authToken)
      this.url = `${this.baseAPI}system/diets/show/${this.dietId}`;
    else
      this.url = `${this.baseAPI}system/diets/guests/show${this.dietId}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET DIETS 
   * 
   */

   async getDiets() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    if(this.authToken)
      this.url = `${this.baseAPI}system/diets/list?${query}`;
    else
      this.url = `${this.baseAPI}system/diets/guests/list?${query}`;

    this.method = 'GET';

    return await this.request();
  }

   /**
   * 
   *  EXERCISE ROUTINE //===//
   * 
   */

  /**
   * 
   * GET EXERCISE ROUTINE 
   * 
   */

   async getExerciseRoutine() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.exerciseRoutineId){
      console.error("exerciseRoutineId is not provided");
      return null;
    }

    if(this.authToken)
      this.url = `${this.baseAPI}system/exercise-routine/show/${this.exerciseRoutineId}`;
    else
      this.url = `${this.baseAPI}system/exercise-routine/guests/show/${this.exerciseRoutineId}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET EXERCISE ROUTINES 
   * 
   */

   async getExerciseRoutines() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    if(this.authToken)
      this.url = `${this.baseAPI}system/exercise-routine/list?${query}`;
    else
      this.url = `${this.baseAPI}system/exercise-routine/guests/list?${query}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   *  LEVEL OF ACTIVITIES //===//
   * 
   */

  /**
   * 
   * GET LEVEL OF ACTIVITY
   * 
   */

   async getLevelOfActivity() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.levelOfActivityId){
      console.error("levelOfActivitiesId is not provided");
      return null;
    }

    if(this.authToken)
      this.url = `${this.baseAPI}system/level-of-activities/show/${this.levelOfActivityId}`;
    else
      this.url = `${this.baseAPI}system/level-of-activities/guests/show/${this.levelOfActivityId}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET LEVEL OF ACTIVITIES
   * 
   */

   async getLevelOfActivities() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    if(this.authToken)
      this.url = `${this.baseAPI}system/level-of-activities/list?${query}`;
    else
      this.url = `${this.baseAPI}system/level-of-activities/guests/list?${query}`;

    this.method = 'GET';

    return await this.request();
  }

   /**
   * 
   *  CHRONIC DISEASES //===//
   * 
   */

  /**
   * 
   * GET CHRONIC DISEASE
   * 
   */

   async getChronicDisease() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.chronicDiseaseId){
      console.error("chronicDiseaseId is not provided");
      return null;
    }

    if(this.authToken)
      this.url = `${this.baseAPI}system/chronic-diseases/show/${this.chronicDiseaseId}`;
    else
      this.url = `${this.baseAPI}system/chronic-diseases/guests/show/${this.chronicDiseaseId}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET CHRONIC DISEASES 
   * 
   */

   async getChronicDiseases() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    if(this.authToken)
      this.url = `${this.baseAPI}system/chronic-diseases/list?${query}`;
    else
      this.url = `${this.baseAPI}system/chronic-diseases/guests/list?${query}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   *  ALLERGIES //===//
   * 
   */

  /**
   * 
   * GET ALLERGY
   * 
   */

   async getAllergy() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.allergyId){
      console.error("allergyId is not provided");
      return null;
    }

    if(this.authToken)
      this.url = `${this.baseAPI}system/allergies/show/${this.allergyId}`;
    else
      this.url = `${this.baseAPI}system/allergies/guests/show/${this.allergyId}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET ALLERGIES 
   * 
   */

   async getAllergies() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    if(this.authToken)
      this.url = `${this.baseAPI}system/allergies/list?${query}`;
    else
      this.url = `${this.baseAPI}system/allergies/guests/list?${query}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   *  MEDICAL REPORTS CATEGORIES //===//
   * 
   */

  /**
   * 
   * GET MEDICAL REPORTS CATEGORY
   * 
   */

   async getMedicalReportsCategory() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.medicalReportsCategoryId){
      console.error("medicalReportsCategoryId is not provided");
      return null;
    }

    if(this.authToken)
      this.url = `${this.baseAPI}system/medical-reports-categories/show/${this.medicalReportsCategoryId}`;
    else
      this.url = `${this.baseAPI}system/medical-reports-categories/guests/show/${this.medicalReportsCategoryId}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET MEDICAL REPORTS CATEGORIES 
   * 
   */

   async getMedicalReportsCategories() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    if(this.authToken)
      this.url = `${this.baseAPI}system/medical-reports-categories/list?${query}`;
    else
      this.url = `${this.baseAPI}system/medical-reports-categories/guests/list?${query}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * SERVICES //===//
   * 
   */

  /**
   * 
   * GET SERVICES
   * 
   */

  async getServices() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';
    
    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;
    if(this.active) query += '&active=' + this.active;
    if(this.activeCategories) query += '&activeCategories=' + this.activeCategories;

    if(this.authToken)
      this.url = `${this.baseAPI}system/services/list/?${query}`;
    else
      this.url = `${this.baseAPI}system/services/guests/list/?${query}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET SERVICE
   * 
   */

  async getService() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.serviceId) {
      console.error('Missing serviceId');
      return null;
    }

    if(this.authToken)
      this.url = `${this.baseAPI}system/services/show/${this.serviceId}`;
    else
      this.url = `${this.baseAPI}system/services/guests/show/${this.serviceId}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * ADD SERVICE
   * 
   */

  async addService() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.categoryId) {
      console.error('categoryId is required');
      return null;
    }

    if(!this.title) {
      console.error('title is required');
      return null;
    }

    if(!this.titleLocal) {
      console.error('titleLocal is required');
      return null;
    }

    if(!this.descriptionLocal) {
      console.error('descriptionLocal is required');
      return null;
    }

    if(!this.cost) {
      console.error('cost is required');
      return null;
    }

    this.body = {}

    if(this.categoryId) {
      this.body = {
        ...this.body,
        category_id: this.categoryId
      }
    }

    if(this.title) {
      this.body = {
        ...this.body,
        title: this.title
      }
    }

    if(this.titleLocal) {
      this.body = {
        ...this.body,
        title_local: this.titleLocal
      }
    }

    if(this.descriptionLocal) {
      this.body = {
        ...this.body,
        description_local: this.descriptionLocal
      }
    }

    if(this.cost) {
      this.body = {
        ...this.body,
        cost: this.cost
      }
    }

    if(this.active) {
      this.body = {
        ...this.body,
        active: this.active
      }
    }

    if(this.listingOrder) {
      this.body = {
        ...this.body,
        listing_order: this.listingOrder
      }
    }

    this.url = `${this.baseAPI}system/services/`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * UPDATE SERVICE
   * 
   */

  async updateService() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.serviceId) {
      console.error('serviceId is required');
      return null; 
    }

    this.body = {}

    if(this.categoryId) {
      this.body = {
        ...this.body,
        category_id: this.categoryId
      }
    }

    if(this.title) {
      this.body = {
        ...this.body,
        title: this.title
      }
    }

    if(this.titleLocal) {
      this.body = {
        ...this.body,
        title_local: this.titleLocal
      }
    }

    if(this.descriptionLocal) {
      this.body = {
        ...this.body,
        description_local: this.descriptionLocal
      }
    }

    if(this.cost) {
      this.body = {
        ...this.body,
        cost: this.cost
      }
    }

    if(this.active) {
      this.body = {
        ...this.body,
        active: this.active
      }
    }

    if(this.listingOrder) {
      this.body = {
        ...this.body,
        listing_order: this.listingOrder
      }
    }

    this.url = `${this.baseAPI}system/services/${this.serviceId}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * DELETE SERVICE
   * 
   */

  async deleteServices() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.serviceId) {
      console.error('Missing serviceId');
      return null;
    }

    this.url = `${this.baseAPI}system/services/${this.serviceId}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * SERVICES CATEGORIES //===//
   * 
   */

  /**
   * 
   * GET SERVICES CATEGORIES
   * 
   */

  async getServicesCategories() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';
    
    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.active) query += '&active=' + this.active;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    if(this.authToken)
      this.url = `${this.baseAPI}system/services-categories/list/?${query}`;
    else
      this.url = `${this.baseAPI}system/services-categories/guests/list/?${query}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET SERVICE CATEGORY
   * 
   */

  async getServiceCategory() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.categoryId) {
      console.error('Missing categoryId');
      return null;
    }

    if(this.authToken)
      this.url = `${this.baseAPI}system/services-categories/show/${this.categoryId}`;
    else
      this.url = `${this.baseAPI}system/services-categories/guests/show/${this.categoryId}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * ADD SERVICE CATEGORY
   * 
   */

  async addServiceCategory() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.body) {
      if(!this.title) {
        console.error('title is required');
        return null;
      }
  
      if(!this.local) {
        console.error('local is required');
        return null;
      }
  
      if(!this.colorCode) {
        console.error('colorCode is required');
        return null;
      }
  
      this.body = {}
  
      if(this.title) {
        this.body = {
          ...this.body,
          title: this.title
        }
      }
  
      if(this.local) {
        this.body = {
          ...this.body,
          local: this.local
        }
      }
  
      if(this.colorCode) {
        this.body = {
          ...this.body,
          color_code: this.colorCode
        }
      }

      if(this.active) {
        this.body = {
          ...this.body,
          active: this.active
        }
      }
  
      if(this.listingOrder) {
        this.body = {
          ...this.body,
          listing_order: this.listingOrder
        }
      }
    }

    this.url = `${this.baseAPI}system/services-categories/`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * UPDATE SERVICE CATEGORY
   * 
   */

   async updateServiceCategory() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.categoryId) {
      console.error('categoryId is required');
      return null; 
    }

    if(this.active) {
      this.body = {
        ...this.body,
        active: this.active
      }
    }

    if(this.listingOrder) {
      this.body = {
        ...this.body,
        listing_order: this.listingOrder
      }
    }

    this.url = `${this.baseAPI}system/services-categories/${this.categoryId}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * DELETE SERVICE CATEGORY
   * 
   */

  async deleteServiceCategory() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.categoryId) {
      console.error('Missing categoryId');
      return null;
    }

    this.url = `${this.baseAPI}system/services-categories/${this.categoryId}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * NATIONALITIES //===//
   * 
   */

  /**
   * 
   * GET NATIONALITIES
   * 
   */

  async getNationalities() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    if(this.showDeleted) query += '&showDeleted=' + this.showDeleted;

    if(this.authToken)
      this.url = `${this.baseAPI}system/nationalities/list/?${query}`;
    else
      this.url = `${this.baseAPI}system/nationalities/guests/list/?${query}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET NATIONALITY
   * 
   */

  async getNationality() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.nationalityId) {
      console.error('Missing nationalityId');
      return null;
    }

    if(this.authToken)
      this.url = `${this.baseAPI}system/nationalities/show/${this.nationalityId}`;
    else
      this.url = `${this.baseAPI}system/nationalities/guests/show/${this.nationalityId}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * ADD NATIONALITY
   * 
   */

  async addNationality() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.label) {
      console.error('label is required');
      return null;
    }

    if(!this.local) {
      console.error('local is required');
      return null;
    }
    
    this.body = {}

    if(this.nationalityId) {
      this.body = {
        ...this.body,
        nationalityId: this.nationalityId
      }
    }

    if(this.label) {
      this.body = {
        ...this.body,
        label: this.label
      }
    }

    if(this.local) {
      this.body = {
        ...this.body,
        local: this.local
      }
    }

    this.url = `${this.baseAPI}system/nationalities/`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * UPDATE NATIONALITY
   * 
   */

  async updateNationality() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.nationalityId) {
      console.error('nationalityId is required');
      return null; 
    }

    this.body = {}

    if(this.label) {
      this.body = {
        ...this.body,
        label: this.label
      }
    }

    if(this.local) {
      this.body = {
        ...this.body,
        local: this.local
      }
    }

    this.url = `${this.baseAPI}system/nationalities/${this.nationalityId}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * DELETE NATIONALITY
   * 
   */

  async deleteNationality() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.nationalityId) {
      console.error('Missing nationalityId');
      return null;
    }

    this.url = `${this.baseAPI}system/nationalities/${this.nationalityId}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * ADDITIONAL CHARGES //===//
   * 
   */

  /**
   * 
   * GET ADDITIONAL CHARGES
   * 
   */

  async getAdditionalCharges() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';

    if(this.active) query += '&active=' + this.active;

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    if(this.showDeleted) query += '&showDeleted=' + this.showDeleted;

    if(this.authToken)
      this.url = `${this.baseAPI}system/additional-charges/list/?${query}`;
    else
      this.url = `${this.baseAPI}system/additional-charges/guests/list/?${query}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET ADDITIONAL CHARGES
   * 
   */

  async getAdditionalCharge() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.additionalChargeId) {
      console.error('Missing additionalChargeId');
      return null;
    }

    if(this.authToken)
      this.url = `${this.baseAPI}system/additional-charges/show/${this.additionalChargeId}`;
    else
      this.url = `${this.baseAPI}system/additional-charges/guests/show/${this.additionalChargeId}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * ADD ADDITIONAL CHARGES
   * 
   */

  async addAdditionalCharge() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.description) {
      console.error('description is required');
      return null;
    }

    if(!this.price) {
      console.error('price is required');
      return null;
    }
    
    this.body = {}

    if(this.description) {
      this.body = {
        ...this.body,
        description: this.description
      }
    }

    if(this.price) {
      this.body = {
        ...this.body,
        price: this.price
      }
    }

    if(this.active) {
      this.body = {
        ...this.body,
        active: this.active
      }
    }

    this.url = `${this.baseAPI}system/additional-charges/`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * UPDATE ADDITIONAL CHARGES
   * 
   */

  async updateAdditionalCharge() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.additionalChargeId) {
      console.error('additionalChargeId is required');
      return null; 
    }

    this.body = {}

    if(this.description) {
      this.body = {
        ...this.body,
        description: this.description
      }
    }

    if(this.price) {
      this.body = {
        ...this.body,
        price: this.price
      }
    }

    if(this.active) {
      this.body = {
        ...this.body,
        active: this.active
      }
    }

    this.url = `${this.baseAPI}system/additional-charges/${this.additionalChargeId}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * DELETE ADDITIONAL CHARGES
   * 
   */

  async deleteAdditionalCharge() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.additionalChargeId) {
      console.error('Missing additionalChargeId');
      return null;
    }

    this.url = `${this.baseAPI}system/additional-charges/${this.additionalChargeId}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * SYSTEM STATS //==//
   * 
   */

  /**
   * 
   * GET CAREGIVER STATS
   * 
   */

   async getCaregiverStats() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.caregiverUuid) {
      console.error('caregiverUuid is required');
      return null; 
    }

    this.url = `${this.baseAPI}system/statistics/caregiver-stats/${this.caregiverUuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET TOP REQUESTED SERVICES STATS
   * 
   */

   async getTopRequestedServicesrStats() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.numberOfResults) query += '&numberOfResults=' + this.numberOfResults;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    this.url = `${this.baseAPI}system/statistics/top-requested-services?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET SYSTEM STATS
   * 
   */

   async getSystemStats() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    this.url = `${this.baseAPI}system/statistics/system-stats`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET TOP ASSIGNED CAREGIVERS
   * 
   */

  async getTopAssignedCaregivers() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    var query = '';

    if(this.numberOfResults) query += '&numberOfResults=' + this.numberOfResults;
    if(this.orderByColumn) query += '&orderByColumn=' + this.orderByColumn;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    this.url = `${this.baseAPI}system/statistics/top-assigned-caregivers?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET MAP PINS 
   * 
   */

  async getMapPins() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.serviceCityId) {
      console.error('serviceCityId is required');
      return null; 
    }

    var query = '';

    if(this.type) {
      query+= `type=${this.type}`;
    }

    this.url = `${this.baseAPI}system/statistics/map-pins/${this.serviceCityId}/?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * BANNERS //===//
   * 
   */

  /**
   * 
   * GET BANNERS
   * 
   */

   async getBanners() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    var query = '';

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    if(this.authToken)
      this.url = `${this.baseAPI}system/banners/list/?${query}`;
    else
      this.url = `${this.baseAPI}system/banners/guests/list/?${query}`;

    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * DELETE BANNER
   * 
   */

  async deleteBanner() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.bannerId) {
      console.error('Missing bannerId');
      return null;
    }

    this.url = `${this.baseAPI}system/banners/${this.bannerId}`;
    this.method = 'DELETE';

    return await this.request();
  }

  /**
   * 
   * ADD BANNER
   * 
   */

  async addBanner() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    this.method = 'POST';
    this.url = `${this.baseAPI}system/banners/`;

    return await this.request();
  }


  /**
   * 
   * ACTIVITIES //===//
   * 
   */

  /**
   * 
   * LIST ACTIVITIES
   * 
   */

  async getActivities() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    var query = '';

    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    this.url = `${this.baseAPI}system/activities/list/?${query}`;
    this.method = 'GET';

    return await this.request();
  }
  
  /**
   * 
   * MANAGEMANT //===//
   * 
   */

  /**
   * 
   * GET MANAGERS
   * 
   */

   async getManagers() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    var query = '';

    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;

    if(this.isAdmin) query += '&isAdmin=' + this.isAdmin;
    if(this.isBlocked) query += '&isBlocked=' + this.isBlocked;

    if(this.searchTerm) query += '&searchTerm=' + this.searchTerm;

    this.method = 'GET';
    this.url = `${this.baseAPI}users/management/list/?${query}`;

    return await this.request();
  }

  /**
   * 
   * GET MANAGER
   * 
   */

  async getManager() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.managementUuid) {
      console.error('managerUuid is required');
      return null;
    }

    this.method = 'GET';
    this.url = `${this.baseAPI}users/management/show/${this.managementUuid}`;

    return await this.request();
  }

  /**
   * 
   * ADD MANAGER
   * 
   */

  async addManager() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    var firstName = this.first_name || null;
    var lastName = this.last_name || null;
    var email = this.email || null;
    var phone = this.phone || null;
    var password = this.password || null;
    var countryCode = this.country_code || null ;
    var isBlocked = this.is_blocked || null;
    var isAdmin = this.is_admin || null;
    var canAccessBookings = this.can_access_bookings || null
    var canAccessCaregivers = this.can_access_caregivers || null
    var canAccessNotifications = this.can_access_notifications || null
    var canAccessPayments = this.can_access_payments || null
    var canAccessServiceRequesters = this.can_access_service_requesters || null
    var canAccessServices = this.can_access_services || null
    var canAccessStaff = this.can_access_staff || null

    this.body = {};

    if(firstName) {
      this.body = {
        ...this.body,
        first_name: firstName
      }
    }

    if(lastName) {
      this.body = {
        ...this.body,
        last_name: lastName
      }
    }

    if(email) {
      this.body = {
        ...this.body,
        email: email
      }
    }

    if(phone) {
      this.body = {
        ...this.body,
        phone: phone
      }
    }

    if(password) {
      this.body = {
        ...this.body,
        password: password
      }
    }

    if(countryCode) {
      this.body = {
        ...this.body,
        country_code: countryCode
      }
    }

    if(isBlocked) {
      this.body = {
        ...this.body,
        is_blocked: isBlocked
      }
    }

    if(isAdmin) {
      this.body = {
        ...this.body,
        is_admin: isAdmin
      }
    }

    if(canAccessBookings) {
      this.body = {
        ...this.body,
        can_access_bookings: canAccessBookings
      }
    }

    if(canAccessCaregivers) {
      this.body = {
        ...this.body,
        can_access_caregivers: canAccessCaregivers
      }
    }

    if(canAccessNotifications) {
      this.body = {
        ...this.body,
        can_access_notifications: canAccessNotifications
      }
    }

    if(canAccessPayments) {
      this.body = {
        ...this.body,
        can_access_payments: canAccessPayments
      }
    }

    if(canAccessServiceRequesters) {
      this.body = {
        ...this.body,
        can_access_service_requests: canAccessServiceRequesters
      }
    }

    if(canAccessServices) {
      this.body = {
        ...this.body,
        can_access_services: canAccessServices
      }
    }

    if(canAccessStaff) {
      this.body = {
        ...this.body,
        can_access_staff: canAccessStaff
      }
    }

    this.method = 'POST';
    this.url = `${this.baseAPI}users/management`;

    return await this.request();
  }

  /**
   * 
   * DELETE MANAGER
   * 
   */

  async deleteManager() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.managementUuid) {
      console.error('managerUuid is required');
      return null;
    }

    this.method = 'DELETE';
    this.url = `${this.baseAPI}users/management/${this.managementUuid}`;

    return await this.request();
  }

  /**
   * 
   * UPDATE MANAGER
   * 
   */

  async updateManager() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.managementUuid) {
      console.error('managementUuid is required');
      return null;
    }

    var firstName = this.first_name || null;
    var lastName = this.last_name || null;
    var email = this.email || null;
    var phone = this.phone || null;
    var password = this.password || null;
    var isBlocked = this.is_blocked || null;

    this.body = {};

    if(firstName) {
      this.body = {
        ...this.body,
        first_name: firstName
      }
    }

    if(lastName) {
      this.body = {
        ...this.body,
        last_name: lastName
      }
    }

    if(email) {
      this.body = {
        ...this.body,
        email: email
      }
    }

    if(phone) {
      this.body = {
        ...this.body,
        phone: phone
      }
    }

    if(password) {
      this.body = {
        ...this.body,
        password: password
      }
    }

    if(isBlocked) {
      this.body = {
        ...this.body,
        is_blocked: isBlocked
      }
    }

    this.method = 'PUT';
    this.url = `${this.baseAPI}users/management/${this.managementUuid}`;

    return await this.request();
  }

  /**
   * 
   * SET MANAGER PERMISSIONS
   * 
   */

   async setManagerPermissions() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.permission) {
      console.error('permission is required');
      return null; 
    }

    this.body = {
      permission: this.permission,
      management_uuid: this.management_uuid,
    }

    this.url = `${this.baseAPI}users/management/set`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * UNSET MANAGER PERMISSIONS
   * 
   */

  async unsetManagerPermissions() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null; 
    }

    if(!this.permission) {
      console.error('permission is required');
      return null; 
    }

    this.body = {
      permission: this.permission,
      management_uuid: this.management_uuid,
    }

    this.url = `${this.baseAPI}users/management/unset`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * PAYMENTS / TRANSACTIONS //===//
   * 
   */

  /**
   * 
   * LIST PAYMENTS / TRANSACTIONS
   * 
   */

  async getTransactions() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    var query = '';

    if(this.bookingUuid) query += '&bookingUuid=' + this.bookingUuid;
    if(this.purpose) query += '&purpose=' + this.purpose;
    if(this.purposeId) query += '&purposeId=' + this.purposeId;

    if(this.showAll) query += '&showAll=' + this.showAll;
    if(this.resPerPage) query += '&resPerPage=' + this.resPerPage;
    if(this.currentPage) query += '&page=' + this.currentPage;
    if(this.orderBy) query += '&orderBy=' + this.orderBy;
    if(this.orderDirection) query += '&orderDirection=' + this.orderDirection;

    this.url = `${this.baseAPI}system/payments/list/?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * GET PAYMENT / TRANSACTION
   * 
   */

  async getTransaction() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.transactionUuid) {
      console.error('transactionUuid is required');
      return null;
    }console.log(this.transactionUuid)

    this.url = `${this.baseAPI}system/payments/show/${this.transactionUuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * UPDATE PAYMENTS / TRANSACTIONS
   * 
   */

  async updateTransaction() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.transactionUuid) {
      console.error('transactionUuid is required');
      return null;
    }

    this.body = {}

    if(this.refundAt) this.body = {
      ...this.body,
      refund_at: this.refundAt 
    } 

    if(this.refundNotes) this.body = {
      ...this.body,
      refund_notes: this.refundNotes 
    } 
    
    this.url = `${this.baseAPI}system/payments/${this.transactionUuid}`;
    this.method = 'PUT';

    return await this.request();
  }

  /**
   * 
   * EXPORTS //===//
   * 
   */

  /**
   * 
   * EXPORT Caregivers
   * 
   */

   async exportCaregivers() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    var query = '';

    if(this.fromDate) query += '&fromDate=' + this.fromDate;
    if(this.toDate) query += '&toDate=' + this.toDate;
    if(this.approved) query += '&approved=' + this.approved;
    if(this.isBlocked) query += '&isBlocked=' + this.isBlocked;

    this.url = `${this.baseAPI}system/exports/caregivers?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * EXPORT bookings
   * 
   */

  async exportBookings() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    var query = '';

    if(this.status) query += '&status=' + this.status;
    if(this.paidOut) query += '&paidOut=' + this.paidOut;
    if(this.fromDate) query += '&fromDate=' + this.fromDate;
    if(this.toDate) query += '&toDate=' + this.toDate;
    if(this.caregiverUuid) query += '&caregiverUuid=' + this.caregiverUuid;
    if(this.serviceRequesterUuid) query += '&serviceRequesterUuid=' + this.serviceRequesterUuid;

    this.url = `${this.baseAPI}system/exports/bookings?${query}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * NOTIFICATION //===//
   * 
   */

  /**
   * 
   * SEND NOTIFICATION
   * 
   */

  async sendNotification() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.userType) {
      console.error('usersType is required');
      return null;
    }

    if(!this.receivers) {
      console.error('receivers is required');
      return null;
    }

    this.body = {
      channel: this.channel,
      user_type: this.userType,
      receivers: this.receivers,
      subject: this.subject,
      message: this.messsage,
    }


    this.url = `${this.baseAPI}system/notifications/`;
    this.method = 'POST';

    return await this.request();
  }

  /**
   * 
   * PAYMENT METHODS //===//
   * 
   */

  /**
   * 
   * GET PAYMENT METHODS
   * 
   */

  async getPaymentMethods() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    this.url = `${this.baseAPI}users/service-requesters/payments-cards/list`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * DELETE PAYMENT METHODS
   * 
   */

  async deletePaymentMethod() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.cardUuid) {
      console.error('cardUuid is required');
      return null;
    }

    this.url = `${this.baseAPI}users/service-requesters/payments-cards/${this.cardUuid}`;
    this.method = 'GET';

    return await this.request();
  }

  /**
   * 
   * CALL RECIPIENT
   * 
   */

  async communicationCall() {
    if(!this.baseAPI) {
      console.error('baseAPI path is not set');
      return null; 
    }

    if(!this.authToken) {
      console.error('authToken is required');
      return null;
    }

    if(!this.bookingUuid) {
      console.error('bookingUuid is required');
      return null;
    }

    var query = '';

    if(this.video) {
      query += '&video=true';
    }

    this.url = `${this.baseAPI}bookings/communication/call/${this.bookingUuid}${query}`;
    this.method = 'GET';

    return await this.request();    
  }
}