import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Container,
  Message,
  Divider,
  Segment,
  Checkbox,
  Menu
} from 'semantic-ui-react';

// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import Map from '../framework/map';

import EnsanCareAPI from "../util/EnsanCareLib/EnsanCareAPI";

export default function CaregiverAccess() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const {caregiver_uuid} = useParams<{caregiver_uuid:string}>();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [caregiver, setCaregiver] = useState<any>(null);

  const countryCode = '+966';

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getCaregiver();
  }, [])

  const getCaregiver = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.caregiverUuid = caregiver_uuid;

    var res:any = await EnsanCareLib.getCaregiver();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiver()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getCaregiver()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
  

      setCaregiver(res.data); 
    }
    
    setIsLoading(false);
    return null;
  }

  const processSubmit = async (actionType:'profile'|'block'|'approve'|'cancelCounter'|'delete') => {
    if(isLoading) return true;
    setIsLoading(true);

    var onClickTryAgainAction:any = null;

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.caregiverUuid = caregiver_uuid;

    var res:any = null;

    if(actionType === 'profile'){

      EnsanCareLib.phone = caregiver.phone;
      EnsanCareLib.email = caregiver.email;
      EnsanCareLib.otp = "Skip";

      if(caregiver.password > 1){
        EnsanCareLib.password = caregiver.password
      }

      res = await EnsanCareLib.updateCaregiver();

      onClickTryAgainAction = () => processSubmit('profile');
    }

    else if( actionType === 'cancelCounter') {
      
      EnsanCareLib.cancellation_counter = 'y';

      res = await EnsanCareLib.updateCaregiver();

      onClickTryAgainAction = () => processSubmit('cancelCounter');
    }

    else if( actionType === 'block') {
      
      EnsanCareLib.is_blocked = caregiver.is_blocked === 'y' ? 'n' : 'y';
      
      res = await EnsanCareLib.updateCaregiver();

      onClickTryAgainAction = () => processSubmit('block');
    }

    else if(actionType === 'approve') {
      
      EnsanCareLib.approved_at = caregiver.approved_at !== null ? 'y' : 'n'
      
      res = await EnsanCareLib.updateCaregiver();

      onClickTryAgainAction = () => processSubmit('approve');
    }

    else if(actionType === 'delete') {

      //----
      res = await EnsanCareLib.deleteCaregiver();

      onClickTryAgainAction = () => processSubmit('delete');
    }

    if (!res) {
      if(config.dev)
        console.error('Failed to complete update settings');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack
          },
        ]
      });

    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: onClickTryAgainAction
          },
        ]
      });
    }

    else if (res.status === 'success') {
      if(actionType === 'block') {
        setCaregiver({
          ...caregiver,
          is_blocked: caregiver.is_blocked === 'y' ? 'n' : 'y'
        })
      }

      if(actionType === 'cancelCounter') {
        setCaregiver({
          ...caregiver,
          cancellation_counter: 0
        })
      }

      if(actionType === 'approve') {
        setCaregiver({
          ...caregiver,
          approved_at: 'y'
        })
      }

      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            positive: true,
            onClick: actionType === 'delete' ? () => history.push('./') : () => null
          },
        ]
      });
    }
    
    setIsLoading(false);
    return null;
  }

  const _renderSegments = () => {
    if(caregiver.approved_at) {
      return <>
        <Segment color='red'>
          <Grid>
            <Grid.Row columns={2} >
              <Grid.Column width={12} verticalAlign={'middle'}>
                <Header
                  as='h2'
                  content={t('s.serviceRequesters.cancellationCounter')}
                />
              </Grid.Column>
              <Grid.Column width={4} textAlign='right'>
                <Form.Button
                  content={`(${caregiver.cancellation_counter || 0}) ${t('g.reset')}`}
                  onClick={() => processSubmit('cancelCounter')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment color='red'>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={12}>
                <Header
                  as='h2'
                  content={t('s.profile.accessStatus')}
                />
                
                <div>
                  <div style={{marginBottom: '10px'}}>
                    {t('g.currentStatus')}: <strong>{(caregiver?.is_blocked === 'y') 
                      ? t('s.profile.accountBlocked') 
                      : t('s.profile.accountAllowedAccess') 
                    }</strong>
                  </div>
                  <div>{t('s.profile.blockUserDescription')}</div>
                </div>
              </Grid.Column>
              <Grid.Column width={4} textAlign='right'>
                <Form.Button
                  content={(caregiver?.is_blocked === 'y') ? t('g.unblock') : t('g.block')}
                  onClick={() => processSubmit('block')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment color='red'>

          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Header
                  as='h2'
                  content={`${t('g.delete')} ${t('g.account')}`}
                />
                <div>{t('s.caregivers.deleteCaregiverDescription')}</div>
              </Grid.Column>
              <Grid.Column width={4} textAlign={'right'}>
                <Button
                  content={t('g.delete')}
                  color='red'
                  onClick={() => processSubmit('delete')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>  
      </>
    }
    else {
      return <>
        <Segment color='orange'>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <Header
                  as='h2'
                  content={`${t('g.approve')} ${t('g.account')}`}
                />
              </Grid.Column>
              <Grid.Column width={4} textAlign={'right'}>
                <Button
                  content={t('g.approve')}
                  color='orange'
                  onClick={() => processSubmit('approve')}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>  
      </>
    }
  }

  if(!caregiver) return <Loader active/>

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal?.title||null}
      content={modal?.message || null}
      actions={modal?.action || ['Ok']}
    />

    <Header
      as='h2'
      content={t('s.profile.accessInformation')}
    />

    <Form>
      <Form.Group widths={2}>
        <Form.Dropdown
          width={2}
          fluid
          label={t('s.profile.countryCode')}
          selection
          value={countryCode}
          options={[
            {key: 0, value: '+966', text: '+966'}
          ]}
        />
        <Form.Input
          label={t('g.phone')}
          placeholder={t('g.typeHere')}
          value={caregiver.phone}
          onChange={(e, data) => {
            setCaregiver({
              ...caregiver,
              phone:data.value
            })
          }}
        />
      </Form.Group>

      <Form.Input
        label={t('g.email')}
        placeholder={t('g.typeHere')}
        value={caregiver.email}
        onChange={(e, data) => {
          setCaregiver({
            ...caregiver,
            email:data.value
          })
        }}
      />

      <Form.Input
        label={t('g.password')}
        placeholder={t('g.typeHere')}
        type='password'
        onChange={(e, data) => {
          setCaregiver({
            ...caregiver,
            password:data.value
          })
        }}
      />

      <Divider section />

    </Form>

    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Button
            icon='arrow left'
            labelPosition='left'
            content={t('g.cancel')}
            onClick={() => history.goBack()}
          />
        </Grid.Column>
        <Grid.Column floated="right" textAlign="right">
          <Button
            primary
            content={t('g.save')}
            onClick={() => processSubmit('profile')}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider section hidden />

    {_renderSegments()}   
  </>
}