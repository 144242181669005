import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom';

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Segment,
  Image,
  Divider,
  Dropdown
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../../framework/internal_page';

import EnsanCareAPI from "../../util/EnsanCareLib/EnsanCareAPI";
import ECGeneral from "../../util/EnsanCareLib/funcs/general"

export default function ServicesServiceForm() {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [actionRequested, setActionRequested] = useState<boolean>(true);
  const [categoryOptions, setCategoryOptions] = useState<Array<any>>([]);

  const [service, setService] = useState<any>({
    id: null,
    listing_order: null,
    active: null,
    service_category_id: null,
    title: null,
    cost: null,
    description_local:{
      en: null,
      ar: null
    },
    title_local: {
      en: null,
      ar: null
    }
  }); 

// states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const {service_id} = useParams<{service_id:string}>();

  useEffect(() => {
    if(service_id) getService();
    getServicesCategories()
  }, [])

  const getService = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.serviceId = service_id;

    var res:any = await EnsanCareLib.getService();

    if(!res) {
      if(config.dev) 
        console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => getService()
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => getService()
          },
        ]
      });
    } 
   
    else if (res.status === 'success') {
      if(res.data) {

        var tempObj = {
          id: res.data.id || null,
          listing_order: res.data.listing_order || null,
          active: res.data.active || null,
          service_category_id: res.data.service_category_id || null,
          cost: res.data.cost  || null,
          title: res.data.title  || null,
          description_local: {
            en: res.data.description_local ? ECGeneral.getFromLocal("en", res.data.description_local) : null,
            ar: res.data.description_local ? ECGeneral.getFromLocal("ar", res.data.description_local) : null
          },
          title_local: {
            en: res.data.title_local ? ECGeneral.getFromLocal("en", res.data.title_local) : null,
            ar: res.data.title_local ? ECGeneral.getFromLocal("ar", res.data.title_local) : null
          }
        }

        setService(tempObj);
      }
    }
    
    setIsLoading(false);
    return null;
  }

  const getServicesCategories = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.showAll = 'y';

    var res:any = await EnsanCareLib.getServicesCategories();

    if(!res) {
      if(config.dev) 
        console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => getServicesCategories()
          },
        ]
      });
    }
    
    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => getServicesCategories()
          },
        ]
      });
    } 
   
    else if (res.status === 'success') {

      var serviceCategoriesObj:any[any] = [];

      if(res.data) {
        res.data.map((category:any, key:Number) => {
          serviceCategoriesObj.push({
            key: key,
            value: category.id,
            text: ECGeneral.getFromLocal(i18n.language, category.local)
          })
        })
      } 
      
      else {
        serviceCategoriesObj.push({
          key: 0,
          value: 0,
          text: `${t('g.noResults')} - ${t('g.refresh')}`
        })
      }
        
      setCategoryOptions(serviceCategoriesObj);
    }
    
    setIsLoading(false);
    return null;
  }

  const processSubmit = async () => {
    if(isLoading) return true;
    setIsLoading(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.title = service.title;
    EnsanCareLib.cost = service.cost;
    EnsanCareLib.active = service.active;
    EnsanCareLib.listingOrder = service.listing_order;
    EnsanCareLib.categoryId = service.service_category_id
    EnsanCareLib.descriptionLocal = JSON.stringify(service.description_local);
    EnsanCareLib.titleLocal = JSON.stringify(service.title_local);

    var res:any;

    if (!service.id) {
      res = await EnsanCareLib.addService();
    } 
  
    else {
      EnsanCareLib.serviceId = service.id;
      res = await EnsanCareLib.updateService();
    }

    if (!res) {
      if(config.dev)
        console.error('Failed to complete update or add service');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.missingRequirements'),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => processSubmit()
          },
        ]
      });

    }

    else if (res.status === 'fail') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => processSubmit()
          },
        ]
      });
    }

    else if (res.status === 'success') {

      var btnAction;

      // modal for adding a new service
      if(!service.id && res.data.id) { 
        btnAction = () => history.push('../service/show/' + res.data.id);
      }
      
      else {
        btnAction = () => null;
      }

      // modal for updating service
      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            positive: true,
            onClick: btnAction
          },
        ]
      });
    }
    
    setIsLoading(false);
    return null;
  }

  const processDelete = async () =>{
    if(isLoading) return true;
    setIsLoading(true);
    
    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.serviceId = service.id;

    var res:any = await EnsanCareLib.deleteServices();
    
    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => processDelete()
          },
        ]
      });
    }

    else if (res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.cancel'),
            onClick: () => null
          },
          {
            content: t('g.tryAgain'),
            onClick: () => processDelete()
          },
        ]
      });
    }

    else if (res.status === "success") {
      setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.ok'),
            positive: true,
            onClick: () => history.push('../')
          },
        ]
      });
    }

    setIsLoading(false);
    return null;
  }

  const _renderDeleteBtn = () => {
    if(service_id) {
      if(actionRequested){
        return <>
          <Button
            negative
            content={t('g.delete')}
            onClick={() => setActionRequested(false)}
          />
        </>
      }
      else {
        return <>
          <Button
            color='yellow'
            content={t('g.confirm')}
            onClick={() => processDelete()}
          />
          <Button
            content={t('g.cancel')}
            onClick={() => setActionRequested(true)}
          />
        </>
      }
    }
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.action : null}
    />
    
    <Header
      as='h2'
      content={service.title || '...'}
    />

    <Form>
      <Form.Input
        label={t('g.listingOrder')}
        placeholder={0}
        value={service.listing_order || ''}
        onChange={(e, data) => setService({
          ...service,
          listing_order: data.value||''
        })}
      />

      <Form.Select
        label={t('g.active')}
        placeholder={t('g.selectOne')}
        value={service.active || null}
        onChange={(e, { value }) => setService({
          ...service,
          active: value||null
        })}
        options={[
          {key: 'n', value: 'n', text: t('g.no')},
          {key: 'y', value: 'y', text: t('g.yes')}
        ]}
      />

      <Form.Input
        label={t('g.title')}
        placeholder={service.title ? service.title : t('g.typeHere')}
        value={service.title || ''}
        onChange={(e, data) => setService({
          ...service,
          title: data.value || ''
        })}
      />

      <Form.Dropdown
        label={t('g.category')}
        selection
        fluid
        placeholder={t('g.selectOne')}
        options={categoryOptions}
        onChange={(e, data) => setService({
          ...service,
          service_category_id: data.value || null
        })}
        value={service.service_category_id}
      />

      <Form.Group widths={2}>
        <Form.Input
          label={`${t('g.title')} (EN)`}
          placeholder={service.title_local.en ? service.title_local.en : t('g.typeHere')}
          value={service.title_local.en || ''}
          onChange={(e, data) => setService({
            ...service,
            title_local: {
              ...service.title_local,
              en: data.value || ''
            }
          })}
        />
        <Form.Input
          label={`${t('g.title')} (AR)`}
          placeholder={service.title_local.ar ? service.title_local.ar : t('g.typeHere')}
          value={service.title_local.ar || ''}
          onChange={(e, data) => setService({
            ...service,
            title_local: {
              ...service.title_local,
              ar: data.value || ''
            }
          })}
        />
      </Form.Group>

      <Form.Group widths={2}>
        <Form.TextArea
          label={`${t('g.description')} (EN)`}
          placeholder={service.description_local.en ? service.description_local.en : t('g.typeHere')}
          value={service.description_local.en || ''}
          onChange={(e, data) => setService({
            ...service,
            description_local: {
              ...service.description_local,
              en: data.value || ''
            }
          })}
        />
        <Form.TextArea
          label={`${t('g.description')} (AR)`}
          placeholder={service.description_local.ar ? service.description_local.ar : t('g.typeHere')}
          value={service.description_local.ar || ''}
          onChange={(e, data) => setService({
            ...service,
            description_local: {
              ...service.description_local,
              ar: data.value || ''
            }
          })}
        />
      </Form.Group>

      <Form.Input
        label={`${t('g.cost')} (${t('c.SR')})`}
        placeholder={service.cost ? service.cost : "0.00"}
        value={service.cost || ''}
        onChange={(e, data) => setService({
          ...service,
          cost: data.value || ''
        })}
      />

    </Form>

    <Divider hidden />

    <Grid stackable>
      <Grid.Row columns={2}>
        <Grid.Column textAlign="left">
          <Button
            icon='arrow left'
            labelPosition='left'
            content={t('g.cancel')}
            onClick={() => service_id ? history.push('../../') : history.push('../')}
          />
          {_renderDeleteBtn()}
        </Grid.Column>
        <Grid.Column floated="right" textAlign="right">
          <Button
            primary
            content={t('g.save')}
            onClick={() => processSubmit()}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </InternalPage>
}