import { useEffect, useState } from 'react';
import { useHistory } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Segment,
  Image,
  Menu,
  Dropdown,
  Icon
} from 'semantic-ui-react';

// import SVG
import Logo from '../assets/logo-icon.png';

// import app config
import { config } from '../../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from '../../framework/internal_page';

import EnsanCareAPI from "../../util/EnsanCareLib/EnsanCareAPI";
import moment from 'moment';

const BannerCard = (props:any) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const [isProcessing, setIsProcessing] = useState<boolean>(false); 

  const [isDeleted, setIsDeleted] = useState<boolean>(false); 

  const [actionReqeusted, setActionRequested] = useState<boolean>(true);

  const _handleUpdateRequest = async (action:'delete', banner_id:number) => {
    if(isProcessing) return null;
    setIsProcessing(true);

    if(action === 'delete') {
      var EnsanCareLib:any = new EnsanCareAPI;
      EnsanCareLib.authToken = localStorage.getItem("auth_token");
      EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
      EnsanCareLib.bannerId = banner_id;

      var res:any = await EnsanCareLib.deleteBanner();

      if(!res) {
        if(config.dev) console.error('Error with the response');
      }
      else if (res.status === "fail") {
        setModal({
          visible: true,
          title: t('g.failedToProcessRequest'),
          message: t('g.failedToProcessRequestMessage'),
          action: [
            {
              content: t('g.goBack'),
              onClick: () => history.goBack()
            },
          ]
        });
      }
      else if (res.status === "success") {
        if(action === 'delete') {
          setIsDeleted(true);
        }
        else {
          if(config.dev) console.warn('Provided action is not supported');
        }
      }
      return null;
    }
  }

  const _renderBtns = () => {
    if(actionReqeusted) {
      return <>
        <Button
          size='tiny'
          color='red'
          icon='trash'
          labelPosition='left'
          content={t('g.delete')}
          onClick={() => {
            setActionRequested(false);
          }}
        />
      </>
    }
    else {
      return <>
        <Button
          color='yellow'
          size={'tiny'}
          content={t('g.confirm')}
          onClick={() => {
            _handleUpdateRequest("delete", props.id)
          }}
        />
        <Button
          size={'tiny'}
          content={t('g.cancel')}
          onClick={() => setActionRequested(true)}
        />
      </>
    } 
  }

  if (isDeleted) return null;

  return (
    <Grid.Column>
    <Segment>
      <Image
        src={props.imageSrc}
        fluid
      />

      <div style={{marginTop: 20}}>
        <div style={{marginBottom: 10}}>
          Created: <strong>{moment(props.createdAt).format(config.momentDateTimeFormat)}</strong>
        </div>
        {_renderBtns()}
      </div>
    </Segment>
  </Grid.Column>
  )
}

const BannersList = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false); 

  const [isProcessingImage, setIsProcessingImage] = useState<boolean>(false);

  const [banners, setBanneres] = useState<any>(null); 

  const [images, setImages] = useState<any[]>();
  const [selectedImage, setSelectedImage] = useState<any>(null);

  const [selectedResPerPage, setSelectedResPerPage] = useState<number>(25);
  const [selectedPageNumber, setSelectedPageNumber] = useState<number>(1);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getBanners();
  }, [])

  useEffect(() => {
    getBanners();
  }, [
    selectedResPerPage,
    selectedPageNumber,
    images
  ]);

  useEffect(() => { 
    uploadImage() 
  }, [selectedImage]);

  const getBanners = async (page:number = 1) => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.resPerPage = selectedResPerPage;
    EnsanCareLib.currentPage = page||selectedPageNumber;

    var res:any = await EnsanCareLib.getBanners();

    if(!res) {
      if(config.dev) console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getBanners()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getBanners()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
   
     
      setBanneres(res.data);
    }
    
    setIsLoading(false);
    return null;
  }

  const uploadImage = async () => {
    // > Validate image uploading
    if(isProcessingImage || !selectedImage) return null;

    setIsProcessingImage(true);

    const data:any = new FormData();
    data.append("image", selectedImage);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.baseAPI = config.dev ? config.d.API : config.p.API;
    EnsanCareLib.debug = config.dev;
    EnsanCareLib.authToken = localStorage.getItem('auth_token');
    EnsanCareLib.body = data;
    EnsanCareLib.useAppend = true;
    
    var res:any = await EnsanCareLib.addBanner();
    setIsProcessingImage(false);

    if(!res || res.status !== 'success') {
      if(config.dev) console.warn('uploadImage error', res);

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: EnsanCareLib.getErrorsString(),
        action: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });
    }
    else {
      var updatedImages = images || [];

      setImages([...updatedImages, res.data]);
    }

    return null;
  }

  const _renderLoadingCard = () => {
    return <Grid.Column verticalAlign='middle' textAlign='center'>
    <Loader active/>
  </Grid.Column>
  }

  const _renderCards = () => {
    var assetsUris=require("../../remote_assets_uri.json");
    var host=(config.dev) ? config.d.API : config.p.API;

    if(!banners || banners.length < 1) { 
      return <Table.Row disabled>
        <Table.Cell>
          {t('g.noResults')}
        </Table.Cell>
      </Table.Row>
    };

    return banners.map( (banner:any, key:number) => {
      return <BannerCard 
        id={banner.id}
        imageSrc={host+assetsUris.banners_images+banner.id+".png"}
        createdAt={banner.created_at}
      />
    })
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal?.title||null}
      content={modal?.message||null}
      actions={modal?.action||['Ok']}
    />
    
    <Header
      as='h1'
      content={t('s.banners.title')}
    />

    <Grid columns={4}>
      <Grid.Column verticalAlign='middle' textAlign='center'>
        <Input
          type='file'
          accept='image/*'
          onChange={(event, e) => {
            if(event && event.target && event.target.files) {
              var file:any = event.target.files[0];
              setSelectedImage(file);
            }
          }} 
          style={{display: 'none'}}
          id='image-upload-selector'
        />
        <Button
          type='submit'
          icon='upload'
          content={t('g.upload')}
          labelPosition='left'
          loading={isProcessingImage}
          disabled={isProcessingImage}
          onClick={() => {
            document.getElementById('image-upload-selector')?.click()
          }}
        />
      </Grid.Column>
      {_renderCards()}
      {isLoading && _renderLoadingCard()}
    </Grid>
    <Grid verticalAlign='middle'>
      <Grid.Row columns={3}>
        <Grid.Column>
          {t('g.resPerPage')}
          <Dropdown
            style={{
              marginLeft: '10px'
            }}
            inline
            options={[
              {key: 0, value: 25, text: "25"},
              {key: 1, value: 50, text: "50"},
              {key: 2, value: 100, text: "100"},
              {key: 3, value: 500, text: "500"},
            ]}
            defaultValue={selectedResPerPage}
            onChange={(e, data) => {

              if(typeof data.value === 'number'){
                return setSelectedResPerPage(data.value); 
              }

              if(typeof data.value === 'string' && /^[0-9]/.test(data.value)){
                return setSelectedResPerPage(parseInt(data.value));
              }
              
              if(config.dev) console.warn("result per page type is not valid", typeof data.value)
              return null;
            }}
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Menu floated='right' pagination size='mini'>
            <Menu.Item 
              as='a' 
              icon 
              onClick={() => {
                if(selectedPageNumber <= 1) {
                  setSelectedPageNumber(selectedPageNumber)
                  return null;
                }

                setSelectedPageNumber(selectedPageNumber-1)
                getBanners(selectedPageNumber-1);
              }}
              >
              <Icon name='chevron left'/>
            </Menu.Item>
            <Menu.Item>
              <Input 
                value={selectedPageNumber}
                onChange={(e,data) => {
                  if(parseInt(data.value) < 1) return null;
                  if(/^[0-9]/.test(data.value))
                    setSelectedPageNumber(parseInt(data.value));
                }}
              />
              <Button 
                style={{
                  marginLeft: '10px'
                }}
                color='blue'
                onClick={() => {
                  var error = false;

                  if(typeof selectedPageNumber !== 'number') {
                    error = true;
                    if(config.dev) console.warn('Provided page number is not a number', selectedPageNumber);
                  }

                  if(selectedPageNumber < 1) {
                    error = true;
                    if(config.dev) console.warn('Provided page number is less than 1', selectedPageNumber);
                  }

                  if(error) {
                    setModal({
                      visible: true,
                      title: t('g.error'),
                      message: t('g.invalidInput'),
                      action: [
                        {
                          content: t('g.goBack'),
                          onClick: () => history.goBack()
                        },
                      ]
                    });

                    return null;
                  }

                  setSelectedPageNumber(selectedPageNumber)
                  getBanners(selectedPageNumber);
                }}
              >
                {t('g.go')}
              </Button>
            </Menu.Item>
            <Menu.Item 
              as='a' 
              icon
              onClick={() => {
                setSelectedPageNumber(selectedPageNumber+1)
                getBanners(selectedPageNumber+1);
              }}
              >
              <Icon name='chevron right' />
            </Menu.Item>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </InternalPage>
}

export default BannersList;