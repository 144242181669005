import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Image,
  Dropdown,
  Divider,
  Menu,
  ButtonGroup
} from 'semantic-ui-react';

// import SVG
import Logo from '../../assets/logo-icon.png';

// import app config
import { config } from '../../config';

import EnsanCareAPI from "../../util/EnsanCareLib/EnsanCareAPI";
import ECGeneral from '../../util/EnsanCareLib/funcs/general';

// translation
import { useTranslation } from "react-i18next";
import moment from 'moment';

import remoteAssetsUrl from '../../remote_assets_uri.json';

export default function BookingSessionMedicalReport() {
  const host = config.dev ? config.d.API : config.p.API;
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { booking_uuid, session_id } = useParams<any>();

  const [medicalReport, setMedicalReport] = useState<any>(null); 
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // states
  interface modalType {
    visible: boolean,
    title: string,
    message: string,
    actions: any
  }

  const [modal, setModal] = useState<modalType|null>(null);

  useEffect(() => {
    getBookingSessionMedicalReports()
  }, []);

  const getBookingSessionMedicalReports = async () => {
    if(isLoading) return null;
    setIsLoading(true);

    var EnsanCareLib:any = new EnsanCareAPI;
    EnsanCareLib.authToken = localStorage.getItem("auth_token");
    EnsanCareLib.baseAPI = (config.dev) ? config.d.API : config.p.API;
    EnsanCareLib.lang = i18n.language;
    EnsanCareLib.showAll = 'y';
    EnsanCareLib.sessionId = session_id

    var res:any = await EnsanCareLib.getBookingSessionMedicalReports();
    if(!res || !res.data) {
      if(config.dev) console.error('Error with the response');
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        actions: [
          {
            content: t('g.goBack'),
            onClick: () => history.goBack()
          },
        ]
      });
    } 

    else {
      if(config.dev) console.warn('no results found');
     
      setMedicalReport(res.data);
    }
    
    setIsLoading(false);
    return null;
  }

  const deleteMedicalReport = async (uuid:string) => {}

  const _renderLoadingRow = () => {
    if(isLoading) return <Table.Row>
      <Table.Cell colSpan={7} verticalAlign='middle'>
        <Loader active inline size='mini' />
      </Table.Cell>
    </Table.Row>
  }

  const _renderRows = () => {
    if(!medicalReport || medicalReport.length < 1) return null;
    
    return medicalReport.map( (mr:any, key:number) => {
      return <Table.Row>
        <Table.Cell>{mr.uuid}</Table.Cell>
        <Table.Cell>
          {ECGeneral.getFromLocal(
            i18n.language,
            mr.medical_report_category_local,
            mr.medical_report_category_title
          )}
        </Table.Cell>
        <Table.Cell>{mr.ext}</Table.Cell>
        <Table.Cell>{mr.size}</Table.Cell>
        <Table.Cell>{moment(mr.size).format(config.momentDateTimeFormat)}</Table.Cell>
        <Table.Cell textAlign='right'>
          <Button
            size='tiny'
            content={t('g.view')}
            onClick={() => window.open(
              `${host}${remoteAssetsUrl.bookings_documents}${booking_uuid}/sessions/${session_id}/${mr.uuid}.${mr.ext}`, 
              '_blank'
            )}
          />
          <Button
            size='tiny'
            color='red'
            icon='trash'
            onClick={() => setModal({
              visible: true,
              title: 'title',
              message: 'message',
              actions: [
                {
                  content: t('g.cancel'),
                  onClick: () => null
                },
                {
                  negative: true,
                  content: t('g.confirm'),
                  onClick: () => deleteMedicalReport(mr.uuid)
                }
              ]
            })}
          />
        </Table.Cell>
      </Table.Row>
    })
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : null}
    />

    <Header
      as='h2'
      content={t('s.bookings.sessions.medicalReport.title')}
    />

    <Table stackable striped color='blue'>
      <Table.Header>
        <Table.HeaderCell width={2}>{t('g.uuid')}</Table.HeaderCell>
        <Table.HeaderCell width={6}>{t('g.category')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.fileExtension')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.size')} ({t('u.mb')})</Table.HeaderCell>
        <Table.HeaderCell>{t('g.createdAt')}</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderRows()}
        {_renderLoadingRow()}
      </Table.Body>
    </Table>
  </>
}